import { AccountIdentificationDetails } from "@app/account/_models";
import { AccountRegistration } from "@app/registration/_models/account-registration";

export class ConfirmAccountDetails {
    public account: AccountRegistration
    public identification: AccountIdentificationDetails

    constructor() {
        this.account = new AccountRegistration();
        this.identification = new AccountIdentificationDetails();
    }
}