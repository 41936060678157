<div class="vehicle-details-view-container">
  <!--Apply teaser, this message should only show on dashboard If the user has met all necessary registration steps, ID verification, addition of address, register as pending liable user-->
  <div *ngIf="isEligibleForTeaser 
    && !hasLiableUser 
    && !showRegisterPendingLiableUser 
    && !showPendingApproval 
    && !showAddIdentification 
    && !showAddAddress  
    && !showRetryPersonalDetails 
    && !showRetryPoorDocuments 
    && !identityIsBlocked
    && allowBuyNow
    && completeRegistrationForTeaserMessage" class="alert alert-info alert-dismissible"
    data-test-id="vehicle-details-info-teaser-eligible">
    <div>
      <span>
        <b>
          <a (click)="submitProductCheckout()" data-test-id="vehicle-details-info-teaser-eligible-link">
            {{'Vehicle_Claim_Teaser_Plan' | translate }}
          </a>
        </b>
      </span>
    </div>
  </div>

  <!-- This message is for vehicles that are eligible to inform them to complete registration steps to claim teaser -->
  <div *ngIf="!completedMessage 
    && isBrandEligible 
    && isEligibleForTeaser 
    && !hasLiableUser 
    && showRegisterPendingLiableUser 
    && !showPendingApproval 
    && !showAddIdentification 
    && !showAddAddress" class="alert alert-success alert-dismissible"
    data-test-id="vehicle-details-info-teaser-eligible">
    <span>
      <b>
        <a role="button" (click)="removeCompleteRegistrationMessage()"
          data-test-id="vehicle-details-info-teaser-eligible-complete-registration-link">
          {{'Vehicle_Claim_Teaser_Plan_Complete_Registration' | translate }}
        </a>
      </b>
    </span>
  </div>
  <div *ngIf="!hasLiableUser && showAddIdentification" class="alert alert-warning alert-dismissible"
    data-test-id="vehicle-details-warning-add-identification">
    <span>{{'Vehicle_Details_View_Warning_Add_Identification' | translate }}
      <b>
        <a role="button" (click)="redirectToAddIdentification()"
          data-test-id="vehicle-details-warning-add-identification-link">
          {{'Vehicle_Details_View_Warning_Add_Identification_Link' | translate }}
        </a>
      </b>
    </span>
  </div>
  <div *ngIf="!hasLiableUser && showPendingApproval && !showVideoPendingApproval"
    class="alert alert-warning alert-dismissible" data-test-id="vehicle-details-warning-identification-pending">
    <span>{{'Vehicle_Details_View_Warning_Identification_Pending' | translate }}
    </span>
  </div>
  <div *ngIf="!hasLiableUser && showPendingApproval && showVideoPendingApproval"
    class="alert alert-warning alert-dismissible" data-test-id="vehicle-details-warning-identification-video-pending">
    <span> {{'Vehicle_Details_View_Warning_Identification_Video_Pending' | translate }}
      <b>
        <a role="button" (click)="redirectToAddIdentification()"
          data-test-id="vehicle-details-warning-identification-video-pending-link">{{'Vehicle_Details_View_Warning_Identification_Video_Pending_Link'
          | translate }}</a>
      </b>
    </span>
  </div>
  <div *ngIf="!hasLiableUser 
    && !showRegisterPendingLiableUser 
    && !showPendingApproval 
    && !showAddIdentification 
    && !showAddAddress 
    && showRetryPersonalDetails 
    && !showRetryPoorDocuments" class="alert alert-warning alert-dismissible"
    data-test-id="vehicle-details-retry-identification-validation">
    <span> {{'Identification_Validation_Rejected_User_Data' | translate }}
      <b>
        <a role="button" (click)="redirectToAddIdentification()" data-test-id="vehicle-details-retry-upload-link">
          {{'Identification_Validation_Rejected_User_Data_Link' | translate }}
        </a>
      </b>
    </span>
  </div>

  <div *ngIf="!hasLiableUser 
    && !showRegisterPendingLiableUser 
    && !showPendingApproval 
    && !showAddIdentification 
    && !showAddAddress 
    && !showRetryPersonalDetails 
    && showRetryPoorDocuments" class="alert alert-warning alert-dismissible"
    data-test-id="vehicle-details-retry-identification-validation">
    <span> {{'Identification_Validation_Rejected_Document' | translate }}
      <b>
        <a role="button" (click)="redirectToAddIdentification()" data-test-id="vehicle-details-retry-upload-link">
          {{'Identification_Validation_Rejected_Document_Link' | translate }}
        </a>
      </b>
    </span>
  </div>

  <div class="row">
    <div class="col-sm-12 col-md-7 order-md-2" data-test-id="vehicle-details-view-photo">
      <img [src]='vehicle.vehiclePhotoImage' alt="car-model" class="mx-auto d-block car" *ngIf='showVehicleImage'
        (error)="vehicleImageLoadError()" />
    </div>
    <div class="col-sm-12 col-md-5">
      <div class="d-flex align-items-center" (appClickOutside)="disableFavNameEditor()">
        <app-favourite-name [show]="showFavNameEditor" [model]="vehicle.personalisedName"
          (cancelClick)="disableFavNameEditor()" (saveClick)="changePersonalisedName($event)"></app-favourite-name>
        <label class="vehicle-name d-none d-md-inline mb-0"
          data-test-id="vehicle-details-personalised-name-text">{{(vehicle.personalisedName.length>12)?
          (vehicle.personalisedName | slice:0:12)+'..':(vehicle.personalisedName)}}</label>
        <label class="vehicle-name d-sm-inline d-md-none mb-0">{{(vehicle.personalisedName.length>18)?
          (vehicle.personalisedName | slice:0:18)+'..':(vehicle.personalisedName)}}</label>
        <a class="personalised-icon" (click)="enableFavNameEditor()"
          data-test-id="vehicle-details-view-personalised-name-form-link">
          <app-icon-pencil></app-icon-pencil>
        </a>
      </div>

      <div class="vehicle-details">
        <p data-test-id="vehicle-details-view-model-text">
          <span class="sub-title">{{'Vehicle_Details_View_Model' | translate}}</span>
          {{vehicle.vehicleBrand}} {{vehicle.vehicleModel}}
        </p>
        <p data-test-id="vehicle-details-view-colour-text">
          <span class="sub-title"> {{'Vehicle_Details_View_Colour' | translate}}</span>
          {{vehicle.vehicleColour}}
        </p>
        <p data-test-id="vehicle-details-view-vin-text">
          <span class="sub-title">{{'Vehicle_Details_View_Vin' | translate}}</span>
          {{vehicle.vin}}
        </p>
      </div>
      <div class="favourite">
        <!-- Favourite Name -->
        <form [formGroup]="vehicleSetFavouriteForm" novalidate class="" data-test-id="vehicle-favourite-vehicle-form">
          <div class="form-group d-flex content-justify-between align-items-center">
            <span class="sub-title"
              data-test-id="vehicle-details-view-favourite-text">{{'Vehicle_Details_View_Favourite' | translate
              }}</span>
            <ui-switch defaultBoColor="#cccccc" defaultBgColor="#cccccc" size="medium" formControlName="isFavourite"
              [(ngModel)]="vehicle.isFavourite" (change)="favouriteVehicle($event)"
              [attr.data-test-id]="'vehicle-details-view-favourite-input-' + (vehicle.isFavourite ? 'favourite' : 'notFavourite')">
            </ui-switch>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>