<div class="margin-wrapper">
  <div class="container">
    <div class="row justify-content-center" data-test-id="home-connectivity">
      <div class="col-md-5 order-md-2">
        <img src="{{assets}}/home/connectivity.jpg{{assetVersionNumber}}" class="mx-auto d-block connectivity-img" />
      </div>
      <div class="col-md-7">
        <div class="connectivity-text">
          <h2 class="title" data-test-id="home-connectivity-title">{{'Home_Connectivity_Seamless' | translate }}</h2>
          <p data-test-id="home-connectivity-text">
            {{'Home_Connectivity_TravelingSim' | translate }}
          </p>
          <p>
            <a class="standard-link" data-test-id="home-connectivity-link"
              [routerLink]="['/faq' | localize]">{{'Home_Connectivity_Countries'
              | translate }}</a>
          </p>
          <button data-test-id="home-conynectivity-btn" class="mx-auto btn btn-primary btn-connectivity"
            [routerLink]="['/faq' | localize]">{{'Home_Connectivity_Explore' | translate }}</button>
        </div>
      </div>
    </div>
  </div>
</div>