import { Injectable } from '@angular/core';
import { PurchaseHistory } from '../_models/purchase-history';
import { ResponseError } from '@shared/models';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { BaseHttpService } from '@shared/services/base-http-service.service';
import { ConfigService } from '@shared/services/config.service';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class PurchaseHistoryService extends BaseHttpService {

  constructor(httpClient: HttpClient, configService: ConfigService, translateService: TranslateService,) {
    super(httpClient, configService, translateService);
  }

  // POST
  getSummaryPurchaseHistory(request: PurchaseHistory): Observable<PurchaseHistory | ResponseError> {
    return this.post<PurchaseHistory>('/accounts/purchaseHistory/getSummaryPurchaseHistory?', request);
  }

}
