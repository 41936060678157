<div class="purchase-history-card">
  <strong data-test-id="purchase-history-title-text" class="header">{{'Purchase_History_Title' | translate }}</strong>
  <div *ngIf="purchaseHistory?.length > 0" data-test-id="dashboard-purchase-history-withhistory">
    <div>
      <div *ngFor="let purchase of purchaseHistory">
        <div class="row justify-content-left purchase-history-delimiter">
          <div class="col-9 text-left" data-test-id="dashboard-purchase-history-date"> {{purchase.planPurchaseDate |
            date:'dd/MM/yyyy'}} </div>
          <div class="col-9 text-left">
            <b data-test-id="dashboard-purchase-history-planName">{{purchase.planName}} </b>
          </div>
          <div class="col-9 text-left">
            <span data-test-id="dashboard-purchase-history-displayPrice">{{purchase.currency}} {{purchase.totalPrice |
              number:'1.2-5'}}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="(purchaseHistory?.length > 0) === false" data-test-id="dashboard-purchase-history-no-history">
    <div class="purchase-history-delimiter">
      <span class="text-center no-purchase-history">
        {{'Purchase_History_No_Purchase_History_Text' | translate }}
      </span>
    </div>
  </div>
  <div class="card-button">
    <div class="d-flex justify-content-center">
      <a data-test-id="dashboard-purchase-history-viewmore"
        class="btn btn-primary"
        (click)="goToPurchaseHistory()">{{'View_More' | translate }}</a>
    </div>
  </div>
</div>