import { ErrorHandler, Injector, Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { ResponseError } from '@shared/models';
import { Logger } from '@shared/logging/Logger';
import { LocationStrategy, PathLocationStrategy } from '@angular/common';

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
    constructor(private injector: Injector) {
    }

    handleError(error: any) {
        const logger = this.injector.get(Logger);
        let message: string = '';
        switch (error.constructor) {
            case HttpErrorResponse:
                message = message.concat(error.message, '\nStatus code:', error.status.toString());
                break;
            case ResponseError:
                message = error.toString();
                break;
            case Error:
                message = error.message;
                break;
            default:
                message = error;
        }
        logger.error(this.buildErrorMessage(message));
    }

    private buildErrorMessage(error: string): string {
        if (!error) {
            return;
        }

        const time = new Date().toISOString();
        const location = this.injector.get(LocationStrategy);
        const url = location instanceof PathLocationStrategy ? location.path() : '';
        return '\nTime: ' + time
            + '\nURL: ' + url
            + '\nError: ' + error;
    }
}
