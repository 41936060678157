import { Component } from '@angular/core';

@Component({
  selector: 'app-forgot-password-email',
  templateUrl: './forgot-password-email.component.html',
  styleUrls: ['./forgot-password-email.component.scss']
})
export class ForgotPasswordEmailComponent {

  constructor() { }

}
