<div class="product-checkout-order-card">
  <div class="product-checkout-order-card-container">
    <div class="d-none d-md-block page-header" data-test-id="product-checkout-order-card-page-header">
      {{'Product_Checkout_Order_Card_Page_Header' | translate}}</div>
    <div class="product-checkout-order-card-information">
      <div class="row justify-content-center">
        <div class="col-sm-12">
          <div class="container">
            <div class="col-sm-12" id="packet-title">
              <div class="row">
                <div class="col-sm-12">
                  <h5 class="product-checkout-order-card-title text" data-test-id="product-checkout-order-card-title">
                    {{context.order.productName}}</h5>
                </div>
                <div class="col-sm-12">
                  <h6 class="product-checkout-order-card-subtitle" data-test-id="product-checkout-order-card-subtitle">
                    {{ 'Product_Checkout_Order_Card_Subtitle' | translate:{ dataAmount: context.order.dataAmount ?
                    context.order.dataAmount : '',
                    dataUnits: context.order.dataUnits ? context.order.dataUnits : ''} }}
                  </h6>
                </div>
              </div>
            </div>
            <hr>

            <div class="container order-summary-info">
              <div class="row">
                <div class="col d-flex justify-content-start order-summary-subtotal-text">
                  <div class="product-checkout-order-card-subtotal" data-test-id="product-checkout-order-card-subtotal">
                    <p>{{'Product_Checkout_Order_Card_Subtotal' | translate}}</p>
                  </div>
                </div>
                <div class="col d-flex justify-content-end order-summary-subtotal">
                  <div class="product-checkout-order-card-subtotal-value text"
                    [attr.data-test-id]="'product-checkout-order-card-subtotal-value-'+context.order.packageSKU">
                    <p>{{context.order.currencyFormat}} {{context.order.salePrice | number:'1.2-5'}}</p>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col col-lg-6 d-flex justify-content-start order-summary-total-text">
                  <div class="product-checkout-order-card-total" data-test-id="product-checkout-order-card-total">
                    <h5>{{'Product_Checkout_Order_Card_Total' | translate}}</h5>
                  </div>
                </div>
                <div class="col col-lg-6 d-flex justify-content-end order-summary-total">
                  <div class="product-checkout-order-card-total-value text"
                    [attr.data-test-id]="'product-checkout-order-card-total-value-'+context.order.packageSKU">
                    <h5>{{context.order.currencyFormat}} {{context.order.salePrice | number:'1.2-5'}}</h5>
                  </div>
                </div>
              </div>
            </div>
            <hr>

            <div class="col-sm-12 d-flex justify-content-between" id="plan-applied-to-text">
              <div class="product-checkout-order-card-text" data-test-id="product-checkout-order-card-text">
                <p>{{'Product_Checkout_Order_Card_Text' | translate}}</p>
              </div>
              <div class="product-checkout-order-card-model text" data-test-id="product-checkout-order-card-model">
                <p>{{context.vehicle.personalisedName || context.vehicle.vehicleModel}}</p>
              </div>
            </div>
            <div class="col-sm-12 justify-content-center" id="vehicle-img" *ngIf="showVehicleImage">
              <img data-test-id="product-checkout-order-card-vehicle-photo" src='{{context.vehicle.vehiclePhotoImage}}'
                alt="car-model" class="mx-auto d-block car" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>