import { Injectable } from '@angular/core';
import { DataService } from './data.service';
import { SessionKeys, AppAuthUser } from '../models';
import { UserContextService } from '@shared/services/user-context.service';
import { IdentificationValidationContextService } from '@shared/services/identification-validation-context.service';
import { RedirectContextService } from '@shared/services/redirect-context.service';
import { ProductCheckoutHppContextService } from './product-checkout-context-hpp.service';
import { LanguageService } from '.';
import { AccountLoginResponse } from '@app/login/_models/account-login-response';

@Injectable()
export class AuthenticationService {

  sessionKeys = new SessionKeys();
  public appAuthUser: AppAuthUser = new AppAuthUser();

  constructor(
    private dataService: DataService,
    private languageService: LanguageService,
    private userContextService: UserContextService,
    private productCheckoutHppContextService: ProductCheckoutHppContextService,
    private identificationValidationContextService: IdentificationValidationContextService,
    private redirectContextService: RedirectContextService) {
    this.retrieveAuthUserFromSession();
  }

  setToken(token: string) {
    this.appAuthUser.bearerToken = token;
    this.dataService.setSession(SessionKeys.authUser, JSON.stringify(this.appAuthUser));
  }

  removeToken() {
    this.appAuthUser.bearerToken = '';
    this.dataService.setSession(SessionKeys.authUser, JSON.stringify(this.appAuthUser));
  }

  getToken(): string {
    return this.appAuthUser.bearerToken;
  }

  setAuthenticatedUser(userAccount: AccountLoginResponse, isSSOUser: boolean = false) {
    this.appAuthUser.identityId = userAccount.identityId;
    this.appAuthUser.isAuthenticated = true;
    this.appAuthUser.email = userAccount.email;
    this.appAuthUser.firstName = userAccount.firstName ? userAccount.firstName : 'My';
    this.appAuthUser.lastName = userAccount.lastName ? userAccount.lastName : 'User';
    this.appAuthUser.bearerToken = userAccount.token;
    this.appAuthUser.isSSOUser = isSSOUser;
    this.dataService.setSession(SessionKeys.authUser, JSON.stringify(this.appAuthUser));
  }

  changeName(firstName: string, lastName: string) {
    this.appAuthUser.firstName = firstName ? firstName : 'My';
    this.appAuthUser.lastName = lastName ? lastName : 'User';
    this.dataService.setSession(SessionKeys.authUser, JSON.stringify(this.appAuthUser));
  }

  logout() {
    this.resetAuthenticatedUser();

    // reset only on logout
    this.redirectContextService.resetContext();

    this.languageService.localizedNavigate(['/']);
  }

  reset() {
    this.resetAuthenticatedUser();
  }

  private resetAuthenticatedUser() {
    this.appAuthUser.identityId = '';
    this.appAuthUser.firstName = '';
    this.appAuthUser.lastName = '';
    this.appAuthUser.email = '';
    this.appAuthUser.isAuthenticated = false;
    this.appAuthUser.bearerToken = '';
    this.appAuthUser.isSSOUser = false;

    // reset user context
    this.userContextService.resetUserContext();

    // reset product checkout
    this.productCheckoutHppContextService.resetProductCheckoutContext();

    // reset identification validation
    this.identificationValidationContextService.resetContext();

    this.dataService.removeSession(SessionKeys.authUser);
  }

  private retrieveAuthUserFromSession() {
    const sessionData = this.dataService.getSession(SessionKeys.authUser);
    if (sessionData) {
      this.appAuthUser = JSON.parse(sessionData);
    }
  }
}
