
import { of, Observable, forkJoin } from 'rxjs';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AuthenticationService } from '../services/authentication.service';
import { OrdersService } from '../../app/product/_services';
import { ICreateOrderRequest, IGetOrderResponse } from '@app/product/_models';
import { catchError, mergeMap, map } from 'rxjs/operators';
import { VehicleService } from '@app/vehicles/_services';
import { Vehicle, VehicleInfomationRequest } from '@app/vehicles/_models';
import { ProductCheckoutHppContextService, AlertService } from '@shared/services';
import { ProductCheckoutHppContext } from '@shared/models/product-checkout-hpp-context';
import { NgxSpinnerService } from 'ngx-spinner';

@Injectable()
export class ProductCheckoutHppResolverService {
  productContextHpp = new ProductCheckoutHppContext();

  vin: string;
  identityId: string;
  packageSku: string;

  constructor(
    private orderService: OrdersService,
    private authenticationService: AuthenticationService,
    private vehicleService: VehicleService,
    private contextService: ProductCheckoutHppContextService,
    private alertService: AlertService,
    private spinnerService: NgxSpinnerService
  ) {
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> {

    this.vin = route.params['vin'];
    this.packageSku = route.params['product'];
    this.identityId = this.authenticationService.appAuthUser.identityId;
    const request = new VehicleInfomationRequest;
    request.identityId = this.authenticationService.appAuthUser.identityId;
    this.spinnerService.show();

    return this.orderService.createOrder(this.setCreateOrderModel())
      .pipe(
        mergeMap(
          (orderNumber: any) => {
            // console.log('CreateOrder Success');
            const order = this.orderService.getOrder(orderNumber);
            const vehicle = this.vehicleService.getVehicle(this.vin, request, false);
            return forkJoin([order, vehicle]);
          }
        ),
        map((data: [IGetOrderResponse, Vehicle]) => {
          // console.log('order/vehicle', data);
          this.productContextHpp.order = data[0];
          this.productContextHpp.vehicle = this.mapVehicleContext(data[1]);
          this.updateContext();
        }),
        catchError(err => {
          // Do not proceed
          this.alertService.error(err.message, 5, true, true);
          return of(err);
        })
      );
  }

  private mapVehicleContext(vehicle: Vehicle): Vehicle {
    const _vehicle = vehicle;
    return _vehicle;
  }

  private setCreateOrderModel(): ICreateOrderRequest {
    return {
      packageSku: this.packageSku,
      vin: this.vin
    };
  }

  private updateContext() {
    this.contextService.setProductCheckoutContext(this.productContextHpp);
    this.contextService.update();
  }

}
