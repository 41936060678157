import { Component, Output, EventEmitter, Input } from '@angular/core';

@Component({
  selector: 'app-page-header',
  templateUrl: './page-header.component.html',
  styleUrls: ['./page-header.component.scss']
})
export class PageHeaderComponent {

  @Input() btnText: string;
  @Input() title: string;
  @Output() clickEvt = new EventEmitter();

  constructor() {
  }

  actionButtonClicked() {
    this.clickEvt.emit();
  }

}
