import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationStart, Router } from '@angular/router';
import { AlertService, AuthenticationService, GoogleAnalyticsEcommerceService, LanguageService, ProductCheckoutHppContextService } from '@shared/services';
import { ConfigService } from '@shared/services/config.service';
import { ProductCheckoutHppContext } from '@shared/models/product-checkout-hpp-context';
import { NgxSpinnerService } from 'ngx-spinner';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-product-checkout-confirmation',
  templateUrl: './product-checkout-confirmation.component.html',
  styleUrls: ['./product-checkout-confirmation.component.scss']
})
export class ProductCheckoutConfirmationComponent implements OnInit, OnDestroy {

  context: ProductCheckoutHppContext;

  vin: string;
  packageCode: string;
  email: string;
  routerSubscription: Subscription;

  constructor(
    private languageService: LanguageService,
    private activatedRoute: ActivatedRoute,
    private contextService: ProductCheckoutHppContextService,
    private alertService: AlertService,
    private authenticationService: AuthenticationService,
    private translationsService: TranslateService,
    private configService: ConfigService,
    private gaEcommerceService: GoogleAnalyticsEcommerceService,
    private spinnerService: NgxSpinnerService,
    private router: Router
  ) {
  }

  ngOnInit() {
    this.spinnerService.show();

    this.email = this.authenticationService.appAuthUser.email;

    this.context = this.contextService.context;
    this.vin = this.activatedRoute.snapshot.params['vin'];
    this.packageCode = this.activatedRoute.snapshot.params['product'];

    // Check if valid context
    this.isValidContext();

    // Check if alert needs to be displayed
    this.shouldShowAlertFromContext();

    // Manage GA
    this.shouldSendDataToGoogleEcommerce();

    this.spinnerService.hide();

    //Reset order context when navigate to other route
    this.routerSubscription = this.router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        if (!this.removeIsoCodeFromUrl(event.url).includes(this.removeIsoCodeFromUrl(this.router.url))) {
          this.contextService.resetProductCheckoutContext();
        }
      }
    });
  }

  private isValidContext() {
    if (this.context.order.orderNumber == null) {
      if (this.vin == null || this.packageCode == null) {
        this.alertService.warn(this.translationsService.instant('Product_Checkout_Confirmation_Invalid_Requeriments'), 5, true, true);
        return this.languageService.localizedNavigate(['/dashboard/plan']);
      } else {
        this.alertService.warn(this.translationsService.instant('Product_Checkout_Confirmation_Invalid_Requeriments'), 5, true, true);
        return this.languageService.localizedNavigate(['/dashboard/plan/checkout', this.vin, this.packageCode], { relativeTo: this.activatedRoute });
      }
    }
  }

  // If PROD set GA
  private shouldSendDataToGoogleEcommerce() {
    if (this.configService.config.isProductionEnv) {
      this.gaEcommerceService.sendDataToGoogleEcommerce(this.context);
    }
  }

  private shouldShowAlertFromContext() {
    if (this.context.showConfirmationAlert) {
      this.alertService.error(this.context.confirmationAlertMessage, 5, true, true);
    }
  }

  returnToDashboard() {
    this.languageService.localizedNavigate(['/dashboard'], { queryParams: { forceDataRefresh: true } });
  }

  private removeIsoCodeFromUrl(url: String) {
    return url.substring(3, url.length - 3);
  }

  ngOnDestroy(): void {
    this.routerSubscription.unsubscribe();
  }
}
