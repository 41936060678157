import { Injectable } from '@angular/core';
import { ConfigService, BaseHttpService, DataService, UserContextService, AuthenticationService } from '@shared/services';
import { HttpClient } from '@angular/common/http';
import { AccountLogin } from '../_models/account-login';
import { ResponseError, SessionKeys, BaseResponse, UserContext } from '@shared/models';
import { Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { AccountLoginResponse } from '../_models/account-login-response';

@Injectable()
export class LoginService extends BaseHttpService {

    constructor(
        protected httpClient: HttpClient,
        protected configService: ConfigService,
        protected translateService: TranslateService,
        private authService: AuthenticationService,
        private dataService: DataService,
        private userContextService: UserContextService) {
        super(httpClient, configService, translateService);
    }

    login(accountLogin: AccountLogin): Observable<AccountLoginResponse | ResponseError> {
        this.authService.reset();
        return this.postBaseResponse<AccountLoginResponse>('/accounts/login', accountLogin)
            .pipe(
                map(res => {
                    this.authService.setAuthenticatedUser(res.result);
                    this.setUserContext(res.result);
                    return res.result;
                }),
                catchError(err => this.handlerError(err))
            );
    }

    private setUserContext(accountLogin: AccountLoginResponse) {
        const userContext = new UserContext();
        userContext.firstName = accountLogin.firstName;
        userContext.lastName = accountLogin.lastName;
        userContext.userId = accountLogin.userId;
        userContext.homeCountryCode = accountLogin.homeCountry ? accountLogin.homeCountry.countryCode : null;
        this.userContextService.setUserContext(userContext);
    }

    private handlerError(res: BaseResponse<AccountLogin>): Observable<ResponseError> {
        const err = res.errors;
        const result = res.result;
        switch (err.code) {
            case '10.3':
                if (result !== undefined) {
                    this.dataService.setSession(SessionKeys.email, result.email);
                }
                break;
        }
        const baseError = res.errors;
        return throwError(baseError);
    }
}
