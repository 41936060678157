<div>
    <app-step [steps]="steps" *ngIf="!isUserRegisterd"></app-step>

    <app-vehicle-verification-identification (verifyClick)="verifyCallback($event)" (cancelClick)="cancelClickEvent()"
        *ngIf="showVinForm && !currentVehicle" [currentVehicle]="currentVehicle">
    </app-vehicle-verification-identification>

    
    <app-sso-vehicle-verification-details *ngIf="!showVinForm && showVehicleDetails" 
        [currentVehicle]="currentVehicle" [hasVinValue]="hasVinValue" [isUserRegisterd]="isUserRegisterd"
        (continueClick)="continueClickEvent($event)"
        (retryClick)="retryCallback()" (returnClick)="returnCallback()"
        (dashboardClick)="dashboardClick()">
    </app-sso-vehicle-verification-details>
    
</div>