<div class="main-reset-password-email-failed">
  <div class="container">
    <div class="reset-password-email-failed-container">
      <div class="row justify-content-center">
        <div class="col-sm-12">
          <h3 class="text-center" data-test-id="reset-password-email-failed-title">{{'ResetPasswordEmailFailed_Title' |
            translate }}</h3>
          <p data-test-id="reset-password-email-failed-description-1"
            [innerHTML]="'ResetPasswordEmailFailed_Description_1' | translate: {link: ('/forgotPassword' | localize)}">
          </p>
          <p data-test-id="reset-password-email-failed-description-2"
            [innerHTML]="'ResetPasswordEmailFailed_Description_2' | translate: {link: ('/contactUs' | localize)} ">
          </p>
        </div>
      </div>
    </div>
  </div>
</div>