import { Component, OnInit } from '@angular/core';
import { IntroSection } from '@shared/models/intro-section';
import { ConfigService } from '@shared/services/index';

@Component({
  selector: 'app-getting-started',
  templateUrl: './getting-started.component.html',
  styleUrls: ['./getting-started.component.scss']
})
export class GettingStartedComponent implements OnInit {
  public introSection: IntroSection;
  constructor(
    private configService: ConfigService
  ) { }

  ngOnInit() {
    this.introSection = new IntroSection();
    this.introSection.image = './assets/getting-started/launch.svg';
    this.introSection.Title = 'Getting_Started_SetUp_FirstSteps';
    this.introSection.Description = 'Getting_Started_SetUp_Guide';
    window.scrollTo(0, 0);
  }

  showInCarTopup() {
    return this.configService.config.showInCarTopup;
  }

}
