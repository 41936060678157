<div class="container">
  <div class="contact-us-form-container">
    <div class="row justify-content-center">
      <div class="col-sm-12 col-md-8">
        <form [formGroup]="contactUsForm" novalidate class="contactUsForm" data-test-id="contact-us-form">
          <!-- Name -->
          <div class="row justify-content-center">
            <div class="col-sm-12 col-md-6">
              <div class="form-group">
                <label data-test-id="contact-us-form-name-text">{{'Contact_Us_Form_Name' | translate }}</label><label
                  class="text-danger ml-1">*</label>
                <input type="text" class="form-control" formControlName="name"
                  data-test-id="contact-us-form-name-input">
                <div *ngIf="contactUsForm.controls['name'].errors">
                  <span data-test-id="contact-us-form-name-error" class="invalid-note"
                    *ngIf="!contactUsForm.controls['name'].pristine && contactUsForm.controls['name'].errors.required">{{'Contact_Us_Form_Name_Error'
                    | translate }}</span>
                  <span data-test-id="contact-us-form-name-maxLength-error" class="invalid-note"
                    *ngIf="!contactUsForm.controls['name'].pristine && contactUsForm.controls['name'].errors.maxlength && !contactUsForm.controls['name'].errors.required">
                    {{'Contact_Us_Form_Name_MaxLength_Error' | translate }}
                  </span>
                </div>
              </div>
            </div>
            <!-- Email -->
            <div class="col-sm-12 col-md-6">
              <div class="form-group">
                <label data-test-id="contact-us-form-email-text">{{'Email_Address' | translate }}</label><label
                  class="text-danger ml-1">*</label>
                <input type="text" class="form-control" formControlName="email"
                  data-test-id="contact-us-form-email-input">
                <div *ngIf="contactUsForm.controls['email'].errors">
                  <span data-test-id="contact-us-form-email-error" class="invalid-note"
                    *ngIf="!contactUsForm.controls['email'].pristine && contactUsForm.controls['email'].errors.required">{{'Contact_Us_Email_Error'
                    | translate }}</span>
                  <span data-test-id="contact-us-form-email-invalid-error" class="invalid-note"
                    *ngIf="!contactUsForm.controls['email'].pristine && contactUsForm.controls['email'].errors.email && !contactUsForm.controls['email'].errors.required">
                    {{'Contact_Us_From_Email_Invalid_Error' | translate }}
                  </span>
                </div>
              </div>
            </div>
          </div>
          <!-- Contact Number -->
          <div class="row justify-content-center">
            <div class="col-sm-12">
              <div class="form-group">
                <label data-test-id="contact-us-form-contact-number-text">{{'Contact_Us_Form_Contact_Number' | translate
                  }}</label><label class="text-danger ml-1">*</label>

                <input type="text" class="form-control" formControlName="contactNumber"
                  (intlTelInputObject)="initCountryCode($event)" data-test-id="incar-topup-mobile-number-input"
                  appNgxTelInput (countryChange)="onCountryChange($event)" data-intl-tel-input-id />

                <div *ngIf="contactUsForm.controls['contactNumber'].errors">
                  <span data-test-id="contact-us-form-contact-number-error" class="invalid-note"
                    *ngIf="!contactUsForm.controls['contactNumber'].pristine && contactUsForm.controls['contactNumber'].errors.required">
                    {{'Contact_Us_Form_Contact_Number_Error' | translate }}</span>
                  <span data-test-id="contact-us-form-contact-number-invalid-error" class="invalid-note" *ngIf="!contactUsForm.controls['contactNumber'].pristine 
                    && !contactUsForm.controls['contactNumber'].errors.maxlength 
                    && !contactUsForm.controls['contactNumber'].errors.required 
                    && contactUsForm.controls['contactNumber'].errors.phoneNumber">
                    {{'Contact_Us_Form_Contact_Number_Invalid_Error' | translate }}
                  </span>
                  <span data-test-id="contact-us-form-contact-number-maxLegth-error" class="invalid-note" *ngIf="!contactUsForm.controls['contactNumber'].pristine 
                    && contactUsForm.controls['contactNumber'].errors.maxlength 
                    && !contactUsForm.controls['contactNumber'].errors.required
                    && !contactUsForm.controls['contactNumber'].errors.phoneNumber">
                    {{'Contact_Us_Form_Contact_Number_MaxLength_Error' | translate }}</span>
                </div>
              </div>
            </div>
          </div>
          <!-- Message -->
          <div class="row justify-content-center">
            <div class="col-sm-12">
              <div class="form-group">
                <label data-test-id="contact-us-form-message-text"> {{'Contact_Us_Form_Message' | translate
                  }}</label><label class="text-danger ml-1">*</label>
                <textarea rows="2" class="form-control" formControlName="message"
                  data-test-id="contact-us-form-message-textarea"></textarea>
                <div *ngIf="contactUsForm.controls['message'].errors">
                  <span data-test-id="contact-us-form-message-error" class="invalid-note"
                    *ngIf="!contactUsForm.controls['message'].pristine && contactUsForm.controls['message'].errors.required">
                    {{'Contact_Us_Form_Message_Error' | translate }}</span>
                </div>
              </div>
            </div>
          </div>
          <button type="submit" class="btn btn-primary btn-block" [disabled]="!contactUsForm.valid"
            aria-disabled="!contactUsForm.valid" (click)="contactUs()"
            data-test-id="contact-us-send-btn">{{'Form_SendBtn' | translate }}</button>
        </form>
        <div class="required-fields" data-test-id="contact-us-required-fields-text">
          {{'Form_Note_Required_Fields' | translate }}
        </div>
        <div class="reachUs">
          <p data-test-id="contact-us-reach-phone">
            {{'Contact_Us_Reach_Phone' | translate }}
          </p>
        </div>
        <div class="row countries">
          <div class="col-6 col-md-3">
            <img src="assets/contact-us/icons8-great-britain-96.png">
            <a data-test-id="contact-us-gb-phone-number">+44-20-35142395**</a>
          </div>
          <div class="col-6 col-md-3">
            <img src="assets/contact-us/icons8-germany-96.png">
            <a data-test-id="contact-us-de-phone-number">+49-89-120895018</a>
          </div>
          <div class="col-6 col-md-3">
            <img src="assets/contact-us/icons8-spain-flag-96.png">
            <a data-test-id="contact-us-es-phone-number">+34-911-238409</a>
          </div>
          <div class="col-6 col-md-3">
            <img src="assets/contact-us/icons8-luxembourg-96.png">
            <a data-test-id="contact-us-lu-phone-number">+352-20-203604</a>
          </div>
          <div class="col-6 col-md-3">
            <img src="assets/contact-us/icons8-austria-96.png">
            <a data-test-id="contact-us-at-phone-number">+43-800-090084</a>
          </div>
          <div class="col-6 col-md-3">
            <img src="assets/contact-us/icons8-netherlands-96.png">
            <a data-test-id="contact-us-nl-phone-number">+31-20-8086085**</a>
          </div>
          <div class="col-6 col-md-3">
            <img src="assets/contact-us/icons8-sweden-96.png">
            <a data-test-id="contact-us-se-phone-number">+46-8-40839794**</a>
          </div>
          <div class="col-6 col-md-3">
            <img src="assets/contact-us/icons8-france-96.png">
            <a data-test-id="contact-us-fr-phone-number">+33-1-86260789</a>
          </div>
          <div class="col-6 col-md-3">
            <img src="assets/contact-us/icons8-italy-96.png">
            <a data-test-id="contact-us-it-phone-number">+39-06-94803502</a>
          </div>
          <div class="col-6 col-md-3">
            <img src="assets/contact-us/icons8-switzerland-96.png">
            <a data-test-id="contact-us-ch-phone-number">+41-22-5180039</a>
          </div>
          <div class="col-6 col-md-3">
            <img src="assets/contact-us/icons8-ireland-96.png">
            <a data-test-id="contact-us-ie-phone-number">+353-1-5136735**</a>
          </div>
          <div class="col-6 col-md-3">
            <img src="assets/contact-us/icons8-belgium-96.png">
            <a data-test-id="contact-us-be-phone-number">+32-2-5880388</a>
          </div>
          <div class="col-6 col-md-3">
            <img src="assets/contact-us/icons8-denmark-96.png">
            <a data-test-id="contact-us-dk-phone-number">+45-43-959857**</a>
          </div>
          <div class="col-6 col-md-3">
            <img src="assets/contact-us/icons8-bulgaria-96.png">
            <a data-test-id="contact-us-bg-phone-number">+359-80-0210-0403**</a>
          </div>
          <div class="col-6 col-md-3">
            <img src="assets/contact-us/icons8-croatia-96.png">
            <a data-test-id="contact-us-hr-phone-number">+385-13-003-508**</a>
          </div>
          <div class="col-6 col-md-3">
            <img src="assets/contact-us/icons8-czech-96.png">
            <a data-test-id="contact-us-cs-phone-number">+420-225-379-351</a>
          </div>
          <div class="col-6 col-md-3">
            <img src="assets/contact-us/icons8-estonia-96.png">
            <a data-test-id="contact-us-ee-phone-number">+372-609-3011**</a>
          </div>
          <div class="col-6 col-md-3">
            <img src="assets/contact-us/icons8-finland-96.png">
            <a data-test-id="contact-us-fi-phone-number">+358-94-272-2102**</a>
          </div>
          <div class="col-6 col-md-3">
            <img src="assets/contact-us/icons8-hungary-96.png">
            <a data-test-id="contact-us-hu-phone-number">+36-1-677-7378**</a>
          </div>
          <div class="col-6 col-md-3">
            <img src="assets/contact-us/icons8-latvia-96.png">
            <a data-test-id="contact-us-lv-phone-number">+371-6600-2490**</a>
          </div>
          <div class="col-6 col-md-3">
            <img src="assets/contact-us/icons8-lithuania-96.png">
            <a data-test-id="contact-us-lt-phone-number">+370-8-008-0034**</a>
          </div>
          <div class="col-6 col-md-3">
            <img src="assets/contact-us/icons8-norway-96.png">
            <a data-test-id="contact-us-no-phone-number"> 800 62 679**</a>
          </div>
          <div class="col-6 col-md-3">
            <img src="assets/contact-us/icons8-poland-96.png">
            <a data-test-id="contact-us-pl-phone-number">+48-22-116-84-56</a>
          </div>
          <div class="col-6 col-md-3">
            <img src="assets/contact-us/icons8-portugal-96.png">
            <a data-test-id="contact-us-pt-phone-number">+351-21-145-1344</a>
          </div>
          <div class="col-6 col-md-3">
            <img src="assets/contact-us/icons8-slovakia-96.png">
            <a data-test-id="contact-us-sk-phone-number">+421-2333-25529</a>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="justify-content-center">
    <div class="english-support-numbers" data-test-id="contact-us-english-support-numbers">
      {{'Contact_Us_English_Support_Numbers' | translate }} <br>
      {{'Contact_Us_Support_Rates' | translate }}
    </div>
  </div>
</div>