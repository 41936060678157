import { CreditCardType } from '../../product/_models';

export class SavedCreditCard {
    public referenceId: string;
    public description: string;
    public type: CreditCardType;
    public lastFourDigits: string;
    public expireMonth: string;
    public expireYear: string;
    public isDefault: boolean;
    public providerImage: string;
    public isBeingUpdated: boolean;
}
