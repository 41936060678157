import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { ConfigService } from '@shared/services';
import { Html5Qrcode } from "html5-qrcode";

@Component({
    selector: 'app-vehicle-verification-identification-scan',
    templateUrl: './vehicle-verification-identification-scan.html',
    styleUrls: ['./vehicle-verification-identification-scan.scss']
})
export class VehicleVerificationIdentificationScanComponent implements OnInit {
    @Output() scanResult = new EventEmitter<string>();

    html5QrCode: Html5Qrcode;
    isScanning: boolean;
    lastReading: string;
    canShowScanButton: boolean;

    constructor(private configService: ConfigService) {
        this.canShowScanButton = this.configService.config.showQRCodeScanner;
    }

    ngOnInit(): void {
        this.html5QrCode = new Html5Qrcode("reader");
    }

    startScan() {
        this.isScanning = true;
        const config = {
            fps: 10,
            qrbox: { width: 250, height: 250 },
            aspectRatio: 1
        };

        this.html5QrCode.start({ facingMode: "environment" }, config, (decodedText, decodedResult) => {
            this.onScanSuccess(decodedText)
        }, null);
    }

    onScanSuccess(decodedText: string) {
        this.emitSuccessEvent(decodedText);
        this.stopScan();
    }

    onScanFailure(error) {
        this.logError(error);
    }

    stopScan() {
        this.isScanning = false;

        if (this.html5QrCode.isScanning)
            this.html5QrCode.stop();
    }

    emitSuccessEvent(text: string) {
        this.scanResult.emit(text);
    }

    logError(error: any) {
        console.warn(`Error scaning QR error = ${error}`);
    }

    onSelects(files: any) {

        const imageFile = files[0];
        // Scan QR Code
        this.html5QrCode.scanFile(imageFile, false)
            .then(decodedText => {
                // success, use decodedText
                this.emitSuccessEvent(decodedText);
            })
            .catch(err => {
                this.logError(err);
            });
    }

    onResized() {
        this.stopScan();
    }
}
