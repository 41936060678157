import { Routes } from '@angular/router';
import { AuthGuard } from '@shared/guards/auth.guard';
import { ProductCheckoutHppResolverService } from '@shared/resolvers';
import { ProductCheckoutConfirmationComponent } from './product-checkout-confirmation/product-checkout-confirmation.component';
import { ProductCheckoutRefreshComponent } from './product-checkout-refresh/product-checkout-refresh';
import { ProductCheckoutHppComponent } from './product-checkout/product-checkout-hpp/product-checkout-hpp.component';

export const productRoutes: Routes = [
    {
        path: 'dashboard/plan/checkout/:vin/:product',
        component: ProductCheckoutHppComponent,
        canActivate: [AuthGuard],
        resolve: { productContext: ProductCheckoutHppResolverService }
    },
    {
        path: 'dashboard/plan/checkout/refresh/:vin/:product',
        component: ProductCheckoutRefreshComponent,
        canActivate: [AuthGuard]
    },
    {
        path: 'dashboard/plan/checkout/confirm/:vin/:product',
        component: ProductCheckoutConfirmationComponent,
        canActivate: [AuthGuard]
    }
];
