<div class="main-forgot-password-email">
  <div class="container">
    <div class="forgot-password-email-container">
      <div class="row justify-content-center">
        <div class="col-sm-12">
          <h3 class="text-center" data-test-id="forgot-password-email-title">{{'ForgotPasswordEmail_Title' | translate
            }}</h3>
          <p data-test-id="forgot-password-email-description"> {{'ForgotPasswordEmail_Description' | translate }}
          </p>
            <a class="btn btn-primary btn-block" data-test-id="forgot-password-email-login-link" [routerLink]="['/login' | localize]">{{'Login' |
              translate }}</a>
        </div>
      </div>
    </div>
  </div>
</div>