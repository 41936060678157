import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { CustomValidators } from '@shared/validators/custom-validators';
import { AccountResetPassword } from '../_models/account-reset-password';
import { AccountResetPasswordService } from '../_services/account-reset-password.service';
import { ResponseError } from '@shared/models';
import { AlertService, LanguageService } from '@shared/services/index';
import { Logger } from '@shared/logging/Logger';
import { NgxSpinnerService } from 'ngx-spinner';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {

  email: string;
  token: string;
  isRequestReady = false;
  resetPasswordForm: UntypedFormGroup;
  public resetPasswordData: AccountResetPassword;

  constructor(
    private activatedRoute: ActivatedRoute,
    private languageService: LanguageService,
    private fb: UntypedFormBuilder,
    private resetPasswordService: AccountResetPasswordService,
    private spinnerService: NgxSpinnerService,
    private alertService: AlertService,
    private translationsService: TranslateService,
    private logger: Logger) {
    this.resetPasswordData = new AccountResetPassword();
    this.createForm();
  }

  ngOnInit() {

    this.activatedRoute.queryParams.subscribe((params: Params) => {
      if (params['resetToken'] !== null && params['resetToken'] !== undefined) {
        this.token = params['resetToken'];
        this.resetPasswordToken();
      } else {
        this.languageService.localizedNavigate(['/'], { relativeTo: this.activatedRoute });
      }
    });
  }

  createForm() {
    this.resetPasswordForm = this.fb.group({
      email: this.email,
      password: [this.resetPasswordData.password, [Validators.required, CustomValidators.containsNumberOrSpecial,
      CustomValidators.containsLowerCase, CustomValidators.containsUpperCase, Validators.minLength(8), Validators.maxLength(25)]],
      token: this.token
    });
  }

  prepareAccountResetPasswordTokenData(): AccountResetPassword {
    const accountResetPasswordData = new AccountResetPassword();
    accountResetPasswordData.token = this.token;
    return accountResetPasswordData;
  }

  resetPasswordToken() {
    this.spinnerService.show();
    this.resetPasswordService.resetPasswordToken(this.prepareAccountResetPasswordTokenData()).subscribe(
      (data: AccountResetPassword) => {
        this.isRequestReady = true;
        this.email = data['email'];
        this.spinnerService.hide();
      },
      (err: ResponseError) => {
        this.handleError(err);

      },
      () => { this.spinnerService.hide(); }
    );
  }
  private handleError(err: ResponseError) {
    this.spinnerService.hide();
    this.logger.error(err);
    this.alertService.error(err.message, 0, true, true);
    switch (err.code) {
      case '10.10':
        this.languageService.localizedNavigate(['/resetLinkExpired'], { relativeTo: this.activatedRoute });
        break;
      case '10.11':
      case '10.12':
        this.languageService.localizedNavigate(['/'], { relativeTo: this.activatedRoute });
        break;
    }
  }

  prepareAccountResetPasswordData(): AccountResetPassword {
    const formModel = this.resetPasswordForm.value;
    const accountResetPasswordData = new AccountResetPassword();
    accountResetPasswordData.email = this.email;
    accountResetPasswordData.password = formModel.password;
    accountResetPasswordData.token = this.token;
    return accountResetPasswordData;
  }

  resetPassword() {
    this.alertService.clear();
    this.spinnerService.show();
    this.resetPasswordService.resetPassword(this.prepareAccountResetPasswordData()).subscribe(
      (data: AccountResetPassword) => {
        this.alertService.success(this.translationsService.instant('Reset_Password_Success'), 0, true, true);
        this.spinnerService.hide();
        this.languageService.localizedNavigate(['/'], { relativeTo: this.activatedRoute });
      },
      (err: ResponseError) => {
        this.spinnerService.hide();
        this.alertService.error(err.message, 0, true, false);
      },
      () => { this.spinnerService.hide(); }
    );
  }
}
