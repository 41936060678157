import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { ConfigService } from './config.service';
import { BaseResponse, ResponseError } from '../models/index';
import { AuthenticationError } from '@shared/models/errors/authentication.error';
import { NotFoundError } from '@shared/models/errors/not-found.error';
import { IResponseError } from '@shared/models/errors/iresponse-error';
import { BadRequestError } from '@shared/models/errors/bad-request.error';
import { UnprocessableEntityError } from '@shared/models/errors/unprocessable-entity.error';
import { TranslateService } from '@ngx-translate/core';

export abstract class BaseHttpService {

  protected baseUrl: string;

  constructor(protected httpClient: HttpClient, protected _configService: ConfigService,
    protected translateService: TranslateService) {
    this.setUrls();
  }

  private setUrls() {
    this.baseUrl = this._configService.config.api;
  }

  post<T>(actionUrl: string, object: object, responseRootNodeToMapFrom?: string): Observable<T | ResponseError> {
    if (responseRootNodeToMapFrom) {
      return this.httpClient.post<BaseResponse<T>>(this.baseUrl + actionUrl, object).pipe(
        map(res => res.result[responseRootNodeToMapFrom]),
        catchError(err => this.handleError(err))
      );
    }
    return this.httpClient.post<BaseResponse<T>>(this.baseUrl + actionUrl, object).pipe(
      map(res => res.result),
      catchError(err => this.handleError(err))
    );
  }

  get<T>(actionUrl: string, httpParams: HttpParams = null, responseRootNodeToMapFrom?: string): Observable<T | ResponseError> {
    if (responseRootNodeToMapFrom) {
      return this.httpClient.get<BaseResponse<T>>(this.baseUrl + actionUrl, { params: httpParams }).pipe(
        map(res => res.result[responseRootNodeToMapFrom]),
        catchError(err => this.handleError(err))
      );
    }
    return this.httpClient.get<BaseResponse<T>>(this.baseUrl + actionUrl, { params: httpParams }).pipe(
      map(res => res.result),
      catchError(err => this.handleError(err))
    );
  }

  getBaseResponse<T>(actionUrl: string, httpParams: HttpParams = null): Observable<BaseResponse<T>> {
    return this.httpClient.get<BaseResponse<T>>(this.baseUrl + actionUrl, { params: httpParams }).pipe(
      catchError(err => {
        return this.handleErrorBaseResponse<T>(err);
      })
    );
  }

  postBaseResponse<T>(actionUrl: string, object: T): Observable<BaseResponse<T>> {
    return this.httpClient.post<BaseResponse<T>>(this.baseUrl + actionUrl, object).pipe(
      catchError(err => {
        return this.handleErrorBaseResponse<T>(err);
      })
    );
  }

  private handleError(error: HttpErrorResponse): Observable<ResponseError> {
    const baseError = this.extractError(error);
    return throwError(baseError);
  }

  private handleErrorBaseResponse<T>(error: HttpErrorResponse): Observable<BaseResponse<T>> {
    const baseErrorResponse = new BaseResponse<T>();
    const customError = this.extractError(error);
    baseErrorResponse.result = error.error.result;
    baseErrorResponse.errors = customError;
    return throwError(baseErrorResponse);
  }

  private extractError(error: HttpErrorResponse): ResponseError {
    let baseError = new ResponseError();
    if (error.error.errors !== undefined) {
      baseError = this.mapBaseError(error.error.errors);
    }
    if (error.error instanceof ErrorEvent) {
      baseError.message = 'Client Side error';
      baseError.code = '0.3';
      baseError.key = 'Client side error';
      return baseError;
    }
    switch (error.status) {
      case 400:
        return new BadRequestError(baseError as IResponseError);
      case 403:
        return new AuthenticationError(baseError as IResponseError);
      case 404:
        return new NotFoundError(baseError as IResponseError);
      case 422:
        return new UnprocessableEntityError(baseError as IResponseError);
      case 0:
      default:
        const message = this.translateService.instant('BaseError_Message');
        baseError.code = '0';
        baseError.message = message ? message : 'Apologies, there seems to be an error. Please try again later. ';
        return baseError;
    }
  }

  private mapBaseError(error: any): ResponseError {
    const _error: ResponseError = new ResponseError();
    if (error.code) {
      _error.code = error.code;
    }
    if (error.key) {
      _error.key = error.key;
    }
    if (error.message) {
      _error.message = error.message;
    }
    if (error.moreInfo) {
      _error.moreInfo = error.moreInfo;
    }
    if (error.redirectLink) {
      _error.redirectLink = error.redirectLink;
    }
    return _error;
  }
}
