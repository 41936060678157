import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'joinStrings'
})
export class JoinStringsPipePipe implements PipeTransform {

    transform(stringArray: string[], args?: any): string {
        return this.joinItemsInString(stringArray);
    }

    private joinItemsInString(stringArray: string[]): string {
        return stringArray.join(', ');
    }
}
