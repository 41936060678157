import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { RedirectType } from '@shared/models/redirect-type';
import { ConfigService, LanguageService, RedirectContextService } from '@shared/services';
import { NgxSpinnerService } from 'ngx-spinner';
import { RedirectService } from './_services/redirect.service';
import { SSOService } from '@app/sso-authentication/_services/sso.service';
import { AlertType, ResponseError } from '@shared/models';
import { LoginAlert } from '@shared/models/login-alert';

@Component({
    selector: 'app-redirect',
    templateUrl: './redirect.component.html',
    styleUrls: ['./redirect.component.scss']
})

export class RedirectComponent implements OnInit {

    constructor(
        private languageService: LanguageService,
        private redirectContextService: RedirectContextService,
        private spinnerService: NgxSpinnerService,
        private redirectService: RedirectService,
        private activatedRoute: ActivatedRoute,
        private configService: ConfigService,
        private ssoService: SSOService) {
    }

    ngOnInit(): void {
        this.spinnerService.show();
        this.redirectContextService.resetContext();
        let cacheKey = this.activatedRoute.snapshot.queryParamMap.get("cacheKey");

        if (!cacheKey) {
            this.languageService.localizedNavigate(['/']);
            return;
        }

        this.redirectService.getRedirectData(cacheKey)
            .subscribe(
                (response: any) => {
                    this.redirectUser(response);
                }
            );
    }

    private redirectUser(response: any) {
        let redirectPath = '/';

        if (response && response.result) {
            const mappedResult = this.redirectService.mapRedirectData(response.result);
            this.redirectContextService.setContext(mappedResult);

            //If SSO is enabled we have to go through the sso process
            if (this.configService.config.ssoSettings.enabled) {
                const scopes = this.configService.config.ssoSettings.scopes;

                this.ssoService.getSSOLink(scopes)
                    .subscribe((response: string | ResponseError) => {
                        if (response instanceof ResponseError) {
                            const loginAlert = new LoginAlert(AlertType.Error, response.message);
                            this.languageService.localizedNavigate(['/login'], { state: { alert: loginAlert } });
                            return;
                        }
                
                    window.location.href = response;
                    this.spinnerService.hide();
                  });
            } else {
                if (mappedResult.RedirectType === RedirectType.Registration) {
                    redirectPath = '/register';
                }
                else if (mappedResult.RedirectType === RedirectType.Login) {
                    redirectPath = '/login';
                }

                this.spinnerService.hide();
                this.languageService.localizedNavigate([redirectPath]);
            }
        } else {
            this.spinnerService.hide();
            this.languageService.localizedNavigate([redirectPath]);
        }
    }
}
