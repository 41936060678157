import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { SharedComponentsModule } from '@shared/components/shared-components.module';
import { SSOAuthenticationComponent } from './sso-authentication.component';
import { SSOService } from './_services/sso.service';
import { SSOConfirmAccountComponent } from './sso-confirm-details/sso-confirm-account/sso-confirm-account.component';
import { SSOConfirmDetailsComponent } from './sso-confirm-details/sso-confirm-details.component';
import { SSOVehicleVerificationComponent } from './sso-confirm-details/sso-vehicle-verification/sso-vehicle-verification.component';
import { SSOVehicleVerificationDetailsComponent } from './sso-confirm-details/sso-vehicle-verification/sso-vehicle-verification-details/sso-vehicle-verification-details.component';
import { RouterModule } from '@angular/router';

@NgModule({
    imports: [
        RouterModule,
        CommonModule,
        NgbModule,
        ReactiveFormsModule,
        SharedComponentsModule
    ],
    declarations: [
        SSOAuthenticationComponent,
        SSOConfirmDetailsComponent,
        SSOVehicleVerificationComponent,
        SSOConfirmAccountComponent,
        SSOVehicleVerificationDetailsComponent
    ],
    providers: [
        SSOService
    ]
})
export class SSOAuthenticationModule { }
