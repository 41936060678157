import { Component, OnInit, OnDestroy } from '@angular/core';
import { IntroSection } from '@shared/models/intro-section';
import { ResponseError } from '@shared/models/response-error';
import { ContentSection } from '@shared/models/content-section';
import { NgxSpinnerService } from 'ngx-spinner';
import { LocalizeRouterService } from '@gilsdav/ngx-translate-router';
import { Subscription } from 'rxjs';
import { Logger } from '@shared/logging/Logger';
import { ContentService } from '@shared/services/content.service';
import { ContentType } from '@shared/models/content-type';

@Component({
  selector: 'app-privacy-notice',
  templateUrl: './privacy-notice.component.html',
  styleUrls: ['./privacy-notice.component.scss']
})
export class PrivacyNoticeComponent implements OnInit, OnDestroy {
  public introSection: IntroSection;
  public content: ContentSection[];
  public legal: string;
  private localizeSubscription: Subscription;
  constructor(
    private logger: Logger,
    private spinnerService: NgxSpinnerService,
    private localizeService: LocalizeRouterService,
    private contentService: ContentService) {
  }

  ngOnInit() {
    this.prepareIntroSectionValues();
    this.updateContent();
    this.localizeSubscription = this.localizeService.routerEvents.subscribe((language: string) => {
      this.updateContent(language);
    });
  }

  updateContent(language?: string) {
    this.spinnerService.show();

    this.contentService.getContent(ContentType.DPP, language).subscribe((response) => {
      if (response instanceof ResponseError) {
        this.logger.error(response);
        this.spinnerService.hide();
        return;
      }
      this.content = response.content;
      window.scrollTo(0, 0);
      this.spinnerService.hide();
    });
  }

  prepareIntroSectionValues() {
    this.introSection = new IntroSection();
    this.introSection.image = './assets/privacy-notice/Privacy-Hero.png';
    this.introSection.Title = 'Privacy_Notice_Title_Text';
    this.introSection.Description = 'Privacy_Notice_Description_Text';
  }

  ngOnDestroy(): void {
    this.localizeSubscription.unsubscribe();
  }
}
