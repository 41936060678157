export class SessionKeys {

    constructor() {
    }

    // Used for account activation/reset password
    public static get email(): string {
        return 'email';
    }

    // VEHICLE
    public static get newVehicle(): string {
        return 'newVehicle';
    }

    public static get vin(): string {
        return 'vin';
    }

    // SETTINGS
    public static get authUser(): string {
        return 'authUser';
    }

    public static get bearerToken(): string {
        return 'bearerToken';
    }

    public static get languageCookie(): string {
        return 'autoLanguage'; // to review the name, name specified in jira
    }

    public static get appVersion(): string {
        return 'appVersion';
    }

    public static get translationKeys(): string {
        return 'tKeys';
    }

    // CONTEXT
    public static get userContext(): string {
        return 'userContext';
    }

    public static get productCheckoutHppContext(): string {
        return 'checkoutContext';
    }

    public static get registrationContext(): string {
        return 'registrationContext';
    }

    public static get teaserEligibilityContext(): string {
        return 'teaserEligibilityContext';
    }

    public static get identificationValidationContext(): string {
        return 'identificationValidationContext';
    }

    public static get redirectContext(): string {
        return 'redirectContext';
    }

    public static get incarTopupContext(): string {
        return 'incarTopupContext';
    }

    public static get isTeaserEligible(): string {
        return 'isEligibleForTeaser';
    }
}
