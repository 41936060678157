import { Component, OnInit } from '@angular/core';
import { PlansService } from './_services/plans.service';
import { Plan } from './_models/plan';
import { ResponseError } from '@shared/models';
import { LocationService } from '@shared/services';
import { PriceFormatLocalisationService } from '@shared/services/PriceFormatLocalisationService';

@Component({
  selector: 'app-home-plans',
  templateUrl: './home-plans.component.html',
  styleUrls: ['./home-plans.component.scss']
})
export class HomePlansComponent implements OnInit {

  public arePlansAvailable: boolean = false;
  public Plans: Plan[];
  constructor(private _plansService: PlansService,
    private _locationService: LocationService,
    private priceLocalisation: PriceFormatLocalisationService) {
  }

  ngOnInit() {
    this.getHomeCountry();
  }

  private getHomeCountry() {
    this._locationService.getClientCountryCode().subscribe(
      (countryCode) => {
        if (countryCode) { this.getBestSellingPlans(countryCode); }
      },
      () => { this.getBestSellingPlans(this._locationService.DefaultCountryCode); }
    );
  }

  private getBestSellingPlans(countryCode: string) {
    let countryCultureString = this.priceLocalisation.getCultureCode(countryCode)
    this._plansService.getBestSellingPlans(countryCode).subscribe(
      (plans: Plan[]) => {
        this.Plans = plans.slice(0, 3);
        let indexofLargest = 0;
        let largestElement = this.Plans[0];

        for (let index = 0; index < this.Plans.length; index++) {
          const currentElement = this.Plans[index];
          currentElement.price = this.priceLocalisation.getLocalisedPrice(countryCultureString, currentElement.price);
          if (currentElement.amountOfPurchases > largestElement.amountOfPurchases) {
            largestElement = currentElement;
            indexofLargest = index;
          }
        }

        const tmpPlan = plans[1];
        this.Plans[1] = plans[indexofLargest];
        this.Plans[indexofLargest] = tmpPlan;
        this.Plans[1].enlarge = true;
        this.arePlansAvailable = true;
      },
      (error: ResponseError) => {
        this.arePlansAvailable = false;
      }
    );
  }

}
