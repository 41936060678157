import { LanguageCode } from './language-code';

export class Language {
    name: string;
    isoCode: LanguageCode;

    constructor(name: string, locale: LanguageCode) {
        this.name = name;
        this.isoCode = locale;
    }
}
