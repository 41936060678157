import { Component } from '@angular/core';
import { AuthenticationService } from '@shared/services/authentication.service';
import { VerifyVehicle } from '../_models/verify-vehicle';
import { AlertService, LanguageService } from '@shared/services';
import { NgxSpinnerService } from 'ngx-spinner';
import { VehicleService } from '../_services';
import { ResponseError } from '@shared/models';
import { Logger } from '@shared/logging/Logger';
import { AddConfirmVehicleRequest } from '../_models/add-confirm-vehicle-request';

@Component({
  selector: 'app-vehicle-add',
  templateUrl: './vehicle-add.component.html',
  styleUrls: ['./vehicle-add.component.scss']
})
export class VehicleAddComponent {

  identityId: string;
  currentVehicle: VerifyVehicle;

  constructor(private authenticationService: AuthenticationService,
    private languageService: LanguageService,
    private vehicleService: VehicleService,
    private spinnerService: NgxSpinnerService,
    private alertService: AlertService,
    private logger: Logger) {
    this.identityId = this.authenticationService.appAuthUser.identityId;
  }

  cancelCallback() {
    this.languageService.localizedNavigate(['/dashboard']);
  }

  continueCallback(response: VerifyVehicle) {
    this.alertService.clear();
    this.spinnerService.show();

    let addVehicle = new AddConfirmVehicleRequest();
    addVehicle.vin = response.vehicle.vin;
    if(response.teaser) {
      addVehicle.teaserPackageSku = response.teaser.packageSku;
    }

    this.vehicleService.confirmAddVehicle(addVehicle).subscribe({
      next: () => {
        this.languageService.localizedNavigate(['/dashboard'], { queryParams: { forceDataRefresh: true } });
      },
      error: (err: ResponseError) => {
        this.handleError(err);
      },
      complete: () => { this.spinnerService.hide(); }
    });
  }

  private handleError(err: ResponseError) {
    this.spinnerService.hide();
    this.alertService.error(err.message, 0, true, true);
    this.logger.error(err);
    //Warning: Do not redirect to any other page, it will cause bug on alert message
  }
}
