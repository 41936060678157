<div class="incar-topup-card">
  <strong data-test-id="incar-topup-card-title">{{'Incar_TopUp_Title' | translate}}</strong>
  <div class="incar-topup-container">
    <div class="position-relative">
      <ngx-loading [show]="loading"></ngx-loading>
      <div *ngIf="loading === false">
        <!-- 
          Request doesn't fail when: 
            - user has in-car topup enabled 
        -->
        <div *ngIf="!requestFailed">
          <span data-test-id="incar-topup-card-status">{{'Incar_TopUp_Status' | translate}}</span>
          <div class="incar-topup-status active" data-test-id="incar-topup-card-status-icon">
            <span class="status-value" data-test-id="incar-topup-card-status-active">{{'Incar_TopUp_Status_Active' |
              translate}}</span>
          </div>
          <div class="weekly-limit">
            <label data-test-id="incar-topup-card-weekly-limit">{{'Incar_TopUp_WeeklyLimit' | translate}}</label>:
            <label *ngIf="weeklyLimitValue===null" data-test-id="incar-topup-card-weekly-limit-no-limit">
              No limit </label>
            <label *ngIf="weeklyLimitValue!==null" data-test-id="incar-topup-card-weekly-limit-selected-ammout">
              {{weeklyLimitValue}} </label>
          </div>
          <div class="card-ending">
            <label data-test-id="incar-topup-card-card-ending">{{'Incar_TopUp_CardEnding' | translate}}</label>:
            <label *ngIf="cardEndingValue===null"> ---- </label>
            <label *ngIf="cardEndingValue!==null"> {{cardEndingValue}} </label>
          </div>
          <div class="sms-update">
            <label data-test-id="incar-topup-card-sms-update">{{'Incar_TopUp_SMSUpdate' | translate}}</label>:
            <label *ngIf="inCarTopup.sponsorUserDetails.sendLowBalanceNotification"
              data-test-id="incar-topup-card-sms-update-enabled">
              {{'Incar_TopUp_Enabled' | translate}}
            </label>
            <label *ngIf="!inCarTopup.sponsorUserDetails.sendLowBalanceNotification"
              data-test-id="incar-topup-card-sms-update-disabled">
              {{'Incar_TopUp_Disabled' | translate}}
            </label>
          </div>
        </div>
        <!-- 
          Request Fails when: 
            - user doesnt have saved credit cards
            - user needs to set up in-car Topup 
        -->
        <div *ngIf="requestFailed">
          <div *ngIf="showRequestFailedTextSection">
            <label data-test-id="incar-topup-card-warning1"> {{inCarTopup_Card_Convenient}} </label>
            <br />
            <label data-test-id="incar-topup-card-warning2"> {{incarTopup_Card_Warning2}}
            </label>
          </div>
          <div *ngIf="!showRequestFailedTextSection">
            <label data-test-id="incar-topup-card-warning3">
              {{'InCarTopup_Card_Retrieval_Error' | translate}}
            </label>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-sm-12">
      <div class="d-flex justify-content-center">
        <div *ngIf="loading === false && showBtn">
          <button type="button" class="btn btn-primary" (click)="redirectToManageIncarTopup()"
            data-test-id="incar-topup-card-btn">{{incarTopup_Card_Btn}}</button>
        </div>
      </div>
    </div>
  </div>
</div>