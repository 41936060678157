import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Vehicle, WalletItem, UsersAndHighestVehicleRoleStatus, HighestVehicleRoleStatus, VehicleUserStatus, InCarTopup, VehicleInfomationRequest } from '../_models/index';
import { ResponseError, BaseResponse } from '@shared/models';
import { BaseHttpService, ConfigService, IdentificationValidationContextService, AuthenticationService } from '@shared/services';
import { catchError, map, switchMap } from 'rxjs/operators';
import { UserContextService } from '@shared/services/user-context.service';
import { ProductCheckoutStatus } from '../../product/_models';
import { VehicleImageUrl } from '../_models/vehicle-image-url';
import { VehicleImagesUrlService } from './vehicle-images-url.service';
import { TranslateService } from '@ngx-translate/core';
import { DatesUtilityService } from '../../../_shared/services/dates-utility.service';
import { forkJoin, Observable, of, Subject } from 'rxjs';
import { AddConfirmVehicleRequest } from '../_models/add-confirm-vehicle-request';

@Injectable()
export class VehicleService extends BaseHttpService {

  defaultImage: string;
  audiBrand: string = 'audi';
  vwBrand: string = 'vw';
  skodaBrand: string = 'skoda';
  Gen4EcuVariations: string[] = ['OCU4', 'OCU4UNECE'];
  teaserEligibleBrands: string[] = ['VW', 'AUDI', 'BENTLEY'];

  constructor(
    _httpClient: HttpClient,
    _configService: ConfigService,
    _translateService: TranslateService,
    private authenticationService: AuthenticationService,
    private userContextService: UserContextService,
    private identificationValidationContextService: IdentificationValidationContextService,
    private vehicleImagesUrlService: VehicleImagesUrlService,
    private dateUtilityService: DatesUtilityService
  ) {
    super(_httpClient, _configService, _translateService);
  }

  /* GET */
  getVehicleWallet(vin: string): Observable<WalletItem[] | ResponseError> {
    return this.get<any>(`/vehicles/${vin}/wallets`).pipe(
      map(res => {
        const activePlans: WalletItem[] = res.activeWallets as WalletItem[];
        const pendingPlans: WalletItem[] = res.pendingWallets as WalletItem[];
        activePlans.forEach(plan => {
          plan.isActive = true;
          plan.validityDate = this.dateUtilityService.getLocalisedDateFromUtc(plan.validityDate);
          return plan;
        });
        pendingPlans.forEach(plan => { plan.isActive = false; return plan; });

        return activePlans.concat(pendingPlans);
      })
    );
  }

  /* POST */
  getVehicles(request: VehicleInfomationRequest, forceDataRefresh = false): Observable<Vehicle[] | ResponseError> {
    if (forceDataRefresh === false) {
      const vehiclesFromSession = this.getVehiclesFromUserContext();
      if (vehiclesFromSession !== null && vehiclesFromSession.length > 0) {
        return of(vehiclesFromSession);
      }
    }

    const subject = new Subject<Vehicle[] | ResponseError>();
    this.post<Vehicle[]>('/v2/vehicles/getAll', request).subscribe(res => {
      const vehicles: Vehicle[] = res as Vehicle[];
      vehicles.forEach(data => {
        data = this.defaultVehicleValues(data);
        this.vehicleImagesUrlService.getPhoto(data).subscribe((imageUrl: VehicleImageUrl) => {
          const vehicle = data as Vehicle;
          vehicle.vehiclePhotoImage = imageUrl.imageUrl;
          return vehicle;
        });
      });
      this.userContextService.userContext.vehicles = vehicles;
      this.userContextService.update();
      subject.next(vehicles);
    }, () => {
      subject.next(new Array<Vehicle>());
    });

    return subject;
  }

  getVehicle(vin: string, request: VehicleInfomationRequest, forceDataRefresh = false): Observable<Vehicle | ResponseError> {
    if (forceDataRefresh === false) {
      const vehicleFromSession = this.getVehicleFromUserContext(vin);
      if (vehicleFromSession !== null) {
        return of(vehicleFromSession);
      }
    }

    const subject = new Subject<Vehicle | ResponseError>();
    this.post<Vehicle>(`/v2/vehicles/${vin}`, request).subscribe((vehicleDetails: Vehicle) => {
      this.vehicleImagesUrlService.getPhoto(vehicleDetails).subscribe((imageUrl: VehicleImageUrl) => {
        const vehiclesArray = Vehicle[1] = [vehicleDetails];
        vehicleDetails.vehiclePhotoImage = imageUrl.imageUrl;
        this.userContextService.userContext.vehicles = vehiclesArray;
        this.userContextService.update();
        this.defaultVehicleValues(vehicleDetails);
        subject.next(vehicleDetails);
      });
    });

    return subject;
  }

  verifyVehicle(vin: string,): Observable<Vehicle | ResponseError> {
    return this.get<Vehicle>(`/v2/vehicles/${vin}/verify`).pipe(
      switchMap(response => {
        if (response instanceof ResponseError) {
          return of(response);
        }

        return forkJoin([of(response), this.vehicleImagesUrlService.getPhoto(response)])
          .pipe(
            switchMap(forkJoinResponse => {
              const vehicle = forkJoinResponse[0];
              const vehicleImageUrl = forkJoinResponse[1] as VehicleImageUrl;

              if (vehicleImageUrl) {
                vehicle.vehiclePhotoImage = vehicleImageUrl.imageUrl
              }
              return of(vehicle);
            })
          );
      }),
      catchError(error => {
        return of(error);
      })
    )
  }

  vehicleHasLiableUser(vin: string): boolean {
    const vehicle = this.getVehicleFromUserContext(vin);
    return vehicle.hasLiableUser;
  }

  confirmAddVehicle(vehicleConfirmData: AddConfirmVehicleRequest): Observable<Vehicle | ResponseError> {
    return this.post<Vehicle>('/vehicles/confirm', vehicleConfirmData);
  }

  removeVehicle(request: Vehicle): Observable<Vehicle | ResponseError> {
    this.resetVehicleFromUserContext();
    return this.post<Vehicle>('/vehicles/remove', request);
  }

  setPersonalisedName(request: Vehicle): Observable<Vehicle | ResponseError> {
    return this.post<Vehicle>('/vehicles/setPersonalisedName', request).pipe(
      map(res => {
        this.updateVehicleSetPetName(request.vin, request.personalisedName);
        return res;
      })
    );
  }

  updateInCarTopup(request: InCarTopup): Observable<BaseResponse<InCarTopup | ResponseError>> {
    return this.postBaseResponse<InCarTopup>(`/vehicles/editInCarTopUp`, request).pipe(
      map(res => res)
    );
  }
  setAsFavourite(request: Vehicle, isFavouriteVehicle: boolean): Observable<Vehicle | ResponseError> {
    const setAsFavourite = isFavouriteVehicle ? 'setAsFavourite' : 'setAsNotFavourite';
    return this.post<Vehicle>(`/vehicles/${setAsFavourite}`, request).pipe(
      map(res => {
        this.updateVehicleSetAsFavourite(request.vin, isFavouriteVehicle);
        return res;
      })
    );
  }

  /**** VEHICLE USER ASSOCIATIONS ****/

  forceRefreshVehicleUserStatus(vin: string): Observable<boolean> {
    return this.get<UsersAndHighestVehicleRoleStatus>(`/vehicles/${vin}/users`).pipe(
      map(res => {
        const data = res as UsersAndHighestVehicleRoleStatus;
        this.UpdateUserVehicleRoleStatus(data, vin);
        return true;
      })
    );
  }

  userCanBuyPlan(vin: string): Observable<boolean> {
    if (this.identificationValidationContextService.context.isValidIdentification &&
      (this.userContextService.userContext.homeCountryCode !== null
        && this.userContextService.userContext.homeCountryCode !== undefined
        && this.userContextService.userContext.homeCountryCode !== '')
    ) {
      const vehicle = this.getVehicleFromUserContext(vin);
      if (vehicle !== null) {
        if (vehicle.hasLiableUser) {
          return of(true);
        } else {
          if (vehicle.userStatus.toString() === VehicleUserStatus[VehicleUserStatus.STANDARDUSER].toString()) {
            return of(false);
          } else {
            if (vehicle.userStatus.toString() === VehicleUserStatus[VehicleUserStatus.PENDINGLIABLEUSER].toString()) {
              return of(true);
            } else {
              return of(false);
            }
          }
        }
      } else {
        return of(false);
      }
    } else {
      return of(false);
    }
  }

  isBrandTeaserEligible(brand: String): boolean {
    return this.teaserEligibleBrands.includes(brand.toUpperCase());
  }

  canPromoteToPendingLiableUser(vin: string): Observable<boolean> {
    // IF vehicle has no liable AND (user is identified OR user doesn't needs proof) AND user status is standard
    // THEN can promote
    const vehicle = this.getVehicleFromUserContext(vin);
    if (vehicle !== null) {
      if (!vehicle.hasLiableUser && this.identificationValidationContextService.context.isValidIdentification
        && ((vehicle.userStatus === VehicleUserStatus.STANDARDUSER || vehicle.userStatus.toString() === VehicleUserStatus[VehicleUserStatus.STANDARDUSER].toString()))) {
        return of(true);
      } else {
        return of(false);
      }
    }
    return of(true);
  }

  /*SPONSOR USER*/
  prepareInCarTopUp(request: InCarTopup): Observable<BaseResponse<InCarTopup | ResponseError>> {
    return this.postBaseResponse<InCarTopup>(`/vehicles/prepareInCarTopUp`, request).pipe(
      map(res => res)
    );
  }

  /**** PRIVATE METHODS ****/

  private updateVehicleUserStatusForAllTypes(vin: string, userStatus: VehicleUserStatus,
    hasLiableUser: boolean, isLiableUser: boolean = null, isSponsorUser: boolean = null, isSecondaryUser: boolean = null): void {

    const vehicle = this.userContextService.userContext.vehicles.find(x => x.vin === vin);
    const index = this.userContextService.userContext.vehicles.indexOf(vehicle);
    vehicle.userStatus = userStatus;
    vehicle.hasLiableUser = hasLiableUser;

    if (isLiableUser !== null) {
      vehicle.isLiableUser = isLiableUser;
    }

    if (isSponsorUser !== null) {
      vehicle.isSponsorUser = isSponsorUser;
    }

    if (isSecondaryUser !== null) {
      vehicle.isSecondaryUser = isSecondaryUser;
    }

    this.userContextService.userContext.vehicles[index] = vehicle;
  }

  private updateVehicleSetAsFavourite(vin: string, isFavourite: boolean): void {
    // First update the vehicle to set as favourite
    // is isFavourite = true: Update remaining cars to be not favourite
    const vehicle = this.getVehicleFromUserContext(vin);
    if (vehicle !== null) {
      const index = this.userContextService.userContext.vehicles.indexOf(vehicle);
      vehicle.isFavourite = isFavourite;
      this.userContextService.userContext.vehicles[index] = vehicle;
      this.userContextService.update();
      if (isFavourite) {
        if (this.userContextService.userContext.vehicles.length > 0) {
          const notFavVehicles = this.userContextService.userContext.vehicles.filter(x => x.vin !== vin);
          if (notFavVehicles !== undefined && notFavVehicles !== null) {
            if (notFavVehicles.length > 0) {
              notFavVehicles.forEach(notFavourite => {
                const ind = this.userContextService.userContext.vehicles.indexOf(vehicle);
                notFavourite.isFavourite = false;
                this.userContextService.userContext.vehicles[ind] = vehicle;
              });
              this.userContextService.update();
            }
          }
        }
      }
    }
  }

  private updateVehicleSetPetName(vin: string, name: string): void {
    const vehicle = this.getVehicleFromUserContext(vin);
    if (vehicle !== null) {
      const index = this.userContextService.userContext.vehicles.indexOf(vehicle);
      vehicle.personalisedName = name;
      this.userContextService.userContext.vehicles[index] = vehicle;
      this.userContextService.update();
    }
  }

  private defaultVehicleValues(vehicle: Vehicle): Vehicle {
    if (vehicle.personalisedName === null) { vehicle.personalisedName = 'My Car'; }
    return vehicle;
  }


  /* Vehicle Data */

  private getVehiclesFromUserContext(): Vehicle[] {
    const vehicles = this.userContextService.userContext.vehicles;
    if (vehicles === null) { return null; }
    if (vehicles.length === 0) { return null; }
    return vehicles;
  }

  private getVehicleFromUserContext(vin: string): Vehicle {
    const vehicles = this.userContextService.userContext.vehicles;
    if (vehicles === null) { return null; }
    if (vehicles.length === 0) { return null; }
    const vehicle = vehicles.find(x => x.vin === vin);
    if (vehicle === undefined || vehicle === null) { return null; }
    return vehicle;
  }

  private resetVehicleFromUserContext(): void {
    this.userContextService.userContext.vehicles = new Array<Vehicle>();
    this.userContextService.update();
  }

  public UpdateUserVehicleRoleStatus(data: UsersAndHighestVehicleRoleStatus, vin: string) {
    let productCheckoutStatus: any = null;
    let vehicleUserStatus: any = null;
    let hasLiableUser: boolean = false;
    let isLiableUser: boolean = null;
    let isSponsorUser: boolean = null;

    /* A secondary user is a different user that also added the VIN to their account
    // The secondary user shouldn't be allowed to see information or buy plans for the device
    */
    let isSecondaryUser: boolean = null;

    switch (data.highestVehicleRoleStatus) {
      case HighestVehicleRoleStatus.Sponsor: {
        productCheckoutStatus = ProductCheckoutStatus.AsSponsorUser;
        hasLiableUser = true;

        const actualRole = data.users.filter(x => x.role === VehicleUserStatus[VehicleUserStatus.SPONSORUSER].toString() &&
          x.identityId.toLowerCase() === this.authenticationService.appAuthUser.identityId.toLowerCase())[0];
        if (actualRole !== null && actualRole !== undefined) {
          isSponsorUser = true;
          vehicleUserStatus = actualRole.role;
        } else {
          isSponsorUser = false;
          isSecondaryUser = true;
          vehicleUserStatus =
            data.users.filter(x => x.identityId.toLowerCase() === this.authenticationService.appAuthUser.identityId.toLowerCase())[0].role;
        }
        break;
      }

      case HighestVehicleRoleStatus.Liable: {
        productCheckoutStatus = ProductCheckoutStatus.AsLiableUser;
        hasLiableUser = true;
        const actualRole = data.users.filter(x => x.role === VehicleUserStatus[VehicleUserStatus.LIABLEUSER].toString() &&
          x.identityId.toLowerCase() === this.authenticationService.appAuthUser.identityId.toLowerCase())[0];
        if (actualRole !== null && actualRole !== undefined) {
          isLiableUser = true;
          vehicleUserStatus = actualRole.role;
        } else {
          isLiableUser = false;
          isSecondaryUser = true;
          vehicleUserStatus =
            data.users.filter(x => x.identityId.toLowerCase() === this.authenticationService.appAuthUser.identityId.toLowerCase())[0].role;
        }
        break;
      }

      case HighestVehicleRoleStatus.NoLiable: {
        productCheckoutStatus = ProductCheckoutStatus.AsStandardUser;
        isLiableUser = false;
        isSponsorUser = false;
        isSecondaryUser = false;
        vehicleUserStatus = VehicleUserStatus[VehicleUserStatus.STANDARDUSER];
        break;
      }

      case HighestVehicleRoleStatus.PendingLiable: {

        const actualRole = data.users.filter(x => x.role === VehicleUserStatus[VehicleUserStatus.PENDINGLIABLEUSER].toString() &&
          x.identityId.toLowerCase() === this.authenticationService.appAuthUser.identityId.toLowerCase())[0];
        if (actualRole !== null && actualRole !== undefined) {
          productCheckoutStatus = ProductCheckoutStatus.AsPendingLiableUser;
          vehicleUserStatus = VehicleUserStatus[VehicleUserStatus.PENDINGLIABLEUSER];
        } else {
          isSecondaryUser = false;
          productCheckoutStatus = ProductCheckoutStatus.AsStandardUser;
          vehicleUserStatus =
            data.users.filter(x => x.identityId.toLowerCase() === this.authenticationService.appAuthUser.identityId.toLowerCase())[0].role;
        }
        isLiableUser = false;
        isSponsorUser = false;
        break;
      }
      default: {
        productCheckoutStatus = ProductCheckoutStatus.AsStandardUser;
        vehicleUserStatus = VehicleUserStatus[VehicleUserStatus.Unknown];
      }
    }

    // update product checkout status
    this.userContextService.userContext.productCheckoutStatus = productCheckoutStatus;

    // update vehicle user status
    this.updateVehicleUserStatusForAllTypes(vin, vehicleUserStatus, hasLiableUser, isLiableUser, isSponsorUser, isSecondaryUser);

    // update context will all new properties
    this.userContextService.update();
  }


  checkIsGen4Vehicle(vin: string): boolean {
    const { vehicles } = this.userContextService.userContext;
    const vehicleIndex = vehicles.findIndex(vehicle => vehicle.vin === vin);
    if (vehicleIndex === -1) return false;
    const vehicleEcuGeneration = vehicles[vehicleIndex].vehicleEcuGeneration?.toUpperCase() ?? "";
    return this.Gen4EcuVariations.includes(vehicleEcuGeneration);
  }
}
