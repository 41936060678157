import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { UiSwitchModule } from 'ngx-ui-switch';
// Shared
import { SidebarComponent } from './sidebar/sidebar.component';
import { NgxLoadingModule } from 'ngx-loading';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { DataVolumePipePipe } from '@shared/pipes/data-volume-pipe.pipe';
import { JoinStringsPipePipe } from '@shared/pipes/join-strings-pipe.pipe';
import { VehicleResolverService } from '@shared/resolvers/vehicle-resolver.service';
import { ProductService } from '../product/_services/product.service';
import { DatesUtilityService } from '@shared/services/dates-utility.service';
import { IdentificationValidationContextService } from '@shared/services/identification-validation-context.service';

// Components
import { DashboardComponent } from './dashboard.component';
import { DashboardViewComponent } from './dashboard-view/dashboard-view.component';

// Account
import { AccountDeleteComponent } from '../account/account-delete/account-delete.component';

// Identification
import { SubmitIdentityComponent } from '../user/identification/id-verification/submit-identity.component';

// Vehicle
import { VehicleDetailsViewComponent } from '../vehicles/vehicle-details/vehicle-details-view/vehicle-details-view.component';
import { VehicleDetailsComponent } from '../vehicles/vehicle-details/vehicle-details.component';
import { VehicleRemoveComponent } from '../vehicles/vehicle-remove/vehicle-remove.component';
import { VehicleDetailsCardComponent } from '../vehicles/vehicle-details-card/vehicle-details-card.component';
import { VehiclePlansComponent } from '../vehicles/vehicle-plans/vehicle-plans.component';
import { WalletItemComponent } from '../vehicles/wallet-item/wallet-item.component';
import { VehicleService } from '../vehicles/_services';
import { VehicleIncarTopupCardComponent } from '../vehicles/vehicle-incar-topup-card/vehicle-incar-topup-card.component';

// History
import { UsageHistoryCardComponent } from '../history/usage-history/usage-history-card/usage-history-card.component';
import { UsageHistoryService } from '../history/_services/usage-history.service';
import { PurchaseHistoryCardComponent } from '../history/purchase-history/purchase-history-card/purchase-history-card.component';
import { PurchaseHistoryService } from '../history/_services/purchase-history.service';

// Plan
import { ProductCatalogComponent } from '../product/product-catalog/product-catalog.component';
import { ProductCardComponent } from '../product/product-card/product-card.component';
import { SharedComponentsModule } from '@shared/components/shared-components.module';
import { ProductModule } from '../product/product.module';

import { RouterModule } from '@angular/router';
import { FavouriteNameComponent } from '../vehicles/favourite-name/favourite-name.component';
import { AccountService } from '../account/_services/account.service';
import { ProductCheckoutHppContextService } from '@shared/services/product-checkout-context-hpp.service';
import { OrdersService, PaymentsService } from '@app/product/_services';

@NgModule({
    declarations: [
        DashboardComponent,
        DashboardViewComponent,
        VehicleRemoveComponent,
        VehicleDetailsViewComponent,
        VehicleDetailsComponent,
        UsageHistoryCardComponent,
        SidebarComponent,
        VehicleDetailsCardComponent,
        VehiclePlansComponent,
        WalletItemComponent,
        DataVolumePipePipe,
        JoinStringsPipePipe,
        PurchaseHistoryCardComponent,
        SubmitIdentityComponent,
        ProductCatalogComponent,
        ProductCardComponent,
        FavouriteNameComponent,
        AccountDeleteComponent,
        VehicleIncarTopupCardComponent
        // CardPageHeaderComponent
    ],
    imports: [
        CommonModule,
        ReactiveFormsModule,
        NgbModule,
        ProductModule,
        RouterModule,
        NgxLoadingModule.forRoot({
            backdropBackgroundColour: 'rgba(255,255,255,0)',
            backdropBorderRadius: '4px',
            primaryColour: '#cecece',
            secondaryColour: '#cecece',
            tertiaryColour: '#cecece'
        }),
        SharedComponentsModule,
        UiSwitchModule
    ],
    providers: [
        UsageHistoryService,
        VehicleService,
        VehicleResolverService,
        PurchaseHistoryService,
        ProductService,
        DatesUtilityService,
        AccountService,
        IdentificationValidationContextService,
        ProductCheckoutHppContextService,
        OrdersService,
        PaymentsService
    ]
})
export class DashboardModule { }
