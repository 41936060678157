import { Injectable } from '@angular/core';
import { BaseHttpService, ConfigService, LanguageService } from '@shared/services';
import { HttpClient } from '@angular/common/http';
import { AccountPassword, UpdatePersonalDetails, AccountDelete, DeleteSavedCreditCard, CountryLegalRequirementsResponse } from '../_models';
import { ResponseError } from '@shared/models';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';

@Injectable()
export class AccountService extends BaseHttpService {

    public myAccountLink: string;
    private faqLink: string;
    private language: string;

    constructor(
        _httpClient: HttpClient,
        _configService: ConfigService,
        _translateService: TranslateService,
        private _languageService: LanguageService
    ) {
        super(_httpClient, _configService, _translateService);
        this.language = this._languageService.getActiveLanguage().isoCode || 'en';
        this.myAccountLink = this.getMyAccountLink();
        this.faqLink = this.getFaqLink();
    }

    // POST
    changePassword(request: AccountPassword): Observable<AccountPassword | ResponseError> {
        return this.post<AccountPassword>('/accounts/updatePassword', request);
    }

    updatePersonalDetails(request: UpdatePersonalDetails): Observable<UpdatePersonalDetails | ResponseError> {
        return this.post<UpdatePersonalDetails>('/accounts/updateAccountDetails', request);
    }

    deleteAccount(request: AccountDelete): Observable<AccountDelete | ResponseError> {
        request.myAccountLink = this.myAccountLink;
        request.faqLink = this.faqLink;
        return this.post<AccountDelete>('/accounts/delete', request);
    }

    getPersonalDetails(): Observable<UpdatePersonalDetails | ResponseError> {
        return this.get<UpdatePersonalDetails>('/v2/accounts/getAccountDetails');
    }

    getLegalRequirementsPerCountry(): Observable<CountryLegalRequirementsResponse | ResponseError> {
        return this.get<CountryLegalRequirementsResponse>('/accounts/countrysLegalRequirements');
    }

    deleteSavedCreditCard(request: DeleteSavedCreditCard): Observable<DeleteSavedCreditCard | ResponseError> {
        return this.post<DeleteSavedCreditCard>('/accounts/deleteSavedCreditCard', request);
    }

    // GET
    private getMyAccountLink() {
        return this._configService.config.myAccountLink + '/' + this.language;
    }

    private getFaqLink() {
        return this._configService.config.faqLink + '/' + this.language + '/faq';
    }
}
