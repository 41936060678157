<div class="main-content">
  <div class="container">
    <div class="forgot-password-container">
      <div class="row justify-content-center">
        <div class="col-sm-12">
          <h3 class="text-center" data-test-id="forgot-password-title">{{'Reset_Password' | translate}}</h3>
          <p class="forgot-password-description" data-test-id="forgot-password-description">
            {{'ForgotPassword_Description' | translate}}
          </p>
        </div>
      </div>
      <form [formGroup]="forgotPasswordForm" novalidate class="forgot-password-form"
        data-test-id="forgot-password-form">
        <!-- Email -->
        <div class="form-group">
          <label data-test-id="forgot-password-form-email-text">{{'Email_Address' | translate}}</label><label
            class="text-danger ml-1">*</label>
          <input type="text" class="form-control" formControlName="email"
            data-test-id="forgot-password-form-email-input">
          <div *ngIf="forgotPasswordForm.controls['email'].errors">
            <span data-test-id="forgot-password-form-email-error" class="invalid-note"
              *ngIf="!forgotPasswordForm.controls['email'].pristine && forgotPasswordForm.controls['email'].errors.required">{{'Email_Required_Error'
              | translate}}</span>
            <span data-test-id="forgot-password-form-email-invalid-error" class="invalid-note"
              *ngIf="!forgotPasswordForm.controls['email'].pristine && forgotPasswordForm.controls['email'].errors.email && !forgotPasswordForm.controls['email'].errors.required">
              {{'Email_Invalid_Error' | translate}}
            </span>
          </div>
        </div>
        <button type="submit" class="btn btn-primary btn-block" [disabled]="!forgotPasswordForm.valid"
          aria-disabled="!forgotPasswordForm.valid" (click)="forgotPassword()"
          data-test-id="forgot-password-send-btn">{{'ForgotPassword_Form_SendLinkBtn' | translate}}</button>
      </form>
      <div class="row justify-content-center">
        <div class="required-fields" data-test-id="forgot-password-required-text">
          {{'Form_Note_Required_Fields' | translate}}
        </div>
      </div>
    </div>
  </div>
</div>