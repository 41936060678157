<div class="container">
  <div class="row justify-content-center ">
    <div class="col col-12">
      <div class="not-found-container no-gutters text-center">
        <div class="not-found-main " data-test-id="not-found">
          <div data-test-id="not-found-icon" class="not-found-icon"> </div>
          <h4 data-test-id="not-found-title">
            {{'App_NotFound_Title' | translate }}</h4>
          <p data-test-id="not-found-subtitle">{{'App_NotFound_Subtitle' | translate }}
            <a class="standard-link" [routerLink]="['/' | localize]" data-test-id="not-found-subtitle-link"
              [attr.title]="'App_NotFound_Title_Link' | translate">
              {{'App_NotFound_Title_Link' | translate }}</a>
          </p>
        </div>
      </div>
    </div>
  </div>
</div>