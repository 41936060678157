import { TranslateLoader } from "@ngx-translate/core";
import { ConfigService } from "@shared/services";
import { from, Observable } from "rxjs";


export class WebpackTranslateLoader implements TranslateLoader {
    constructor(private configService: ConfigService) {
    }

    getTranslation(lang: string): Observable<any> {
        return from(import(`locale/${this.configService.config.theme}/messages-${lang}.json`));
    }
}