import { NgModule } from '@angular/core';
import { ShowHideInputComponent } from './show-hide-input/show-hide-input.component';
import { CommonModule } from '@angular/common';
import { PencilComponent } from '@shared/components/pencil/pencil.component';
import { TickMarkComponent } from '@shared/components/tick-mark/tick-mark.component';
import { PageHeaderComponent } from '@shared/components/page-header/page-header.component';
import { TabComponent } from '@shared/components/tab/tab.component';
import { DataCurrencyPipePipe } from '@shared/pipes/data-currency-pipe.pipe';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { RouterModule } from '@angular/router';
import { ProductUpsellComponent } from '@shared/components/product-upsell/product-upsell.component';
import { ProgressbarComponent } from '@shared/components/progressbar/progressbar.component';
import { ImageToggleComponent } from '@shared/components/image-toggle/image-toggle.component';
import { ClickOutsideDirective } from '@shared/directives/click-outside.directive';
import { TranslateModule } from '@ngx-translate/core';
import { LocalizeRouterModule } from '@gilsdav/ngx-translate-router';
import { SelectDropdownComponent } from './select-dropdown/select-dropdown.component';
import { NgxTelInputDirective } from '@shared/directives/ngx-tel-input.directive';
import { ReactiveFormsModule } from '@angular/forms';
import { MoveNextOnMaxlengthDirective } from '@shared/directives/move-next-on-maxlength.directive';
import { VehicleVerificationComponent } from './vehicle-verification/vehicle-verification';
import { VehicleVerificationDetailsComponent } from './vehicle-verification/vehicle-verification-details/vehicle-verification-details';
import { VehicleVerificationIdentificationComponent } from './vehicle-verification/vehicle-verification-identification/vehicle-verification-identification';
import { VehicleVerificationIdentificationScanComponent } from './vehicle-verification/vehicle-verification-identification/vehicle-verification-identification-scan/vehicle-verification-identification-scan';
import { VehicleVerificationVehicleImageComponent } from './vehicle-verification/vehicle-verification-identification/vehicle-verification-vehicle-image/vehicle-verification-vehicle-image';
import { PartnershipComponent } from './partnership/partnership.component';
import { StepComponent } from './step/step.component';
import { CompatibilityBadgeComponent } from './compatibility-badge/compatibility-badge.component';
import { BundleDetailsComponent } from './dynamic/bundle-details/bundle-details.component';

@NgModule({
    imports: [RouterModule, CommonModule, NgbModule, TranslateModule, LocalizeRouterModule, ReactiveFormsModule],
    exports: [
        ShowHideInputComponent,
        PencilComponent,
        TickMarkComponent,
        PageHeaderComponent,
        TabComponent,
        DataCurrencyPipePipe,
        ProductUpsellComponent,
        ProgressbarComponent,
        ImageToggleComponent,
        SelectDropdownComponent,
        TranslateModule,
        LocalizeRouterModule,
        NgxTelInputDirective,
        MoveNextOnMaxlengthDirective,
        VehicleVerificationComponent,
        VehicleVerificationDetailsComponent,
        VehicleVerificationIdentificationComponent,
        VehicleVerificationIdentificationScanComponent,
        VehicleVerificationVehicleImageComponent,
        PartnershipComponent,
        StepComponent,
        CompatibilityBadgeComponent,
        ClickOutsideDirective,
        BundleDetailsComponent
    ],
    declarations: [
        ShowHideInputComponent,
        PencilComponent,
        TickMarkComponent,
        PageHeaderComponent,
        TabComponent,
        DataCurrencyPipePipe,
        ProductUpsellComponent,
        ProgressbarComponent,
        ImageToggleComponent,
        ClickOutsideDirective,
        NgxTelInputDirective,
        MoveNextOnMaxlengthDirective,
        SelectDropdownComponent,
        VehicleVerificationComponent,
        VehicleVerificationDetailsComponent,
        VehicleVerificationIdentificationComponent,
        VehicleVerificationIdentificationScanComponent,
        VehicleVerificationVehicleImageComponent,
        PartnershipComponent,
        StepComponent,
        CompatibilityBadgeComponent,
        BundleDetailsComponent
    ],
    providers: []
})
export class SharedComponentsModule { }
