<div class="main-account-reset-password-expired">
  <div class="container">
    <div class="account-reset-password-expired-container">
      <div class="row justify-content-center">
        <div class="col-sm-12">
          <h3 class="text-center" data-test-id="account-reset-password-expired-title">
            {{'AccountResetPasswordExpired_Title' | translate }}</h3>
          <p class="password-description" data-test-id="account-reset-password-expired-description">
            {{'AccountResetPasswordExpired_Description' | translate }}</p>
          <button data-test-id="account-reset-password-expired-btn" type="submit" class="btn btn-primary btn-block"
            (click)="resendResetPassword()">{{'SendLinkBtn' | translate
            }}</button>

        </div>
      </div>
    </div>
  </div>
</div>