<app-intro-section [introSection]='introSection'>
</app-intro-section>
<div class="container">
  <app-product-upsell [title]="'Getting_Started_SetUp_Title'" imageSrc="{theme}/getting-started/vehicle-add.png"
    paragraphTags="Getting_Started_SetUp_Instructions,Getting_Started_SetUp_IDValidation_Note" testIdPrefix="set-up"
    imageSide="left" class="upsell-separator"></app-product-upsell>
  <app-product-upsell [title]="'Getting_Started_Vehcile_Management_Title'"
    imageSrc="{theme}/getting-started/dashboard.png"
    paragraphTags="Getting_Started_Vehcile_Management_Paragraph1,Getting_Started_Vehcile_Management_Paragraph2"
    testIdPrefix="vehicle-management" class="upsell-separator"></app-product-upsell>
  <app-product-upsell *ngIf="showInCarTopup()" [title]="'Getting_Started_Sponsor_Title'"
    imageSrc="{theme}/getting-started/in-car-shop.png"
    paragraphTags="Getting_Started_Sponsor_Paragraph1,Getting_Started_Sponsor_Paragraph2,Getting_Started_Sponsor_Paragraph3"
    testIdPrefix="in-car-top-up" imageSide="left" class="upsell-separator"></app-product-upsell>
  <app-product-upsell [title]="'Getting_Started_Wi-Fi_Title'" imageSrc="{theme}/getting-started/hotspot.png"
    paragraphTags="Getting_Started_Wi-Fi_Paragraph1,Getting_Started_Wi-Fi_Paragraph2,Getting_Started_Wi-Fi_Paragraph3"
    testIdPrefix="wi-fi">
  </app-product-upsell>
</div>