export class Step {
    title: string;
    isMarked: boolean;
    isActive: boolean;
    constructor(title: string, isMarked: boolean, isActive: boolean) {
        this.title = title;
        this.isMarked = isMarked;
        this.isActive = isActive;
    }
}
