import { Component, OnInit } from '@angular/core';
import { IntroSection } from '@shared/models/intro-section';
@Component({
  selector: 'app-about-cubic',
  templateUrl: './about-cubic.component.html',
  styleUrls: ['./about-cubic.component.scss']
})
export class AboutCubicComponent implements OnInit {
  public introSection: IntroSection;
  constructor() { }

  ngOnInit() {
    this.introSection = new IntroSection();
    this.introSection.image = './assets/about-cubic/About-Hero.png';
    this.introSection.Title = 'What_Is_Cubic_Telecom_Title';
    this.introSection.Description = 'About_Cubic_Description_Text';
    window.scrollTo(0, 0);
  }

}
