import { Component, OnInit, Input } from '@angular/core';
import { ThemeService } from '@shared/services';
import { Logger } from '@shared/logging/Logger';

@Component({
  selector: 'app-product-upsell',
  templateUrl: './product-upsell.component.html',
  styleUrls: ['./product-upsell.component.scss']
})
export class ProductUpsellComponent implements OnInit {

  @Input() title: string;
  @Input() paragraphTags: string;
  @Input() imageSrc: string;
  @Input() imageAlt: string;
  @Input() imageSide: string;
  @Input() testIdPrefix: string;

  public isImageOnLeft: boolean = false;
  public paragraphs: string[] = [];

  constructor(
    private themeService: ThemeService,
    private logger: Logger) { }

  ngOnInit() {
    this.updateImageTheme();
    this.parseImageLocation();
    this.retrieveParagraphs();
  }

  private updateImageTheme(): void {
    if (this.imageSrc === undefined) {
      return;
    }
    try {
      const re = /{theme}/gi;
      this.imageSrc = this.imageSrc.replace(re, this.themeService.assetsLocation) + this.themeService.assetVersionNumber;
    } catch (e) {
      this.logger.error(e);
    }

  }

  private parseImageLocation(): void {
    if (this.imageSide === undefined) {
      return;
    }
    switch (this.imageSide.trim().toLowerCase()) {
      case 'left':
      case 'l':
        this.isImageOnLeft = true;
        break;
    }
  }

  private retrieveParagraphs(): void {
    if (this.paragraphTags === undefined) { return; }
    this.paragraphs = this.paragraphTags.split(',');
  }

}
