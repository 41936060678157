import { Component, Input } from "@angular/core";

@Component({
    selector: 'app-compatibility-badge',
    templateUrl: './compatibility-badge.component.html',
    styleUrls: ['./compatibility-badge.component.scss']
  })
  
  export class CompatibilityBadgeComponent {
  
    @Input() isCompatible: boolean;
  }
  