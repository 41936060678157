import { Country } from '@shared/models/country';

export class AccountAddress {
    public streetAddress1: string;
    public streetAddress2: string;
    public city: string;
    public state: string;
    public postCode: string;
    public country: string;
    public countryId: number;
    public countryCode: number;
    public supportedCountries: Country[];
}
