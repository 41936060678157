import { ValidationErrors, AbstractControl } from '@angular/forms';

export class CustomValidators {

    static containsUpperCase(control: AbstractControl): ValidationErrors {
        const value = control.value;
        if (!value) {
            return { '': true };
        }
        if (value.match('.*[A-Z].*')) {
            return null;
        }
        return { upperCase: true };
    }

    static containsLowerCase(control: AbstractControl): ValidationErrors {
        const value = control.value;
        if (!value) {
            return { lowerCase: true };
        }
        if (value.match('.*[a-z].*')) {
            return null;
        }
        return { lowerCase: true };
    }

    static containsNumber(control: AbstractControl): ValidationErrors {
        const value = control.value;
        if (!value) {
            return { number: true };
        }
        if (value.match('.*\\d.*')) {
            return null;
        }
        return { number: true };
    }

    static containsSpecialCharacter(control: AbstractControl): ValidationErrors {
        const value = control.value;
        if (!value) {
            return { specialCharacter: true };
        }
        if (value.match('[\/!@#$%^&*(),.?":{}|<>=+;_§\-]')) {
            return null;
        }
        return { specialCharacter: true };
    }

    static containsNumberOrSpecial(control: AbstractControl): ValidationErrors {
        const value = control.value;
        if (!value) {
            return { numberOrSpecial: true };
        }
        if (value.match('(?=.*?[/!@#$%^&*(),.?":{}|<>=+;_§0-9-])')) {
            return null;
        }
        return { numberOrSpecial: true };
    }

    static email(control: AbstractControl): ValidationErrors {
        const value = control.value;
        const regEx = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (!value) {
            return { email: true };
        }
        if (regEx.test(value)) {
            return null;
        }
        return { email: true };
    }

    static containsOnlyNumbers(control: AbstractControl): ValidationErrors {
        const value = control.value;
        if (!value) {
            return { number: true };
        }
        if (value.match('^[0-9]*$')) {
            return null;
        }
        return { number: true };
    }

    static phoneNumber(control: AbstractControl): ValidationErrors {
        const value = control.value;
        if (!value)
            return null;
        const regEx = /((?:\+|00)[17](?: |\-)?|(?:\+|00)[1-9]\d{0,2}(?: |\-)?|(?:\+|00)1\-\d{3}(?: |\-)?)?(0\d|\([0-9]{3}\)|[1-9]{0,3})(?:((?: |\-)[0-9]{2}){4}|((?:[0-9]{2}){4})|((?: |\-)[0-9]{3}(?: |\-)[0-9]{4})|([0-9]{7}))/;        
        if (regEx.test(value)) {
            return null;
        }
        return { phoneNumber: true };
    }
}
