import { Component, OnDestroy } from '@angular/core';
import { LocalizeRouterService } from '@gilsdav/ngx-translate-router';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnDestroy {
  public today: Date;
  public hideContractualSummary: Boolean;
  private localizeSubscription: Subscription;

  constructor(private translationsService: TranslateService,
    private localizeService: LocalizeRouterService) {

    this.localizeSubscription = this.localizeService.routerEvents.subscribe((language: string) => {
      this.updateOnLanguageChange();
    });

    //corrigir isso quando trocar the indioma
    this.updateOnLanguageChange();
    this.today = new Date();
  }

  updateOnLanguageChange() {
    const translationValue = this.translationsService.instant('Validity_Plan_Contract_Summary');
    this.hideContractualSummary = !translationValue || translationValue == '' || translationValue == 'Validity_Plan_Contract_Summary';
  }

  ngOnDestroy(): void {
    this.localizeSubscription.unsubscribe();
  }
}
