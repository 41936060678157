import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AlertService, AuthenticationService, LanguageService } from '@shared/services/index';
import { ResponseError } from '@shared/models';
import { Vehicle } from '../../vehicles/_models/vehicle';
import { Logger } from '@shared/logging/Logger';
import { AuthenticationError } from '@shared/models/errors/authentication.error';
import { TeaserResponse } from '../../vehicles/_models/teaser-response';
import { TeaserEligibilityService } from '../../registration/_services/teaser-eligibility.service';
import { VehicleService } from '@app/vehicles/_services';
import { VehicleInfomationRequest } from '@app/vehicles/_models';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-dashboard-view',
  templateUrl: './dashboard-view.component.html',
  styleUrls: ['./dashboard-view.component.scss']
})
export class DashboardViewComponent implements OnInit {

  isVehicleRequestReady = false;
  vehicles: Vehicle[] = [];
  public btnText = 'Vehicle_Add_Title';
  public title = 'Title_Dashboard';

  teaserPlans: TeaserResponse;
  isEligibleForTeaser: boolean;
  vin: string;

  constructor(
    private spinnerService: NgxSpinnerService,
    private authenticationService: AuthenticationService,
    private vehicleService: VehicleService,
    private activatedRoute: ActivatedRoute,
    private languageService: LanguageService,
    private teaserEligibilityService: TeaserEligibilityService,
    private alertService: AlertService,
    private logger: Logger) { }

  ngOnInit() {
    this.getVehicles();
  }

  getVehicles() {
    const request = new VehicleInfomationRequest;
    request.identityId = this.authenticationService.appAuthUser.identityId;
    this.spinnerService.show();
    return this.vehicleService.getVehicles(request, true)
      .subscribe(vehiclesData => {
        if (vehiclesData instanceof ResponseError) {
          this.handleError(vehiclesData);
          this.spinnerService.hide();
          return;
        }
        if ((vehiclesData as Vehicle[]).length === 1) {
          this.vin = vehiclesData[0].vin;
          this.getTeaserPlans();
          this.languageService.localizedNavigate(['../vehicle', vehiclesData[0].vin], { relativeTo: this.activatedRoute });
          this.spinnerService.hide();
          return;
        }
        this.vehicles = vehiclesData;
        this.vehicles.sort(this.compare);
        this.vehicles = this.vehicles.sort(x => {
          return (x.isFavourite) ? -1 : 1;
        });
        this.spinnerService.hide();
        this.isVehicleRequestReady = true;
      }, () => { this.spinnerService.hide(); })
  }

  redirectToAddVehicle() {
    this.languageService.localizedNavigate(['/dashboard/vehicle/add']);
  }

  private handleError(err: ResponseError) {
    if (err instanceof AuthenticationError) {
      return;
    }
    this.isVehicleRequestReady = true;
    switch (err.key) {
      case 'resourceNotFound':
        break;
      default: {
        this.alertService.error(err.message, 0, true, false);
        this.logger.error(err);
        break;
      }
    }
  }

  private compare(a, b) {
    const nameA = a.personalisedName.toUpperCase();
    const nameB = b.personalisedName.toUpperCase();

    let comparison = 0;
    if (nameA > nameB) {
      comparison = 1;
    } else if (nameA < nameB) {
      comparison = -1;
    }
    return comparison;
  }

  private getTeaserPlans() {
    this.teaserEligibilityService.getTeasers(this.vin, null)
      .subscribe(
        (data: TeaserResponse) => {
          this.teaserPlans = data;
          this.isEligibleForTeaser = this.teaserPlans.teaserPlans.length > 0;
        }
      );
  }
}
