import { Component } from '@angular/core';
import { ThemeService } from '../services/theme-service.service';

@Component({
  selector: 'app-theme',
  template: ''
})
export class ThemeComponent {

  public assets: string;
  public assetVersionNumber: string;

  constructor(private _themeService: ThemeService) {
    this.assets = this._themeService.assetsLocation;
    this.assetVersionNumber = this._themeService.assetVersionNumber;
  }

}
