<span class="compatibility-badge compatible"
    *ngIf="isCompatible; else imcompatible">
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd"
            d="M8.0001 14.4C9.69748 14.4 11.3253 13.7257 12.5256 12.5255C13.7258 11.3253 14.4001 9.69739 14.4001 8.00001C14.4001 6.30262 13.7258 4.67476 12.5256 3.47452C11.3253 2.27429 9.69748 1.60001 8.0001 1.60001C6.30271 1.60001 4.67485 2.27429 3.47461 3.47452C2.27438 4.67476 1.6001 6.30262 1.6001 8.00001C1.6001 9.69739 2.27438 11.3253 3.47461 12.5255C4.67485 13.7257 6.30271 14.4 8.0001 14.4ZM10.9657 6.96561C11.1114 6.81472 11.1921 6.61264 11.1902 6.40288C11.1884 6.19313 11.1043 5.99248 10.956 5.84415C10.8076 5.69582 10.607 5.61169 10.3972 5.60987C10.1875 5.60804 9.98538 5.68868 9.8345 5.83441L7.2001 8.46881L6.1657 7.43441C6.01482 7.28868 5.81273 7.20804 5.60298 7.20987C5.39322 7.21169 5.19257 7.29582 5.04424 7.44415C4.89592 7.59248 4.81178 7.79313 4.80996 8.00288C4.80814 8.21264 4.88877 8.41472 5.0345 8.56561L6.6345 10.1656C6.78452 10.3156 6.98797 10.3998 7.2001 10.3998C7.41223 10.3998 7.61567 10.3156 7.7657 10.1656L10.9657 6.96561Z"
            fill="currentColor" />
    </svg>
    <span class="ml-1 mr-1">{{'Vehicle_Compatibility_Vehicle_Compatible' | translate }}</span>
</span>

<ng-template #imcompatible>
    <span class="compatibility-badge incompatible">
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd"
                d="M8.0001 14.4C9.69748 14.4 11.3253 13.7257 12.5256 12.5255C13.7258 11.3253 14.4001 9.69739 14.4001 8.00001C14.4001 6.30262 13.7258 4.67476 12.5256 3.47452C11.3253 2.27429 9.69748 1.60001 8.0001 1.60001C6.30271 1.60001 4.67485 2.27429 3.47461 3.47452C2.27438 4.67476 1.6001 6.30262 1.6001 8.00001C1.6001 9.69739 2.27438 11.3253 3.47461 12.5255C4.67485 13.7257 6.30271 14.4 8.0001 14.4ZM6.9657 5.83441C6.81482 5.68868 6.61273 5.60804 6.40298 5.60987C6.19322 5.61169 5.99257 5.69582 5.84424 5.84415C5.69592 5.99248 5.61178 6.19313 5.60996 6.40288C5.60814 6.61264 5.68877 6.81472 5.8345 6.96561L6.8689 8.00001L5.8345 9.03441C5.75809 9.1082 5.69714 9.19648 5.65522 9.29408C5.61329 9.39169 5.59122 9.49666 5.5903 9.60289C5.58937 9.70911 5.60962 9.81445 5.64984 9.91277C5.69006 10.0111 5.74947 10.1004 5.82458 10.1755C5.8997 10.2506 5.98902 10.31 6.08733 10.3503C6.18565 10.3905 6.291 10.4107 6.39722 10.4098C6.50344 10.4089 6.60842 10.3868 6.70602 10.3449C6.80362 10.303 6.8919 10.242 6.9657 10.1656L8.0001 9.13121L9.0345 10.1656C9.18538 10.3113 9.38746 10.392 9.59722 10.3901C9.80698 10.3883 10.0076 10.3042 10.156 10.1559C10.3043 10.0075 10.3884 9.80688 10.3902 9.59713C10.3921 9.38737 10.3114 9.18529 10.1657 9.03441L9.1313 8.00001L10.1657 6.96561C10.3114 6.81472 10.3921 6.61264 10.3902 6.40288C10.3884 6.19313 10.3043 5.99248 10.156 5.84415C10.0076 5.69582 9.80698 5.61169 9.59722 5.60987C9.38746 5.60804 9.18538 5.68868 9.0345 5.83441L8.0001 6.86881L6.9657 5.83441Z"
                fill="currentColor" />
        </svg>
        <span class="ml-1 mr-1">{{'Vehicle_Compatibility_Vehicle_Incompatible' | translate }}</span>
    </span>
</ng-template>