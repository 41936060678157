<!-- <img [src]="pencilPath"> -->
<span class="text-primary">
    <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 24 24" version="1.1"
        width="24px" height="24px">
        <g id="surface1">
            <path
                d="M 18.40625 2 C 18.15625 2 17.886719 2.113281 17.6875 2.3125 L 16 4 L 20 8 L 21.6875 6.3125 C 22.085938 5.914063 22.085938 5.207031 21.6875 4.90625 L 19.09375 2.3125 C 18.894531 2.113281 18.65625 2 18.40625 2 Z M 15 5.09375 L 2 18 L 2 22 L 6 22 L 18.90625 9 Z "
                fill="currentColor" />
        </g>
    </svg>
</span>