
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BaseHttpService, ConfigService } from '@shared/services';
import { map } from 'rxjs/operators';
import { IPaymentProviderJsonRequest, IPaymentProviderRequest } from '../_models';
import { Observable } from 'rxjs';
import { BaseResponse, ResponseError } from '@shared/models';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class PaymentsService extends BaseHttpService {

    buildPaymentProviderJsonUrl = '/payments/buildPaymentProviderJson';
    paymentProviderResponseUrl = '/payments/paymentProviderResponse';

    constructor(
        _httpClient: HttpClient,
        _configService: ConfigService,
        _translateService: TranslateService,
    ) {
        super(_httpClient, _configService, _translateService);
    }

    /* POST */

    // Should return HPP request JSON request object
    buildPaymentProviderJson(request: IPaymentProviderJsonRequest)
        : Observable<BaseResponse<any | ResponseError>> {
        return this.postBaseResponse<any>(this.buildPaymentProviderJsonUrl, request)
            .pipe(
                map(res => res)
            );
    }

    // Processes the response from PaymentProvider
    // Response IHttpActionResult
    processPaymentProviderResponse(request: IPaymentProviderRequest)
        : Observable<BaseResponse<any | ResponseError>> {
        return this.postBaseResponse<any>(this.paymentProviderResponseUrl, request)
            .pipe(
                map(res => res)
            );
    }

}
