import { Component, OnInit } from '@angular/core';
import { ConfigService } from '@shared/services';
import { DeviceDetectorService } from 'ngx-device-detector';

@Component({
  selector: 'app-browser-update-notification',
  templateUrl: './browser-update-notification.component.html',
  styleUrls: ['./browser-update-notification.component.scss']
})
export class BrowserUpdateNotificationComponent implements OnInit {
  public isBrowserOutdated: boolean = false;

  constructor(
    private deviceService: DeviceDetectorService,
    private configService: ConfigService) { }

  ngOnInit() {
    this.checkBrowserCompatibility();
  }

  private checkBrowserCompatibility() {
    const browser = this.deviceService.browser.trim().toLowerCase();
    const browserVersion = parseFloat(this.deviceService.browser_version);
    const browserSupport = this.configService.config.browserSupport.find(x => x.browser.trim().toLowerCase() === browser);
    if (browserSupport === undefined) {
      return;
    }
    const configMinVersion = browserSupport.minVersion;
    if (browserVersion < configMinVersion) {
      this.isBrowserOutdated = true;
    }
  }
}
