import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';

@Injectable()
export class DataService {
    constructor(private cookieService: CookieService) {
    }

    setSession(key: string, value: string): void {
        if (this.getSession(key)) {
            this.removeSession(key);
        }
        sessionStorage.setItem(key, value);
    }

    getSession(key: string): string {
        const session = sessionStorage.getItem(key);
        if (session == null) {
            return null;
        }
        return session;
    }

    removeSession(key: string): void {
        sessionStorage.removeItem(key);
    }

    clearSession(): void {
        sessionStorage.clear();
    }

    setCookie(key: string, value: string): void {
        this.cookieService.set(key, value);
    }

    getCookie(key: string): string {
        return this.cookieService.get(key);
    }

    hasCookie(key: string): boolean {
        return this.cookieService.check(key);
    }

    clearCookies(): void {
        this.cookieService.deleteAll();
    }

    setStorageItem(key: string, value: string): void {
        localStorage.setItem(key, value);
    }

    getStorageItem(key: string): string {
        return localStorage.getItem(key);
    }

    removeStorageItem(key: string) {
        localStorage.removeItem(key);
    }

}
