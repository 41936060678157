import { Component, Input, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { LanguageService } from "@shared/services";
import { NgxSpinnerService } from "ngx-spinner";

@Component({
    selector: 'app-product-checkout-refresh',
    template: ''
})
export class ProductCheckoutRefreshComponent implements OnInit {

    @Input() public product: string;
    @Input() public vin: string;

    constructor(
        private languageService: LanguageService,
        private activatedRoute: ActivatedRoute,
        private spinnerService: NgxSpinnerService
    ) {
        this.activatedRoute.params.subscribe(params => {
            this.vin = params['vin'];
            this.product = params['product'];
        });
    }

    ngOnInit() {
        this.spinnerService.show();
        this.goToCheckout();
    }

    goToCheckout() {
        this.languageService.localizedNavigate(['/dashboard/plan/checkout', this.vin, this.product]);
    }
}