import { Component, OnInit } from '@angular/core';
import { AppAuthUser } from '@shared/models/app-auth-user';
import { AuthenticationService } from '@shared/services/authentication.service';
import { Language } from '@shared/models/language';
import { LanguageService } from '@shared/services/language.service';
import { LocalizeRouterService } from '@gilsdav/ngx-translate-router';
import { IdentificationValidationContextService } from '@shared/services';


@Component({
  selector: 'app-header-component',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})

export class HeaderComponent implements OnInit {
  public isCollapsed = true;
  public appAuthUser: AppAuthUser = null;
  public languages: Language[];
  public filteredLanguages: Language[];
  public currentLanguage: Language;
  constructor(
    private authenticationService: AuthenticationService,
    private languageService: LanguageService,
    private localizeService: LocalizeRouterService,
    private identificationValidationContextService: IdentificationValidationContextService
  ) { }

  ngOnInit() {
    this.appAuthUser = this.authenticationService.appAuthUser;
    this.languages = this.languageService.availableLanguages;
    this.updateLanguageDropdown();

    this.localizeService.routerEvents.subscribe(() => {
      this.updateLanguageDropdown();
    })
  }

  updateLanguageDropdown() {
    this.updateCurrentLanguage();
    this.excludeActiveItem();
  }

  updateCurrentLanguage() {
    this.currentLanguage = this.languageService.getActiveLanguage();
  }

  logout(): void {
    this.isCollapsed = this.isCollapsed ? false : true;
    this.authenticationService.logout();
  }

  changeLanguage(language: Language) {
    this.languageService.changeLanguage(language);
  }

  getIdentificationStatus(): boolean {
    if (this.identificationValidationContextService.context)
      return this.identificationValidationContextService.context.isValidIdentification;
    return false;
  }

  private excludeActiveItem() {
    this.filteredLanguages = this.languages.filter(element => element.isoCode !== this.currentLanguage.isoCode);
  }


}
