import { Component, OnInit, Input, ViewChild, ElementRef, OnChanges, SimpleChanges } from '@angular/core';
import { ContentSection } from '@shared/models/content-section';
import { ScrollToService } from '@shared/services/scrollto.service';

@Component({
  selector: 'app-selectable-content',
  templateUrl: './selectable-content.component.html',
  styleUrls: ['./selectable-content.component.scss']
})
export class SelectableContentComponent implements OnInit, OnChanges {
  @Input() content: ContentSection[];
  @Input() isQuestionStyle: boolean;
  @ViewChild('singleContentDiv') singleContentDiv: ElementRef;
  @ViewChild('multipleContentDiv') multipleContentDiv: ElementRef;
  public activeSections: ContentSection[];
  public selectedHeader: string;

  public contentType: string;
  public headers: any[];
  public activeGroupTitle: string;
  constructor(private scrollService: ScrollToService) { }


  ngOnInit() {
    this.initializeActiveContent();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.content.currentValue) {
      this.content = changes.content.currentValue;
      this.initializeActiveContent();
    }
  }

  setActiveContent(header: string) {
    if (!header)
        return;

    this.selectedHeader = header;
    this.activeSections = this.content.filter(x => x.groupTitle == header || x.content == header);
  }

  initializeActiveContent() {
    if (this.content?.length > 0) {
      this.contentType = this.content[0].contentType;
      this.setActiveContent(this.content[0].groupTitle ? this.content[0].groupTitle : this.content[0].content);
      this.headers = Array.from(new Set(this.content.map(item => (item.groupTitle ? item.groupTitle : item.content))));
    }
  }

  scrollToTop(element) {
    this.scrollService.scrollTo(element, 500, -200);
  }

  trim(value: string): string {
    return value.replace(/\s+/g, '').toLocaleLowerCase();
  }
}
