<div class="position-relative">
  <!-- <ngx-loading [show]="loading"></ngx-loading> -->
  <div *ngIf="loading === false">
    <div *ngIf="activePlans?.length > 0">
      <div *ngFor="let plan of activePlans">
        <app-wallet-item [plan]="plan"></app-wallet-item>
      </div>
    </div>
    <div *ngIf="pendingPlans?.length > 0">
      <div *ngFor="let plan of pendingPlans">
        <app-wallet-item [plan]="plan"></app-wallet-item>
      </div>
    </div>
    <div *ngIf="(activePlans?.length > 0) === false && (pendingPlans?.length > 0) === false">
      <p class="no-plans-note" data-test-id="vehicle-plans-no-plans-txt">{{'Vehicle_Plans_No_Plans' | translate }}</p>
    </div>
  </div>
</div>