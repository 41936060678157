<div class="fav-name-container" [@visabilityState]='show'>
  <form [formGroup]="form" novalidate data-test-id="fav-car-name-form"
    data-test-id="vehicle-details-view-personalised-name-form">
    <div class="form-group">
      <label>{{'Friendly_Name' | translate }}</label>
      <input #favName [value]="model" class="form-control" formControlName="favName"
        data-test-id="vehicle-details-view-personalised-name-form-input" maxlength="50">
      <span data-test-id="friendlyName-form-error" class="invalid-note d-block"
        *ngIf="!form.controls['favName'].pristine && form.controls['favName'].errors">
        {{'Friendly_Name_Form_Error' | translate }}</span>
    </div>
    <div class="form-controls">
      <button (click)="cancel()" class="btn btn-outline-secondary mr-3"
        data-test-id="personalised-name-form-cancel">{{'Cancel'
        | translate }}</button>
      <button (click)="save(favName.value)" [disabled]="!form.valid" aria-disabled="!form.valid" class="btn btn-primary"
        data-test-id="personalised-name-form-save">{{'Save' | translate }}</button>
    </div>
  </form>
</div>