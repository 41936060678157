import { Injectable, Inject } from '@angular/core';
import { ConfigService } from './config.service';
import { DOCUMENT } from '@angular/common';

@Injectable()
export class AnalyticsToolsService {

    private gaTrackingId: string;
    private hotjarTrackingId: string;
    private optimizePluginId: string;

    constructor(private _configService: ConfigService, @Inject(DOCUMENT) private doc: any) {
        this.gaTrackingId = this.getgaTrackingId();
        this.hotjarTrackingId = this.getHotjarTrackingId();
        this.optimizePluginId = this.getOptimizePluginId();
    }

    private getgaTrackingId(): string {
        return this._configService.config.gaTrackingId;
    }

    private getHotjarTrackingId(): string {
        return this._configService.config.hotjarTrackingId;
    }

    private getOptimizePluginId(): string {
        return this._configService.config.optimizePluginId;
    }

    private get hotjarTrackingJavaScriptString(): string {
        return '(function (h, o, t, j, a, r) {'
            + 'h.hj = h.hj || function () { (h.hj.q = h.hj.q || []).push(arguments) }; '
            + 'h._hjSettings = { hjid: \'' + this.hotjarTrackingId + '\', hjsv: 6 }; '
            + 'a = o.getElementsByTagName(\'head\')[0]; '
            + 'r = o.createElement(\'script\'); r.async = 1; '
            + 'r.src = t + h._hjSettings.hjid + j + h._hjSettings.hjsv; '
            + 'a.appendChild(r); '
            + '})(window, document, \'https://static.hotjar.com/c/hotjar-\', \'.js?sv=\');';
    }

    private get gtagManagerJavaScriptString(): string {
        return 'window.dataLayer = window.dataLayer || [];'
            + 'function gtag() { dataLayer.push(arguments); } '
            + 'gtag(\'js\', new Date()); '
            + 'gtag(\'config\', \'' + this.gaTrackingId + '\');';
    }

    private get googleAnalyticsJavaScriptString(): string {
        return '(function (i, s, o, g, r, a, m) {'
            + 'i[\'GoogleAnalyticsObject\'] = r; i[r] = i[r] || function () {'
            + '(i[r].q = i[r].q || []).push(arguments)'
            + '}, i[r].l = 1 * new Date(); a = s.createElement(o),'
            + 'm = s.getElementsByTagName(o)[0]; a.async = 1; a.src = g; m.parentNode.insertBefore(a, m)'
            + '})(window, document, \'script\', \'https://www.google-analytics.com/analytics.js\', \'ga\'); '
            + 'ga(\'create\', \'' + this.gaTrackingId + '\', \'auto\'); '
            + 'ga(\'require\', \'' + this.optimizePluginId + '\');'
            + 'ga(\'send\', \'pageview\');';
    }

    private get optimizePageHidingJavaScriptString(): string {
        return '(function(a,s,y,n,c,h,i,d,e){s.className+=\' \'+y;h.start=1*new Date;'
            + 'h.end=i=function(){s.className=s.className.replace(RegExp(\' ?\'+y),\'\')};'
            + '(a[n]=a[n]||[]).hide=h;setTimeout(function(){i();h.end=null},c);h.timeout=c;'
            + '})(window,document.documentElement,\'async-hide\',\'dataLayer\',4000,'
            + '{\'' + this.optimizePluginId + '\':true});';
    }

    private get optimizePageHidingStyleString(): string {
        return '.async-hide { opacity: 0 !important}';
    }

    public setAnalyticsTools() {
        this.setOptimizePlugin();
        this.setStyleForOptimizePlugin();
        this.setGtagManager();
        this.setGoogleAnalytics();
        this.setHotjarTracking();
    }

    private setGtagManager() {
        this.insertTagInHead(this.gtagManagerJavaScriptString);
    }

    private setGoogleAnalytics() {
        this.insertTagInHead(this.googleAnalyticsJavaScriptString);
    }

    private setHotjarTracking() {
        this.insertTagInHead(this.hotjarTrackingJavaScriptString);
    }

    private setOptimizePlugin() {
        this.insertAsFirstTagInHead(this.optimizePageHidingJavaScriptString);
    }

    private setStyleForOptimizePlugin() {
        this.insertStyleTagAsFirstTagInHead(this.optimizePageHidingStyleString);
    }

    private insertTagInHead(javaScriptString: string) {
        const s = this.doc.createElement('script');
        s.type = 'text/javascript';
        s.innerHTML = javaScriptString;
        const head = this.doc.getElementsByTagName('head')[0];
        head.appendChild(s);
    }

    private insertAsFirstTagInHead(javaScriptString: string) {
        const s = this.doc.createElement('script');
        s.type = 'text/javascript';
        s.innerHTML = javaScriptString;
        const head = this.doc.getElementsByTagName('head')[0];
        head.insertBefore(s, head.firstChild);
    }

    private insertStyleTagAsFirstTagInHead(styleTag: string) {
        const s = this.doc.createElement('style');
        s.type = 'text/css';
        s.innerHTML = styleTag;
        const head = this.doc.getElementsByTagName('head')[0];
        head.insertBefore(s, head.firstChild);
    }
}
