<app-page-header [title]="title" [btnText]="btnText" (clickEvt)="redirectToAddVehicle()">
</app-page-header>
<div class="row">
  <div class="col-sm-12">
    <app-vehicle-details-view [vehicle]="vehicle" (isVehicleDetailsReadyEvt)="handleVehicleDetailsReadyEvt($event)">
    </app-vehicle-details-view>
  </div>

  <ng-container *ngIf="!vehicle.isSecondaryUser else secondUserMessage">
    <div class="col-sm-12 col-md-6">
      <div class="vehicle-wallet-card">
        <strong data-test-id="vehicle-details-my-shop-title">{{'Vehicle_Details_My_Shop' | translate }}</strong>
        <div class="wallet-container">
          <app-vehicle-plans [vin]="vehicle.vin"></app-vehicle-plans>
        </div>
        <div class="d-flex justify-content-center">
          <ngx-loading [show]="loading"></ngx-loading>
          <div *ngIf="loading === false && isVehicleDetailsRequestReady">
            <button type="button" class="btn btn-primary" [disabled]="!enableBuyPlan"
              (click)="redirectToBuyPlan()" data-test-id="vehicle-details-buy-plan-button">{{'Buy_Plan_Button' |
              translate
              }}</button>
          </div>
        </div>
      </div>
      <div *ngIf="showInCarTopup() && showIncarTopupCard && !isGen4Vehicle">
        <app-vehicle-incar-topup-card [vin]="vehicle.vin"></app-vehicle-incar-topup-card>
      </div>
    </div>
    <div class="col-sm-12 col-md-6">
      <ng-container *ngIf="vehicle.isLiableUser">
        <app-usage-history-card [vin]="vehicle.vin"></app-usage-history-card>
        <app-purchase-history-card [vin]="vehicle.vin"></app-purchase-history-card>
      </ng-container>
    </div>
  </ng-container>
  <ng-template #secondUserMessage>
    <div class="col-sm-12">
      <div class="alert alert-warning" role="alert" [innerHtml]="'Secondary_User_Popup_Message' | translate">
      </div>
    </div>
  </ng-template>

  <div class="col-sm-12">
    <div *ngIf="!loading && isVehicleDetailsRequestReady" class="vehicle-remove">
      <a data-test-id="vehicle-details-remove" class="standard-link"
        [routerLink]="['/dashboard/vehicle/remove' | localize, vehicle.vin]">{{'Vehicle_Remove_Title' | translate }}</a>
    </div>
  </div>
</div>