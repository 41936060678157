import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BaseHttpService, ConfigService } from '@shared/services';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { ResponseError } from '@shared/models';
import { TeaserResponse } from '../../vehicles/_models/teaser-response';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class TeaserEligibilityService extends BaseHttpService {

  isEligibleForTeaser = false;

  constructor(
    httpClient: HttpClient,
    configService: ConfigService,
    translateService: TranslateService,
  ) {
    super(httpClient, configService, translateService);
  }

  // HTTP GET
  getTeasers(vin: string, homeCountry: string = null): Observable<TeaserResponse | ResponseError> {
    return this.get<TeaserResponse>('/vehicles/getTeaserPlansForVin/' + vin + '?HomeCountry=' + homeCountry).pipe(
      map(res => {
        const teasers: TeaserResponse = res as TeaserResponse;
        return teasers;
      })
    );
  }
}
