<div class="selectable-content-container">
  <div class="container-fluid">
    <div class="d-block d-md-none">
      <select class="form-control col-12 selectable-content-header-dropdown"
        (change)="setActiveContent($event.target.value)">
        <option
          [attr.data-test-id]="contentType + '-dropdown-option-' + trim(header)"
          *ngFor="let header of headers" [value]="header">
          <ngx-dynamic-hooks [content]="header"></ngx-dynamic-hooks>
        </option>
      </select>
    </div>
    <div class="row">
      <div class="d-none d-md-block col-md-5 col-lg-4 col-xl-3">
        <ul class="sidebar-list">
          <li *ngFor="let header of headers" class="sidebar-list-items">
            <a [attr.data-test-id]="contentType + '-sidebar-option-' + trim(header)"
              (click)="setActiveContent(header)" class="sidebar-link">
              <ngx-dynamic-hooks [content]="header"></ngx-dynamic-hooks>
            </a>
          </li>
        </ul>
      </div>

      <div  class="col-12 col-md-7 col-lg-8 col-xl-7">
        <h3 #singleContentDiv class="active-content-header"
          [attr.data-test-id]="'selected_header_' + selectedHeader">
          <ngx-dynamic-hooks [content]="selectedHeader"></ngx-dynamic-hooks>
        </h3>

          <div *ngIf="!isQuestionStyle">
            <div *ngFor="let section of activeSections,let index = index">
                <ngx-dynamic-hooks [content]="section.content"></ngx-dynamic-hooks>
            </div>
          </div>
        
          <ngb-accordion #a="ngbAccordion" activeIds="section_questions" *ngIf="isQuestionStyle">
            <ngb-panel *ngFor="let section of activeSections,let index = index" [id]="'account-details-' + section.id">
                  <ng-template ngbPanelHeader let-opened="opened" class="p-0">
                    <button ngbPanelToggle class="w-100 accordion-btn pt-3 pb-3 d-flex align-items-center justify-content-between text-left">
                      <div class="d-flex">
                        <h5 [attr.data-test-id]="section.id">
                          <ngx-dynamic-hooks [content]="section.content"></ngx-dynamic-hooks>
                        </h5>
                      </div>
                      <span class="accordion-icon" [ngClass]="{'rotated': opened}">
                          <svg width="17" height="11" viewBox="0 0 17 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M15.0399 10.4713L8.91992 4.35125L2.79992 10.4713L0.919922 8.57792L8.91992 0.577918L16.9199 8.57792L15.0399 10.4713Z" fill="currentColor"/>
                          </svg>                        
                      </span>
                    </button>
                  </ng-template>
                  <ng-template ngbPanelContent>
                    <div *ngFor="let subSection of section.subSections, let i = index">
                      <ngx-dynamic-hooks [content]="subSection.content"></ngx-dynamic-hooks>
                    </div>
                  </ng-template>
            </ngb-panel>
          </ngb-accordion>
      </div>
    </div>
  </div>
</div>