import { filter } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { ConfigService } from './config.service';
import { ApplicationInsights, IEventTelemetry, IExceptionTelemetry, IMetricTelemetry, IPageViewTelemetry, ITraceTelemetry } from '@microsoft/applicationinsights-web'
import { AuthenticationService } from '@shared/services/authentication.service';
import { Router, ResolveEnd, ActivatedRouteSnapshot } from '@angular/router';
import { Subscription } from 'rxjs';

@Injectable()
export class MonitoringService {

    protected routerSubscription: Subscription;
    appInsights: ApplicationInsights;

    constructor(
        private _configService: ConfigService,
        private _authService: AuthenticationService,
        private _router: Router) {

        this.appInsights = new ApplicationInsights({
            config: {
                instrumentationKey: this._configService.config.appInsightsInstKey
            }
        });

        this.appInsights.loadAppInsights();

        this.routerSubscription = this._router.events.pipe(
            filter(event => event instanceof ResolveEnd))
            .subscribe((event: ResolveEnd) => {
                const activatedComponent = this.getActivatedComponent(event.state.root);
                if (activatedComponent) {
                    this.logPageView(`${activatedComponent.name} ${this.getRouteTemplate(event.state.root)}`, event.urlAfterRedirects);
                    this.logMetric(`${activatedComponent.name} ${this.getRouteTemplate(event.state.root)}`);
                    this.logEvent(`${activatedComponent.name} ${this.getRouteTemplate(event.state.root)}`);
                }
            });
    }

    info(message: string) {
        this.log(message, 1);
    }

    warn(message: string) {
        this.log(message, 2);
    }

    error(message: string, properties?: { [name: string]: string }) {
        this.log(message, 3, properties);
    }

    fatal(message: string | Error) {
        if (message instanceof Error) {
            const exception: IExceptionTelemetry = {
                exception: message as Error
            };

            this.appInsights.trackException(exception);
            return;
        }
        this.log(message as string, 4);
        this.appInsights.flush();
    }

    private log(message: string, severity?: number, properties?: { [name: string]: string }) {
        const trace: ITraceTelemetry = {
            message: message,
            properties: this.getEnrichments(properties),
            severityLevel: severity
        }

        this.appInsights.trackTrace(trace);
    }

    private getEnrichments(additionalProperties?: { [name: string]: string }): { [name: string]: string } {
        let appProperties: { [name: string]: string };
        let userProperties: { [name: string]: string };
        if (this._authService.appAuthUser.isAuthenticated) {
            userProperties = { user_name: this._authService.appAuthUser.firstName, user_identity: this._authService.appAuthUser.identityId };
        }
        appProperties = { app_version: this._configService.config.version, app_client: this._configService.config.theme };
        return { ...userProperties, ...appProperties, ...additionalProperties };
    }

    private getActivatedComponent(snapshot: ActivatedRouteSnapshot): any {

        if (snapshot.firstChild) {
            return this.getActivatedComponent(snapshot.firstChild);
        }

        return snapshot.component;
    }

    private getRouteTemplate(snapshot: ActivatedRouteSnapshot): string {
        let path = '';
        if (snapshot.routeConfig) {
            path += snapshot.routeConfig.path;
        }

        if (snapshot.firstChild) {
            return path + this.getRouteTemplate(snapshot.firstChild);
        }

        return path;
    }

    private logPageView(
        name: string,
        url?: string,
        properties?: { [key: string]: string },
        duration?: number) {

        const pageView: IPageViewTelemetry = {
            name: name,
            uri: url,
            properties: this.getEnrichments(properties),
        };
        if (duration)
            pageView.properties.duration = duration;

        this.appInsights.trackPageView(pageView);
    }

    private logMetric(
        name: string,
        properties?: { [key: string]: string }) {

        const metric: IMetricTelemetry = {
            name: name,
            average: 10,
            max: 10,
            min: 10,
            sampleCount: 10,
            properties: this.getEnrichments(properties)
        };

        this.appInsights.trackMetric(metric);
    }

    private logEvent(
        name: string,
        properties?: { [key: string]: string }) {

        const event: IEventTelemetry = {
            name: name,
            properties: this.getEnrichments(properties)
        };

        this.appInsights.trackEvent(event);
    }
}
