import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BaseHttpService, ConfigService } from '@shared/services/index';
import { Observable } from 'rxjs';
import { ResponseError } from '@shared/models/response-error';
import { TranslateService } from '@ngx-translate/core';
import { AccountWithDeviceRequest } from '@app/registration/_models/account-with-device-request';
import { AccountWithDeviceResponse } from '@app/registration/_models/account-with-device-response';
import { SSOAccountWithDeviceRequest } from '../_models/sso-account-with-device-request';

@Injectable()
export class AccountRegistrationService extends BaseHttpService {

  constructor(httpClient: HttpClient, configService: ConfigService,
    translateService: TranslateService) {
    super(httpClient, configService, translateService);
  }

  registerUserAccountWithDevice(accountWithDeviceRequest: AccountWithDeviceRequest): Observable<AccountWithDeviceResponse | ResponseError> {
    return this.post<AccountWithDeviceResponse>('/accounts/registerWithDevice', accountWithDeviceRequest);
  }

  registerSSOUserAccountWithDevice(accountWithDeviceRequest: SSOAccountWithDeviceRequest): Observable<AccountWithDeviceResponse | ResponseError> {
    return this.post<AccountWithDeviceResponse>('/accounts/registerSsoUser', accountWithDeviceRequest);
  }
}
