<div class="intro-section-container">
  <div class="background">
    <div class="row justify-content-center">
      <div class="intro-section-content">
        <img class="mx-auto d-block launch" src="{{introSection.image}}">
        <h2 #introSectionTitle class="intro-section-header col-12"> {{this.introSection.Title | translate}}</h2>
        <p #introSectionDescription class="intro-section-description col-12">
          {{this.introSection.Description | translate}}
        </p>
      </div>
    </div>
  </div>
</div>