<div class="product-checkout-confirmation-container">
  <div class="container-fluid">
    <div class="d-none d-sm-block page-header">{{'Product_Checkout' | translate}}</div>
    <div class="row">
      <div class="col-12 p-30">
        <p class="thank-you-text" data-test-id="product-checkout-confirmation-thank-you-text">
          <strong>{{'Product_Checkout_Confirmation_Thank_You_Text' | translate}}</strong>
        </p>
        <div class="confirmation-email-text">
          <span data-test-id="product-checkout-confirmation-email-text">
            {{'Product_Checkout_Confirmation_Email_Text' | translate}}
          </span>
          {{email}}
        </div>
        <div class="checkout-information">
          <div class="col-sm-12 d-flex justify-content-between">
            <div class="order-number-text text" data-test-id="product-checkout-confirmation-order-number-text">
              <h5> {{'Product_Checkout_Confirmation_Order_Number_Text' | translate}}</h5>
            </div>
            <div class="order-number text" data-test-id="product-checkout-confirmation-order-number">
              <h5>{{context.order.orderNumber}}</h5>
            </div>
          </div>
          <hr>
          <div class="col-sm-12">
            <div class="row">
              <div class="col-sm-12">
                <h5 class="plan-title text" data-test-id="product-checkout-confirmation-plan-title">
                  {{context.order.productName}}</h5>
              </div>
              <div class="col-sm-12">
                <h6 class="data-amount" data-test-id="product-checkout-confirmation-data-amount">
                  {{'Product_Checkout_Confirmation_Data_Amount' | translate:{dataAmount: context.order.dataAmount ?
                  context.order.dataAmount : '',
                  dataUnits: context.order.dataUnits ? context.order.dataUnits : ''} }}
                </h6>
              </div>
            </div>
          </div>
          <hr>
          <div class="col-sm-12 d-flex justify-content-between">
            <div class="plan-applied-to-text" data-test-id="product-checkout-confirmation-plan-applied-to-text">
              <p>{{'Product_Checkout_Confirmation_Plan_Applied_To_Text' | translate}}</p>
            </div>
            <div class="vehicle-model text" data-test-id="product-checkout-confirmation-vehicle-model">
              <p>{{context.vehicle.vehicleModel}}</p>
            </div>
          </div>
          <hr>
          <div class="container order-summary-info">
            <div class="row">
              <div class="col d-flex justify-content-start order-summary-subtotal-text">
                <div class="product-checkout-order-card-subtotal" data-test-id="product-checkout-order-card-subtotal">
                  <p>{{'Product_Checkout_Order_Card_Subtotal' | translate}}</p>
                </div>
              </div>
              <div class="col d-flex justify-content-end order-summary-subtotal">
                <div class="product-checkout-order-card-subtotal-value text"
                  [attr.data-test-id]="'product-checkout-order-card-subtotal-value-'+context.order.packageSKU">
                  <p>{{context.order.currencyFormat}} {{context.order.salePrice | number:'1.2-5'}}</p>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col col-lg-6 d-flex justify-content-start order-summary-total-text">
                <div class="product-checkout-order-card-total" data-test-id="product-checkout-order-card-total">
                  <h5>{{'Product_Checkout_Order_Card_Total' | translate}}</h5>
                </div>
              </div>
              <div class="col col-lg-6 d-flex justify-content-end order-summary-total">
                <div class="product-checkout-order-card-total-value text"
                  [attr.data-test-id]="'product-checkout-order-card-total-value-'+context.order.packageSKU">
                  <h5>{{context.order.currencyFormat}} {{context.order.salePrice | number:'1.2-5'}}</h5>
                </div>
              </div>
            </div>
          </div>
        </div>
        <button type="submit" class="btn btn-primary btn-return-to_dashboard"
          data-test-id="product-checkout-confirmation-return-button"
          (click)="returnToDashboard()">{{'Product_Checkout_Confirmation_Return_Button' | translate}}</button>
      </div>
    </div>
  </div>
</div>