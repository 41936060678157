import { Component } from "@angular/core";
import { ThemeService } from "@shared/services";

@Component({
    selector: 'app-vehicle-verification-vehicle-image',
    templateUrl: './vehicle-verification-vehicle-image.html',
    styleUrls: ['./vehicle-verification-vehicle-image.scss']
})
export class VehicleVerificationVehicleImageComponent {

    vehicleImageTemplate: string;

    constructor(private themeService: ThemeService) {
        if (this.themeService.themeName === "lamborghini") {
            this.vehicleImageTemplate = "lamborghini";
        } else if (this.themeService.themeName === "bentley") {
            this.vehicleImageTemplate = "bentley";
        } else {
            this.vehicleImageTemplate = "default";
        }
    }
}