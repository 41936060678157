import { SponsorUserDetails, RegisteredCreditCard } from '../../product/_models';
import { VehicleUserStatus } from './vehicle-user-status';
export class InCarTopup {
    public userStatus: VehicleUserStatus;
    public isEnabled: boolean;
    public registeredPaymentCards: RegisteredCreditCard;
    public sponsorUserDetails: SponsorUserDetails;

    constructor() {
        this.sponsorUserDetails = new SponsorUserDetails();
        this.registeredPaymentCards = new RegisteredCreditCard();
    }
}
