import { TopUpLimit } from '../../app/product/_models/top-up-limit';
import { RegisteredCreditCard, SponsorUserDetails } from '../../app/product/_models';

export class IncarTopupContext {
    public vin: string;
    public topUpLimit: TopUpLimit;
    public isEnabled: boolean;
    public registeredPaymentCards: RegisteredCreditCard;
    public sponsorUserDetails: SponsorUserDetails;
    constructor() {
        this.sponsorUserDetails = new SponsorUserDetails();
    }
}
