<div class="page-header" data-test-id="page-header">
  <div class="row">
    <div class="col">
      <h3 data-test-id="page-header-title" class="title">{{title | translate}}</h3>
    </div>
    <div *ngIf="btnText" class="col">
      <button data-test-id="page-header-btn" type="submit" class="btn btn-primary float-right btn-action"
        (click)="actionButtonClicked()">{{ btnText | translate }}</button>
    </div>
  </div>
</div>