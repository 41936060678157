import { Component } from '@angular/core';
import { ThemeComponent } from '@shared/components/theme.component';
import { ThemeService } from '@shared/services/theme-service.service';
@Component({
  selector: 'app-home-connectivity',
  templateUrl: './connectivity.component.html',
  styleUrls: ['./connectivity.component.scss']
})
export class ConnectivityComponent extends ThemeComponent {

  constructor(_themeService: ThemeService) {
    super(_themeService);
  }
}
