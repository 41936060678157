import { NotForAuthenticatedGuard } from "@shared/guards/not-for-authenticated.guard";
import { SSOAuthenticationComponent } from "./sso-authentication.component";
import { Routes } from "@angular/router";
import { SSOConfirmDetailsComponent } from "./sso-confirm-details/sso-confirm-details.component";

export const ssoAuthenticationRoutes: Routes = [
    {
        path: 'ssoAuth', //WARN: IF changed it will require change on VW IDKit side
        component: SSOAuthenticationComponent,
        canActivate: [NotForAuthenticatedGuard],
        data: { skipRouteLocalization: { localizeRedirectTo: true } } //WARN: Need to skip language code into url so we don't need to add all language URL into IDKIT
    },
    {
        path: 'ssoAuth/confirmDetails',
        component: SSOConfirmDetailsComponent,
        canActivate: [NotForAuthenticatedGuard]
    }
];
