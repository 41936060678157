import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from '@shared/services/authentication.service';
import { ThemeService } from '@shared/services/theme-service.service';
import { AppAuthUser, ResponseError } from '@shared/models';
import { ThemeComponent } from '@shared/components/theme.component';
import { AccountService } from '../_services/account.service';
import { AccountDelete } from '../_models';
import { AlertService, LanguageService } from '@shared/services/index';
import { Logger } from '@shared/logging/Logger';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
    selector: 'app-account-delete',
    templateUrl: './account-delete.component.html',
    styleUrls: ['./account-delete.component.scss']
})
export class AccountDeleteComponent extends ThemeComponent implements OnInit {
    identityId: string;
    public appAuthUser: AppAuthUser = null;

    constructor(
        private authenticationService: AuthenticationService,
        private spinnerService: NgxSpinnerService,
        private accountService: AccountService,
        private alertService: AlertService,
        private logger: Logger,
        private languageService: LanguageService,
        _themeService: ThemeService
    ) {
        super(_themeService);
    }

    ngOnInit() {
        this.appAuthUser = this.authenticationService.appAuthUser;
        this.identityId = this.appAuthUser.identityId;
    }

    public deleteAccount() {
        this.spinnerService.show();
        this.accountService.deleteAccount(this.prepareAccountDeleteData())
            .subscribe(
                (data: AccountDelete) => {
                    this.authenticationService.logout();
                },
                (err: ResponseError) => {
                    this.handleError(err);
                },
                () => { this.spinnerService.hide(); }
            );
    }

    private prepareAccountDeleteData(): AccountDelete {
        const accountDeleteData = new AccountDelete();
        accountDeleteData.hasConfirmedDataDeletion = true;
        return accountDeleteData;
    }

    private handleError(err: ResponseError) {
        this.spinnerService.hide();
        this.languageService.localizedNavigate(['/']);
        this.alertService.error(err.message, 0, true, true);
        this.logger.error(err);
    }

}
