export class CookieKeys {
    private readonly COOKIE_CONSENT = 'CookieConsent';

    constructor() {
    }

    get cookieConsent(): string {
        return this.COOKIE_CONSENT;
    }
}
