import { PurchaseDetails } from '../_models/purchase-details';

export class PurchaseHistory {
    public vin: string;
    public totalSpend: number;
    public currency: number;
    public displayTotalSpendPrice: string;
    public fromDate: string;
    public toDate: string;
    public purchaseDetailsList: PurchaseDetails[];
}
