import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { VerifyVehicle } from '@app/vehicles/_models/verify-vehicle';
import { VehicleService } from '@app/vehicles/_services';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AlertService, ConfigService } from '@shared/services';
import { ResponseError } from '@shared/models';
import { NgxSpinnerService } from 'ngx-spinner';
import { Vehicle } from '@app/vehicles/_models';
import { Logger } from '@shared/logging/Logger';

@Component({
    selector: 'app-vehicle-verification-identification',
    templateUrl: './vehicle-verification-identification.html',
    styleUrls: ['./vehicle-verification-identification.scss']
})
export class VehicleVerificationIdentificationComponent implements OnInit {
    vinForm: UntypedFormGroup;
    canShowScanButton: boolean;

    @Input() currentVehicle: VerifyVehicle;
    @Output() verifyClick = new EventEmitter<VerifyVehicle | null>();
    @Output() cancelClick = new EventEmitter();

    constructor(private modalService: NgbModal,
        private formBuilder: UntypedFormBuilder,
        private spinnerService: NgxSpinnerService,
        private vehicleService: VehicleService,
        private alertService: AlertService,
        private logger: Logger,
        private configService: ConfigService) {
        this.canShowScanButton = this.configService.config.showQRCodeScanner;
        
        this.createForm();
    }

    ngOnInit(): void {
        if (this.currentVehicle && this.currentVehicle.vehicle) {
            this.setForm(this.currentVehicle.vehicle.vin);
        }
    }

    createForm() {
        this.vinForm = this.formBuilder.group({
            vinInputOne: ['', Validators.compose([Validators.required, Validators.minLength(3)])],
            vinInputTwo: ['', Validators.compose([Validators.required, Validators.minLength(1)])],
            vinInputThree: ['', Validators.compose([Validators.required, Validators.minLength(1)])],
            vinInputFour: ['', Validators.compose([Validators.required, Validators.minLength(1)])],
            vinInputFive: ['', Validators.compose([Validators.required, Validators.minLength(2)])],
            vinInputSix: ['', Validators.compose([Validators.required, Validators.minLength(1)])],
            vinInputSeven: ['', Validators.compose([Validators.required, Validators.minLength(1)])],
            vinInputEight: ['', Validators.compose([Validators.required, Validators.minLength(1)])],
            vinInputNine: ['', Validators.compose([Validators.required, Validators.minLength(6)])],
        });
    }

    scanResult(vin: string) {
        this.setForm(vin);
    }

    pasteEvent(e: ClipboardEvent) {
        e.preventDefault();
        let copiedText = e.clipboardData.getData('Text');
        copiedText = copiedText.replace(/ +/g, '');
        this.setForm(copiedText);
    }

    private setForm(vin: string) {
        if (vin && vin.length == 17) {
            this.vinForm.setValue({
                vinInputOne: vin.substring(0, 3),
                vinInputTwo: vin.substring(3, 4),
                vinInputThree: vin.substring(4, 5),
                vinInputFour: vin.substring(5, 6),
                vinInputFive: vin.substring(6, 8),
                vinInputSix: vin.substring(8, 9),
                vinInputSeven: vin.substring(9, 10),
                vinInputEight: vin.substring(10, 11),
                vinInputNine: vin.substring(11, vin.length)
            });
        }
    }

    openVinTips(content: any) {
        this.modalService.open(content, { centered: true });
    }

    verify() {
        const vin = this.getVinFromForm();

        if (!this.currentVehicle ||
            !this.currentVehicle.vehicle ||
            this.currentVehicle.vehicle.vin !== vin) {
            this.spinnerService.show();

            this.vehicleService.verifyVehicle(vin)
                .subscribe(
                    (data: Vehicle | ResponseError) => {
                        this.spinnerService.hide();
                        if (data instanceof ResponseError) {
                            this.handleVerifyErrorResponse(data);
                        } else {
                            let verifiedVehicle = new VerifyVehicle();
                            verifiedVehicle.vehicle = data;
                            this.verifyClick.emit(verifiedVehicle);
                        }
                    },
                    () => { this.spinnerService.hide(); }
                );
        } else {
            this.verifyClick.emit(this.currentVehicle);
        }
    }

    cancel() {
        this.cancelClick.emit();
    }

    private getVinFromForm(): string {
        const formValue = this.vinForm.value;
        return `${formValue.vinInputOne}${formValue.vinInputTwo}${formValue.vinInputThree}${formValue.vinInputFour}${formValue.vinInputFive}${formValue.vinInputSix}${formValue.vinInputSeven}${formValue.vinInputEight}${formValue.vinInputNine}`;
    }

    private handleVerifyErrorResponse(error: ResponseError) {
        this.logger.error(error);
        switch (error.code) {
            case '20.1':
                this.alertService.warn(error.message, 0, true, false);
                break;
            case '0.2':
                //vehicle not found
                this.verifyClick.emit(null);
                break;
            default:
                this.alertService.error(error.message, 0, true, false);
                break;
        }
    }
}
