<div>
    <app-vehicle-verification-identification-scan
        (scanResult)="scanResult($event)"></app-vehicle-verification-identification-scan>

    <div class="container text-center">
        <div class="mt-4" *ngIf="canShowScanButton">
            <p>{{'Vehicle_Compatibility_Enter_Vin_Manually' | translate }}</p>
        </div>
        <form class="mt-4" [formGroup]="vinForm" novalidate>
            <div class="d-flex flex-row justify-content-center mb-3">
                <input type="text" class="form-control mr-2 w-120 text-center text-uppercase" maxlength="3" id="focus1"
                    appMoveNextOnMaxlength formControlName="vinInputOne" (paste)="pasteEvent($event)" autofocus>
                <input type="text" class="form-control mr-2 w-35 text-center text-uppercase" maxlength="1" id="focus2"
                    appMoveNextOnMaxlength formControlName="vinInputTwo" (paste)="pasteEvent($event)">
                <input type="text" class="form-control mr-2 w-35 text-center text-uppercase" maxlength="1" id="focus3"
                    appMoveNextOnMaxlength formControlName="vinInputThree" (paste)="pasteEvent($event)">
                <input type="text" class="form-control mr-2 w-35 text-center text-uppercase" maxlength="1" id="focus4"
                    appMoveNextOnMaxlength formControlName="vinInputFour" (paste)="pasteEvent($event)">
                <input type="text" class="form-control w-85 text-center text-uppercase" maxlength="2" id="focus5"
                    appMoveNextOnMaxlength formControlName="vinInputFive" (paste)="pasteEvent($event)">
            </div>
            <div class="d-flex flex-row justify-content-center mb-3">
                <input type="text" class="form-control mr-2 w-35 text-center text-uppercase" maxlength="1" id="focus6"
                    appMoveNextOnMaxlength formControlName="vinInputSix" (paste)="pasteEvent($event)">
                <input type="text" class="form-control mr-2 w-35 text-center text-uppercase" maxlength="1" id="focus7"
                    appMoveNextOnMaxlength formControlName="vinInputSeven" (paste)="pasteEvent($event)">
                <input type="text" class="form-control mr-2 w-35 text-center text-uppercase" maxlength="1" id="focus8"
                    appMoveNextOnMaxlength formControlName="vinInputEight" (paste)="pasteEvent($event)">
                <input type="text" class="form-control w-214 text-center text-uppercase" maxlength="6" id="focus9"
                    appMoveNextOnMaxlength formControlName="vinInputNine" (paste)="pasteEvent($event)">
            </div>
            <div class="d-flex flex-row justify-content-center button-container">
                <button type="button" class="btn btn-outline-secondary btn-spacing" (click)="cancel()">{{'Cancel' |
                    translate}}</button>
                <button type="submit" class="btn btn-primary" [disabled]="!vinForm.valid"
                    (click)="verify()">{{'Vehicle_Compatibility_Verify_Button' | translate }}</button>
            </div>

            <p class="text-center">
                {{'Vehicle_Compatibility_Where_Is_The_Vin' | translate }}
                <b>
                    <a role="button" (click)="openVinTips(vinTips)">{{'Vehicle_Compatibility_Click_Here_For_Tips' |
                        translate }}
                    </a>
                </b>
            </p>
        </form>
    </div>
</div>

<ng-template #vinTips let-modal>
    <div class="modal-header">
        <h4 class="mb-2">{{'Vehicle_Compatibility_Finding_The_Vin_Is_Easy' | translate }}</h4>
        <a class="btn-close" role="button" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M1 1L13 13M1 13L13 1L1 13Z" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                    stroke-linejoin="round" />
            </svg>
        </a>
    </div>
    <div class="modal-body">
        <p class="mb-0">{{'Vehicle_Compatibility_Depending_On_The_Vehicle_Model' | translate }}</p>

        <div class="tips-container text-center">
            <span class="first-span">{{'Vehicle_Compatibility_Under_The_Hood' | translate }}</span>
            <span class="second-span">{{'Vehicle_Compatibility_Left_Side_Of_The_Windshield' | translate }}</span>

            <div class="car-container">
                <app-vehicle-verification-vehicle-image class="vehicle-image-container">
                </app-vehicle-verification-vehicle-image>
            </div>
            <span class="third-span">{{'Vehicle_Compatibility_Left_Door_Frame' | translate }}</span>
        </div>
    </div>
    <div class="modal-footer">
        <p class="mb-0">{{'Vehicle_Compatibility_Please_Contact_Dealer' | translate }}</p>
    </div>
</ng-template>