import { Injectable } from '@angular/core';
import { SessionKeys, IncarTopupContext } from '../models';
import { DataService } from '../services/data.service';

@Injectable()
export class IncarTopupContextService {

    public context: IncarTopupContext = new IncarTopupContext();

    constructor(private dataService: DataService) {
        this.getIncarTopupContext();
    }

    public resetIncarTopupContext() {
        this.context.vin = '';
        this.dataService.removeSession(SessionKeys.incarTopupContext);
    }

    public setIncarTopupContext(context: IncarTopupContext) {
        this.context = context;
        this.setSessionValues(context);
    }

    public update() {
        const contextWithoutCreditCards = this.context;
        this.dataService.setSession(SessionKeys.incarTopupContext, JSON.stringify(contextWithoutCreditCards));
    }

    private setSessionValues(data: IncarTopupContext) {
        this.dataService.setSession(SessionKeys.incarTopupContext, JSON.stringify(this.context));
    }

    private getIncarTopupContext() {
        const user = this.dataService.getSession(SessionKeys.incarTopupContext);
        if (user) {
            this.context = JSON.parse(user) as IncarTopupContext;
            return;
        }
    }

}
