import { IdentificationValidationStatus } from '../../app/user/_models/identification-validation-status';

export class IdentificationValidationContext {
    vin: string;
    dataFields: string[];
    status: IdentificationValidationStatus;
    isIdentificationSubmitted: any;
    isValidIdentification: boolean;

    constructor() {
        this.isValidIdentification = false;
        this.dataFields = null;
    }
}
