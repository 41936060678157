import { Directive, ElementRef } from "@angular/core";

@Directive({
    selector: '[appMoveNextOnMaxlength]',
})
export class MoveNextOnMaxlengthDirective {
    constructor(private el: ElementRef) {
        this.el.nativeElement.addEventListener("keyup", function (event: any) {
            const element = event.target as HTMLInputElement;
            var partsId = element.id.match(/focus(\d+)/);
            var currentId = parseInt(partsId[1]);

            var valueLenth = element.value.length;
            if (valueLenth == element.maxLength) {
                const nextElement = document.querySelector('#focus' + (currentId + 1)) as HTMLElement;

                if (nextElement)
                    nextElement.focus();
            }
            else if (valueLenth == 0) {
                const previousElement = document.querySelector('#focus' + (currentId - 1)) as HTMLElement;

                if (previousElement)
                    previousElement.focus();
            }
        }, true);
    }
}