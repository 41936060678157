import { Component, OnInit } from '@angular/core';
import { Vehicle, VehicleInfomationRequest } from '../../vehicles/_models';
import { ActivatedRoute } from '@angular/router';
import { ThemeComponent } from '@shared/components/theme.component';
import { ThemeService } from '@shared/services/theme-service.service';
import { AlertService, ConfigService, LanguageService } from '@shared/services/index';
import { VehicleService } from '../_services';
import { ResponseError, AppAuthUser, SessionKeys } from '@shared/models';
import { AuthenticationService } from '@shared/services/authentication.service';
import { Logger } from '@shared/logging/Logger';
import { NgxSpinnerService } from 'ngx-spinner';
import { TranslateService } from '@ngx-translate/core';
import { VehicleImagesUrlService } from '../_services/vehicle-images-url.service';

@Component({
  selector: 'app-vehicle-remove',
  templateUrl: './vehicle-remove.component.html',
  styleUrls: ['./vehicle-remove.component.scss']
})
export class VehicleRemoveComponent extends ThemeComponent implements OnInit {
  vin: string;
  public vehicle = new Vehicle();
  identityId: string;
  sessionKeys = new SessionKeys();
  isRequestReady = false;
  public appAuthUser: AppAuthUser = null;
  public showVehicle: boolean;

  constructor(
    private activatedRoute: ActivatedRoute,
    private languageService: LanguageService,
    _themeService: ThemeService,
    private spinnerService: NgxSpinnerService,
    private vehicleService: VehicleService,
    private alertService: AlertService,
    private authenticationService: AuthenticationService,
    private configService: ConfigService,
    private logger: Logger,
    private translationsService: TranslateService,
    private vehicleImagesUrlService: VehicleImagesUrlService) {
    super(_themeService);
  }

  ngOnInit() {
    this.appAuthUser = this.authenticationService.appAuthUser;
    this.showVehicle = this.configService.config.showVehicleImage;
    this.vin = this.activatedRoute.snapshot.params['vin'];
    if (this.vin !== null && this.vin !== undefined) {
      this.identityId = this.appAuthUser.identityId;
      if (this.identityId !== null && this.identityId !== undefined) {
        this.retrieveVehicleDetails();
        this.isRequestReady = true;
        return;
      }
    }
    this.isRequestReady = false;
    this.languageService.localizedNavigate(['/dashboard/vehicles']);
    this.alertService.error(this.translationsService.instant('Vehicle_Remove_Invalid_Requirements'), 0, true, true);
  }

  prepareRemoveVehicleData(): Vehicle {
    const vehicleRemoveData = new Vehicle();
    vehicleRemoveData.vin = this.vin;
    return vehicleRemoveData;
  }

  confirmToRemoveVehicle() {
    this.alertService.clear();
    this.spinnerService.show();
    this.vehicleService.removeVehicle(this.prepareRemoveVehicleData())
      .subscribe(
        (data: Vehicle) => {
          this.spinnerService.hide();
          this.alertService.success(this.translationsService.instant('Vehicle_Remove_Success'), 5, true, true);
          this.languageService.localizedNavigate(['/dashboard'], { queryParams: { forceDataRefresh: true } })
        },
        (err: ResponseError) => {
          this.spinnerService.hide();
          this.alertService.error(err.message, 0, true, false);
          this.logger.error(err);
        },
        () => { this.spinnerService.hide(); }
      );
  }

  retrieveVehicleDetails(): Vehicle {
    this.spinnerService.show();
    const request = new VehicleInfomationRequest;
    request.identityId = this.identityId;
    this.vehicleService.getVehicle(this.vin, request)
      .subscribe(
        (data: Vehicle) => {
          this.spinnerService.hide();
          this.vehicle = data;
          return this.vehicle;
        },
        (err: ResponseError) => {
          this.spinnerService.hide();
          this.alertService.error(err.message, 0, true, false);
          this.logger.error(err);
          return null;
        },
        () => {
          this.spinnerService.hide();
        }
      ).unsubscribe();
    return null;
  }

  goBack() {
    this.languageService.localizedNavigate(['/dashboard']);
  }

  vehicleImageLoadError() {
    if (this.vehicle) {
      const defaultImage = this.vehicleImagesUrlService.getDefaultImageUrl();

      if (this.vehicle.vehiclePhotoImage !== defaultImage) {
        this.vehicle.vehiclePhotoImage = defaultImage;
      }
    }
  }
}
