<div>
  <div class="vehicle-container">
    <div class="image-container" style.background-image="url('{{vehicle.vehiclePhotoImage}}')">
      <img style="display: none;" [src]="vehicle.vehiclePhotoImage" (error)="vehicleImageLoadError()" />
    </div>

    <div class="container h-100 d-flex flex-column justify-content-end">
      <div class="d-flex flex-row align-items-center z-100 mb-2">
        <div class="d-flex flex-column text-left">
          <p class="mb-1">{{vehicle.vin}}</p>
          <h4 *ngIf="vehicle.vehicleModel">{{vehicle.vehicleModel}}</h4>
        </div>
      </div>
    </div>
  </div>

  <div class="container text-center">
    <div class="mt-4">

      <h4>{{'Vehicle_Remove_Question' | translate}}</h4>

      <p class="m-0">
        {{'Vehicle_Remove_Warning' | translate}}
      </p>
    </div>
    <div class="d-flex flex-row justify-content-center button-container">
      <button type="button" class="btn btn-outline-secondary btn-spacing" (click)="goBack()">
        {{'Back' | translate}}
      </button>
      <button type="button" class="btn btn-primary" (click)="confirmToRemoveVehicle()">{{'Remove' |
        translate }}</button>
    </div>
  </div>
</div>