export class WalletItem {
    planName: string;
    initialBalance: number;
    initialBalanceUnit: string;
    remainingBalance: number;
    remainingBalanceUnit: string;
    remainingBalancePercent: number;
    totalDuration: string;
    totalDurationUnit: string;
    remainingTime: string;
    remainingTimePercent: number;
    remainingTimeUnit: string;
    validityDate?: Date;
    isActive: boolean;
    addedDate: Date;
    validityDateString?: string;


    constructor(planName: string, initialBalance: number, initialBalanceUnit: string, remainingBalance: number, remainingBalanceUnit: string, remainingBalancePercent: number,
        totalDuration: string, totalDurationUnit: string, remainingTime: string, remainingTimePercent: number, remainingTimeUnit: string, isActive: boolean, addedDate: Date, validityDate?: Date) {
        this.planName = planName;
        this.initialBalance = initialBalance;
        this.initialBalanceUnit = initialBalanceUnit;
        this.remainingBalance = remainingBalance;
        this.remainingBalanceUnit = remainingBalanceUnit;
        this.remainingBalancePercent = remainingBalancePercent;
        this.totalDuration = totalDuration;
        this.totalDurationUnit = totalDurationUnit;
        this.remainingTime = remainingTime;
        this.remainingTimePercent = remainingTimePercent;
        this.remainingTimeUnit = remainingTimeUnit;
        this.validityDate = validityDate;
        this.isActive = isActive;
        this.addedDate = addedDate;
    }


}