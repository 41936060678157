import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { UsageHistory } from '../_models/usage-history';
import { BaseHttpService } from '@shared/services/base-http-service.service';
import { ConfigService } from '@shared/services/config.service';
import { ResponseError } from '@shared/models';
import { DataUsageEntry } from '../_models/data-usage-entry';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class UsageHistoryService extends BaseHttpService {
  usageEntries: DataUsageEntry[] = new Array<DataUsageEntry>();
  constructor(httpClient: HttpClient, configService: ConfigService, translateService: TranslateService,) {
    super(httpClient, configService, translateService);
  }

  // GET
  getSummary(request: UsageHistory): Observable<UsageHistory | ResponseError> {
    return this.post<UsageHistory>('/accounts/usageHistory/getSummary?', request);
  }

}
