export enum CreditCardType {
    Unknown = 0,
    Visa = 1,
    MasterCard = 2,
    Amex = 3,
    Switch = 4,
    Laser = 5,
    JCB = 6,
    DinersClub = 7,
    BankCard = 8
}
