import { Component } from '@angular/core';
import { IAppInfo } from './entities/appinfo';

import { ConfigService } from '@shared/services/config.service';

@Component({
  selector: 'app-info',
  templateUrl: './info.component.html',
  styleUrls: ['./info.component.scss']
})

export class InfoComponent {

  public name: string;
  public version: string;
  public websiteName: string;
  public hostname: string;
  public api: string;
  public info: IAppInfo;

  constructor(protected _configService: ConfigService) {
    this.name = _configService.config.name || 'N/A';
    this.version = _configService.config.version || 'N/A';
    this.websiteName = _configService.config.websiteName || 'N/A';
    this.hostname = _configService.config.hostname || 'N/A';
    this.api = _configService.config.api || 'N/A';

    this.info = {
      name: this.name,
      version: this.version,
      websiteName: this.websiteName,
      hostname: this.hostname,
      utcNow: new Date(),
      api: this.api
    };
  }
}
