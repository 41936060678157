<div (appClickOutside)="clickedOutside()" class="ct-select-dropdown position-relative d-flex">
    <button type="button" name="selectOption" class="ct-select-toggle" (click)="toggleOptions()">
        <span *ngIf="selectedType === selectType.Icon" class="icon" [ngClass]="activeItem?.icon"></span>
        <img *ngIf="selectedType === selectType.Image" [src]="activeItem?.icon" class="image">
        <span class="label" [attr.disabled]="buttonLabelDisabled ? 'disabled' : null">{{ activeItem?.label }}</span>
        <span class="caret" [ngClass]="{'caret-down': !showOptions, 'caret-up': showOptions}"></span>
    </button>
    <ul *ngIf="showOptions" class="ct-select-menu" tabindex="0">
        <li *ngFor="let item of items" [attr.data-test-id]="'ct-option-' + item.value.toLowerCase()" class="select-item"
            (click)="selectItem(item)">
            <span *ngIf="selectedType === selectType.Icon" class="icon" [ngClass]="item.icon"></span>
            <img *ngIf="selectedType === selectType.Image" [src]="item.icon" class="image">
            <span class="label">{{ item.label }}</span>
        </li>
    </ul>
</div>