import { Component, ContentChild } from '@angular/core';

@Component({
  selector: 'app-show-hide-input',
  templateUrl: './show-hide-input.component.html',
  styleUrls: ['./show-hide-input.component.scss']
})
export class ShowHideInputComponent {

  @ContentChild('showhideinput') input;
  public show = false;

  constructor() { }

  toggleShow() {
    this.show = !this.show;
    this.show ? this.input.nativeElement.type = 'text' : this.input.nativeElement.type = 'password';
  }
}
