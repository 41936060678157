import { Injectable } from '@angular/core';

@Injectable()
export class FormatSmsService {
    constructor() { }

    public get(smsNumber: string): string {
        if (smsNumber === null || smsNumber === '') {
            return smsNumber;
        }
        if (smsNumber.charAt(0) === '0') {
            smsNumber = smsNumber.substring(1);
        }
        if (smsNumber.indexOf(' ') >= 0) {
            smsNumber = smsNumber.replace(new RegExp(' ', 'g'), '');
        }
        return smsNumber;
    }
}
