import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-image-toggle',
  templateUrl: './image-toggle.component.html',
  styleUrls: ['./image-toggle.component.scss']
})
export class ImageToggleComponent {

  @Input() ActiveImage;
  @Input() InactiveImage;
  @Input() Model: boolean;
  @Output() toggle = new EventEmitter<boolean>();

  constructor() { }

  click() {
    if (this.Model) {
      this.Model = false;
    } else {
      this.Model = true;
    }
    this.toggle.emit(this.Model);
  }
}
