// Shared
import { NgModule } from '@angular/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { SharedComponentsModule } from '@shared/components/shared-components.module';
import { ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { PipeModule } from '@shared/pipes/pipe.module';
import { UiSwitchModule } from 'ngx-ui-switch';
// Product
import { ProductComponent } from './product.component';
import { ProductCheckoutConfirmationComponent } from '../product/product-checkout-confirmation/product-checkout-confirmation.component';
import { ProductCheckoutOrderCardComponent } from '../product/product-checkout/product-checkout-order-card/product-checkout-order-card.component';
import { CardPageHeaderComponent } from './product-checkout/card-page-header/card-page-header.component';
import { RouterModule } from '@angular/router';
import { ProductCheckoutHppComponent } from './product-checkout/product-checkout-hpp/product-checkout-hpp.component';
import { ProductCheckoutHppContextService } from '@shared/services/product-checkout-context-hpp.service';
import { ProductCheckoutHppResolverService } from '@shared/resolvers/product-checkout-hpp-resolver.service';
import { ProductMoqService } from './_services';
import { ProductCheckoutRefreshComponent } from './product-checkout-refresh/product-checkout-refresh';

@NgModule({
    imports: [
        CommonModule,
        NgbModule,
        ReactiveFormsModule,
        SharedComponentsModule,
        RouterModule,
        PipeModule,
        UiSwitchModule
    ],
    declarations: [
        ProductComponent,
        ProductCheckoutConfirmationComponent,
        ProductCheckoutOrderCardComponent,
        CardPageHeaderComponent,
        ProductCheckoutHppComponent,
        ProductCheckoutRefreshComponent
    ],
    providers: [
        ProductCheckoutHppContextService,
        ProductCheckoutHppResolverService,
        ProductMoqService
    ]
})
export class ProductModule { }
