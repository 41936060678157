import { ResponseError } from '@shared/models/response-error';
import { IResponseError } from '@shared/models/errors/iresponse-error';

export class NotFoundError extends ResponseError {
    constructor(obj?: IResponseError) {
        obj.code = '0.2';
        obj.key = 'resourceNotFound';
        super(obj);
    }
}
