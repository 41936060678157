
export class RedirectContext {
    CountryISOId: string = null;
    LanguageISOId: string = null;
    VIN: string = null;
    VehicleName: string = null;
    Email: string = null;
    Salutation: string = null;
    FirstName: string = null;
    LastName: string = null;
    Country: string = null;
    BirthDate: string = null;
    CareOf: string = null;
    Street: string = null;
    HouseNumber: string = null;
    City: string = null;
    Zip: string = null;
    PhoneMobile: string = null;
    RedirectType: number = -1;

    public static get CountryISOId(): string {
        return 'CountryISOId';
    }
    public static get LanguageISOId(): string {
        return 'LanguageISOId';
    }
    public static get VIN(): string {
        return 'VIN';
    }
    public static get VehicleName(): string {
        return 'VehicleName';
    }
    public static get Email(): string {
        return 'Email';
    }
    public static get Salutation(): string {
        return 'Salutation';
    }
    public static get FirstName(): string {
        return 'FirstName';
    }
    public static get LastName(): string {
        return 'LastName';
    }
    public static get Country(): string {
        return 'Country';
    }
    public static get BirthDate(): string {
        return 'BirthDate';
    }
    public static get CareOf(): string {
        return 'CareOf';
    }
    public static get Street(): string {
        return 'Street';
    }
    public static get HouseNumber(): string {
        return 'HouseNumber';
    }
    public static get City(): string {
        return 'City';
    }
    public static get Zip(): string {
        return 'Zip';
    }
    public static get PhoneMobile(): string {
        return 'PhoneMobile';
    }
    constructor() {
    }
}
