import { Component, OnInit } from '@angular/core';
import { Vehicle } from '../_models/vehicle';
import { ActivatedRoute } from '@angular/router';
import { VehicleService } from '../../vehicles/_services';
import { ConfigService, LanguageService } from '@shared/services/index';

@Component({
  selector: 'app-vehicle-details',
  templateUrl: './vehicle-details.component.html',
  styleUrls: ['./vehicle-details.component.scss']
})
export class VehicleDetailsComponent implements OnInit {
  public vehicle: Vehicle;
  public enableBuyPlan: boolean;
  public loading: boolean;
  public btnText = 'Vehicle_Add_Title';
  public title = 'Title_Dashboard';
  public isVehicleDetailsRequestReady = false;
  public isGen4Vehicle = false;
  public showIncarTopupCard = false;

  constructor(
    private activatedRoute: ActivatedRoute,
    private languageService: LanguageService,
    private vehicleService: VehicleService,
    private configService: ConfigService) { }

  ngOnInit() {
    this.loading = true;
    this.vehicle = this.activatedRoute.snapshot.data['vehicle'];
    this.isGen4Vehicle = this.vehicleService.checkIsGen4Vehicle(this.vehicle.vin);
    this.enableBuyPlan = false;
  }

  redirectToAddVehicle() {
    this.languageService.localizedNavigate(['/dashboard/vehicle/add']);
  }

  redirectToBuyPlan() {
    this.languageService.localizedNavigate(['/dashboard/plan', this.vehicle.vin]);
  }

  handleVehicleDetailsReadyEvt(event) {
    this.isVehicleDetailsRequestReady = event;
    this.forceRefreshVehicleUserData();
  }

  private forceRefreshVehicleUserData() {
    this.vehicleService.forceRefreshVehicleUserStatus(this.vehicle.vin).subscribe(
      refresh => {
        this.vehicleService.userCanBuyPlan(this.vehicle.vin).subscribe(
          res => {
            this.enableBuyPlan = res;
            this.loading = false;
          }
        );
        this.canShowIncarTopupCard();
      }
    );
  }

  showInCarTopup() {
    return this.configService.config.showInCarTopup;
  }

  private canShowIncarTopupCard() {
    if (this.vehicle.isLiableUser || this.vehicle.isSponsorUser) {
      this.showIncarTopupCard = true;
    }
  }
}
