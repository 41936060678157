
import { filter } from 'rxjs/operators';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { LanguageService, AnalyticsToolsService, RumService } from '@shared/services';
import { ConfigService } from '@shared/services/config.service';
import { Subscription } from 'rxjs';
import { Router, NavigationEnd } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
  public isBrowserOutdated: boolean = false;
  private isProductionEvn: boolean = false;
  routerSubscription: Subscription;
  constructor(
    private languageService: LanguageService,
    private _analyticsToolsService: AnalyticsToolsService,
    private _rumService: RumService,
    private _configService: ConfigService,
    private translateService: TranslateService,
    private router: Router
  ) {
    this.preventNonProductionIndexing();
    this.translateService.addLangs(this.languageService.availableLanguages.map(a => a.isoCode));
    this.translateService.setDefaultLang('en');

    this.routerSubscription = this.router.events.pipe(
      filter(event => event instanceof NavigationEnd))
      .subscribe((event: NavigationEnd) => {
        window.scroll(0, 0);
        (<any>window).ga('set', 'page', event.urlAfterRedirects);
        (<any>window).ga('send', 'pageview');
      });
  }
  ngOnInit(): void {
    this._rumService.setDatadogRum();
    this.isProductionEvn = this._configService.config.isProductionEnv;
    if (this.isProductionEvn) {
      this._analyticsToolsService.setAnalyticsTools();
    }
  }

  ngOnDestroy() {
    this.routerSubscription.unsubscribe();;
  }

  preventNonProductionIndexing() {
    if (!this._configService.config.isProductionEnv) {
      const metaTag = document.createElement('meta');
      metaTag.name = 'robots';
      metaTag.content = 'noindex, nofollow';
      document.getElementsByTagName('head')[0].appendChild(metaTag);
    }
  }


}
