import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { AccountActivate } from '../_models/account-activate';
import { ResponseError } from '@shared/models/response-error';
import { Logger } from '@shared/logging/Logger';
import { NgxSpinnerService } from 'ngx-spinner';
import { LanguageService } from '@shared/services';
import { AccountActivateService } from '@app/registration/_services/account-activate.service';
import { LoginAlert } from '@shared/models/login-alert';
import { AlertType } from '@shared/models';

@Component({
  selector: 'app-account-activate',
  templateUrl: './account-activate.component.html',
  styleUrls: ['./account-activate.component.scss']
})
export class AccountActivateComponent implements OnInit {

  token: string;

  constructor(
    private activatedRoute: ActivatedRoute,
    private languageService: LanguageService,
    private accountActivateService: AccountActivateService,
    private spinnerService: NgxSpinnerService,
    private logger: Logger) { }

  ngOnInit() {
    this.activatedRoute.queryParams.subscribe((params: Params) => {
      if (params['accountToken'] !== null && params['accountToken'] !== undefined) {
        this.token = params['accountToken'];
        this.activateAccount();
      } else {
        this.languageService.localizedNavigate(['../'], { relativeTo: this.activatedRoute });
      }
    });
  }

  prepareActivateAccountData(): AccountActivate {
    const accountResetPasswordData = new AccountActivate();
    accountResetPasswordData.token = this.token;
    return accountResetPasswordData;
  }

  activateAccount() {
    this.spinnerService.show();
    this.accountActivateService.activateAccount
      (this.prepareActivateAccountData())
      .subscribe(
        (data: AccountActivate) => {
          const loginAlert = new LoginAlert(AlertType.Success, 'Account_Activated');
          this.languageService.localizedNavigate(['/login'], { relativeTo: this.activatedRoute, state: { alert: loginAlert } });

          this.spinnerService.hide();
        },
        (err: ResponseError) => {
          this.spinnerService.hide();
          var loginAlert = null;
          switch (err.code) {
            case '10.6':
              break;
            case '10.7':
              loginAlert = new LoginAlert(AlertType.Error,
                'Activation_Link_Expired_Popup_Description',
                'Activation_Link_Expired_Popup_Title',
                'Activation_Link_Expired_Popup_Button'
              );
              break;
            default:
              loginAlert = new LoginAlert(AlertType.Error, err.message);
              this.logger.error(err);
              break;
          }
          this.languageService.localizedNavigate(['/login'], { relativeTo: this.activatedRoute, state: { alert: loginAlert } });
        },
        () => { this.spinnerService.hide(); }
      );
  }
}
