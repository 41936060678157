import { AccountAddress } from '@app/account/_models';
import { Teaser, Vehicle } from '../../app/vehicles/_models';

export class RegistrationContext {
    email = '';
    firstName = '';
    lastName = '';
    identityId = '';
    acceptedTermsAndConditions: boolean;
    promoEmailSubscribe: boolean;
    newVehicle: Vehicle;
    vinAdded = false;
    teaser: Teaser;
    address: AccountAddress;
    dateOfBirth: string;
}
