import { TopUpLimit } from '../_models/top-up-limit';
export class SponsorUserDetails {
    public vin: string;
    public homeCountry: string;
    public sendLowBalanceNotification: boolean;
    public topUpLimit: TopUpLimit;
    public creditCardRef: string;
    public mobileNumber: string;
    public mobileCountryCode: string; // eg 353
    public mobileCountryISOCode: string; // eg IE
    public mobileLocalNumber: string; // eg 086123456
    constructor() {
        this.topUpLimit = new TopUpLimit();
    }
}
