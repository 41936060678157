import { Routes } from '@angular/router';
import { DashboardComponent } from './dashboard.component';
import { DashboardViewComponent } from './dashboard-view/dashboard-view.component';
import { ProductCatalogComponent } from '../product/product-catalog/product-catalog.component';
import { VehicleDetailsComponent } from '../vehicles/vehicle-details/vehicle-details.component';
// Services
import { VehicleResolverService } from '@shared/resolvers/index';
// Shared
import { AuthGuard } from '@shared/guards/auth.guard';

export const dashboardRoutes: Routes = [
    {
        path: 'dashboard',
        component: DashboardComponent,
        canActivate: [AuthGuard],
        children: [
            { path: '', redirectTo: 'vehicles', pathMatch: 'full' },
            { path: 'vehicles', component: DashboardViewComponent },
            { path: 'vehicle/:vin', component: VehicleDetailsComponent, resolve: { vehicle: VehicleResolverService } },

            // Plan
            { path: 'plan', component: ProductCatalogComponent },
            { path: 'plan/:vin', component: ProductCatalogComponent },

            // History
            { path: 'history', loadChildren: () => import('app/history/history.module').then(m => m.HistoryModule), pathMatch: 'prefix' },

            // Account
            { path: 'account', loadChildren: () => import('app/account/account.module').then(m => m.AccountModule), pathMatch: 'prefix' }
        ]
    }
];
