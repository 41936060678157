import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Teaser } from '@app/vehicles/_models';
import { VerifyVehicle } from '@app/vehicles/_models/verify-vehicle';

@Component({
    selector: 'app-vehicle-verification',
    templateUrl: './vehicle-verification.html',
    styleUrls: ['./vehicle-verification.scss']
})
export class VehicleVerificationComponent {

    @Input() currentVehicle: VerifyVehicle;

    @Output() cancelClick = new EventEmitter();
    @Output() continueClick = new EventEmitter<VerifyVehicle>();
    @Output() retryClick = new EventEmitter();

    showVinForm = true;

    constructor() {
    }

    verifyCallback(verifiedVehicle: VerifyVehicle) {
        this.showVinForm = false;
        this.currentVehicle = verifiedVehicle;
    }

    retryCallback() {
        this.currentVehicle = null;
        this.showVinForm = true;
        this.retryClick.emit();
    }

    cancelClickEvent() {
        this.cancelClick.emit();
    }

    continueClickEvent(vehicleTeaser: Teaser) {
        this.currentVehicle.IsVerified = true;
        this.currentVehicle.teaser = vehicleTeaser;
        this.continueClick.emit(this.currentVehicle);
    }
}
