import { Injectable } from '@angular/core';
import { BaseHttpService, ConfigService, LanguageService } from '@shared/services';
import { HttpClient } from '@angular/common/http';
import { ResponseError } from '@shared/models';
import { ContactUs } from '../_models/contact-us';
import { Observable } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class ContactUsService extends BaseHttpService {

    private myAccountLink: string;
    private faqLink: string;
    private language: string;

    constructor(httpClient: HttpClient, configService: ConfigService,
        translateService: TranslateService,
        private languageService: LanguageService) {
        super(httpClient, configService, translateService);
        this.language = this.languageService.getActiveLanguage().isoCode || 'en';
        this.myAccountLink = this.getMyAccountLink();
        this.faqLink = this.getFaqLink();
    }

    // POST
    contactUs(request: ContactUs): Observable<ContactUs | ResponseError> {
        request.myAccountLink = this.myAccountLink;
        request.faqLink = this.faqLink;
        return this.post<ContactUs>('/support/contactUs', request);
    }

    private getMyAccountLink() {
        return this._configService.config.myAccountLink + '/' + this.language;
    }

    private getFaqLink() {
        return this._configService.config.faqLink + '/' + this.language + '/faq';
    }
}
