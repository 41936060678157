import { Injectable } from '@angular/core';
import { BaseHttpService, ConfigService } from '@shared/services';
import { HttpClient } from '@angular/common/http';
import { Plan } from '../_models/plan';
import { ResponseError } from '@shared/models';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class PlansService extends BaseHttpService {

    constructor(protected _httpClient: HttpClient, protected _configService: ConfigService, translateService: TranslateService,) {
        super(_httpClient, _configService, translateService);
    }

    getBestSellingPlans(countryIsoCode: string, takeTop: number = null): Observable<Plan[] | ResponseError> {
        let response;
        if (takeTop) {
            response = this.get<Plan[]>('/products/' + countryIsoCode + '/getBestSellingProducts?takeTop=' + takeTop, null);
        }
        response = this.get<Plan[]>('/products/' + countryIsoCode + '/getBestSellingProducts', null);
        return response.pipe(
            map(plans => {
                if (plans instanceof Array) {
                    const listOfPlans = plans as Plan[];
                    return listOfPlans.sort(this.orderByFn);
                }
                return plans;
            })
        );
    }

    orderByFn(a: Plan, b: Plan) {
        if (a.amountOfPurchases < b.amountOfPurchases) {
            return 1;
        }
        if (a.amountOfPurchases > b.amountOfPurchases) {
            return -1;
        }
        return 0;
    }

    getBundleRates(): Observable<Plan[] | ResponseError> {
        return this.get<Plan[]>('/products/bundleRates');
    }
}
 