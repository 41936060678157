<div class="plans-container" *ngIf="arePlansAvailable">
  <div class="row text-center justify-content-center no-gutters">
    <div class="col-12">
      <h2 data-test-id="home-plans-title">
        {{'Home_Plans_Title' | translate }}
      </h2>
    </div>
    <div class="col-12">
      <p data-test-id="home-plans-description-text">
        {{'Home_Plans_Description' | translate }}
      </p>
    </div>
    <ul class="plans-list">
      <li *ngFor="let plan of Plans; let left = first; let right = last">
        <app-plan-tile [Plan]="plan" [ngClass]="{right: right, left: left}"></app-plan-tile>
      </li>
    </ul>

    <div class="col-12">
      <p>{{'Speed_Information_Notice' | translate}}
      </p>
      <a [routerLink]="['/faq'| localize]" class="speed-information-notice text-light">{{'Learn_More_Speed_Information'
        |
        translate}}</a>
    </div>
  </div>
</div>