<div class="alerts-container" [ngClass]="{fixed: isFixed}">
  <app-alert *ngFor="let alert of alerts" 
    [alertType]="alert.type" 
    [hideCloseButton]="!alert.dissmisable"
    [data]="alert"
    (closeClick)="removeAlert($event)">
    <span description>
      {{alert.message | translate}}
    </span>
  </app-alert>
</div>