import { Component, OnInit } from '@angular/core';
import { ContactUs } from '../_models/contact-us';
import { UntypedFormGroup, Validators, UntypedFormBuilder } from '@angular/forms';
import { ContactUsService } from '../_services/contact-us.service';
import { AlertService, UserContextService } from '@shared/services';
import { ResponseError } from '@shared/models';
import { Logger } from '@shared/logging/Logger';
import { CountriesService } from '@shared/services/countries.service';
import { CustomValidators } from '@shared/validators/custom-validators';
import { NgxSpinnerService } from 'ngx-spinner';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-contact-us-form',
  templateUrl: './contact-us-form.component.html',
  styleUrls: ['./contact-us-form.component.scss']
})
export class ContactUsFormComponent implements OnInit {

  public contactUsForm: UntypedFormGroup;
  public contactUsData: ContactUs;

  public homeCountryCode: string;

  private alertSuccess = 'Contact_Us_Success';

  constructor(
    private fb: UntypedFormBuilder,
    private contactUsService: ContactUsService,
    private spinnerService: NgxSpinnerService,
    private alertService: AlertService,
    private translationsService: TranslateService,
    private userContextService: UserContextService,
    private countriesService: CountriesService,
    private logger: Logger
  ) {
    this.contactUsData = new ContactUs();
    this.createForm();
  }

  ngOnInit() {
    this.alertSuccess = this.translationsService.instant(this.alertSuccess);
    this.getCountry();
  }

  createForm() {
    this.contactUsForm = this.fb.group({
      name: ['', [Validators.required, Validators.maxLength(80)]],
      email: ['', [Validators.required, Validators.email, CustomValidators.email]],
      contactNumber: ['', [Validators.required, CustomValidators.phoneNumber, Validators.maxLength(80)]],
      contactCountryCode: ['', [Validators.required]],
      message: ['', [Validators.required]]
    });
  }

  private getCountry() {
    this.homeCountryCode = this.userContextService.userContext.homeCountryCode;
    if (this.homeCountryCode === null || this.homeCountryCode === '' || this.homeCountryCode === undefined) {
      this.homeCountryCode = this.countriesService.getIsoCodeForNonAuthenticatedUser().toLowerCase();
    } else {
      this.homeCountryCode = this.userContextService.userContext.homeCountryCode.toLowerCase();
    }
  }

  private prepareData(): ContactUs {
    const formModel = this.contactUsForm.value;
    const data = new ContactUs();
    data.name = formModel.name;
    data.email = formModel.email;
    data.contactNumber = '+' + formModel.contactCountryCode + ' ' + formModel.contactNumber;
    data.message = formModel.message;
    return data;
  }

  contactUs() {
    this.spinnerService.show();
    this.contactUsService.contactUs(this.prepareData())
      .subscribe(
        data => {
          this.spinnerService.hide();
          this.contactUsForm.reset();
          this.alertService.success(this.alertSuccess, 0, true, false);
        },
        (err: ResponseError) => {
          this.spinnerService.hide();
          switch (err.code) {
            default:
              this.alertService.error(err.message, 0, true, false);
              this.logger.error(err);
              break;
          }
        },
        () => { this.spinnerService.hide(); }
      );
  }

  public initCountryCode(obj) {
    obj.setCountry(this.homeCountryCode);
  }

  public onCountryChange(country: any) {
    this.contactUsForm.controls['contactCountryCode'].setValue(country.dialCode);
  }
}
