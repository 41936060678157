import { Component } from '@angular/core';
import { ThemeComponent } from '@shared/components/theme.component';
import { ConfigService } from '@shared/services/index';
import { ThemeService } from '@shared/services/theme-service.service';
@Component({
  selector: 'app-home-compatibility',
  templateUrl: './compatibility.component.html',
  styleUrls: ['./compatibility.component.scss']
})
export class CompatibilityComponent extends ThemeComponent {

  resellerCode: string;
  isBrandEligible: boolean;

  constructor(
    private configService: ConfigService,
    _themeService: ThemeService) {
    super(_themeService);
  }

  showSkodaHyperlink() {
    return this.configService.config.showSkodaLink;
  }

  isTeaserPromotionRunning() {
    this.resellerCode = this.configService.config.resellerCode;
    if (this.resellerCode.toUpperCase() === 'vw'.toUpperCase()) {
      return this.isBrandEligible = true;
    } else {
      return this.isBrandEligible = false;
    }
  }
}
