import { Component, OnInit, ElementRef, ViewChild, Input } from '@angular/core';
import Chart from 'chart.js/auto';
import { UsageHistoryService } from '../../_services/usage-history.service';
import { UsageHistory } from '../../_models/usage-history';
import { SessionKeys } from '@shared/models';
import { ResponseError } from '@shared/models/response-error';
import { AlertService, LanguageService, ThemeService } from '@shared/services/index';
import { DatesUtilityService } from '@shared/services/dates-utility.service';
import { Logger } from '@shared/logging/Logger';
import { DataUsageEntry } from '../../_models/data-usage-entry';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-usage-history-card',
  templateUrl: './usage-history-card.component.html',
  styleUrls: ['./usage-history-card.component.scss']
})

export class UsageHistoryCardComponent implements OnInit {
  public usageHistoryData: UsageHistory;
  sessionKeys = new SessionKeys();
  usageList: string[];
  public lastsevendaysMB: string = '0.00';
  public todayMB: string = '0.00';
  public noUsageHistory: boolean = false;
  formattedUsage: string[] = [];
  usageChart: any;
  daysOfTheWeek: string[] = [];
  @Input() vin: string;
  @ViewChild('usageHistoryChart') canvas: ElementRef;
  constructor(
    protected languageService: LanguageService,
    private spinnerService: NgxSpinnerService,
    private usageHistoryService: UsageHistoryService,
    private alertService: AlertService,
    private datesUtilityService: DatesUtilityService,
    private logger: Logger,
    private themeService: ThemeService) {
  }

  ngOnInit() {
    this.getSummary();
  }

  getSummary() {
    this.usageHistoryService.getSummary(this.prepareUsageHistoryData()).subscribe(
      (data: UsageHistory) => {
        this.noUsageHistory = this.IsZero(data.totalDataConsumed.toFixed(2));
        if (data.usageList.length > 0) {
          this.prepareChartValues(data);
          this.lastsevendaysMB = this.getTotalDataConsumedInMB(data.totalDataConsumed).toFixed(2);
          this.todayMB = this.usageHistoryData.todaysDateDataConsumed.toFixed(2);
        }
      },
      (err: ResponseError) => {
        this.logger.error(err);
        this.lastsevendaysMB = '0.00';
        this.todayMB = '0.00';
        this.noUsageHistory = true;
        this.handleError(err);
      });
  }

  private getUsageForChart(data: UsageHistory) {
    const datesRange = this.datesUtilityService.getDatesListByRange(new Date(this.usageHistoryData.usageHistoryFrom), new Date(this.usageHistoryData.usageHistoryTo));
    const usage = datesRange.map(date => {
      const dataUsageEntry = new DataUsageEntry();
      dataUsageEntry.date = date;
      dataUsageEntry.dataConsumed = 0;
      data.usageList.forEach((usage: DataUsageEntry) => {
        const formattedDate = this.datesUtilityService.getFormattedDate(new Date(usage.date));
        if (dataUsageEntry.date === formattedDate) {
          dataUsageEntry.dataConsumed += this.getTotalDataConsumedInMB(usage.dataConsumed);
          if (dataUsageEntry.date === this.usageHistoryData.usageHistoryTo) {
            this.usageHistoryData.todaysDateDataConsumed = dataUsageEntry.dataConsumed;
          }
        }
      });
      return dataUsageEntry;
    });
    return usage;
  }

  getTotalDataConsumedInMB(size: number): number {
    const dataConsumedInMb = (size / 1024);
    return dataConsumedInMb;
  }

  prepareChartValues(data: UsageHistory) {
    this.usageChart?.destroy();
    const _usage: DataUsageEntry[] = this.getUsageForChart(data);
    if (!this.noUsageHistory) {
      this.daysOfTheWeek = this.datesUtilityService.getDaysOfTheWeekByRange(_usage.map(usage => usage.date));
      this.usageChart = new Chart(this.canvas.nativeElement.getContext('2d'), {
        type: 'line',
        data: {
          datasets: [
            {
              backgroundColor: 'transparent',
              borderColor: this.themeService.themeColour,
              pointBackgroundColor: this.themeService.themeColour,
              label: 'MB',
              data: _usage.map(usage => usage.dataConsumed.toFixed(2)),
              tension: 0,
              pointBorderWidth: 2
            }],
          labels: this.daysOfTheWeek
        },
        options: {
          plugins: {
            legend: {
              display: false
            },
            tooltip: {
              mode: 'point',
              backgroundColor: this.themeService.themeColour,
              displayColors: false
            }
          },
          scales: {
            xAxis: {
              grid: {
                drawOnChartArea: false
              }
            },
            yAxis: {
              display: false,
              grid: {
                drawOnChartArea: false
              }
            }
          },
        }
      }
      );
    }
  }

  prepareBaseUsageHistoryData(): UsageHistory {
    this.usageHistoryData = new UsageHistory();
    this.usageHistoryData.vin = this.vin;
    return this.usageHistoryData;
  }

  prepareUsageHistoryData(): UsageHistory {
    // base request
    this.usageHistoryData = this.prepareBaseUsageHistoryData();

    // today's date
    const _today = new Date();
    // date intervals
    this.usageHistoryData.usageHistoryFrom = this.datesUtilityService.getFormattedDate(new Date(_today.setDate(_today.getDate() - 6)));
    this.usageHistoryData.usageHistoryTo = this.datesUtilityService.getFormattedDate(new Date());
    return this.usageHistoryData;
  }

  IsZero(value) {
    return (value === '0.00');
  }

  goToUsageHistory() {
    this.spinnerService.show();
    const route = `/dashboard/history/${this.vin}/usage`
    this.languageService.localizedNavigate([route]);
  }

  private handleError(err: ResponseError) {
    switch (err.code) {
      case '0.2':
      case '10.29':
      case '10.30':
        break;
      default:
        this.alertService.error(err.message, 5, true, false);
        break;
    }
  }

}
