import { Injectable } from '@angular/core';
import { IdentificationValidationContext } from '../models/identification-validation-context';
import { SessionKeys } from '../models';
import { DataService } from '../services/data.service';

@Injectable()
export class IdentificationValidationContextService {

    public context: IdentificationValidationContext = new IdentificationValidationContext();

    constructor(private dataService: DataService) {
        this.getContext();
    }

    public resetContext() {
        this.context.dataFields = null;
        this.context.status = null;
        this.context.isValidIdentification = false;
        this.context.isIdentificationSubmitted = null;
        this.dataService.removeSession(SessionKeys.identificationValidationContext);
    }

    public setContext(context: IdentificationValidationContext) {
        this.context = context;
        this.setSessionValues(context);
    }

    public update() {
        const contextWithoutCreditCards = this.context;
        this.dataService.setSession(SessionKeys.identificationValidationContext, JSON.stringify(contextWithoutCreditCards));
    }

    private setSessionValues(data: IdentificationValidationContext) {
        this.dataService.setSession(SessionKeys.identificationValidationContext, JSON.stringify(data));
    }

    private getContext() {
        const object = this.dataService.getSession(SessionKeys.identificationValidationContext);
        if (object) {
            this.context = JSON.parse(object) as IdentificationValidationContext;
            return;
        }
    }

}
