import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'arrayKeyPairPipe'
})
export class ArrayKeyPairPipePipe implements PipeTransform {

  transform(value: any, args?: any[]): any[] {
    const keyArr: any[] = Object.keys(value);
    const dataArr = [];

    keyArr.forEach((key: any) => {
      dataArr.push({key, value: value[key] });
    });
    return dataArr;
  }
}
