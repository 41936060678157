export class SelectDropdownItem {
    value: string;
    label: string;
    isActive?: boolean;
    icon?: string;

    constructor(value: string, label: string, isActive?: boolean, icon?: string) {
        this.value = value;
        this.label = label;
        this.isActive = isActive;
        this.icon = icon;
    }
}

export enum SelectDropdownType {
    Default,
    Image,
    Icon
}
