import { Injectable, Inject, LOCALE_ID } from '@angular/core';
import { Language } from '../models/language';
import { LanguageCode } from '../models/language-code';
import { LocalizeRouterService } from '@gilsdav/ngx-translate-router';
import { NavigationExtras, Router } from '@angular/router';

@Injectable()
export class LanguageService {
    public readonly locale: string;
    public readonly availableLanguages: Language[];

    constructor(
        private localizeService: LocalizeRouterService,
        private router: Router,
        @Inject(LOCALE_ID) _locale: string) {
        this.locale = _locale;
        this.availableLanguages = this.getAvailableLanguages();
    }

    private setLanguage(language: Language): void {
        //Force angular to reload the component to fix issues with translations
        //https://stackoverflow.com/questions/47813927/how-to-refresh-a-component-in-angular
        this.router.routeReuseStrategy.shouldReuseRoute = () => false;
        this.localizeService.changeLanguage(language.isoCode);
    }

    public getActiveLanguage() {
        return this.lookUpLanguage(this.localizeService.parser.currentLang);
    }

    private lookUpLanguage(isoCode: string): Language {
        const _languages = this.getAvailableLanguages();
        let language = _languages.find(e => e.isoCode === LanguageCode.English);
        _languages.forEach(element => {
            if (element.isoCode === isoCode) {
                language = element;
                return;
            }
        });
        return language;
    }

    private getAvailableLanguages(): Language[] {
        return [
            new Language('English', LanguageCode.English),
            new Language('Dansk', LanguageCode.Danish),
            new Language('Nederlands', LanguageCode.Duth),
            new Language('Français', LanguageCode.French),
            new Language('Deutsch', LanguageCode.German),
            new Language('Italiano', LanguageCode.Italian),
            new Language('Español', LanguageCode.Spanish),
            new Language('Svenska', LanguageCode.Swedish),
            new Language('Český', LanguageCode.Czech),
            new Language('български', LanguageCode.Bulgarian),
            new Language('Português', LanguageCode.Portuguese),
            new Language('Hrvatski', LanguageCode.Croatian),
            new Language('Polski', LanguageCode.Polish),
            new Language('Magyar', LanguageCode.Hungarian)
        ];
    }

    public changeLanguage(languge: Language) {
        this.setLanguage(languge);
    }

    public localizedNavigate(commands: any[], extras?: NavigationExtras): Promise<boolean> {
        const array = this.localizeService.translateRoute(commands) as any[];
        return this.router.navigate(array, extras);
    }
}
