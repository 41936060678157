
// Error codes returned in product paymet process
export enum ErrorCode {
    CouldNotCreateOrder = '120.1',
    FailedPayment = '40.10',
    FailedAddPaymentDetails = '40.22',
    FailedAddPackageToWallet = '40.22',
    FailedRegisterUserAsLiable = '20.17',
    FailedRegisterUserAsSponsor = '20.18'
}
