import { ISink } from '../sink';
import { MonitoringService } from '@shared/services';
import { ResponseError } from '@shared/models';

export class AppInsightsSink implements ISink {

    constructor(private appInsights: MonitoringService) {
    }

    info(message: string) {
        this.appInsights.info(message);
    }
    warn(message: string) {
        this.appInsights.warn(message);
    }
    error(message: string | Error | ResponseError) {
        if (message instanceof Error) {
            this.appInsights.error(
                'Error:' + message.name + '\n' +
                'Message: ' + message.message + '\n' +
                'Stack Trace: ' + message.stack
            );
            return;
        }
        if (message instanceof ResponseError) {
            this.appInsights.error(message.message, { error_message: message.message, error_key: message.key, error_code: message.code, error_extraInfo: message.moreInfo });
            return;
        }
        this.appInsights.error(message as string);
    }
    fatal(message: string | Error) {
        this.appInsights.fatal(message);
    }

}
