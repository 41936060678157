import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { LanguageService } from '@shared/services';
import { AuthenticationService } from '../services/authentication.service';

@Injectable()
export class NotForAuthenticatedGuard  {
    constructor(private authService: AuthenticationService, private languageService: LanguageService) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        if (this.authService.appAuthUser.isAuthenticated === false) {
            return true;
        }
        this.languageService.localizedNavigate(['/dashboard']);
        return false;
    }
}
