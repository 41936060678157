import { Component, OnInit } from '@angular/core';
import { ConfigService, ProductCheckoutHppContextService } from '@shared/services';
import { ProductCheckoutHppContext } from '@shared/models/product-checkout-hpp-context';

@Component({
  selector: 'app-product-checkout-order-card',
  templateUrl: './product-checkout-order-card.component.html',
  styleUrls: ['./product-checkout-order-card.component.scss']
})
export class ProductCheckoutOrderCardComponent implements OnInit {
  context: ProductCheckoutHppContext;
  showVehicleImage: boolean;

  constructor(
    private contextService: ProductCheckoutHppContextService,
    private configService: ConfigService) { }

  ngOnInit() {
    this.context = this.contextService.context;
    this.showVehicleImage = this.configService.config.showVehicleImage;
  }
}
