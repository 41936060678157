import { AlertType } from "./alert";

export class LoginAlert {
    title: string;
    message: string;
    hyperlink: string;
    type: AlertType;

    constructor(type: AlertType, message: string, title: string = '', hyperlink: string = '') {
        this.type = type;
        this.message = message;
        this.title = title;
        this.hyperlink = hyperlink;
    }
}