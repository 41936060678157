<div class="delete-account-container">
  <div class="row text-center justify-content-center no-gutters">
    <div class="delete-account-card">
      <div class="delete-account-title" data-test-id="delete-account-title-text">{{'DeleteAccount_AreYouSure' |
        translate }}</div>
      <div class="col-12">
        <div class="additional-space">
          <div class="delete-account-description" data-test-id="delete-account-description-text">
            {{'DeleteAccount_DescriptionText' | translate }}
          </div>
          <button class="btn btn-primary delete-account-btn" (click)="deleteAccount()"
            data-test-id="delete-account-delete-account-btn">
            {{'DeleteAccount_DeleteAccountBtn' | translate }}
          </button>
        </div>
      </div>
    </div>
  </div>
</div>