import { Component, OnInit, Input } from '@angular/core';
import { VehicleUserStatus, InCarTopup } from '../_models';
import { VehicleService } from '../_services';
import { UserContextService, AlertService, LanguageService } from '@shared/services';
import { ResponseError, BaseResponse } from '@shared/models';
import { Logger } from '@shared/logging/Logger';
import { NgxSpinnerService } from 'ngx-spinner';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-vehicle-incar-topup-card',
  templateUrl: './vehicle-incar-topup-card.component.html',
  styleUrls: ['./vehicle-incar-topup-card.component.scss']
})
export class VehicleIncarTopupCardComponent implements OnInit {

  @Input() vin: string;
  loading: boolean;
  inCarTopup: InCarTopup = new InCarTopup();
  requestFailed: boolean;

  // shared template elements
  incarTopup_Card_Button: string = null;
  showBtn = false;
  cardEndingValue: string = null;
  redirectUrl: string;
  weeklyLimitValue: string = null;

  // request failure: template elements
  showRequestFailedTextSection: boolean = false;

  // Translations keys
  inCarTopup_Card_Convenient: string = 'InCarTopup_Card_Convenient';
  InCarTopup_Card_Setup_SaveCreditCard_Text1: string = 'InCarTopup_Card_Setup_SaveCreditCard_Text1';
  InCarTopup_Card_Setup_SaveCreditCard_Text2: string = 'InCarTopup_Card_Setup_SaveCreditCard_Text2';
  inCarTopup_Card_Setup_Now: string = 'InCarTopup_Card_Setup_Now';
  incarTopup_Card_Warning2: string = null;
  incarTopup_Card_Btn: string = '';
  incarTopup_Card_Btn_Manage: string = 'IncarTopup_Card_Btn_Manage';
  incarTopup_Card_Btn_Setup: string = 'IncarTopup_Card_Btn_Setup';

  constructor(
    private languageService: LanguageService,
    private spinnerService: NgxSpinnerService,
    private alertService: AlertService,
    private userContextService: UserContextService,
    private vehicleService: VehicleService,
    private translationsService: TranslateService,
    private logger: Logger) {
    // Populate translated messages
    this.getTranslatedMessages();
  }

  ngOnInit() {
    this.loading = true;
    this.requestFailed = false;
    this.redirectUrl = '/dashboard/account/inCarTopup';
    this.prepareInCarTopUp();
  }

  redirectToManageIncarTopup() {
    this.languageService.localizedNavigate([this.redirectUrl, this.vin]);
  }

  private prepareInCarTopUp() {
    this.vehicleService.prepareInCarTopUp(this.preparePrepareInCarTopupRequest())
      .subscribe(
        (data: BaseResponse<InCarTopup>) => {
          this.inCarTopup = data.result;
          this.parseCardEndingValue();
          this.parseSelectedAmoutValue();
          this.populateContentForActiveIncarTopup();
          this.loading = false;
          this.requestFailed = false;
        },
        (err: BaseResponse<InCarTopup | ResponseError>) => {
          this.handleBaseResponseObject(err);
          this.spinnerService.hide();
          this.loading = false;
          this.requestFailed = true;
        },
        () => { }
      );
  }

  private preparePrepareInCarTopupRequest(): InCarTopup {
    const model = new InCarTopup();
    model.sponsorUserDetails.vin = this.vin;
    model.sponsorUserDetails.homeCountry = this.userContextService.userContext.homeCountryCode;
    model.userStatus = this.getVehicleUserStatus();
    return model;
  }

  private getVehicleUserStatus(): VehicleUserStatus {
    const vehicles = this.userContextService.userContext.vehicles;
    const vehicle = vehicles.filter(x => x.vin === this.vin)[0];
    if (vehicle !== null && vehicle !== undefined) {
      return vehicle.userStatus;
    } else {
      return VehicleUserStatus.Unknown;
    }
  }

  private parseSelectedAmoutValue() {
    // parse value for selected amount
    // selectedAmount = 2 -> search in the options object form the key with value selectedAmount
    // when card reference exists in registeredCards
    if (this.inCarTopup.sponsorUserDetails.topUpLimit !== null) {
      const options = this.inCarTopup.sponsorUserDetails.topUpLimit.options as { [options: string]: number | null; };
      if (options != null) {
        if (this.inCarTopup.sponsorUserDetails.topUpLimit.selectedAmount !== null) {
          const value = this.inCarTopup.sponsorUserDetails.topUpLimit.selectedAmount;
          for (const key in options) {
            if (options[key] !== undefined && options[key] === value) {
              this.weeklyLimitValue = key;
            }
          }
        } else {
          this.weeklyLimitValue = null;
        }
      }
    }
  }

  private parseCardEndingValue() {
    // get value for cardEndingValue
    // when card reference exists in registeredCards
    if (this.inCarTopup.registeredPaymentCards.availableCards !== null && this.inCarTopup.registeredPaymentCards.availableCards.length > 0) {
      const availableCards = this.inCarTopup.registeredPaymentCards.availableCards;
      availableCards.forEach(card => {
        if (card.referenceId === this.inCarTopup.sponsorUserDetails.creditCardRef) {
          this.cardEndingValue = card.lastFourDigits;
        }
      });
    }
  }

  private populateContentForNoSavedCreditCards() {
    this.showRequestFailedTextSection = true;
    this.incarTopup_Card_Warning2 = this.InCarTopup_Card_Setup_SaveCreditCard_Text1 + ' ' + this.InCarTopup_Card_Setup_SaveCreditCard_Text2;
  }

  private populateContentForSavedCreditCards() {
    this.showRequestFailedTextSection = true;
    this.showBtn = true;
    this.incarTopup_Card_Warning2 = this.inCarTopup_Card_Setup_Now;
    this.incarTopup_Card_Btn = this.incarTopup_Card_Btn_Setup;
  }

  private populateContentForActiveIncarTopup() {
    this.showRequestFailedTextSection = false;
    this.showBtn = true;
    this.incarTopup_Card_Btn = this.incarTopup_Card_Btn_Manage;
  }

  private getTranslatedMessages() {
    this.inCarTopup_Card_Convenient = this.translationsService.instant(this.inCarTopup_Card_Convenient);
    this.inCarTopup_Card_Setup_Now = this.translationsService.instant(this.inCarTopup_Card_Setup_Now);
    this.InCarTopup_Card_Setup_SaveCreditCard_Text1 = this.translationsService.instant(this.InCarTopup_Card_Setup_SaveCreditCard_Text1);
    this.InCarTopup_Card_Setup_SaveCreditCard_Text2 = this.translationsService.instant(this.InCarTopup_Card_Setup_SaveCreditCard_Text2);
    this.incarTopup_Card_Btn_Manage = this.translationsService.instant(this.incarTopup_Card_Btn_Manage);
    this.incarTopup_Card_Btn_Setup = this.translationsService.instant(this.incarTopup_Card_Btn_Setup);
  }

  private handleBaseResponseObject(res: BaseResponse<InCarTopup | ResponseError>) {
    this.spinnerService.hide();
    const err = res.errors;
    this.logger.error(err);
    switch (err.code) {
      case '2':
        break;
      case '20.16': // retrieveSponsorUserDetailsFailed
        this.populateContentForSavedCreditCards();
        break;
      case '40.27': // noSavedCreditCardsForInCarTopup
        this.populateContentForNoSavedCreditCards();
        break;
      default:
        this.logger.error(err);
        this.alertService.error(err.message, 0, true, false);
        break;
    }
  }
}
