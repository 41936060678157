<div class="caption-container">
  <div class="background">
    <div class="gradient-mask">
      <div class="cta-container">
        <div class="client-logo"></div>
        <div *ngIf="isTeaserPromotionRunning(); else teaserNotRunning">
          <h1 class="main-caption" data-test-id="home-main-caption-connect-header">
            <!-- More connected than ever -->
            {{'Home_Main_Caption_Header_Teaser' | translate}}
          </h1>
          <button [routerLink]="[ (isSsoEnabled ? '/login' : '/register') | localize]" class="mx-auto d-block btn btn-primary btn-cta"
            data-test-id="home-main-caption-connect-btn">
            {{'Home_Main_Caption_Connect_Teaser' | translate}}
          </button>
          <p class="home-availability" data-test-id="home-main-caption-availability">
            <a class="text-light" [routerLink]="['/termsAndConditions' | localize]">
              {{'Home_Main_Caption_Availability' | translate}}
            </a>
          </p>
        </div>
        <ng-template #teaserNotRunning>
          <h1 class="main-caption" data-test-id="home-main-caption-connect-header"
            [innerHTML]="'Home_Main_Caption_Header' | translate">
          </h1>
          <hr>
          <button [routerLink]="[(isSsoEnabled ? '/login' : '/register') | localize]" class="mx-auto d-block btn btn-primary btn-cta"
            data-test-id="home-main-caption-connect-btn">
            {{'Home_Main_Caption_Connect' | translate}}
          </button>
        </ng-template>
      </div>
    </div>
  </div>
</div>