import { Injectable } from '@angular/core';
import { Config } from '../models/config/config';

@Injectable()
export class ConfigService {
    private _config: Config;

    constructor() {
        this._config = new Config();
    }

    get config(): Config {
        return this._config;
    }
}
