<div>
  <div class="trigger" [ngClass]="{show: !show}" *ngIf="!show" (click)="toggleShow()">
    <a role="button" data-test-id="show-hide-input-show" class="toggle show dark-link">
      {{'Shared_ShowHideInput_Show' | translate }}

      <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M11.1794 11.1794C11.6248 10.734 11.875 10.1299 11.875 9.5C11.875 8.87011 11.6248 8.26602 11.1794 7.82062C10.734 7.37522 10.1299 7.125 9.5 7.125C8.87011 7.125 8.26602 7.37522 7.82062 7.82062C7.37522 8.26602 7.125 8.87011 7.125 9.5C7.125 10.1299 7.37522 10.734 7.82062 11.1794C8.26602 11.6248 8.87011 11.875 9.5 11.875C10.1299 11.875 10.734 11.6248 11.1794 11.1794Z"
          stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
        <path
          d="M1.9458 9.5C2.95438 6.28821 5.95559 3.95834 9.49988 3.95834C13.045 3.95834 16.0454 6.28821 17.054 9.5C16.0454 12.7118 13.045 15.0417 9.49988 15.0417C5.95559 15.0417 2.95438 12.7118 1.9458 9.5Z"
          stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
      </svg>
    </a>

  </div>
  <div class="trigger" [ngClass]="{hide: show}" *ngIf="show" (click)="toggleShow()">
    <a role="button" data-test-id="show-hide-input-hide" class="hide dark-link">
      {{'Shared_ShowHideInput_Hide' | translate }}

      <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M10.9846 14.9031C10.4952 14.9957 9.99823 15.042 9.50019 15.0417C5.9551 15.0417 2.95469 12.7118 1.94531 9.5C2.21707 8.63558 2.63564 7.82441 3.18269 7.10204M7.82027 7.82087C8.26571 7.37544 8.86985 7.12519 9.49979 7.12519C10.1297 7.12519 10.7339 7.37544 11.1793 7.82087C11.6248 8.26631 11.875 8.87045 11.875 9.5004C11.875 10.1303 11.6248 10.7345 11.1793 11.1799M7.82027 7.82087L11.1793 11.1799M7.82027 7.82087L11.1785 11.1783M11.1793 11.1799L13.7847 13.7845M7.82186 7.82167L5.21727 5.21708M5.21727 5.21708L2.37519 2.375M5.21727 5.21708C6.49372 4.39341 7.98106 3.95628 9.50019 3.95833C13.0453 3.95833 16.0457 6.28821 17.0551 9.5C16.4978 11.2658 15.3406 12.7811 13.7839 13.7837M5.21727 5.21708L13.7839 13.7837M13.7839 13.7837L16.6252 16.625"
          stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
      </svg>
    </a>
  </div>
  <ng-content></ng-content>
</div>