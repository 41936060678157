import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'currencySign'
})
export class DataCurrencyPipePipe implements PipeTransform {

    currencyDict = { EUR: '€', SEK: 'kr', DKK: 'kr', CHF: 'Fr.', GBP: '£', CZK: 'Kč' };

    transform(key: string): string {
        return this.currencyDict[key];
    }
}
