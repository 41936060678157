import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AccountResetPassword } from '../_models/account-reset-password';
import { Observable, throwError } from 'rxjs';
import { BaseHttpService } from '@shared/services/base-http-service.service';
import { ConfigService } from '@shared/services/config.service';
import { AccountForgotPassword } from '../../login/_models/account-forgot-password';
import { ResponseError, BaseResponse, SessionKeys } from '@shared/models';
import { map, catchError } from 'rxjs/operators';
import { DataService } from '@shared/services';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class AccountResetPasswordService extends BaseHttpService {

    constructor(
        httpClient: HttpClient,
        configService: ConfigService,
        translateService: TranslateService,
        private dataService: DataService) {
        super(httpClient, configService, translateService);
    }

    resetPassword(request: AccountResetPassword): Observable<AccountResetPassword | ResponseError> {
        return this.postBaseResponse<AccountResetPassword>('/accounts/resetPassword', request)
            .pipe(
                map(res => res.result),
                catchError(err => this.handlerError(err))
            );
    }

    resetPasswordToken(request: AccountResetPassword): Observable<AccountResetPassword | ResponseError> {
        return this.getBaseResponse<AccountResetPassword>('/accounts/resetPassword/' + request.token).pipe
            (map(res => res.result),
                catchError(err => this.handlerError(err)));
    }

    forgotPassword(request: AccountForgotPassword): Observable<AccountForgotPassword | ResponseError> {
        return this.post<AccountForgotPassword>('/accounts/forgotPassword', request);
    }

    resendResetPassword(request: AccountForgotPassword): Observable<AccountForgotPassword | ResponseError> {
        return this.post<AccountForgotPassword>('/accounts/sendResetPassword?', request);
    }

    private handlerError(res: BaseResponse<AccountResetPassword>): Observable<ResponseError> {
        const err = res.errors;
        const result = res.result;
        switch (err.code) {
            case '10.10':
                err.redirectLink = '../resetLinkExpired';
                if (result !== undefined) {
                    this.setSessionStorage(SessionKeys.email, result.email);
                }
                break;
            default:
                err.redirectLink = '/';
                break;
        }

        const baseError = res.errors;
        return throwError(baseError);
    }
    private setSessionStorage(key: string, value: any) {
        if (key !== undefined && key !== '') {
            this.dataService.setSession(key, value);
        }
    }
}
