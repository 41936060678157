import { Component, OnInit, Input } from '@angular/core';
import { WalletItem } from '../_models/wallet-item';
import { VehicleService } from '../_services/vehicle.service';
import { ResponseError } from '@shared/models';
import { AlertService } from '@shared/services/index';
import { Logger } from '@shared/logging/Logger';
import { AuthenticationError } from '@shared/models/errors/authentication.error';
import { NotFoundError } from '@shared/models/errors/not-found.error';
import { UnprocessableEntityError } from '@shared/models/errors/unprocessable-entity.error';
import { BadRequestError } from '@shared/models/errors/bad-request.error';
import { TranslateService } from '@ngx-translate/core';
import { DatesUtilityService } from '@shared/services/dates-utility.service';

@Component({
  selector: 'app-vehicle-plans',
  templateUrl: './vehicle-plans.component.html',
  styleUrls: ['./vehicle-plans.component.scss']
})
export class VehiclePlansComponent implements OnInit {

  @Input() public vin: string;
  public loading: boolean;

  public activePlans: WalletItem[];
  public pendingPlans: WalletItem[];

  constructor(
    private vehicleService: VehicleService,
    private alertService: AlertService,
    private translationsService: TranslateService,
    private dateUtilityService: DatesUtilityService,
    private logger: Logger) { }

  ngOnInit() {
    this.loading = true;
    this.getPlans();
  }

  getPlans() {
    this.vehicleService.getVehicleWallet(this.vin).subscribe(
      (data: WalletItem[]) => {
        const sortedData = [...data].sort(this.sortByAddedDate);

        this.activePlans = sortedData
          .filter(item => item.isActive)
          .map(item => ({
            ...item,
            validityDateString: this.dateUtilityService.getLocalDateString(item.validityDate)
          }));

        this.pendingPlans = sortedData
          .filter(item => !item.isActive)
          .map(item => ({
            ...item,
            validityDateString: this.dateUtilityService.getLocalDateString(item.validityDate)
          }));
        this.loading = false;
      },
      (err: ResponseError) => {
        this.handleError(err);
        this.loading = false;
      },
      () => { this.loading = false; }
    );
  }


  sortByAddedDate(a: WalletItem, b: WalletItem) {
    let firstDate = new Date(a.addedDate).getTime();
    let secondDate = new Date(b.addedDate).getTime();
    return firstDate > secondDate ? 1 : -1;
  }

  private handleError(err: ResponseError) {
    if (err instanceof AuthenticationError) {
      return;
    }
    switch (err.constructor) {
      case NotFoundError:
      case UnprocessableEntityError:
      case BadRequestError:
        // swallow that and show no plans
        break;
      default:
        this.alertService.error(this.translationsService.instant('Vehicle_Plans_Failed'), 0, true, false);
        break;
    }
    this.loading = false;
    this.logger.error(err);
  }
}
