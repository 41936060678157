import { Component } from "@angular/core";
import { VerifyVehicle } from "@app/vehicles/_models/verify-vehicle";
import { VehicleService } from "@app/vehicles/_services";
import { AlertType, ResponseError, UserContext } from "@shared/models";
import { AlertService, AuthenticationService, LanguageService, RedirectContextService, UserContextService } from "@shared/services";
import { NgxSpinnerService } from "ngx-spinner";
import { ConfirmAccountDetails } from "../_models/confirm-account-details";
import { AccountRegistrationService } from "@app/registration/_services/account-registration.service";
import { Logger } from "@shared/logging/Logger";
import { TranslateService } from "@ngx-translate/core";
import { SSOAccountWithDeviceRequest } from "@app/registration/_models/sso-account-with-device-request";
import { SSOService } from "../_services/sso.service";
import { LoginAlert } from "@shared/models/login-alert";
import { SSOToken } from "../_models/sso-token";
import { Location } from '@angular/common';
import { AddConfirmVehicleRequest } from "@app/vehicles/_models/add-confirm-vehicle-request";

@Component({
    selector: 'app-sso-confirm-details',
    templateUrl: './sso-confirm-details.component.html',
    styleUrls: ['./sso-confirm-details.component.scss']
  })
  export class SSOConfirmDetailsComponent {
  verifiedVehicle: VerifyVehicle = null;
  
  cachedVin: string;
  isUserRegisterd: boolean;

  constructor(private redirectContextService: RedirectContextService,
    private authService: AuthenticationService,
    private languageService: LanguageService,
    private vehicleService: VehicleService,
    private spinnerService: NgxSpinnerService,
    private alertService: AlertService,
    private accountRegistrationService: AccountRegistrationService,
    private logger: Logger,
    private translationsService: TranslateService,
    private ssoService: SSOService,
    private userContextService: UserContextService,
    private location: Location) {

    const params = this.location.getState();
    if (!params['isUserTokenValidated'] || params['isUserTokenValidated'] === false) {
      //The user token was not validated so send back to login, this to avoid people type the URL on the browser.
      this.languageService.localizedNavigate(['/login']);
    }

    this.isUserRegisterd = this.authService.appAuthUser.isAuthenticated;

    if (this.redirectContextService.context) {
      const vehicle = this.redirectContextService.mapRedirectToVehicle();
      if (vehicle && vehicle.vin) {
        this.cachedVin = vehicle.vin;
      }
    }
  }

  verifiedVehicleCallback(verifiedVehicle: VerifyVehicle) {
    this.verifiedVehicle = verifiedVehicle;

    if(this.isUserRegisterd) {
      this.addVehicle(this.verifiedVehicle);
    }
  }

  addVehicle(response: VerifyVehicle) {
    this.alertService.clear();
    this.spinnerService.show();

    let confirmAddVehicle = new AddConfirmVehicleRequest();
    confirmAddVehicle.vin = response.vehicle.vin;
    if(response.teaser) {
      confirmAddVehicle.teaserPackageSku = response.teaser.packageSku;
    }

    this.vehicleService.confirmAddVehicle(confirmAddVehicle).subscribe({
      next: () => {
        this.spinnerService.hide();
        this.GoToDashboard();
      },
      error: (err: ResponseError) => {
        this.spinnerService.hide();
        this.logger.error(err);
        this.alertService.error(err.message, 0, true, true);
        this.GoToDashboard();
      }
    });
  }

  confirmAccountCallback(details: ConfirmAccountDetails) {
    this.spinnerService.show();

    this.accountRegistrationService.registerSSOUserAccountWithDevice(this.prepareAccountRegistrationData(details))
    .subscribe({
      next:() => {
        this.redirectContextService.resetContext();

        //Exchange token for cubic one
        this.ssoService.authorize()
        .subscribe({
          next: (loginResponse: SSOToken) => {
            this.spinnerService.hide();
            this.setupUserData(loginResponse);
            this.GoToDashboard();
          },
          error: (err: ResponseError) => {
            this.spinnerService.hide();
            const apiAlert = new LoginAlert(AlertType.Error, err.message);
            this.languageService.localizedNavigate(['/login'], { state: { alert: apiAlert } });
          }
        });
      },
      error: (err: ResponseError) => {
        this.spinnerService.hide();
        this.handleAccountRegistrationErrors(err);
      }
    });
  }

  private setupUserData(accountLogin: SSOToken) {
    const isSSOUser = true;
    accountLogin.loginDetails.token = accountLogin.token;
    this.authService.setAuthenticatedUser(accountLogin.loginDetails, isSSOUser);

    const userContext = new UserContext();
    userContext.firstName = accountLogin.loginDetails.firstName;
    userContext.lastName = accountLogin.loginDetails.lastName;
    userContext.userId = accountLogin.loginDetails.userId;
    userContext.homeCountryCode = accountLogin.loginDetails.homeCountry ? accountLogin.loginDetails.homeCountry.countryCode : null;
    userContext.isSSOUser = isSSOUser;
    this.userContextService.setUserContext(userContext);
  }

  private GoToDashboard() {
    this.languageService.localizedNavigate(['/dashboard'], { queryParams: { forceDataRefresh: true } });
  }

  private prepareAccountRegistrationData(details: ConfirmAccountDetails) {
    var request = new SSOAccountWithDeviceRequest();
    request.state = details.identification.address.state;
    request.streetAddress1 = details.identification.address.streetAddress1;
    request.streetAddress2 = details.identification.address.streetAddress2;
    request.city = details.identification.address.city;
    request.country = details.identification.address.country;
    request.countryCode = details.identification.address.countryCode;
    request.birthDate = details.identification.dateOfBirth;
    request.postCode = details.identification.address.postCode;

    request.email = details.account.email;
    request.firstName = details.account.firstName;
    request.lastName = details.account.lastName;
    request.acceptedTermsAndConditions = details.account.acceptedTermsAndConditions;
    request.promoEmailSubscribe = details.account.promoEmailSubscribe;

    request.DeviceReferences = new Array<string>();
    request.DeviceReferences.push(this.verifiedVehicle.vehicle.vin);

    if(this.verifiedVehicle.teaser) {
      request.teaserPackageSku = this.verifiedVehicle.teaser.packageSku;
    }

    return request;
  }

  private handleAccountRegistrationErrors(error: ResponseError) {
    this.logger.error(error);
    switch (error.code) {
        case '10.30':
        case '10.1':
        case '10.40':
        case '60.1':
        case '10.50':
        case '10.70':
        case '10.80':
          this.alertService.error(error.message, 0, true, true);
          break;
        case '10.60':
          const alertVehicleNotFound = this.translationsService.instant('Vehicle_Add_Not_Found');
          this.alertService.error(alertVehicleNotFound, 0, true, true);
        default:
          this.alertService.error(error.message, 0, true, false);
          break;
    }
  }
}