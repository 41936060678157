import { Component, OnInit, ViewChild, ElementRef, } from '@angular/core';
import { SessionKeys, ResponseError, AppAuthUser } from '@shared/models';
import { AlertService, LanguageService } from '@shared/services/index';
import { IdentificationValidationService } from '../../_services/identification-validation.service';
import { SubmitVideoValidation } from '../../_models/submit-video-validation';
import { AuthenticationService } from '@shared/services/authentication.service';
import { Logger } from '@shared/logging/Logger';
import { ThemeComponent } from '@shared/components/theme.component';
import { ThemeService } from '@shared/services/theme-service.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { TranslateService } from '@ngx-translate/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

@Component({
  selector: 'app-submit-identity',
  templateUrl: './submit-identity.component.html',
  styleUrls: ['./submit-identity.component.scss']
})
export class SubmitIdentityComponent extends ThemeComponent implements OnInit {

  identityId: string;
  redirectUrl: string;
  sanitizedRedirectUrl: SafeResourceUrl;
  sessionKeys = new SessionKeys();
  idNowVisible: boolean = false;
  public appAuthUser: AppAuthUser = null;
  @ViewChild('idnowIframe', { static: false }) idnowIframe: ElementRef;

  constructor(
    _themeService: ThemeService,
    private alertService: AlertService,
    private spinnerService: NgxSpinnerService,
    private languageService: LanguageService,
    private identificationValidationService: IdentificationValidationService,
    private authenticationService: AuthenticationService,
    private translationsService: TranslateService,
    private logger: Logger,
    private sanitizer: DomSanitizer

  ) {
    super(_themeService);
  }

  ngOnInit() {
    this.appAuthUser = this.authenticationService.appAuthUser;
    this.identityId = this.appAuthUser.identityId;
    if (this.identityId === null) {
      this.alertService.error(this.translationsService.instant('Submit_Video_Invalid_Requirements'), 0, true, true);
      this.languageService.localizedNavigate(['/dashboard']);
    }
  }

  getValidationURL() {
    this.spinnerService.show();
    this.identificationValidationService.submitVideoValidation(this.prepareSubmitValidation())
      .subscribe(
        (data: SubmitVideoValidation) => {
          this.spinnerService.hide();
          this.redirectUrl = data.redirectUrl;
          this.sanitizedRedirectUrl = this.sanitizer.bypassSecurityTrustResourceUrl(data.redirectUrl);
          this.idNowVisible = true;
        },
        (err: ResponseError) => {
          this.spinnerService.hide();
          this.alertService.error(err.message, 0, true, true);
          this.logger.error(err);
          this.languageService.localizedNavigate(['/dashboard']);
        },
        () => { this.spinnerService.hide(); }
      );
  }

  requestFullscreen() {
    const iframeElement = this.idnowIframe.nativeElement;
    if (iframeElement.requestFullscreen) {
      iframeElement.requestFullscreen();
    } else if (iframeElement.mozRequestFullScreen) {
      iframeElement.mozRequestFullScreen();
    } else if (iframeElement.webkitRequestFullscreen) {
      iframeElement.webkitRequestFullscreen();
    } else if (iframeElement.msRequestFullscreen) {
      iframeElement.msRequestFullscreen();
    }
  }

  beginValidationClick() {
    this.getValidationURL();
  }

  prepareSubmitValidation(): SubmitVideoValidation {
    const submitValidationData = new SubmitVideoValidation();
    submitValidationData.identityId = this.identityId;
    return submitValidationData;
  }

  onIFrameLoad() {
    var pathArray = this.redirectUrl.split('/');
    var protocol = pathArray[0];
    var host = pathArray[2];
    var eventUrl = protocol + '//' + host;

    window.addEventListener('message',
      (event) => {
        if (event.origin == '' || event.origin !== eventUrl)
          return;
        else
          this.languageService.localizedNavigate(['/dashboard']);
      }, false)
  }
}
