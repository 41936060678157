import { IdentificationValidationStatus } from '../../user/_models/identification-validation-status';
import { ReasonOfRejection } from '../../user/_models/reason-of-rejection';

export class IdentificationValidation {
    public vin: string;
    public userId: number;
    public isoCode: string;
    public isIdentificationProofNeeded: boolean;
    public identificationValidationStatus: IdentificationValidationStatus;
    public dataFields: string[];
    public reasonOfRejection: ReasonOfRejection | null;
    public myAccountLink: string;
    public faqLink: string;
    public purchaseLink: string;
    public reUploadLink: string;
    public videoRedirectUrl: string;
    public isVideoValidation: boolean;
}
