import { Vehicle } from '../../app/vehicles/_models';
import { ProductCheckoutStatus } from '../../app/product/_models';

export class UserContext {
    userName = '';
    firstName = '';
    lastName = '';
    userId = '';
    homeCountryCode = '';
    isSSOUser = false;
    productCheckoutStatus: ProductCheckoutStatus;
    vehicles: Vehicle[];

    constructor() {
        this.vehicles = new Array<Vehicle>();
    }
}
