import { Component } from '@angular/core';
import { ThemeComponent } from '@shared/components/theme.component';
import { ThemeService } from '@shared/services/theme-service.service';

@Component({
  selector: 'app-home-hotspot',
  templateUrl: './hotspot.component.html',
  styleUrls: ['./hotspot.component.scss']
})
export class HotspotComponent extends ThemeComponent {

  constructor(_themeService: ThemeService) {
    super(_themeService);
  }

}
