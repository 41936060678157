import { Injectable } from '@angular/core';
import { SessionKeys } from '../models';
import { DataService } from '../services/data.service';
import { ProductCheckoutHppContext } from '@shared/models/product-checkout-hpp-context';

@Injectable()
export class ProductCheckoutHppContextService {

    public context = new ProductCheckoutHppContext();

    constructor(private dataService: DataService) {
        this.getProductCheckoutContext();
    }

    public resetProductCheckoutContext() {
        this.context = new ProductCheckoutHppContext();
        this.dataService.removeSession(SessionKeys.productCheckoutHppContext);
    }

    public setProductCheckoutContext(context: ProductCheckoutHppContext) {
        this.context = context;
        this.dataService.setSession(SessionKeys.productCheckoutHppContext, JSON.stringify(this.context));
    }

    public update() {
        this.dataService.setSession(SessionKeys.productCheckoutHppContext, JSON.stringify(this.context));
    }

    private getProductCheckoutContext() {
        const data = this.dataService.getSession(SessionKeys.productCheckoutHppContext);
        if (data) {
            this.context = JSON.parse(data) as ProductCheckoutHppContext;
            return;
        }
    }

}
