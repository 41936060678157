import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BaseHttpService, IdentificationValidationContextService } from '@shared/services';
import { HttpClient } from '@angular/common/http';
import { ResponseError } from '@shared/models';
import { ConfigService } from '@shared/services/config.service';
import { IdentificationValidation } from '../_models/identification-validation';
import { SubmitVideoValidation } from '../../user/_models/submit-video-validation';
import { LanguageService } from '@shared/services/language.service';
import { IdentificationValidationStatus } from '../../user/_models/identification-validation-status';
import { TranslateService } from '@ngx-translate/core';
@Injectable()
export class IdentificationValidationService extends BaseHttpService {

  private myAccountLink: string;
  private faqLink: string;
  private purchaseLink: string;
  private reUploadLink: string;
  private language: string;

  constructor(httpClient: HttpClient, configService: ConfigService,
    translateService: TranslateService,
    private languageService: LanguageService, private identificationValidationContextService: IdentificationValidationContextService) {
    super(httpClient, configService, translateService);
    this.language = this.languageService.getActiveLanguage().isoCode || 'en';
    this.myAccountLink = this.getMyAccountLink();
    this.faqLink = this.getFaqLink();
    this.purchaseLink = this.getPurchaseLink();
    this.reUploadLink = this.getReUploadLink();
  }

  /* POST */
  getIdentificationValidation(request: IdentificationValidation): Observable<IdentificationValidation | ResponseError> {
    request.myAccountLink = this.myAccountLink;
    request.faqLink = this.faqLink;
    request.purchaseLink = this.purchaseLink;
    request.reUploadLink = this.reUploadLink;
    return this.post<IdentificationValidation>('/identificationValidation', request);
  }

  checkForValidationUpdate(request: IdentificationValidation): Observable<IdentificationValidation | ResponseError> {
    request.myAccountLink = this.myAccountLink;
    request.faqLink = this.faqLink;
    request.purchaseLink = this.purchaseLink;
    request.reUploadLink = this.reUploadLink;
    return this.post<IdentificationValidation>('/identificationValidation/checkForValidationUpdate', request);
  }

  submitVideoValidation(request: SubmitVideoValidation): Observable<SubmitVideoValidation | ResponseError> {
    return this.post<SubmitVideoValidation>('/identificationValidation/submitVideo', request);
  }

  checkCountryRequiresGermanIdentificationProof(isoCode: string): boolean {
    if (isoCode === 'DE')
      return true;
    else
      return false;
  }

  private getMyAccountLink() {
    return this._configService.config.myAccountLink + '/' + this.language;
  }

  private getFaqLink() {
    return this._configService.config.faqLink + '/' + this.language + '/faq';
  }

  private getPurchaseLink() {
    return this._configService.config.purchaseLink + '/' + this.language;
  }

  private getReUploadLink() {
    return this._configService.config.reUploadLink + '/' + this.language;
  }

  public handleIdentificationStatus(data: IdentificationValidation, isFirstTime: boolean, vin: string): IdentificationValidation {
    switch (data.identificationValidationStatus) {
      case IdentificationValidationStatus.PendingUpload:
        if (isFirstTime) {
          this.updateIsIdentificationSubmitted(true);
        }
        this.updateIsValidIdentification(false);
        break;
      case IdentificationValidationStatus.PendingApproval:
        this.updateIsValidIdentification(false);
        break;
      case IdentificationValidationStatus.ValidationRejected:
        this.updateIsValidIdentification(false);
        break;
      case IdentificationValidationStatus.ValidationApproved:
        this.updateIsIdentificationSubmitted(null);
        this.updateIsValidIdentification(true);
        break;
      case IdentificationValidationStatus.IdentityCheckBlocked:
        this.updateIsValidIdentification(false);
        this.updateIdentificationValidationContext(data.identificationValidationStatus, null, vin);
        break;
      case IdentificationValidationStatus.RejectedIdentificationProofUserData:
        this.updateIsValidIdentification(false);
        this.updateIdentificationValidationContext(data.identificationValidationStatus, data.dataFields, vin);
        break;
      case IdentificationValidationStatus.RejectedIdentificationProofDocument:
        this.updateIsValidIdentification(false);
        this.updateIdentificationValidationContext(data.identificationValidationStatus, data.dataFields, vin);
        break;
      default:
        this.updateIsValidIdentification(false);
        this.updateIdentificationValidationContext(data.identificationValidationStatus, null, vin);
        break;
    }
    return data;
  }

  private updateIsIdentificationSubmitted(value: any) {
    this.identificationValidationContextService.context.isIdentificationSubmitted = value;
    this.identificationValidationContextService.update();
  }

  public updateIsValidIdentification(value: boolean) {
    this.identificationValidationContextService.context.isValidIdentification = value;
    this.identificationValidationContextService.update();
  }

  private updateIdentificationValidationContext(status: IdentificationValidationStatus, dataFields: string[] = null, vin: string) {
    this.identificationValidationContextService.context.dataFields = dataFields;
    this.identificationValidationContextService.context.status = status;
    this.identificationValidationContextService.context.vin = vin;
    this.identificationValidationContextService.update();
  }
}
