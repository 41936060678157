import { Component } from '@angular/core';
import { IdentificationValidationContextService } from '@shared/services';



@Component({
    selector: 'app-sidebar',
    templateUrl: './sidebar.component.html',
    styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent {
    constructor(
        private identificationValidationContextService: IdentificationValidationContextService
    ) {
    }

    getIdentificationStatus(): boolean {
        if (this.identificationValidationContextService.context)
            return this.identificationValidationContextService.context.isValidIdentification;
        return false;
    }
}


