<div class="vehicle-card">
  <h4 data-test-id="vehicle-details-card-vehicleName" class="vehicle-name">{{vehicle.personalisedName}}</h4>
  <img class="img-fluid" data-test-id="vehicle-details-card-vehiclePhoto" [src]="vehicle.vehiclePhotoImage"
    *ngIf='showVehicleImage' />
  <div data-test-id="vehicle-details-card-vehicle-plans" class="card-delimiter" *ngIf="!vehicle.isSecondaryUser">
    <app-vehicle-plans [vin]="vehicle.vin"></app-vehicle-plans>
  </div>
  <div class="card-buttons">
    <div class="row justify-content-between no-gutters">
      <a [attr.data-test-id]="'vehicle-details-card-btn-details-' + vehicle.vin"
        class="btn btn-outline-secondary view-details-btn"
        [routerLink]="['/dashboard/vehicle' | localize, vehicle.vin]">{{'Vehicle_Details_Card_View_Details' | translate
        }}</a>
      <ngx-loading [show]="loading"></ngx-loading>
      <a *ngIf="loading === false && !vehicle.isSecondaryUser" data-test-id="vehicle-details-card-btn-buyPlan"
        class="btn btn-primary buy-plan-btn" [ngClass]="{disabled: !enableBuyPlan}"
        (click)="redirectToBuyPlan()">{{'Buy_Plan_Button' | translate }}</a>
    </div>
  </div>
</div>