import { Component, OnInit } from '@angular/core';
import { ProductCheckoutHppContextService } from '@shared/services';
import { ProductCheckoutHppContext } from '@shared/models/product-checkout-hpp-context';

@Component({
  selector: 'app-card-page-header',
  templateUrl: './card-page-header.component.html',
  styleUrls: ['./card-page-header.component.scss']
})
export class CardPageHeaderComponent implements OnInit {

  context: ProductCheckoutHppContext;

  constructor(
    private contextService: ProductCheckoutHppContextService
  ) { }

  ngOnInit() {
    this.context = this.contextService.context;
  }

}
