import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { Vehicle, VehicleInfomationRequest } from '../../vehicles/_models';
import { AlertService, ConfigService, ThemeService } from '@shared/services/index';
import { SessionKeys, ResponseError, AppAuthUser } from '@shared/models';
import { VehicleService } from '../../vehicles/_services/vehicle.service';
import { ProductService } from '../_services/product.service';
import { SelectItem } from '../_models/selectedItem';
import { Package } from '../../product/_models/package';
import { AuthenticationService } from '@shared/services/authentication.service';
import { ActivatedRoute, Params } from '@angular/router';
import { Logger } from '@shared/logging/Logger';
import { UserContextService } from '@shared/services/user-context.service';
import { ProductCheckoutHppContextService } from '@shared/services/product-checkout-context-hpp.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { SelectDropdownItem, SelectDropdownType } from '@shared/components/select-dropdown/models/select-dropdown.model';
import { LocalizeRouterService } from '@gilsdav/ngx-translate-router';
import { Subscription } from 'rxjs';
import { PriceFormatLocalisationService } from '@shared/services/PriceFormatLocalisationService';

@Component({
  selector: 'app-product-catalog',
  templateUrl: './product-catalog.component.html',
  styleUrls: ['./product-catalog.component.scss']
})

export class ProductCatalogComponent implements OnInit, OnDestroy {

  @Input() vin: string;

  vehicles: Vehicle[] = [];
  selectdVehicle: Vehicle;
  identityId: string;
  sessionKeys = new SessionKeys();
  isActive: boolean;
  package: Package;
  isErrOccurred = false;
  enableBuyPlan = false;
  isRequestReady = true;
  public appAuthUser: AppAuthUser = null;
  private vinFromDashboard: string;
  public title = 'Title_Shop';

  showRegisterPendingLiableUser = false;
  alertAccountReady = 'Vehicle_Details_Account_Ready';

  // 'select dropdown' list
  simpleItems: SelectDropdownItem[];
  selectedType = SelectDropdownType.Image;
  activeItem: SelectDropdownItem[];
  currentSelectedItem: SelectItem;
  private localizeSubscription: Subscription;

  constructor(
    private activatedRoute: ActivatedRoute,
    private vehicleService: VehicleService,
    private productService: ProductService,
    private alertService: AlertService,
    private userContextService: UserContextService,
    private spinnerService: NgxSpinnerService,
    private authenticationService: AuthenticationService,
    private productCheckoutHppContextService: ProductCheckoutHppContextService,
    private configService: ConfigService,
    private themeService: ThemeService,
    private logger: Logger,
    private localizeService: LocalizeRouterService,
    private priceLocalisation: PriceFormatLocalisationService
  ) { }


  ngOnInit() {
    this.productCheckoutHppContextService.resetProductCheckoutContext();
    this.appAuthUser = this.authenticationService.appAuthUser;
    this.identityId = this.appAuthUser.identityId;
    this.package = new Package();
    this.package.productsDetails = [];
    this.activatedRoute.params.subscribe((params: Params) => {
      if (params['vin'] !== null && params['vin'] !== undefined) {
        this.vinFromDashboard = params['vin'];
      }
    });
    this.insertVehiclesIntoSelectedDropDown();

    this.localizeSubscription = this.localizeService.routerEvents.subscribe((language: string) => {
      if (this.currentSelectedItem) {
        this.spinnerService.show();
        this.getProducts(this.currentSelectedItem);
      }
    });
  }

  insertVehiclesIntoSelectedDropDown() {
    this.spinnerService.show();
    const request = new VehicleInfomationRequest;
    request.identityId = this.identityId;
    this.vehicleService.getVehicles(request)
      .subscribe(
        (data: Vehicle[]) => {
          this.vehicles = data;
          this.simpleItems = new Array<SelectDropdownItem>();
          this.addItemsToSelectedDropDownList(this.vehicles, this.simpleItems);
          this.spinnerService.hide();
        },
        (err: ResponseError) => {
          this.spinnerService.hide();
          this.handleError(err);
        },
        () => { this.spinnerService.hide(); }
      );
  }

  onSelect(vehicle: SelectItem) {
    this.selectdVehicle = this.vehicles.find(x => x.vin === vehicle.value);
    if (!this.selectdVehicle.isSecondaryUser) {
      this.spinnerService.show();
      this.getProducts(vehicle);
    }
  }

  private getProducts(vehicle: SelectItem) {
    const request = new VehicleInfomationRequest;
    request.identityId = this.identityId;
    this.vin = vehicle.value;

    this.productService.getProducts(this.vin, request)
      .subscribe(
        (data: Package) => {
          this.package = data;
          this.package.productsDetails.forEach(product => {
            let cultureCode = this.priceLocalisation.getCultureCode(this.userContextService.userContext.homeCountryCode);
            product.packageGrossPrice = this.priceLocalisation.getLocalisedPrice(cultureCode, product.packageGrossPrice)
          })
          this.isErrOccurred = false;
          this.forceRefreshVehicleUserData(this.vin, this.identityId);
        },
        (err: ResponseError) => {
          this.spinnerService.hide();
          this.package = new Package();
          this.package.productsDetails = [];
          // information about occurred error is implemented in html file
          this.isErrOccurred = true;
          this.logger.error(err);
        },
        () => { this.spinnerService.hide(); }
      );
  }

  private addItemsToSelectedDropDownList(vehicles: Vehicle[], simpleItems: SelectDropdownItem[]) {
    vehicles.forEach((vehicle, index) => {
      if (this.vinFromDashboard !== null && this.vinFromDashboard !== undefined) {
        this.isActive = vehicle.vin === this.vinFromDashboard ? true : false;
      } else {
        index === 0 ? this.isActive = true : this.isActive = false;
      }

      const item = new SelectDropdownItem(
        vehicle.vin,
        vehicle.personalisedName,
        this.isActive,
        this.getVehicleDropDownImage(vehicle.vehiclePhotoImage)
      );
      simpleItems.push(item);
    });
  }
  private getVehicleDropDownImage(vehiclePhoto: string) {
    const vehicleIcon = '/icons/car-icon.png';
    if (this.configService.config.showVehicleImage) {
      return vehiclePhoto;
    }
    return this.themeService.assetsLocation + vehicleIcon;
  }

  private forceRefreshVehicleUserData(vin: string, identityId: string) {
    this.vehicleService.forceRefreshVehicleUserStatus(vin).subscribe(
      refresh => {
        this.vehicleService.userCanBuyPlan(vin).subscribe(
          res => {
            this.enableBuyPlan = res;
            this.isRequestReady = false;
          }
        );
      }
    );
  }

  private handleError(err: ResponseError) {
    this.logger.error(err);
    switch (err.key) {
      case 'resourceNotFound':
        break;
      default:
        this.alertService.error(err.message, 0, true, false);
    }
  }

  ngOnDestroy(): void {
    this.localizeSubscription.unsubscribe();
  }
}
