import { Injectable } from '@angular/core';
import { RedirectContext } from '../models/redirect-context';
import { SessionKeys } from '../models';
import { DataService } from '../services/data.service';
import { AccountRegistration } from '../../app/registration/_models/account-registration';
import { AccountIdentificationDetails } from '../../app/account/_models';
import { AccountLogin } from '../../app/login/_models/account-login';
import { Vehicle } from '../../app/vehicles/_models';

@Injectable()
export class RedirectContextService {

    public context: RedirectContext = new RedirectContext();

    constructor(
        private dataService: DataService) {
        this.getContext();
    }

    public resetContext() {
        this.context.CountryISOId = null;
        this.context.LanguageISOId = null;
        this.context.VIN = null;
        this.context.VehicleName = null;
        this.context.Email = null;
        this.context.Salutation = null;
        this.context.FirstName = null;
        this.context.LastName = null;
        this.context.Country = null;
        this.context.BirthDate = null;
        this.context.CareOf = null;
        this.context.Street = null;
        this.context.HouseNumber = null;
        this.context.City = null;
        this.context.Zip = null;
        this.context.PhoneMobile = null;
        this.context.RedirectType = -1;
        this.dataService.removeStorageItem(SessionKeys.redirectContext);
    }

    public setContext(redirect: RedirectContext) {
        if (this.redirectContextHasValues(redirect)) {
            this.context = redirect;
            this.setSessionValues();
        }
    }

    public update() {
        this.dataService.setStorageItem(SessionKeys.redirectContext, JSON.stringify(this.context));
    }

    // MAP REDIRECT METHODS

    public mapRedirectToRegistration(): AccountRegistration {
        const registrationData = new AccountRegistration();
        registrationData.email = this.context.Email;
        registrationData.firstName = this.context.FirstName;
        registrationData.lastName = this.context.LastName;
        return registrationData;
    }

    public mapRedirectToLogin(): AccountLogin {
        const loginData = new AccountLogin();
        loginData.email = this.context.Email;
        return loginData;
    }

    public mapRedirectToVehicle(): Vehicle {
        const vehicleData = new Vehicle();
        vehicleData.personalisedName = this.context.VehicleName;
        vehicleData.vin = this.context.VIN;
        return vehicleData;
    }

    public mapRedirectToAccountIdentification(): AccountIdentificationDetails {
        const accountIdentificationData = new AccountIdentificationDetails();
        accountIdentificationData.address.city = this.context.City;
        accountIdentificationData.address.streetAddress1 = ((this.context.HouseNumber || "") + " " + (this.context.Street || "")).trim();
        accountIdentificationData.address.postCode = this.context.Zip;

        // Country
        accountIdentificationData.homeCountry.countryCode = this.context.CountryISOId;

        accountIdentificationData.dateOfBirth = this.context.BirthDate;

        return accountIdentificationData;
    }

    // REMOVE DATA METHODS

    public removeRegistrationFormData() {
        this.context.FirstName = null;
        this.context.LastName = null;
        this.context.Salutation = null;
        this.update();
    }

    public removeLoginFormData() {
        this.context.Email = null;
        this.update();
    }

    public removeVehicleFormData() {
        this.context.VehicleName = null;
        this.context.VIN = null;
        this.update();
    }

    public removeAddressFormData() {
        this.context.BirthDate = null;
        this.context.City = null;
        this.context.Country = null;
        this.context.HouseNumber = null;
        this.context.PhoneMobile = null;
        this.context.Street = null;
        this.context.Zip = null;
        this.update();
    }

    //   PRIVATE METHODS

    private setSessionValues() {
        this.dataService.setStorageItem(SessionKeys.redirectContext, JSON.stringify(this.context));
    }

    private getContext() {
        const redirect = this.dataService.getStorageItem(SessionKeys.redirectContext);
        if (redirect) {
            this.context = JSON.parse(redirect) as RedirectContext;
            return;
        }
    }

    private redirectContextHasValues(redirect: RedirectContext): boolean {
        if (redirect.BirthDate === null &&
            redirect.CareOf === null &&
            redirect.City === null &&
            redirect.Country === null &&
            redirect.CountryISOId === null &&
            redirect.FirstName === null &&
            redirect.HouseNumber === null &&
            redirect.LanguageISOId === null &&
            redirect.LastName === null &&
            redirect.PhoneMobile === null &&
            redirect.Salutation === null &&
            redirect.Street === null &&
            redirect.VIN === null &&
            redirect.VehicleName === null &&
            redirect.Zip === null &&
            redirect.RedirectType === -1) {
            return false;
        }
        return true;
    }
}
