<app-page-header [title]="title" class="d-none d-md-block"></app-page-header>
<div class="product-catalog-container">
  <div *ngIf="vehicles?.length > 0">
    <div class="row">
      <div class="col-12 text-above-dropdown" data-test-id="product-catalog-text-above-dropdown">
        {{'Product_Catalog_TextAboveDropdown' | translate}}</div>
      <div class="col-12 col-md-8 col-lg-7" data-test-id="product-catalog-dropdown-vehicles">
        <app-select-dropdown class="cars-dropdown" [items]="simpleItems" [selectedType]="selectedType"
          (selectEvent)="onSelect($event)"></app-select-dropdown>
      </div>
      <div *ngIf="selectdVehicle && !selectdVehicle.isSecondaryUser" class="col-12">
        <div *ngIf="package.productsDetails?.length > 0">

          <div class="col text-under-dropdown" data-test-id="product-catalog-text-under-dropdown">
            {{'Product_Catalog_TextUnderDropdown' | translate}}</div>

          <div class="row">
            <div *ngFor="let product of package.productsDetails" class="col-12 col-md-4">
              <app-product-card [product]="product" [vin]="vin" [enableBuyPlan]="enableBuyPlan"
                [loading]="isRequestReady" [attr.data-test-id]="'product-card-planId-' + product.packageCode">
              </app-product-card>
            </div>
            <div class="col-12 plan-description" data-test-id="product-catalog-plan-description"
              [innerHTML]="'Product_Catalog_PlanDescription' | translate: {countries: package.countries ? (package.countries | joinStrings ) : ''}">
              <!-- VAT is charged based on your country of registration
              <br />
              <br />These plans work in: {{package.countries | joinStrings }} -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="vehicles?.length === 0">
    <p data-test-id="product-catalog-no-vehicle" class="no-vehicles" data-test-id="product-catalog-no-vehicles-text">
      {{'Product_Catalog_NoVehicles' | translate}}</p>
  </div>
  <div *ngIf="selectdVehicle && selectdVehicle.isSecondaryUser" class="col-12 alert alert-warning custom-error"
    role="alert" [innerHtml]="'Secondary_User_Popup_Message' | translate">
  </div>
  <div *ngIf="isErrOccurred && (!selectdVehicle || !selectdVehicle.isSecondaryUser)"
    class="col-12 alert alert-warning custom-error" role="alert" data-test-id="vehicle-details-register-pending-liable">
    <span
      [innerHTML]="'Shop_View_Complete_Unfinished_Tasks' | translate: {link: (('/dashboard/vehicle/' + selectdVehicle.vin) | localize)}">
    </span>
  </div>
</div>