import { Injectable } from '@angular/core';
import { Country } from '../models';
import { AccountCountryLegalRequirements } from '../../app/account/_models';
import { LanguageService } from '@shared/services/language.service';

@Injectable()
export class CountriesService {

    countries: Country[] = new Array<Country>();

    // Translations keys
    austria = 'Austria';
    belgium = 'Belgium';
    bulgaria = 'Bulgaria';
    croatia = 'Croatia';
    czech = 'Czech_Republic';
    denmark = 'Denmark';
    estonia = 'Estonia';
    finland = 'Finland';
    france = 'France';
    germany = 'Germany';
    hungary = 'Hungary';
    ireland = 'Ireland';
    italy = 'Italy';
    latvia = 'Latvia';
    lithuania = 'Lithuania';
    luxemburg = 'Luxemburg';
    netherlands = 'Netherlands';
    norway = 'Norway';
    poland = 'Poland';
    portugal = 'Portugal';
    slovakia = 'Slovakia';
    spain = 'Spain';
    sweden = 'Sweden';
    switzerland = 'Switzerland';
    uk = 'Uk';

    constructor(private languageService: LanguageService) {
        this.getCountries();
    }

    private populateCountries() {
        this.countries = new Array<Country>();
        this.countries.push(new Country(this.austria, 43, 'AT'));
        this.countries.push(new Country(this.belgium, 32, 'BE'));
        this.countries.push(new Country(this.bulgaria, 36, 'BG'));
        this.countries.push(new Country(this.croatia, 57, 'HR'));
        this.countries.push(new Country(this.czech, 60, 'CZ'));
        this.countries.push(new Country(this.denmark, 45, 'DK'));
        this.countries.push(new Country(this.estonia, 70, 'EE'));
        this.countries.push(new Country(this.finland, 75, 'FI'));
        this.countries.push(new Country(this.france, 33, 'FR'));
        this.countries.push(new Country(this.germany, 49, 'DE'));
        this.countries.push(new Country(this.hungary, 100, 'HU'));
        this.countries.push(new Country(this.ireland, 353, 'IE'));
        this.countries.push(new Country(this.italy, 39, 'IT'));
        this.countries.push(new Country(this.latvia, 122, 'LV'));
        this.countries.push(new Country(this.lithuania, 128, 'LT'));
        this.countries.push(new Country(this.luxemburg, 352, 'LU'));
        this.countries.push(new Country(this.netherlands, 31, 'NL'));
        this.countries.push(new Country(this.norway, 166, 'NO'));
        this.countries.push(new Country(this.poland, 177, 'PL'));
        this.countries.push(new Country(this.portugal, 178, 'PT'));
        this.countries.push(new Country(this.slovakia, 201, 'SK'));
        this.countries.push(new Country(this.spain, 34, 'ES'));
        this.countries.push(new Country(this.sweden, 46, 'SE'));
        this.countries.push(new Country(this.switzerland, 41, 'CH'));
        this.countries.push(new Country(this.uk, 44, 'GB'));
    }

    getCountries(): Country[] {
        this.countries = [];
        this.populateCountries();
        return this.countries;
    }

    translateCountries(countriesToTranslate: AccountCountryLegalRequirements[]): AccountCountryLegalRequirements[] {
        countriesToTranslate.forEach(country => {
            switch (country.isoCode) {
                case 'AT':
                    country.friendlyName = this.austria;
                    break;
                case 'BE':
                    country.friendlyName = this.belgium;
                    break;
                case 'DK':
                    country.friendlyName = this.denmark;
                    break;
                case 'FR':
                    country.friendlyName = this.france;
                    break;
                case 'DE':
                    country.friendlyName = this.germany;
                    break;
                case 'IE':
                    country.friendlyName = this.ireland;
                    break;
                case 'IT':
                    country.friendlyName = this.italy;
                    break;
                case 'LU':
                    country.friendlyName = this.luxemburg;
                    break;
                case 'NL':
                    country.friendlyName = this.netherlands;
                    break;
                case 'ES':
                    country.friendlyName = this.spain;
                    break;
                case 'SE':
                    country.friendlyName = this.sweden;
                    break;
                case 'CH':
                    country.friendlyName = this.switzerland;
                    break;
                case 'GB':
                    country.friendlyName = this.uk;
                    break;
                case 'HR':
                    country.friendlyName = this.croatia;
                    break;
                case 'CZ':
                    country.friendlyName = this.czech;
                    break;
                case 'FI':
                    country.friendlyName = this.finland;
                    break;
                case 'NO':
                    country.friendlyName = this.norway;
                    break;
                case 'PT':
                    country.friendlyName = this.portugal;
                    break;
                case 'BG':
                    country.friendlyName = this.bulgaria;
                    break;
                case 'EE':
                    country.friendlyName = this.estonia;
                    break;
                case 'LV':
                    country.friendlyName = this.latvia;
                    break;
                case 'LT':
                    country.friendlyName = this.lithuania;
                    break;
                case 'SK':
                    country.friendlyName = this.slovakia;
                    break;
                case 'HU':
                    country.friendlyName = this.hungary;
                    break;
                case 'PL':
                    country.friendlyName = this.poland;
                    break;
            }
        });
        return countriesToTranslate;
    }

    getIsoCode(code: number): string {
        if (this.countries !== null) {
            return this.countries.filter(x => x.countryCode.toString() === code.toString())[0].isoCode;
        }
        return '';
    }

    getCountryCode(iso: string): number {
        if (this.countries !== null) {
            return this.countries.filter(x => x.isoCode.toLowerCase() === iso.toLowerCase())[0].countryCode;
        }
        return 0;
    }

    filterCountryByIso(iso: string): Country {

        if (this.countries !== null) {
            return this.countries.filter(x => x.isoCode.toLowerCase() === iso.toLowerCase())[0];
        }
        return null;
    }

    getIsoCodeForNonAuthenticatedUser() {
        return this.getDefaultIsoCodeByLanguageCode(this.languageService.getActiveLanguage().isoCode);
    }

    getDefaultIsoCodeByLanguageCode(languageCode: string) {
        switch (languageCode) {
            case 'cs':
                return 'CZ';
            case 'bg':
                return 'BG';
            case 'hr':
                return 'HR';
            case 'de':
                return 'DE';
            case 'da':
                return 'DK';
            case 'fi':
                return 'FI';
            case 'it':
                return 'IT';
            case 'nl':
                return 'NL';
            case 'en':
                return 'IE';
            case 'fr':
                return 'FR';
            case 'es':
                return 'ES';
            case 'sv':
                return 'SE';
            case 'no':
                return 'NO';
            case 'pt':
                return 'PT';
            case 'bl':
                return 'BL';
            case 'ee':
                return 'EE';
            case 'lv':
                return 'LV';
            case 'lt':
                return 'LT';
            case 'sk':
                return 'SK';
            case 'hu':
                return 'HU';
            case 'pl':
                return 'PL';
            default:
                return 'IE';
        }
    }
}
