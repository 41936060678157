import { Component } from '@angular/core';
import { Plan } from '@app/home/home-plans/_models/plan';
import { PlansService } from '@app/home/home-plans/_services/plans.service';
import { Country, ResponseError } from '@shared/models';
import { AlertService } from '@shared/services';
import { CountriesService } from '@shared/services/countries.service';

@Component({
  selector: 'app-bundle-details',
  templateUrl: './Bundle-details.component.html',
  styleUrls: ['./Bundle-details.component.scss']
})
export class BundleDetailsComponent {

  private plans: Plan[] = [];
  countries: Country[];
  selectedCountry: Country;
  showBundlesPrices: boolean = false;
  countryBundles: string[] = [];
  countryBundleRates: Plan[];

  constructor(private plansService: PlansService,
    private alertService: AlertService,
    private countriesService: CountriesService) {

    this.countries = this.countriesService.getCountries() as Country[];
    this.plansService.getBundleRates().subscribe({
      next: (value: Plan[] | ResponseError) => {
        if (value instanceof Array) {
          this.plans = value;
        }
      },
      error: (err: ResponseError) => {
        this.alertService.error(err.message, 0, true, false);
      }
    });
  }

  changeCountry(country: Country) {
    this.selectedCountry = country;
    this.showBundlesPrices = false;
    this.countryBundles = [];
    if (this.selectedCountry.isoCode !== '0') {
      this.countryBundleRates = this.plans.filter(x => x.countryISOCode.toLowerCase() == this.selectedCountry.isoCode.toLocaleLowerCase());
      this.showBundlesPrices = true;
    }
  }
}