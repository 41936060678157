<div class="main-content">
  <div class="container">
    <div class="login-container">
      <div class="row justify-content-center mb-3">
        <div class="col-sm-12">
          <h3 class="text-left m-0" data-test-id="login-title">{{'Login_Welcom_Back' | translate}}</h3>
        </div>
      </div>

      <div *ngIf="canShowSSOButton">
        <p class="text-center mb-3">
          <button class="btn btn-primary w-100" role="button" (click)="createMyAudiSSOLink()"
              data-test-id="login-register-link">{{'Login_With_Button' | translate:{appName:ssoAppName} }}</button>
        </p>
        <p class="or-divider mb-3">{{'Or_Word' | translate}}</p>
      </div>

      <div *ngFor="let alert of loginAlerts;">
        <!-- Alert Notifications-->
        <app-alert [alertType]="alert.type" (closeClick)="clearAlerts()">
          <span title *ngIf="alert.title">
            {{alert.title | translate}}
          </span>
          <span description>
            {{alert.message | translate}}
          </span>
          <a *ngIf="alert.hyperlink" hyperlink role="button" (click)="resendActivate()">
            <b>{{ alert.hyperlink | translate}}</b>
          </a>
        </app-alert>
      </div>

      <form [formGroup]="loginForm" novalidate class="login-form mb-4" data-test-id="login-form">
        <!-- Email -->
        <div class="form-group">
          <label data-test-id="login-form-email">{{'Email_Address' | translate}}</label>
          <input type="text" class="form-control" formControlName="email" data-test-id="login-form-email-input" autofocus>
          <div *ngIf="loginForm.controls['email'].errors">
            <span data-test-id="login-form-email-error" class="invalid-note"
              *ngIf="!loginForm.controls['email'].pristine && loginForm.controls['email'].errors.required">{{'Email_Required_Error'
              | translate}}</span>
            <span data-test-id="login-form-email-error-invalid" class="invalid-note"
              *ngIf="!loginForm.controls['email'].pristine && loginForm.controls['email'].errors.email && !loginForm.controls['email'].errors.required">
              {{'Email_Invalid_Error' | translate}}
            </span>
          </div>
        </div>
        <!-- Password -->
        <div class="form-group">
          <app-show-hide-input>
            <label data-test-id="login-form-password">{{'Password' | translate}}</label>
            <input type="password" class="form-control password-input" formControlName="password" #showhideinput
              data-test-id="login-form-password-input">
          </app-show-hide-input>
          <div *ngIf="loginForm.controls['password'].errors">
            <span data-test-id="login-form-password-error" class="invalid-note"
              *ngIf="!loginForm.controls['password'].pristine && loginForm.controls['password'].errors.required">
              {{'Login_Password_Required_Error' | translate}}
            </span>
          </div>
        </div>

        <div class="d-flex flex-row justify-content-between button-container">
          <p class="forgot-password">
            <a [routerLink]="['/forgotPassword' | localize]" class="dark-link btn-spacing"
              data-test-id="login-form-forgot-password-link ">{{'Reset_Password' | translate}}</a>
          </p>
          <button type="submit" class="btn login-button" [ngClass]="canShowSSOButton ? 'btn-outline-secondary' : 'btn-primary'" [disabled]="!loginForm.valid" aria-disabled="!loginForm.valid"
          (click)="login()" data-test-id="login-form-login-btn">{{'Login' | translate }}</button>
        </div>
      </form>

      <p class="text-center mb-3" data-test-id="login-register">
        {{'Login_Link_Register' |
          translate}}
          <b>
            <a class="dark-link" [routerLink]="['/register' | localize]"
              data-test-id="login-register-link">{{'Login_Register_An_Account' |
              translate}}</a>
          </b>
      </p>
      <app-partnership></app-partnership>
    </div>
  </div>
</div>