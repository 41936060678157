import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { LanguageService } from '@shared/services';
import { Observable } from 'rxjs';
import { AuthenticationService } from '../services/authentication.service';

@Injectable()
export class AuthGuard  {

  constructor(private authService: AuthenticationService,
    private languageService: LanguageService) { }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    if (this.authService.appAuthUser.isAuthenticated === true) {
      return true;
    } else {
      const urlWithoutLang = state.url.substring(3, state.url.length);
      this.languageService.localizedNavigate(['/login'], { queryParams: { returnUrl: urlWithoutLang } });
    }
    return false;
  }
}
