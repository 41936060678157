import { Component, OnInit, OnDestroy } from '@angular/core';
import { ResponseError } from '@shared/models/response-error';
import { IntroSection } from '@shared/models/intro-section';
import { ContentSection } from '@shared/models/content-section';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subscription } from 'rxjs';
import { LocalizeRouterService } from '@gilsdav/ngx-translate-router';
import { Logger } from '@shared/logging/Logger';
import { ContentService } from '@shared/services/content.service';
import { ContentType } from '@shared/models/content-type';

@Component({
  selector: 'app-terms-and-conditions',
  templateUrl: './terms-and-conditions.component.html',
  styleUrls: ['./terms-and-conditions.component.scss']
})
export class TermsAndConditionsComponent implements OnInit, OnDestroy {
  public introSection: IntroSection;
  public content: ContentSection[];
  public legal: string;
  private localizeSubscription: Subscription;

  constructor(
    private localizeService: LocalizeRouterService,
    private spinnerService: NgxSpinnerService,
    private contentService: ContentService,
    private logger: Logger) { }


  ngOnInit() {
    this.introSection = new IntroSection();
    this.introSection.image = './assets/terms-and-conditions/Terms-Hero.png';
    this.prepareIntroSectionValues();
    this.updateContent();
    this.localizeSubscription = this.localizeService.routerEvents.subscribe((language: string) => {
      this.updateContent(language);
    });

  }

  updateContent(language?: string) {
    this.spinnerService.show();

    this.contentService.getContent(ContentType.TAC, language).subscribe((response) => {
      if (response instanceof ResponseError) {
        this.logger.error(response);
        this.spinnerService.hide();
        return;
      }
      this.content = response.content;
      window.scrollTo(0, 0);
      this.spinnerService.hide();
    });
  }

  prepareIntroSectionValues() {
    this.introSection = new IntroSection();
    this.introSection.image = './assets/terms-and-conditions/Terms-Hero.png';
    this.introSection.Title = 'Terms_And_Conditions_Title_Text';
    this.introSection.Description = 'Terms_And_Conditions_Description_Text';
  }

  ngOnDestroy(): void {
    this.localizeSubscription.unsubscribe();
  }
}
