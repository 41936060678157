<div class="margin-wrapper">
  <div class="container">
    <div class="row justify-content-center" data-test-id="home-hotspot">
      <div class="col-md-5">
        <img src="{{assets}}/home/hotspot.jpg{{assetVersionNumber}}" class="mx-auto d-block hotspot-img" />
      </div>
      <div class="col-md-7">
        <div class="hotspot-text">
          <h2 class="title" data-test-id="home-hotspot-title">
            {{'Home_Hotspot_Title' | translate}}
          </h2>
          <p data-test-id="home-hotspot-eSim">
            {{'Home_Hotspot_Service' | translate}}
          </p>
          <p data-test-id="home-hotspot-getstarted">
            {{'Home_Hotspot_CreateAccount' | translate}}
          </p>
          <p data-test-id="home-hotspot-registered">
            {{'Home_Hotspot_Registered' | translate}}
          </p>
          <button data-test-id="home-hotspot-btn" class="mx-auto btn btn-primary btn-getstarted"
            [routerLink]="['/register' | localize]">
            {{'Home_Hotspot_Button_Text' | translate}}
          </button>
        </div>
      </div>
    </div>
  </div>
</div>