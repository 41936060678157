<div *ngIf="currentVehicle; else vehicleNotFound">
    <div class="vehicle-container">
        <div class="image-container" style.background-image="url('{{currentVehicle.vehicle.vehiclePhotoImage}}')">
            <img style="display: none;" [src]="currentVehicle.vehicle.vehiclePhotoImage"
                (error)="vehicleImageLoadError()" />
        </div>

        <div class="container h-100 d-flex flex-column justify-content-end">
            <div class="d-flex flex-row align-items-center z-100 mb-2">
                <div class="d-flex flex-column text-left">
                    <p class="mb-1">{{currentVehicle.vehicle.vin}}</p>
                    <h4 *ngIf="currentVehicle.vehicle.vehicleModel">{{currentVehicle.vehicle.vehicleModel}}</h4>
                </div>
                <div class="ml-auto d-flex justify-content-end min-size">
                    <app-compatibility-badge [isCompatible]="!currentVehicle.vehicle.vehicleIsB2CDisabled"></app-compatibility-badge>
                </div>
            </div>
        </div>
    </div>

    <div class="container text-center">
        <div class="mt-4" *ngIf="currentVehicle.vehicle.vehicleIsB2CDisabled; else vehicleCompatible">
            <h4>{{'Vehicle_Compatibility_Vehicle_Imcompatible_Cannot_Offer_Our_Services' | translate }}</h4>
            <p class="m-0">{{'Vehicle_Compatibility_Vehicle_Imcompatible_Does_Not_Support_Connectivity' | translate }}
            </p>
            <div class="d-flex flex-column-reverse align-items-center flex-md-row justify-content-center button-container btn-container-spacing">
                <button type="button" class="btn btn-outline-secondary" (click)="returnToSso()">
                    {{'SSO_Return_To' | translate:{appName:ssoAppName} }}
                </button>

                <button *ngIf="isUserRegisterd" type="button" class="btn btn-primary" (click)="goToDashboard()">
                    {{'SSO_Go_To_Dashboard' | translate }}
                </button>
            </div>
        </div>

        <ng-template #vehicleCompatible>
            <div *ngIf="!showPriceTeaserSection; else priceTeaserBlock">
                <p class="m-0 mt-4">{{'Vehicle_Compatibility_Is_this_The_Vehicle' | translate }}</p>

                <div class="d-flex flex-row justify-content-center button-container">
                    <button *ngIf="!hasVinValue" type="button" class="btn btn-outline-secondary btn-spacing" (click)="retry()">
                        {{'Vehicle_Compatibility_Try_Again_Button' | translate }}
                    </button>
                    <button type="button" class="btn btn-primary" (click)="vehicleConfirmation()">{{'Yes' |
                        translate}}</button>
                </div>
            </div>

            <ng-template #priceTeaserBlock>
                <div class="mt-4">
                    <div *ngIf="vehicleTeaser; else noTeaserBlock">
                        <h4>
                            {{'Vehicle_Compatibility_Vehicle_Compatible_Free_Data_Plan' | translate: {bundleSize:
                            vehicleTeaser.bundleSize, bundleSizeDescription: vehicleTeaser.bundleSizeDescription} }}
                        </h4>
                        <p class="m-0"
                            [innerHTML]="'Vehicle_Compatibility_Vehicle_Compatible_Congratulations_You_Can_Claim_Free_Data_Plan' | translate:{link: ('/termsAndConditions' | localize)}">
                        </p>
                    </div>

                    <ng-template #noTeaserBlock>
                        <h4>{{'Vehicle_Compatibility_Vehicle_Compatible_Enjoy_Seamless_Connectivity' | translate}}</h4>

                        <p class="m-0" [innerHTML]="'Vehicle_Compatibility_Vehicle_Compatible_You_Can_Register_To_Better_Connectivity' |
                        translate:{link:('/termsAndConditions' | localize)}">
                        </p>
                    </ng-template>
                </div>
                <div class="d-flex flex-row justify-content-center button-container">
                    <button *ngIf="!hasVinValue" type="button" class="btn btn-outline-secondary btn-spacing" (click)="retry()">
                        {{'Vehicle_Compatibility_Try_Again_Button' | translate}}
                    </button>
                    <button type="button" class="btn btn-primary" (click)="continue()">
                        <span>{{'Continue_Button' | translate }}</span>
                    </button>
                </div>

                <p class="m-0 mb-2">
                    {{'Registration_Pending_Liable_Confirmation' | translate}}
                </p>
            </ng-template>
        </ng-template>
    </div>
</div>

<ng-template #vehicleNotFound>
    <!-- Vehicle not found -->
    <div class="container text-center">
        <div class="mb-3 mt-4">
            <svg width="162" height="112" viewBox="0 0 162 112" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="50" cy="50" r="50" transform="matrix(-1 0 0 1 139 12)" class="circle-light" />
                <circle cx="15.5" cy="15.5" r="15.5" transform="matrix(-1 0 0 1 43 7)" class="circle-light" />
                <circle cx="10" cy="10" r="10" transform="matrix(-1 0 0 1 162 70)" class="circle-light" />
                <ellipse cx="7.5" cy="7" rx="7.5" ry="7" transform="matrix(-1 0 0 1 61 0)" class="circle-light" />
                <path
                    d="M6.08219 57.1359C6.22534 56.3626 6.68265 55.5445 7.44969 54.7012C8.21197 53.8632 9.21083 53.0769 10.2938 52.371C13.7447 50.1219 18.0527 49.545 22.53 49.5432L111.228 49.5073C115.928 49.5054 120.459 50.1338 124.008 52.5938C125.071 53.3302 126.036 54.1417 126.761 54.9983C127.488 55.8584 127.909 56.6887 128.02 57.4737L131.407 81.3392C132.119 86.353 128.66 90.8716 123.655 91.3247C112.993 92.2899 94.0562 93.5006 66.1415 93.5006C38.6861 93.5006 20.0105 92.3295 9.33409 91.3727C4.18705 90.9114 0.707497 86.169 1.65555 81.0478L6.08219 57.1359Z"
                    fill="#F3F4F6" stroke="#D4D4D8" stroke-width="3" />
                <path d="M3 67H130V90H3V67Z" fill="#E5E7EB" />
                <path d="M52 67L9 90.8737C53 93.2913 103.667 91.8811 124 90.8737L78.5 67H52Z" fill="#D1D5DB" />
                <path
                    d="M65.54 40.3834C65.6 39.5201 65.66 38.6619 65.72 37.814C66.26 37.814 66.81 37.814 67.36 37.814C67.46 38.6568 67.57 39.5201 67.68 40.3782C66.96 40.3782 66.24 40.3782 65.54 40.3834Z"
                    fill="#FAFAFA" />
                <path
                    d="M65.22 44.4892C65.31 43.4563 65.39 42.4337 65.47 41.406C66.24 41.4008 67.02 41.406 67.81 41.406C67.94 42.4337 68.07 43.4614 68.22 44.4892C67.22 44.4892 66.22 44.4892 65.22 44.4892Z"
                    fill="#FAFAFA" />
                <path
                    d="M65.1 46.0359C65.01 47.2281 64.91 48.4254 64.81 49.6227C66.19 49.6227 67.58 49.6227 68.97 49.6279C68.78 48.4306 68.6 47.2281 68.45 46.0359C67.32 46.0256 66.21 46.0359 65.1 46.0359Z"
                    fill="#FAFAFA" />
                <path
                    d="M64.25 55.7943C64.37 54.4223 64.5 53.0502 64.62 51.6833C66.18 51.6782 67.73 51.6782 69.3 51.6885C69.52 53.0502 69.74 54.4223 69.99 55.7943C68.07 55.7943 66.16 55.7943 64.25 55.7943Z"
                    fill="#FAFAFA" />
                <path
                    d="M64.03 57.8549C64.01 58.0245 64 58.1838 64 58.3688C66.15 58.3636 68.29 58.3636 70.45 58.3688C70.41 58.1838 70.38 58.0193 70.35 57.8498C68.25 57.8344 66.15 57.8549 64.03 57.8549Z"
                    fill="#FAFAFA" />
                <path d="M57 51H43.8971L27 91H40.5L57 51Z" fill="url(#paint0_linear_510_15054)" />
                <path d="M66.5 51H61.8971L45 91H50L66.5 51Z" fill="url(#paint1_linear_510_15054)" />
                <path d="M88.5 51H83.8971L67 91H72L88.5 51Z" fill="url(#paint2_linear_510_15054)" />
                <path
                    d="M6.08219 57.1359C6.22534 56.3626 6.68265 55.5445 7.44969 54.7012C8.21197 53.8632 9.21083 53.0769 10.2938 52.371C13.7447 50.1219 18.0527 49.545 22.53 49.5432L111.228 49.5073C115.928 49.5054 120.459 50.1338 124.008 52.5938C125.071 53.3302 126.036 54.1417 126.761 54.9983C127.488 55.8584 127.909 56.6887 128.02 57.4737L131.407 81.3392C132.119 86.353 128.66 90.8716 123.655 91.3247C112.993 92.2899 94.0562 93.5006 66.1415 93.5006C38.6861 93.5006 20.0105 92.3295 9.33409 91.3727C4.18705 90.9114 0.707497 86.169 1.65555 81.0478L6.08219 57.1359Z"
                    stroke="#D1D5DB" stroke-width="3" />
                <rect x="93" y="58" width="25" height="4" rx="2" fill="white" />
                <rect x="97" y="56" width="13" height="3" rx="1.5" fill="white" />
                <rect x="35" y="59.56" width="19" height="3.04" rx="1.52" transform="rotate(-180 35 59.56)"
                    fill="white" />
                <rect x="28.1602" y="61.08" width="9.88" height="2.28" rx="1.14" transform="rotate(-180 28.1602 61.08)"
                    fill="white" />
                <rect x="32.7202" y="57.28" width="6.84" height="2.28" rx="1.14" transform="rotate(-180 32.7202 57.28)"
                    fill="white" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M65 28H63L65 34V48H70V34L72 28H70H65Z"
                    fill="#9CA3AF" />
                <defs>
                    <linearGradient id="paint0_linear_510_15054" x1="38" y1="59.5" x2="26" y2="89"
                        gradientUnits="userSpaceOnUse">
                        <stop stop-color="white" />
                        <stop offset="1" stop-color="#FCFDFF" stop-opacity="0" />
                    </linearGradient>
                    <linearGradient id="paint1_linear_510_15054" x1="56" y1="59.5" x2="44" y2="89"
                        gradientUnits="userSpaceOnUse">
                        <stop stop-color="white" />
                        <stop offset="1" stop-color="#FCFDFF" stop-opacity="0" />
                    </linearGradient>
                    <linearGradient id="paint2_linear_510_15054" x1="78" y1="59.5" x2="66" y2="89"
                        gradientUnits="userSpaceOnUse">
                        <stop stop-color="white" />
                        <stop offset="1" stop-color="#FCFDFF" stop-opacity="0" />
                    </linearGradient>
                </defs>
            </svg>
        </div>

        <h4>
            {{'Vehicle_Compatibility_Vehicle_Not_Found' | translate}}
        </h4>
        <p class="m-0">
            {{'Vehicle_Compatibility_Vehicle_Not_Found_Not_In_Our_System' | translate}}
            <br />
            {{'Vehicle_Compatibility_Vehicle_Not_Found_Please_Try_Again' | translate}}
        </p>

        <div class="button-container">
            <button *ngIf="!hasVinValue" type="button" class="btn btn-outline-secondary" (click)="retry()">
                {{'Vehicle_Compatibility_Try_Again_Button' | translate}}
            </button>

            <button *ngIf="hasVinValue" type="button" class="btn btn-outline-secondary" (click)="returnToSso()">
                {{'SSO_Return_To' | translate:{appName:ssoAppName} }}
            </button>

            <p class="mt-4">
                <a class="standard-link" role="button" [routerLink]="['/contactUs' | localize]">
                    {{'Vehicle_Compatibility_Vehicle_Not_Found_Contact_Support' | translate}}
                </a>
            </p>
        </div>
    </div>
</ng-template>