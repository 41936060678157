import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AlertType } from '@shared/models';

@Component({
  selector: 'app-alert',
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.scss']
})
export class AlertComponent implements OnInit {
  
  public isFixed: boolean;

  @Input() alertType: AlertType;
  @Input() hideCloseButton: boolean = false;
  @Input() data: any = null;

  @Output() closeClick = new EventEmitter<any>();

  iconTemplate: string;
  alertTypeEnum = AlertType;

  ngOnInit(): void {
    this.iconTemplate = this.getAlertTypeKeyByValue(this.alertType);
  }

  getAlertClass() {
    switch (this.alertType) {
      case AlertType.Error:
        return 'alert-danger';
      case AlertType.Warning:
        return 'alert-warning';
      case AlertType.Success:
        return 'alert-success';
      case AlertType.Info:
        return 'alert-info';
    }
  }

  getTextColorClass() {
    switch (this.alertType) {
      case AlertType.Error:
        return 'text-danger';
      case AlertType.Warning:
        return 'text-warning';
      case AlertType.Success:
        return 'text-success';
      case AlertType.Info:
          return 'text-info';
    }
  }

  private getAlertTypeKeyByValue(enumValue: number | string): string {
    let keys = Object.keys(AlertType).filter((x) => AlertType[x] == enumValue);
    return keys.length > 0 ? keys[0].toLowerCase() : '';
  }

  onCloseClick() {
    this.closeClick.emit(this.data);
  }
}