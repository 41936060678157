import { Component, Input } from '@angular/core';
import { IntroSection } from '@shared/models/intro-section';

@Component({
  selector: 'app-intro-section',
  templateUrl: './intro-section.component.html',
  styleUrls: ['./intro-section.component.scss']
})

export class IntroSectionComponent {
  @Input() public introSection: IntroSection;
  constructor() {
  }
}
