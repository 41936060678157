import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { AccountForgotPassword } from '../_models/account-forgot-password';
import { ResponseError } from '@shared/models/response-error';
import { AlertService, LanguageService } from '@shared/services/index';
import { Logger } from '@shared/logging/Logger';
import { CustomValidators } from '@shared/validators/custom-validators';
import { NgxSpinnerService } from 'ngx-spinner';
import { TranslateService } from '@ngx-translate/core';
import { AccountResetPasswordService } from '../_services/account-reset-password.service';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent {
  forgotPasswordForm: UntypedFormGroup;
  public forgotPasswordData: AccountForgotPassword;

  private alertNotFound = 'Forgot_Password_Not_Found';
  constructor(
    private activatedRoute: ActivatedRoute,
    private languageService: LanguageService,
    private fb: UntypedFormBuilder,
    private accountForgotPasswordService: AccountResetPasswordService,
    private spinnerService: NgxSpinnerService,
    private alertService: AlertService,
    private translationsService: TranslateService,
    private logger: Logger) {
    this.forgotPasswordData = new AccountForgotPassword();
    this.createForm();
    this.alertNotFound = this.translationsService.instant(this.alertNotFound);
  }

  createForm() {
    this.forgotPasswordForm = this.fb.group({
      email: [this.forgotPasswordData.email, [Validators.required, Validators.email, CustomValidators.email]]
    });
  }

  prepareAccountForgotPasswordData(): AccountForgotPassword {
    const formModel = this.forgotPasswordForm.value;
    const accountForgotPasswordData = new AccountForgotPassword();
    accountForgotPasswordData.email = formModel.email;
    return accountForgotPasswordData;
  }

  forgotPassword() {
    this.alertService.clear();
    this.spinnerService.show();

    this.accountForgotPasswordService.forgotPassword(this.prepareAccountForgotPasswordData())
      .subscribe(
        data => {
          this.languageService.localizedNavigate(['/checkEmail'], { relativeTo: this.activatedRoute });
          this.spinnerService.hide();
        },
        (err: ResponseError) => {
          this.handleError(err);
        },
        () => { this.spinnerService.hide(); }
      );
  }

  private handleError(err: ResponseError) {
    this.spinnerService.hide();
    switch (err.key) {
      case 'resetPasswordDuplicateRequest':
        this.languageService.localizedNavigate(['/'], { relativeTo: this.activatedRoute });
        break;
      case 'unprocessableEntity':
        if (err.code === '10.39') {
          this.languageService.localizedNavigate(['/emailFailed'], { relativeTo: this.activatedRoute });
        }
        if (err.code === '10.8') {
          this.alertService.error(err.message, 0, true, false);
        }
        break;
      default:
        this.alertService.error(err.message, 0, true, false);
        this.logger.error(err);
        break;
    }
  }
}
