export class AccountWithDeviceRequest {
    public email: string;
    public password: string;
    public firstName: string;
    public lastName: string;
    public promoEmailSubscribe: boolean;
    public acceptedTermsAndConditions: boolean;
    public streetAddress1: string;
    public streetAddress2: string;
    public city: string;
    public state: string;
    public postCode: string;
    public country: string;
    public countryCode: number;
    public birthDate: string;
    public vin: string;
    public teaserPackageSku: string;
}
