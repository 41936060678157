<div class="main-account-activate-expired">
  <div class="container">
    <div class="account-activate-expired-container">
      <div class="row justify-content-center">
        <div class="col-sm-12">
          <h3 class="text-center" data-test-id="account-activate-expired-title">
            {{'AccountActivateExpired_Title' | translate }}</h3>
          <p data-test-id="forgot-password-email-description">{{'AccountActivateExpired_Description' | translate }}</p>
          <button data-test-id="account-activate-expired-btn" type="submit" class="btn btn-primary btn-block"
            (click)="resendActivationEmail()">{{'SendLinkBtn' | translate }}</button>
        </div>
      </div>
    </div>
  </div>
</div>