<div class="row no-gutters">
  <div class="col float-left">
    <nav class="sidebar">
      <a class="sidebar-item" routerLink="vehicles" routerLinkActive="active"
        data-test-id="sidebar-dashboard-text">{{'Title_Dashboard' | translate }}</a>
      <a class="sidebar-item" [ngClass]="{isDisabled: getIdentificationStatus() === false}" routerLink="plan"
        routerLinkActive="active" data-test-id="sidebar-plans-text">{{'Title_Shop' | translate }}</a>
      <a class="sidebar-item" [ngClass]="{isDisabled: getIdentificationStatus() === false}" routerLink="history"
        routerLinkActive="active" data-test-id="sidebar-history-text">{{'Title_History' | translate }}</a>
      <a class="sidebar-item" routerLink="account" routerLinkActive="active"
        data-test-id="sidebar-account-text">{{'Title_Manage_Account' | translate }}</a>
    </nav>
  </div>
</div>