<div *ngIf="!appAuthUser.isAuthenticated">
  <div *ngIf="!hasCookiePolicyBeenDisplayed">
    <div class="cookie-policy-container">
      <div class="row no-gutters align-items-center">
        <div class="col-10 col-xl-11">
          <p class="notification" data-test-id="cookie-policy-description-text"
            [innerHTML]="'Cookie_Policy_Description_Text' | translate:{link: ('/privacyNotice' | localize)}">
          </p>
        </div>
        <div class="col-2 col-xl-1">
          <button type="button" data-test-id="cookie-policy-close-notification-button" class="close text-light"
            (click)="closeNotification()">
            <img src="{{assets}}/icons/close.svg" alt="close-button" class="button-position">
          </button>
        </div>
      </div>
    </div>
  </div>
</div>