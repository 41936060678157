import { Inject,Injectable } from '@angular/core';
import { ConfigService } from './config.service';
import { datadogRum } from '@datadog/browser-rum';
import { DOCUMENT } from '@angular/common';

@Injectable()
export class RumService 
{
  private ApplicationId: string;
  private ClientToken: string;
  private Site: string;
  private Service:string;
  private Version:string;
  private SessionSampleRate: number;
  private SessionReplaySampleRate: number;
  private TrackUserInteractions: boolean;
  private TrackResources: boolean;
  private TrackLongTasks: boolean;
  private PrivacyLevel :any;
  private currentEnv: string;
  private brand :string;

 
  constructor(private _configService: ConfigService ,@Inject(DOCUMENT) private document: Document) {
    this.ApplicationId = this._configService.config.datadogAppId;
    this.ClientToken = this._configService.config.datadogClientToken;
    this.Site = this._configService.config.datadogSite;
    this.Service = this._configService.config.datadogService;
    this.Version = this._configService.config.datadogVersion;
    this.SessionSampleRate = this._configService.config.datadogSessionSR;
    this.SessionReplaySampleRate = this._configService.config.datadogSessionRSR;
    this.TrackUserInteractions = this._configService.config.datadogUserInteraction;
    this.TrackResources = this._configService.config.datadogtrackResources;
    this.TrackLongTasks = this._configService.config.datadogtrackLongTasks;
    this.PrivacyLevel = this._configService.config.datadogPrivacyLevel;
    this.currentEnv = this._configService.config.CurrentEnv;
    this.brand = this._configService.config.CurrentBrand;
  }

  //Method for initialising  datadog rum 
  private initRum(): void
   {
    const brand = this.brand;
    datadogRum.init({
            applicationId: this.ApplicationId,
            clientToken: this.ClientToken,
            site: this.Site,
            service: this.Service,
            version: this.Version,
            env:this.currentEnv,
            sessionSampleRate: this.SessionSampleRate,
            sessionReplaySampleRate: this.SessionReplaySampleRate,
            trackUserInteractions: this.TrackUserInteractions,
            trackResources: this.TrackResources,
            trackLongTasks: this.TrackLongTasks,
            defaultPrivacyLevel:  this.PrivacyLevel 
        });

  datadogRum.startSessionReplayRecording();

 // Filter for analysing  product analytics and perforamce monitoring  by brand name
  datadogRum.setUserProperty('Brand_rum', brand);
 }

//Method for collecting  rum actions  on webshop 
 private  rumAction(eventName, data = {}) {
    const eventData = {
      brand: this.brand
    };
    datadogRum.addAction(eventName, eventData);
  }
  
//Method for tracking DOM Events
 private rumClickEvent() {
    this.document.addEventListener('click', () => {
      this.rumAction('click');
    });
  }
// We will call this in Appcomponent.ts 
  public setDatadogRum() {
    this.initRum();
    this.rumClickEvent();
   }

}




