export class AccountIdentification {
    public documentType: DocumentTypeEnum;
    public documentNumber: string;
}
export enum DocumentTypeEnum {
    "Passport" = 1,
    "Driving License" = 2,
    "Identity Card" = 3,
    "Residence Permit" = 4
}
