import { Component, OnInit, OnDestroy } from '@angular/core';
import { IntroSection } from '@shared/models/intro-section';
import { ResponseError } from '@shared/models';
import { ContentSection } from '@shared/models/content-section';
import { NgxSpinnerService } from 'ngx-spinner';
import { Logger } from '@shared/logging/Logger';
import { LocalizeRouterService } from '@gilsdav/ngx-translate-router';
import { Subscription } from 'rxjs';
import { ContentService } from '@shared/services/content.service';
import { ContentType } from '@shared/models/content-type';

@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.scss']
})
export class FaqComponent implements OnInit, OnDestroy {
  public content: ContentSection[];
  public introSection: IntroSection;
  private localizeSubscription: Subscription;

  constructor(private spinnerService: NgxSpinnerService,
    private logger: Logger,
    private localizeService: LocalizeRouterService,
    private contentService: ContentService) { 
    }

  ngOnInit() {
    this.prepareIntroSectionValues();
    this.updateContent();
    this.localizeSubscription = this.localizeService.routerEvents.subscribe((language: string) => {
      this.updateContent(language);
    });
  }

  prepareIntroSectionValues() {
    this.introSection = new IntroSection();
    this.introSection.image = './assets/faq/FAQ-Hero.png';
    this.introSection.Title = 'FAQ_LetUsHelp';
    this.introSection.Description = 'FAQ_LetUsHelp_Questions';
  }

  updateContent(language?: string) {
    this.spinnerService.show();

    this.contentService.getContent(ContentType.FAQ, language).subscribe((response) => {
      if (response instanceof ResponseError) {
        this.logger.error(response);
        this.spinnerService.hide();
        return;
      }
      this.content = response.content;
      this.spinnerService.hide();
    });
  }

  ngOnDestroy(): void {
    this.localizeSubscription.unsubscribe();
  }
}
