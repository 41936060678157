export class Tab {
    title: string;
    active: boolean = null;
    route: string;
    constructor(title?: string, active?: boolean, route?: string) {
        this.title = title || '';
        this.active = active || false;
        this.route = route || '';
    }
}
