import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AccountForgotPassword } from '../../_models/account-forgot-password';
import { ResponseError, SessionKeys } from '@shared/models';
import { DataService, AlertService, LanguageService } from '@shared/services/index';
import { Logger } from '@shared/logging/Logger';
import { NgxSpinnerService } from 'ngx-spinner';
import { TranslateService } from '@ngx-translate/core';
import { AccountResetPasswordService } from '@app/login/_services/account-reset-password.service';

@Component({
  selector: 'app-reset-password-expired',
  templateUrl: './reset-password-expired.component.html',
  styleUrls: ['./reset-password-expired.component.scss']
})
export class ResetPasswordExpiredComponent implements OnInit {

  private email: string;
  constructor(
    private dataService: DataService,
    private activatedRoute: ActivatedRoute,
    private languageService: LanguageService,
    private spinnerService: NgxSpinnerService,
    private alertService: AlertService,
    private accountResetPasswordService: AccountResetPasswordService,
    private translationsService: TranslateService,
    private logger: Logger) { }

  ngOnInit() {
    this.email = this.dataService.getSession(SessionKeys.email);
    if (this.email === null) {
      this.alertService.error(this.translationsService.instant('Reset_Password_Expired_Invalid_Requirements'), 0, true, true);
      this.languageService.localizedNavigate(['/'], { relativeTo: this.activatedRoute });
    }
  }

  prepareAccountForgotPasswordData(): AccountForgotPassword {
    const accountResetPasswordData = new AccountForgotPassword();
    accountResetPasswordData.email = this.email;
    return accountResetPasswordData;
  }

  resendResetPassword() {
    this.alertService.clear();
    this.spinnerService.show();
    this.accountResetPasswordService.resendResetPassword(this.prepareAccountForgotPasswordData())
      .subscribe(
        (data: AccountForgotPassword) => {
          this.dataService.removeSession(SessionKeys.email);
          this.languageService.localizedNavigate(['/checkEmail'], { relativeTo: this.activatedRoute });
          this.spinnerService.hide();
        },
        (err: ResponseError) => {
          this.spinnerService.hide();
          this.logger.error(err);
          this.alertService.error(err.message, 0, true, false);
        },
        () => { this.spinnerService.hide(); }
      );
  }

}
