import { Component, OnInit } from '@angular/core';
import { ConfigService } from '@shared/services';

@Component({
  selector: 'app-partnership',
  templateUrl: './partnership.component.html',
  styleUrls: ['./partnership.component.scss']
})
export class PartnershipComponent implements OnInit{
  canShow:boolean = false;

  private hideBrands = ['BENTLEY', 'SKODA']

  constructor(private configService: ConfigService) {
  }

  ngOnInit(): void {
    //Only show for brands not in hideBrands array
    this.canShow =  !this.hideBrands.includes(this.configService.config?.resellerCode?.toLocaleUpperCase());
  }
}
