<div>
  <div class="country-bundles-rates">
    <div ngbDropdown class="d-inline-block">
      <button type="button" class="btn btn-outline-dark" ngbDropdownToggle>
        {{selectedCountry ? (selectedCountry.name | translate) : ('FAQ_Bundles_Select_Your_Country_Text' | translate)}}
      </button>
      <div ngbDropdownMenu aria-labelledby="countryDropdown">
        <button ngbDropdownItem *ngFor=" let c of countries" class="dropdown-item text-dark" (click)="changeCountry(c)"
          [attr.data-test-id]="'faq-details-country-selected-' + c.isoCode">
          {{c.name | translate}}</button>
      </div>
    </div>
  </div>
  <div *ngIf="showBundlesPrices && countryBundleRates !== null" class="plans_list">
    <p *ngFor="let bundleRate of countryBundleRates">
      <span> {{'Bundle_Details_Plan' | translate:{dataSize:bundleRate.dataAmount, dataUnit:bundleRate.dataUnit,
        duration:bundleRate.duration, price:bundleRate.price, priceCode:bundleRate.currencyCode} }}</span>
    </p>
  </div>
</div>