import { Component, Input } from '@angular/core';
import { Plan } from '../_models/plan';

@Component({
  selector: 'app-plan-tile',
  templateUrl: './plan-tile.component.html',
  styleUrls: ['./plan-tile.component.scss']
})
export class PlanTileComponent {

  @Input() Plan: Plan;

  constructor() { }
}
