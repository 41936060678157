import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-tick-mark',
    templateUrl: './tick-mark.component.html',
    styleUrls: ['./tick-mark.component.scss']
})

export class TickMarkComponent {
    @Input() isActive;
    constructor() { }
}
