import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { BaseHttpService, ConfigService, DataService } from '@shared/services';
import { AccountActivate } from '../_models/account-activate';
import { ResponseError, BaseResponse, SessionKeys } from '@shared/models';
import { map, catchError } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class AccountActivateService extends BaseHttpService {

    constructor(
        httpClient: HttpClient,
        configService: ConfigService,
        translateService: TranslateService,
        private dataService: DataService) {
        super(httpClient, configService, translateService);
    }

    activateAccount(request: AccountActivate): Observable<AccountActivate | ResponseError> {
        return this.getBaseResponse<AccountActivate>('/accounts/activateAccount/' + request.token)
            .pipe(
                map(res => res.result),
                catchError(err => this.handleErrors(err))
            );
    }

    resendActivationEmail(request: AccountActivate): Observable<AccountActivate | ResponseError> {
        return this.post<AccountActivate>('/accounts/sendActivation', request);
    }

    private handleErrors(res: BaseResponse<AccountActivate>): Observable<ResponseError> {
        const err = res.errors;
        const result = res.result;

        switch (err.code) {
            case '2':
            case '10.5':
                err.redirectLink = '/';
                break;
            case '10.6':
                err.redirectLink = '../login';

                break;
            case '10.7':
                err.redirectLink = '../login';
                if (result !== undefined) {
                    this.setSessionStorage(SessionKeys.email, result.email);
                }
                break;
            default:
                err.redirectLink = '/';
                break;
        }
        const baseError = res.errors;
        return throwError(baseError);
    }

    private setSessionStorage(key: string, value: any) {
        if (key !== undefined && key !== '') {
            this.dataService.setSession(key, value);
        }
    }
}