import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { AccountLogin } from './_models/account-login';
import { ResponseError } from '@shared/models/response-error';
import { ConfigService, DataService, LanguageService, RedirectContextService } from '@shared/services/index';
import { AlertType, SessionKeys } from '@shared/models';
import { LoginService } from './_services/login.service';
import { Logger } from '@shared/logging/Logger';
import { CustomValidators } from '@shared/validators/custom-validators';
import { NgxSpinnerService } from 'ngx-spinner';
import { LocalizeRouterService } from '@gilsdav/ngx-translate-router';
import { LoginAlert } from '@shared/models/login-alert';
import { AccountActivateService } from '@app/registration/_services/account-activate.service';
import { AccountActivate } from '@app/registration/_models/account-activate';
import { Location } from '@angular/common';
import { SSOService } from '@app/sso-authentication/_services/sso.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  loginForm: UntypedFormGroup;
  public loginData: AccountLogin;
  sessionKeys = new SessionKeys();
  private returnUrl: string;
  loginAlerts: Array<LoginAlert> = [];
  canShowSSOButton: boolean;
  ssoAppName: string; 

  constructor(
    private fb: UntypedFormBuilder,
    private loginService: LoginService,
    private activatedRoute: ActivatedRoute,
    private languageService: LanguageService,
    private spinnerService: NgxSpinnerService,
    private localizedService: LocalizeRouterService,
    private redirectContextService: RedirectContextService,
    private logger: Logger,
    private accountActivateService: AccountActivateService,
    private dataService: DataService,
    private location: Location,
    private ssoService: SSOService,
    private configService: ConfigService
  ) {
    //Remove this.configService.config.isQaEnv after
    this.canShowSSOButton = this.configService.config.ssoSettings.enabled;
    this.ssoAppName = this.configService.config.ssoSettings.appName;

    this.loginData = new AccountLogin();
    const params = this.location.getState();
    if (params['alert']) {
      this.loginAlerts.push(params['alert']);
      window.history.replaceState(null, '');
    }
    if (this.redirectContextService.context !== null && this.redirectContextService.context !== undefined) {
      this.loginData = this.redirectContextService.mapRedirectToLogin();
    }
    this.createForm();
  }

  ngOnInit(): void {
    this.returnUrl = this.activatedRoute.snapshot.queryParamMap.get('returnUrl');
    const currentLanguagePath = `/${this.localizedService.parser.currentLang}`;
    if (this.returnUrl?.includes(currentLanguagePath)) {
      this.returnUrl = this.returnUrl.replace(currentLanguagePath, '');
    }
  }

  createForm() {
    this.loginForm = this.fb.group({
      email: [this.loginData.email, [Validators.required, Validators.email, CustomValidators.email]],
      password: [this.loginData.password, [Validators.required]]
    });
  }

  prepareAccountLoginData(): AccountLogin {
    const formModel = this.loginForm.value;
    const accountLoginData = new AccountLogin();
    accountLoginData.email = formModel.email;
    accountLoginData.password = formModel.password;
    return accountLoginData;
  }

  login() {
    this.clearAlerts();
    this.spinnerService.show();
    this.loginService.login(this.prepareAccountLoginData())
      .subscribe(
        () => {
          let route = '/dashboard';
          if (this.returnUrl) {
            route = this.returnUrl;
          }
          this.languageService.localizedNavigate([route]).then(x => {
            this.spinnerService.hide();
            this.redirectContextService.removeLoginFormData();
          });
        },
        (err: ResponseError) => {
          this.handleError(err);
        }
      );
  }

  clearAlerts() {
    this.loginAlerts = [];
  }

  resendActivate() {
    this.clearAlerts();
    this.accountActivateService.resendActivationEmail(this.prepareActivateAccountData())
      .subscribe(
        (data: AccountActivate) => {
          this.dataService.removeSession(SessionKeys.email);
          this.spinnerService.hide();
        },
        (err: ResponseError) => {
          this.handleError(err);

        },
        () => { this.spinnerService.hide(); }
      );

    this.pushAlert(AlertType.Success, 
      'Activation_Link_Popup_Description',
      'Activation_Link_Popup_New_Title');
  }

  prepareActivateAccountData(): AccountActivate {
    const accountResetPasswordData = new AccountActivate();
    accountResetPasswordData.email = this.dataService.getSession(SessionKeys.email);
    return accountResetPasswordData;
  }

  pushAlert(type: AlertType, message: string, title: string = '', hyperlink: string = '') {
    this.loginAlerts.push(new LoginAlert(
      type,
      message,
      title,
      hyperlink,
    ));
  }

  private handleError(err: ResponseError) {
    this.spinnerService.hide();
    this.clearAlerts();
    switch (err.code) {
      case '10.2':
      case '10.4':
        this.pushAlert(AlertType.Warning,  err.message, 'Attention');
        break;
      case '10.3':
        this.pushAlert(AlertType.Error,
          'Account_Activation_Required_Popup_Description',
          'Account_Activation_Required_Popup_Title',
          'Account_Activation_Required_Popup_Button',
        );
        break;
      default:
        this.pushAlert(AlertType.Error, err.message);
        this.logger.error(err);
        break;
    }
  }

  createMyAudiSSOLink() {
    //callback
    const myAudiScopes = this.configService.config.ssoSettings.scopes;
    this.spinnerService.show();
    
    this.ssoService.getSSOLink(myAudiScopes).subscribe((response: string | ResponseError) => {
      if (response instanceof ResponseError) {
        this.pushAlert(AlertType.Error, response.message);
        this.logger.error(response);
        return;
      }

      window.location.href = response;
      this.spinnerService.hide();
    });
  }
}
