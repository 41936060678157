import { Component, Input } from '@angular/core';
import { WalletItem } from '../_models/wallet-item';

@Component({
  selector: 'app-wallet-item',
  templateUrl: './wallet-item.component.html',
  styleUrls: ['./wallet-item.component.scss']
})
export class WalletItemComponent {

  @Input() plan: WalletItem;

  constructor() { }

}
