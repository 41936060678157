<app-intro-section [introSection]="introSection">
</app-intro-section>

<div class="margin-wrapper">
    <div class="justify-content-center">
        <div class="legal-text">
            <div class="container">
                <div class="row">
                    <div class="col-sm-12 col-md-6">
                        <p>
                            {{'Contractual_Summary_Data_Pack_2GB' | translate }}
                        </p>
                        <p>
                            {{'Contractual_Summary_Data_Pack_5GB' | translate }}
                        </p>
                        <p>
                            {{'Contractual_Summary_Data_Pack_25GB' | translate }}
                        </p>
                    </div>
                    <div class="col-sm-12 col-md-6">

                        <p class="w-full">
                            <span class="bold-text">
                                {{'Contractual_Summary_Provider' | translate }}
                            </span>
                            {{'Contractual_Summary_Provider_Address_Text' | translate }}
                        </p>

                        <p class="w-full">
                            <span class="bold-text">
                                {{'Contractual_Summary_Customer_Complaints' | translate }}
                            </span>
                            {{'Contractual_Summary_Customer_Complaints_Address_Text' | translate }}
                        </p>

                    </div>
                </div>
                <br>
                <div class="row justify-content-center">
                    <div class="col-12 text-center">
                        <h3> {{'Contractual_Summary_Title_Text' | translate }}</h3>
                    </div>
                </div>
                <div class="row">

                    <div class="col-12">
                        <ul>
                            <li> {{'Contractual_Summary_First_Point' | translate }} </li>
                            <li> {{'Contractual_Summary_Second_Point' | translate }}</li>
                            <li> {{'Contractual_Summary_Third_Point' | translate }} </li>
                        </ul>
                    </div>
                </div>

                <div class="row">
                    <div class="col-12">
                        <div class="mb-2">
                            <h4 class="text-center text-md-left"> {{'Contractual_Summary_Services_Title' | translate}}
                            </h4>
                            {{'Contractual_Summary_Services_Text' | translate }}<br>
                        </div>

                        <div class="row">
                            <div class="col-12">
                                <div *ngIf="localPlans.length > 0">
                                    <table class="mb-2">
                                        <thead>
                                            <tr>
                                                <th>{{'Contractual_Summary_Data_Pack_Size' | translate}}</th>
                                                <th>{{'Contractual_Summary_Data_Period_Validity' | translate}}</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr *ngFor="let plan of localPlans">
                                                <td>{{ plan.dataAmount }} {{ plan.dataUnit }}</td>
                                                <td>{{ plan.duration }} {{'Register_Address_Day' | translate}}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>

                        <p class="mb-2">{{'Contractual_Summary_Services_Further_Text' | translate}}</p>
                    </div>
                </div>

                <div class="row">
                    <div class="col-12">
                        <h4 class="text-center text-md-left"> {{'Contractual_Summary_Speed_Title' | translate}} </h4>
                        <br />
                        <table class="mb-2">
                            <tr>
                                <th>{{'Contractual_Summary_Network_Type' | translate}}</th>
                                <th>{{'Contractual_Summary_Max_Download_Speed' | translate}}</th>
                                <th>{{'Contractual_Summary_Upload_Speed' | translate}}</th>
                            </tr>
                            <tr>
                                <td> {{'Contractual_Summary_3G' | translate}} </td>
                                <td>{{'Contractual_Summary_Speed_Download_9' | translate}} </td>
                                <td>{{'Contractual_Summary_Speed_Upload_3' | translate}}</td>
                            </tr>
                            <tr>
                                <td> {{'Contractual_Summary_LTE' | translate}} </td>
                                <td>{{'Contractual_Summary_Speed_Download_20' | translate}} </td>
                                <td>{{'Contractual_Summary_Speed_Upload_7' | translate}}</td>
                            </tr>
                        </table>

                        <p class="mb-2">{{'Contractual_Summary_Speed_Information' | translate }}</p>
                    </div>
                </div>

                <div class="row">
                    <div class="col-12">
                        <h4 class="text-center text-md-left">{{'Contractual_Summary_Price' | translate}} </h4>
                        <div *ngIf="localPlans.length > 0">
                            <table class="mb-2">
                                <thead>
                                    <tr>
                                        <th>{{"Contractual_Summary_Data_Pack_Size" | translate}}</th>
                                        <th>{{"Contractual_Summary_Tariff" | translate}}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let plan of localPlans">
                                        <td>{{ plan.dataAmount }} {{ plan.dataUnit }}</td>
                                        <td>{{ plan.price }} {{ plan.currencyCode }}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-12">
                        <h4 class="text-center text-md-left">{{"Contractual_Summary_Duration_Title" | translate}} </h4>
                        <p class="mb-2">{{"Contractual_Summary_Renewal_Text" | translate}}</p>
                    </div>
                </div>

                <div class="row">
                    <div class="col-12">
                        <h4 class="text-center text-md-left">{{"Contractual_Summary_Relevant_Information" | translate}}
                        </h4>
                        <p class="mb-2">{{"Contractual_Summary_Change_Provider" | translate}}</p>
                    </div>
                </div>

                <div class="row text-center text-md-left">
                    <div class="col-12">
                        <span class="bold-text mb-2"> {{"Contractual_Summary_Update" | translate}} </span>
                        &nbsp; {{"Contractual_Summary_Update_Date" | translate}}
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>