import { tap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpEvent, HttpHandler, HttpRequest, HttpErrorResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AuthenticationService, ConfigService } from '@shared/services';
import { LanguageService } from '@shared/services/language.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

    constructor(
        private authTokenService: AuthenticationService,
        private configService: ConfigService,
        private languageService: LanguageService) { }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        let request = req;
        if (req.url.includes('cubictelecom.com')) {
            request = req.clone({
                setHeaders: {
                    'Authorization': 'Bearer ' + this.authTokenService.getToken(),
                    'cubic-reseller-id': this.configService.config.resellerCode,
                    'Accept-Language': this.languageService.getActiveLanguage().isoCode
                },
                withCredentials: true
            });
        }

        return next.handle(request).pipe(tap(
            (data: any) => { },
            (err: any) => {
                if (err instanceof HttpErrorResponse) {
                    if (err.status === 401) {
                        this.authTokenService.logout();
                        return;
                    } else if (err.status === 403) {
                        this.authTokenService.logout();
                        return;
                    }
                }
            }
        ));
    }
}
