import { Component } from '@angular/core';

@Component({
  selector: 'app-reset-password-email-failed',
  templateUrl: './reset-password-email-failed.component.html',
  styleUrls: ['./reset-password-email-failed.component.scss']
})
export class ResetPasswordEmailFailedComponent {

  constructor() { }
}
