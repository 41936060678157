<div class="container">
  <div class="row no-gutters">
    <div class="col-2 d-none d-lg-block float-left sidebar-container">
      <app-sidebar></app-sidebar>
    </div>
    <div class="col-12 col-lg-10">
      <div class="dashboard" data-test-id="dashboard">
        <router-outlet></router-outlet>
      </div>
    </div>
  </div>
</div>