import { DataUsageEntry } from '../_models/data-usage-entry';

export class UsageHistory {
    public vin: string;
    public usageHistoryFrom: string;
    public usageHistoryTo: string;
    public totalDataConsumed: number;
    public usageList: DataUsageEntry[];
    public todaysDateDataConsumed: number;
}
