import { Injectable } from '@angular/core';
import { UserContext } from '../models/user-context';
import { SessionKeys, IdentificationValidationContext } from '../models';
import { DataService } from '../services/data.service';
import { Vehicle } from '../../app/vehicles/_models';
import { IdentificationValidationContextService } from '@shared/services/identification-validation-context.service';

@Injectable()
export class UserContextService {

    public userContext: UserContext = new UserContext();

    constructor(
        private dataService: DataService,
        private identificationValidationContextService: IdentificationValidationContextService) {
        this.getUserContext();
    }

    public resetUserContext() {
        this.userContext.userName = '';
        this.userContext.firstName = '';
        this.userContext.lastName = '';
        this.userContext.userId = '';
        this.userContext.homeCountryCode = '';
        this.userContext.vehicles = null;
        this.userContext.productCheckoutStatus = null;
        this.userContext.isSSOUser = false;
        this.dataService.removeSession(SessionKeys.userContext);
    }

    public setUserContext(user: UserContext) {
        this.userContext.firstName = user.firstName ? user.firstName : 'My';
        this.userContext.lastName = user.lastName ? user.lastName : 'User';
        this.userContext.userId = user.userId;
        this.userContext.homeCountryCode = user.homeCountryCode;
        this.userContext.isSSOUser = user.isSSOUser;
        if (this.userContext.homeCountryCode !== null && this.userContext.homeCountryCode !== undefined && this.userContext.homeCountryCode !== '') {
            this.setIdentificationValidationContext(true);
        } else {
            this.setIdentificationValidationContext(false);
        }
        this.userContext.vehicles = new Array<Vehicle>();
        this.setSessionValues(user);
    }

    private setIdentificationValidationContext(isValidIdentification: boolean) {
        const identificationValidationContext = new IdentificationValidationContext();
        identificationValidationContext.isValidIdentification = isValidIdentification;
        this.identificationValidationContextService.setContext(identificationValidationContext);
    }

    public update() {
        this.dataService.setSession(SessionKeys.userContext, JSON.stringify(this.userContext));
    }

    private setSessionValues(data: UserContext) {
        this.dataService.setSession(SessionKeys.userContext, JSON.stringify(this.userContext));
    }

    private getUserContext() {
        const user = this.dataService.getSession(SessionKeys.userContext);
        if (user) {
            this.userContext = JSON.parse(user) as UserContext;
            return;
        }
    }
}
