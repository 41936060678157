export class Teaser {
    public name: string;
    public packageSku: string;
    public startDate: Date;
    public endDate: Date;
    public bundleSize: number;
    public bundleSizeDescription: string;
    public bundleDuration: number;
    public bundleDurationDescription: string;
}
