export class Country {
    public id: number;
    public name: string;
    public countryCode: number;
    public domainCode: string;
    public isoCode: string;
    public isCustomerIdentificationRequired: boolean;

    constructor(
        public _name: string,
        public _countryCode: number,
        public _isoCode: string) {
        this.name = _name;
        this.countryCode = _countryCode;
        this.isoCode = _isoCode;
    }
}
