import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'dataVolume'
})
export class DataVolumePipePipe implements PipeTransform {

  transform(value: number, args?: any): string {
    return this.getDataAmount(value);
  }

  private getDataAmount(value: number): string {
    const GBTreshold = 1048580;
    let friendlyData: number;
    let dataUnit: string;
    if (value >= GBTreshold) {
      friendlyData = Number(Number((value / 1024) / 1024).toFixed(2));
      dataUnit = 'GB';
    } else {
      friendlyData = Math.round(value / 1024);
      dataUnit = 'MB';
    }
    return friendlyData + dataUnit;
  }

}
