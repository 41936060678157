import { Component, OnInit, OnDestroy } from '@angular/core';
import { ResponseError } from '@shared/models/response-error';
import { IntroSection } from '@shared/models/intro-section';
import { ContentSection } from '@shared/models/content-section';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subscription } from 'rxjs';
import { LocalizeRouterService } from '@gilsdav/ngx-translate-router';;
import { Plan } from '@app/home/home-plans/_models/plan';
import { AlertService, LanguageService, LocationService, UserContextService } from '@shared/services';
import { PlansService } from '@app/home/home-plans/_services/plans.service';
import { CountriesService } from '@shared/services/countries.service';

@Component({
  selector: 'app-contractual-summary',
  templateUrl: './contractual-summary.component.html',
  styleUrls: ['./contractual-summary.component.scss']
})
export class ContractualSummaryComponent implements OnInit, OnDestroy {
  public introSection: IntroSection;
  public content: ContentSection[];
  public legal: string;
  public localPlans: Plan[] = [];
  private localizeSubscription: Subscription;

  constructor(
    private localizeService: LocalizeRouterService,
    private spinnerService: NgxSpinnerService,
    private plansService: PlansService,
    private languageService: LanguageService,
    private countriesService: CountriesService,
    private userContextService: UserContextService,
    private locationService: LocationService,
    private alertService: AlertService) { }

  ngOnInit() {
    this.introSection = new IntroSection();
    this.introSection.image = './assets/terms-and-conditions/Terms-Hero.png';
    this.prepareIntroSectionValues();
    this.initialize();
    this.localizeSubscription = this.localizeService.routerEvents.subscribe((language: string) => {
      this.initialize(language);
    });
  }

  private async initialize(language?: string) {
    this.spinnerService.show();

    const lang = !language ? this.languageService.getActiveLanguage().isoCode : language;

    try {
      let userCountry = await this.getUserCountry();
      if (!userCountry) {
        userCountry = this.getCountry(lang || this.languageService.getActiveLanguage().isoCode);
      }
      this.plansService.getBundleRates().subscribe({
        next: (value: Plan[] | ResponseError) => {
          this.spinnerService.hide();
          if (value instanceof Array) {
            this.localPlans = value.filter(plan => plan.countryISOCode.toLowerCase() === userCountry.toLowerCase());
          }
        },
        error: (err: ResponseError) => {
          this.alertService.error(err.message, 0, true, false);
          this.spinnerService.hide();
        }
      });
    } catch (error) {
      console.error('Error fetching localized bundle rates:', error);
    }
  }

  private async getUserCountry(): Promise<string> {
    try {
      const countryCode = await this.locationService.getClientCountryCode().toPromise();
      return countryCode || null;
    } catch (error) {
      console.error(error);
      return null;
    }
  }

  private getCountry(language: string): string {
    const homeCountryCode = this.userContextService.userContext.homeCountryCode;
    if (homeCountryCode) {
      return homeCountryCode;
    } else {
      return this.countriesService.getDefaultIsoCodeByLanguageCode(language);
    }
  }

  prepareIntroSectionValues() {
    this.introSection = new IntroSection();
    this.introSection.image = './assets/terms-and-conditions/Terms-Hero.png';
    this.introSection.Title = 'Validity_Plan_Contract_Summary';
  }

  ngOnDestroy(): void {
    this.localizeSubscription.unsubscribe();
  }
}
