export enum LanguageCode {
    English = 'en',
    German = 'de',
    Spanish = 'es',
    French = 'fr',
    Danish = 'da',
    Duth = 'nl',
    Italian = 'it',
    Swedish = 'sv',
    Czech = 'cs',
    Bulgarian = 'bg',
    Portuguese = 'pt',
    Croatian = 'hr',
    Polish = 'pl',
    Hungarian = 'hu'
}
