import { Injectable } from '@angular/core';
import { ISink } from '@shared/logging/sink';
import { LogLevel } from './log-level';
import { ResponseError } from '@shared/models';

@Injectable()
export class Logger {

    private sinks: ISink[] = [];
    private initialized: boolean;
    private logLevel: LogLevel = LogLevel.Info;

    constructor() {
    }

    info(message: string) {
        this.validate();
        if (this.logLevel < 4) {
            return;
        }
        this.sinks.forEach(sink => {
            sink.info(message);
        });
    }

    warn(message: string) {
        this.validate();
        if (this.logLevel < 3) {
            return;
        }
        this.sinks.forEach(sink => {
            sink.warn(message);
        });
    }

    error(message: string | Error | ResponseError) {
        this.validate();
        if (this.logLevel < 2) {
            return;
        }
        this.sinks.forEach(sink => {
            sink.error(message);
        });
    }

    fatal(message: string | Error) {
        this.validate();
        if (this.logLevel < 1) {
            return;
        }
        this.sinks.forEach(sink => {
            sink.fatal(message);
        });
    }

    logTo(sink: ISink): Logger {
        this.sinks.push(sink);
        return this;
    }

    create(): Logger {
        if (this.sinks.length <= 0) {
            throw Error('Specify at least 1 Logger');
        }
        this.initialized = true;
        return this;
    }

    minLoggingLevel(logLevel: string): Logger {
        if (logLevel) {
            switch (logLevel.toLowerCase()) {
                case 'info':
                    this.logLevel = LogLevel.Info;
                    break;
                case 'warn':
                    this.logLevel = LogLevel.Warn;
                    break;
                case 'error':
                    this.logLevel = LogLevel.Error;
                    break;
                case 'fatal':
                    this.logLevel = LogLevel.Fatal;
                    break;
                default:
                    this.logLevel = LogLevel.Info;
            }
        }
        return this;
    }

    private validate(): void {
        if (this.initialized === false) {
            throw new Error('No logger available, call create() to initialize logger');
        }
    }
}
