import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { BaseHttpService, ConfigService } from '@shared/services';
import { ResponseError } from '@shared/models';
import { AccountIdentificationDetails } from '../_models/account-identification-details';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class AccountIdentificationService extends BaseHttpService {

    constructor(
        httpClient: HttpClient,
        configService: ConfigService,
        translateService: TranslateService) {
        super(httpClient, configService, translateService);
    }

    accountIdentification(request: AccountIdentificationDetails): Observable<AccountIdentificationDetails | ResponseError> {
        return this.post<AccountIdentificationDetails>('/accounts/identificationDetails', request);
    }
}
