import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { SelectDropdownItem, SelectDropdownType } from './models/select-dropdown.model';


@Component({
    selector: 'app-select-dropdown',
    templateUrl: './select-dropdown.component.html',
    styleUrls: ['./select-dropdown.component.scss']
})
export class SelectDropdownComponent implements OnChanges {

    selectType: typeof SelectDropdownType = SelectDropdownType;
    @Input() items: SelectDropdownItem[];
    @Input() placeholder: string = null;
    @Input() selectedType: SelectDropdownType = SelectDropdownType.Default;

    buttonLabel = '';
    buttonLabelDisabled: boolean;
    showOptions = false;
    btnIconClass = '';
    activeItem: SelectDropdownItem = null;

    @Output() selectEvent = new EventEmitter();

    constructor() {
    }

    findWhere(array: any, criteria: any) {
        return array.find((item: any) => Object.keys(criteria).every(key => item[key] === criteria[key]))
    }

    ngOnChanges() {
        if (this.items.length > 0) {
            const hasActiveItem = this.items.some((item: any) => item.isActive);
            this.setActiveItem(hasActiveItem);
            this.setButtonLabel(hasActiveItem);
        }
    }

    toggleOptions() {
        this.showOptions = !this.showOptions;
    }

    hideOptions() {
        this.showOptions = false;
    }

    clickedOutside() {
        this.hideOptions();
    }

    setActiveItem(hasActiveItem: any) {
        this.activeItem = hasActiveItem
            ? this.findWhere(this.items, { isActive: true })
            : this.placeholder === null
                ? this.items[0]
                : { value: '', label: this.placeholder };

        this.selectEvent.emit(this.activeItem);
    }

    setButtonLabel(hasActiveItem: any) {
        this.buttonLabelDisabled = this.placeholder !== null && !hasActiveItem;
    }

    selectItem(item: SelectDropdownItem) {
        this.hideOptions();
        this.activeItem = item;
        this.buttonLabel = item.label;
        this.buttonLabelDisabled = false;
        this.selectEvent.emit(item);
    }
}