<div class="margin-wrapper">
  <div class="container">
    <div class="row justify-content-center" data-test-id="home-compatibility">
      <div class="col-md-5">
        <picture>
          <source media="(min-width: 720px)" srcset="{{assets}}/home/compatibility-desktop.jpg{{assetVersionNumber}}"
            class="mx-auto d-block compatibility-img">
          <img src="{{assets}}/home/compatibility-mobile.jpg{{assetVersionNumber}}"
            class="mx-auto d-block compatibility-img" />
        </picture>
      </div>
      <div class="col-md-7">
        <div class="compatible-text">
          <h2 class="title" data-test-id="home-compatibility-title">{{'Home_Compatibility_IsCompatible' | translate}}
          </h2>
          <p data-test-id="home-compatibility-preinstalled-text">
            {{'Home_Compatibility_PreInstalled' | translate}}
          </p>

          <div *ngIf="isTeaserPromotionRunning(); else teaserNotRunning">
            <ul class="compatibility-models" [innerHTML]="'Home_Compatibility_Models_Teaser' | translate">
              <!-- <li>Touareg (2017)</li>
              <li>Passat (2020)</li>
              <li>Arteon (2020)</li>
              <li>Golf (2020)</li>
              <li>T-Roc Cabrio (2020)</li>
              <li>Tiguan (2021)</li>
              <li>T-Roc (2021)</li>
              <li>Touran (2021)</li>
              <li>Polo (2021)</li>
              <li>T-Cross (2021)</li>
              <li>Crafter (2021)</li>
              <li>T6.1 (2020)</li>
              <li>ID.4 (2021)</li>
              <li>ID.3 (2020)</li> -->
            </ul>
          </div>
          <ng-template #teaserNotRunning>
            <ul data-test-id="home-compatibility-models-text" [innerHTML]="'Home_Compatibility_Models' | translate">
              <!-- <li>A3 (from 2016)</li>
              <li>A4 (from 2016)</li>
              <li>A5 (from 2016)</li>
              <li>A7 (from 2018)</li>
              <li>A8 (from 2018)</li>
              <li>Q2 (from 2018)</li>
              <li>Q5 (from 2018)</li>
              <li>Q7 (from 2018)</li>
              <li>Q8 (from 2018)</li> -->
            </ul>
          </ng-template>
          <p data-test-id="home-compatibility-more-info-text" [innerHTML]="'Home_Compatibility_MoreInfo' | translate">
            <!-- Please contact your Audi dealer for more information on your <a *ngIf="showSkodaHyperlink()"
              href="https://availability.skoda-auto.com/004/en-com#/" target="_blank">specific model</a>. -->
          </p>
          <p data-test-id="home-compatibility-use-service">{{'Home_Compatibility_UseService' | translate}}
          </p>
        </div>
      </div>
    </div>
  </div>
</div>