import { HighestVehicleRoleStatus } from './highest-vehicle-role-status';

export class UsersAndHighestVehicleRoleStatus {
    highestVehicleRoleStatus: HighestVehicleRoleStatus;
    users: UserAndRoleAssociation[];
    constructor() {
        this.users = new Array<UserAndRoleAssociation>();
    }
}

// eslint-disable-next-line max-classes-per-file
export class UserAndRoleAssociation {
    identityId: string;
    userId: number;
    role: string;
}
