import { VehicleUserStatus } from '../_models/vehicle-user-status';

export class Vehicle {
    public vin: string;
    public vehicleBrand: string;
    public vehicleModel: string;
    public vehicleColour: string;
    public vehiclePhotoThumb: string;
    public vehiclePhotoImage: string;
    public vehicleEcuGeneration: string;
    public vehicleIsB2CDisabled: boolean;
    public identityId: string;
    public personalisedName: string;
    public userId: number;
    public isFavourite: boolean;
    public hasLiableUser: boolean;
    public isLiableUser: boolean;
    public isSponsorUser: boolean;
    public isSecondaryUser: boolean;
    public isUnverifiedLiableUser: boolean;
    public userStatus: VehicleUserStatus;
}
