import { CreditCardType } from './credit-card-type';

export class CreditCard {
    holderName: string;
    number: number;
    type: CreditCardType;
    verificationCode: number;
    expireMonth: string;
    expireYear: string;
    saveCreditCard: boolean;
    referenceId: string;
    description: string;
    lastFourDigits: string;
    isDefault: boolean;
    isSelectedByDefault: boolean;
    constructor() {
    }
}
