import { Component, OnInit, Input } from '@angular/core';
import { AlertService } from '../../services/alert.service';
import { Alert, AlertType } from '../../models/index';

@Component({
  selector: 'app-floating-alert',
  templateUrl: './floating-alert.component.html',
  styleUrls: ['./floating-alert.component.scss']
})
export class FloatingAlertComponent implements OnInit {
  alerts: Alert[] = [];
  public isFixed: boolean;

  @Input() fixed: boolean;

  constructor(private alertService: AlertService) {
  }

  ngOnInit() {
    this.isFixed = this.fixed !== undefined;
    this.alertService.getAlert().subscribe((alert: Alert) => {
      if (!alert) {
        // clear alerts when an empty alert is received
        this.alerts = [];
        return;
      }
      if (alert.showDuration > 0) {
        // success
        setTimeout(() => {
          this.removeAlert(alert);
        }, this.calculateShowDuration(alert.showDuration));
      }
      // add alert to array
      this.alerts.push(alert);
    });
  }

  removeAlert(alert: Alert) {
    this.alerts = this.alerts.filter(x => x !== alert);
  }

  cssClass(alert: Alert) {
    if (!alert) {
      return;
    }
    // return css class based on alert type
    switch (alert.type) {
      case AlertType.Success:
        return 'alert-success';
      case AlertType.Error:
        return 'alert-danger';
      case AlertType.Info:
        return 'alert-info';
      case AlertType.Warning:
        return 'alert-warning';
    }
  }

  private calculateShowDuration(durationInSeconds: number): number {
    return durationInSeconds * 1000;
  }
}
