import { Component, OnInit } from '@angular/core';
import { IntroSection } from '@shared/models/intro-section';

@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.scss']
})
export class ContactUsComponent implements OnInit {
  public introSection: IntroSection;
  constructor() { }

  ngOnInit() {
    this.introSection = new IntroSection();
    this.introSection.image = './assets/contact-us/Contact-Hero.png';
    this.introSection.Title = 'Contact_Us_Title_Text';
    this.introSection.Description = 'Contact_Us_Description_Text';
    window.scrollTo(0, 0);
  }

}
