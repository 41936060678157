import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Teaser } from '@app/vehicles/_models';
import { VerifyVehicle } from '@app/vehicles/_models/verify-vehicle';
import { VehicleService } from '@app/vehicles/_services';
import { Logger } from '@shared/logging/Logger';
import { ResponseError } from '@shared/models';
import { Step } from '@shared/models/step';
import { AlertService, ConfigService, LanguageService } from '@shared/services';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-sso-vehicle-verification',
  templateUrl: './sso-vehicle-verification.component.html',
  styleUrls: ['./sso-vehicle-verification.component.scss']
})
export class SSOVehicleVerificationComponent implements OnInit {

  @Input()
  vin: string;

  @Input()
  isUserRegisterd: boolean;

  @Output() 
  verifiedVehicle = new EventEmitter<VerifyVehicle>();
  
  steps = [
    new Step('Vehicle_Compatibility', true, true), 
    new Step('SSO_Step_Account details', false, false), 
  ];

  vehicleOptions: Array<any> = new Array<any>();

  canContinue: boolean = false;
  canShowReturnButton: boolean = false;

  showVinForm: boolean = false;
  currentVehicle: VerifyVehicle;
  hasVinValue: boolean = false;
  showVehicleDetails: boolean = false;

  constructor(private vehicleService: VehicleService,
    private spinnerService: NgxSpinnerService,
    private alertService: AlertService,
    private logger: Logger,
    private languageService: LanguageService,
    private configService: ConfigService) {
  }

  ngOnInit(): void {
    if (this.vin) {
      //INFO: This flow only happens when coming from myaudi website button
      this.verify(this.vin);
    } else {
      this.showVinForm = true
    }
  }

  verify(vin: string) {
    this.spinnerService.show();

    this.vehicleService.verifyVehicle(vin).subscribe(result => { 
      this.hasVinValue = true;
      this.showVinForm = false

      if (result instanceof ResponseError) {
        this.handleVerifyErrorResponse(result);
      } else {
        this.currentVehicle = new VerifyVehicle();
        this.currentVehicle.vehicle = result;
        this.currentVehicle.IsVerified = true;
      }

      this.showVehicleDetails = true;
      this.spinnerService.hide();
    });
  }

  private handleVerifyErrorResponse(error: ResponseError) {
    this.logger.error(error);
    switch (error.code) {
      case '20.1':
        this.alertService.warn(error.message, 0, true, false);
        this.languageService.localizedNavigate(['/']);
        break;
        case '0.2':
            this.currentVehicle = null;
            break;
        default:
            this.alertService.error(error.message, 0, true, false);
            this.languageService.localizedNavigate(['/']);
            break;
    }
  }

  verifyCallback(verifiedVehicle: VerifyVehicle) {
    this.showVinForm = false;
    this.showVehicleDetails = true;
    this.currentVehicle = verifiedVehicle;
  }

  retryCallback() {
    this.currentVehicle = null;
    this.showVinForm = true;
    this.showVehicleDetails = false;
  }

  cancelClickEvent() {
    this.languageService.localizedNavigate(['/']);
  }

  returnCallback() {
    const ssoReturnUrl = this.configService.config.ssoSettings.returnUrl;
    window.location.replace(ssoReturnUrl);
  }

  dashboardClick() {
    this.languageService.localizedNavigate(['/dashboard']);
  }

  continueClickEvent(vehicleTeaser: Teaser) {
    this.currentVehicle.IsVerified = true;
    this.currentVehicle.teaser = vehicleTeaser;
    this.verifiedVehicle.emit(this.currentVehicle);
  }
}
