import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
@Injectable()
export class LocationService {
    public DefaultCountryCode: string = 'DE';

    constructor(private http: HttpClient) {
    }

    public getClientCountryCode(): Observable<string> {
        return this.http
            .get('https://extreme-ip-lookup.com/json/?key=L3Bp6aNkYrLrVckZ8DAQ').pipe(
                map(response => response['countryCode'] || this.DefaultCountryCode)
            );

    }
}
