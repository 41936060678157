import { AccountAddress } from '../_models/account-address';
import { AccountIdentification } from '../_models/account-identification';
import { HomeCountry } from '@shared/models';
import { ProductCheckoutStatus } from '../../product/_models';

export class AccountIdentificationDetails {
    public address: AccountAddress;
    public identification: AccountIdentification;
    public dateOfBirth: string;
    public vin: string;
    public email: string;
    public requiresIdentificationProof: boolean;
    public registrationDataRequirements: string[];
    public homeCountry: HomeCountry;
    public productCheckoutStatus: ProductCheckoutStatus;
    constructor() {
        this.address = new AccountAddress();
        this.identification = new AccountIdentification();
        this.homeCountry = new HomeCountry();
    }
}
