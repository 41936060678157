<div class="plan-card">
  <span *ngIf="Plan.enlarge" class="upsell">{{'Home_Plans_2GBPlanMostPopular' | translate }}</span>
  <div class="name">{{Plan.dataAmount}} {{Plan.dataUnit}}</div>
  <div>
    <span class="currency-symbol">{{Plan.currencyCode | currencySign}}</span>
    <label class="price">{{Plan.price}}</label>
  </div>
  <div class="validity">{{'ValidityPlan' | translate: {duration: Plan.duration ? Plan.duration : ''} }}
    <span>{{'Validity_Plan_Prompt' | translate }}
      <a class="standard-link" [routerLink]="['/termsAndConditions' | localize]"
        data-test-id="footer-term-n-cond-link">{{'Footer_TermAndCond'
        | translate}}</a>
      {{'Validity_Plan_Contract_Summary_Outlined' | translate }}
      <a class="standard-link" [routerLink]="['/contractualSummary' | localize]">{{'Validity_Plan_Contract_Summary' |
        translate}}.</a>
    </span>
  </div>
  <a class="btn" [ngClass]="Plan.enlarge ? 'btn-primary' : 'btn-outline-secondary'" [routerLink]="['/dashboard/plan' | localize]">{{'Home_Plans_BuyNow' | translate }}</a>
</div>