import { environment } from '../../../environments/environment';
import { IFeatures } from './features';
import { IBrowser } from './browser';
import { IAnalyticsToolsIDs } from './analyticsToolsIDs';
import { ISSOSettings } from './ssoSettings';
import { IDatadogIds } from './datadogIDs';



export class Config {
    private _name: string;
    private _version: string;
    private _websiteName: string;
    private _hostname: string;
    private _appInsightsInstKey: string;
    private _theme: string;
    private _themeColour: string;
    private _resellerCode: string;
    private _api: string;
    private _activationLink: string;
    private _resetPasswordLink: string;
    private _myAccountLink: string;
    private _faqLink: string;
    private _purchaseLink: string;
    private _reUploadLink: string;
    private _features: IFeatures;
    private _minLogLevel: string;
    private _assetVersionNumber: any;
    private _browserSupport: IBrowser[];
    private _analyticsToolsIDs: IAnalyticsToolsIDs;
    private  _datadogIDs : IDatadogIds;
    private _isProductionEnv: boolean;
    private _isQaEnv: boolean;
    private _isDevEnv: boolean;
    private _isUatEnv: boolean;
    private _currentBrand :string;


    constructor() {
        this._version = environment.app.version;
        this._name = environment.app.name;
        this._websiteName = environment.system.websiteName;
        this._hostname = environment.system.hostname;
        this._appInsightsInstKey = environment.azure.appInsightsInstKey;
        this._theme = environment.app.theme;
        this._themeColour = environment.app.themeColour;
        this._resellerCode = environment.app.resellerCode;
        this._api = environment.api.endpoint;
        this._activationLink = environment.app.activationLink;
        this._resetPasswordLink = environment.app.resetPasswordLink;
        this._myAccountLink = environment.app.myAccountLink;
        this._faqLink = environment.app.faqLink;
        this._purchaseLink = environment.app.purchaseLink;
        this._reUploadLink = environment.app.reUploadLink;
        this._features = environment.app.features;
        this._minLogLevel = environment.app.minLogLevel;
        this._assetVersionNumber = environment.app.assetVersionNumber;
        this._browserSupport = environment.app.browserRequirements;
        this._analyticsToolsIDs = environment.app.analyticsToolsIDs;
        this._datadogIDs = environment.app.dataDogIds;
        this._isProductionEnv = environment.production;
        this._isQaEnv = environment.qa;
        this._isDevEnv = environment.dev;
        this._isUatEnv = environment.uat;
        this._currentBrand = environment.app.resellerCode;
    }

    get name(): string {
        return this._name;
    }
    get version(): string {
        return this._version;
    }
    get websiteName(): string {
        return this._websiteName;
    }
    get hostname(): string {
        return this._hostname;
    }
    get appInsightsInstKey(): string {
        return this._appInsightsInstKey;
    }
    get theme(): string {
        return this._theme;
    }
    get api(): string {
        return this._api;
    }
    get activationLink(): string {
        return this._activationLink;
    }

    get resetPasswordLink(): string {
        return this._resetPasswordLink;
    }
    get myAccountLink(): string {
        return this._myAccountLink;
    }

    get faqLink(): string {
        return this._faqLink;
    }

    get assetVersionNumber(): any {
        return this._assetVersionNumber;
    }

    get purchaseLink(): string {
        return this._purchaseLink;
    }

    get reUploadLink(): string {
        return this._reUploadLink;
    }

    get resellerCode(): string {
        return this._resellerCode;
    }

    get showVehicleImage(): boolean {
        return this._features.showVehicleImage;
    }

    get showRegCompleteVideo(): boolean {
        return this._features.showRegCompleteVideo;
    }

    get showInCarTopup(): boolean {
        return this._features.showInCarTopup;
    }

    get showSkodaLink(): boolean {
        return this._features.showSkodaLink;
    }

    get showQRCodeScanner(): boolean {
        return this._features.showQRCodeScanner;
    }

    get regCompleteVideoUrl(): string {
        return this._features.regCompleteVideoUrl;
    }

    get ssoSettings(): ISSOSettings {
        return this._features.ssoSettings;
    }

    get minLogLevel(): string {
        return this._minLogLevel;
    }
    get browserSupport(): IBrowser[] {
        return this._browserSupport;
    }

    get datadogAppId(): string{
        return this._datadogIDs.applicationId;
    }

    get datadogVersion():string{
        return this._datadogIDs.version;
    }

    get datadogClientToken():string{
        return this._datadogIDs.clientToken;
    }

    get datadogSite():string{
        return this._datadogIDs.site;
    }

    get datadogService():string{
        return this._datadogIDs.service;
    }

    get datadogSessionSR():number{
        return this._datadogIDs.sessionSampleRate;
    }

    get datadogSessionRSR():number{
        return this._datadogIDs.sessionReplaySampleRate;
    }
    
    get datadogUserInteraction():boolean{
        return this._datadogIDs.trackUserInteractions;
    }

    get datadogtrackResources():boolean{
        return this._datadogIDs.trackResources;
    }

    get datadogtrackLongTasks():boolean{
        return this._datadogIDs.trackLongTasks;
    }
   
    get datadogPrivacyLevel(): string{
        return this._datadogIDs.defaultPrivacyLevel
    }

    get gaTrackingId(): string {
        return this._analyticsToolsIDs.gaTrackingId;
    }

    get hotjarTrackingId(): string {
        return this._analyticsToolsIDs.hotjarTrackingId;
    }

    get optimizePluginId(): string {
        return this._analyticsToolsIDs.optimizePluginId;
    }

    get themeColour(): string {
        return this._themeColour;
    }
    

    get isProductionEnv(): boolean {
        return this._isProductionEnv;
    }

    get isQaEnv(): boolean {
        return this._isQaEnv;
    }

    get isDevEnv(): boolean {
        return this._isDevEnv;
    }
    
    get isUatEnv(): boolean {
        return this._isUatEnv;
    }
//  Method to gets Current Enviornment value 
    get CurrentEnv(): string {
    if (this.isDevEnv) {
          return 'dev';
        } else if (this.isQaEnv) {
          return 'qa';
        } else if (this.isUatEnv) {
          return 'uat';
        } else if (this._isProductionEnv) {
          return 'production'; 
        } else {
          return 'local';
        }
      }

    get CurrentBrand(): string {
          return this._currentBrand
      }
}

