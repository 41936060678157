import { Component, OnInit, Input } from '@angular/core';
import { PurchaseHistory } from '../../_models/purchase-history';
import { PurchaseHistoryService } from '../../_services/purchase-history.service';
import { ResponseError, SessionKeys, AppAuthUser } from '@shared/models';
import { PurchaseDetails } from '../../_models/purchase-details';
import { AlertService, LanguageService } from '@shared/services/index';
import { AuthenticationService } from '@shared/services/authentication.service';
import { Logger } from '@shared/logging/Logger';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-purchase-history-card',
  templateUrl: './purchase-history-card.component.html',
  styleUrls: ['./purchase-history-card.component.scss']
})
export class PurchaseHistoryCardComponent implements OnInit {
  public purchaseHistory: PurchaseDetails[];
  @Input() vin: string;
  public purchaseHistoryData: PurchaseHistory;
  sessionKeys = new SessionKeys();
  public appAuthUser: AppAuthUser = null;

  constructor(
    private spinnerService: NgxSpinnerService,
    private languageService: LanguageService,
    private purchaseHistoryService: PurchaseHistoryService,
    private alertService: AlertService,
    private authenticationService: AuthenticationService,
    private logger: Logger) {
  }

  ngOnInit() {
    this.appAuthUser = this.authenticationService.appAuthUser;
    this.getSummaryPurchaseHistory();
  }

  getSummaryPurchaseHistory() {
    this.purchaseHistoryService.getSummaryPurchaseHistory(this.preparePurchaseHistoryData()).subscribe(
      (data: PurchaseHistory) => {
        this.purchaseHistory = data.purchaseDetailsList.sort(this.date_sort_desc).slice(0, 2);
      },
      (err: ResponseError) => {
        this.logger.error(err);
        switch (err.code) {
          case '10.23':
          case '0.2':
            break;
          default:
            this.alertService.error(err.message, 0, true, false);
            break;
        }
      }
    );
  }

  preparePurchaseHistoryData(): PurchaseHistory {
    this.purchaseHistoryData = new PurchaseHistory();
    const _today = new Date();
    this.purchaseHistoryData.vin = this.vin;
    this.purchaseHistoryData.fromDate = new Date(_today.setDate(_today.getDate() - 89)).toISOString();
    this.purchaseHistoryData.toDate = new Date().toISOString();
    return this.purchaseHistoryData;
  }

  date_sort_desc(date1, date2) {
    if (date1 > date2) {
      return -1;
    }
    if (date1 < date2) {
      return 1;
    }
    return 0;
  }

  goToPurchaseHistory() {
    this.spinnerService.show();
    const route = `/dashboard/history/${this.vin}/purchase`
    this.languageService.localizedNavigate([route]);
  }
}
