
import { of, Observable, throwError } from 'rxjs';

import { Injectable } from '@angular/core';
import { delay } from 'rxjs/operators';
import { BaseResponse, ResponseError } from '@shared/models';
import { IPaymentProviderJsonRequest, ICreateOrderRequest, IGetOrderResponse } from '../_models';

// The purpose of this service is to hold moq data for
// product purchase process
@Injectable()
export class ProductMoqService {
    constructor() { }

    createOrderSuccess(model: ICreateOrderRequest): Observable<BaseResponse<string | ResponseError>> {
        const response: BaseResponse<string> = new BaseResponse<string>();
        response.result = 'order123';

        return of(response)
            .pipe(delay(200));
    }

    getOrderSuccess(): Observable<BaseResponse<IGetOrderResponse | ResponseError>> {
        const order: IGetOrderResponse = {
            orderId: 1,
            orderNumber: 'AUD201110651827',
            netTotal: 12.976,
            orderTotal: 252,
            paymentCurrency: '£',
            transactionReference: 'transactionReference',
            creditCardReference: 'creditCardReference',
            userReferenceId: 'userReferenceId',
            identityId: 'identityId',
            reseller: 'reseller',
            resellerId: 12345,
            productName: 'productName',
            dataAmount: 'dataAmount',
            dataUnits: 'dataUnits',
            salePrice: 123,
            currencyFormat: 'currencyFormat',
            taxPercentage: 1,
            taxTotal: 1,
            packageSKU: 'packageSku',
            date: 'date'
        };

        const response: BaseResponse<IGetOrderResponse> = new BaseResponse<IGetOrderResponse>();
        response.result = order;

        return of(response)
            .pipe(delay(200));
    }

    buildPaymentProviderJson(request: IPaymentProviderJsonRequest): Observable<any | ResponseError> {
        const response = {
            'MERCHANT_ID': 'dev9813239027116297912', 'ACCOUNT': 'internet', 'ORDER_ID': 'AUD201110651827', 'AMOUNT': '010', 'CURRENCY': 'EUR', 'TIMESTAMP': '20201110140229', 'AUTO_SETTLE_FLAG': '1', 'OFFER_SAVE_CARD': '1', 'PAYER_EXIST': '0', 'PAYER_REF': 'a66018541302429189866ae03a3fea0a', 'HPP_LANG': 'ES', 'MERCHANT_RESPONSE_URL': 'https://b2c-dev.cubictelecom.com/products/paymentProviderResponse', 'CARD_PAYMENT_BUTTON': 'Pay Now', 'CARD_STORAGE_ENABLE': '1', 'HPP_VERSION': '2', 'SHA1HASH': 'f4043bcdb530352763bd44993fcef3642ac46169'
        };

        return of(JSON.stringify(response))
            .pipe(delay(200));
    }

    // Errors

    // Check actuall response
    createOrderError(model: ICreateOrderRequest): Observable<BaseResponse<string | ResponseError>> {
        const response: BaseResponse<ResponseError> = new BaseResponse<ResponseError>();
        response.errors = {
            'code': '120.1',
            'key': 'couldNotCreateOrder',
            'message': 'Could not create order.',
            'moreInfo': '',
            'redirectLink': ''
        };

        return throwError(response)
            .pipe(delay(500));
    }

    getOrderError = () => this.genericErrorResponse();

    buildPaymentProviderJsonError = () => this.genericErrorResponse();

    private genericErrorResponse(): Observable<BaseResponse<ResponseError>> {
        const response: BaseResponse<ResponseError> = new BaseResponse<ResponseError>();
        response.errors = {
            'code': '500',
            'key': 'key',
            'message': 'Some error message',
            'moreInfo': '',
            'redirectLink': ''
        };

        return throwError(response)
            .pipe(delay(500));
    }

}
