import { Component, OnInit, Input } from '@angular/core';
import { Vehicle } from '../_models/vehicle';
import { ConfigService, LanguageService, UserContextService } from '@shared/services';
import { VehicleService } from '../../vehicles/_services';
import { IdentificationValidationService } from '../../user/_services/identification-validation.service';
import { IdentificationValidation } from '../../user/_models/identification-validation';

@Component({
  selector: 'app-vehicle-details-card',
  templateUrl: './vehicle-details-card.component.html',
  styleUrls: ['./vehicle-details-card.component.scss']
})
export class VehicleDetailsCardComponent implements OnInit {

  @Input() public vehicle: Vehicle;
  public enableBuyPlan: boolean;
  public showVehicleImage: boolean;
  public loading: boolean;

  constructor(
    private languageService: LanguageService,
    private vehicleService: VehicleService,
    private configService: ConfigService,
    private identificationValidationService: IdentificationValidationService,
    private userContextService: UserContextService) { }

  ngOnInit() {
    this.loading = true;
    this.enableBuyPlan = false;
    //// TODO: To be deleted once AB2C-1169 is ready
    // this.getProductCheckoutStatus();
    this.forceRefreshVehicleUserData();
    this.showVehicleImage = this.configService.config.showVehicleImage;
  }

  redirectToBuyPlan() {
    this.languageService.localizedNavigate(['/dashboard/plan', this.vehicle.vin]);
  }

  private forceRefreshVehicleUserData() {
    this.vehicleService.forceRefreshVehicleUserStatus(this.vehicle.vin).subscribe(
      refresh => {
        if (this.userContextService.userContext.homeCountryCode === null) {
          this.enableBuyPlan = false;
          this.loading = false;
        } else {
          this.identificationValidationService.getIdentificationValidation(this.prepareIdentificationValidationRequest()).subscribe((data: IdentificationValidation) => {
            if (data.isIdentificationProofNeeded) {
              this.identificationValidationService.handleIdentificationStatus(data, true, this.vehicle.vin);
            } else {
              this.identificationValidationService.updateIsValidIdentification(true);
            }
            this.vehicleService.userCanBuyPlan(this.vehicle.vin).subscribe(
              res => {
                this.enableBuyPlan = res;
                this.loading = false;
              }
            );
          }
          );
        }
      }
    );
  }

  prepareIdentificationValidationRequest(): IdentificationValidation {
    const request = new IdentificationValidation();
    request.isoCode = this.userContextService.userContext.homeCountryCode;
    request.userId = parseInt(this.userContextService.userContext.userId, 10);
    request.vin = this.vehicle.vin;
    return request;
  }
}
