declare const RealexHpp: any;

import { Component, OnInit, OnDestroy, ViewChild, ElementRef } from '@angular/core';
import { BaseResponse, ResponseError } from '@shared/models';
import { ActivatedRoute } from '@angular/router';
import { AlertService, AuthenticationService, ConfigService, ProductCheckoutHppContextService, LanguageService, MonitoringService } from '@shared/services';
import { Logger } from '@shared/logging/Logger';
import { ErrorCode, IPaymentProviderRequest, IPaymentProviderJsonRequest } from '@app/product/_models';
import { PaymentsService } from '@app/product/_services';
import { Vehicle, VehicleInfomationRequest } from '@app/vehicles/_models';
import { VehicleService } from '@app/vehicles/_services';
import { NgxSpinnerService } from 'ngx-spinner';

interface IHppIframe {
  iframe: {
    height: string,
    width: string;
  };
}

@Component({
  selector: 'app-product-checkout-hpp',
  templateUrl: './product-checkout-hpp.component.html',
  styleUrls: ['./product-checkout-hpp.component.scss']
})
export class ProductCheckoutHppComponent implements OnInit, OnDestroy {

  vin: string;
  packageSku: string;

  showPayNow = false;
  showIframe = false;
  showOrderSummary = false;
  showIframeContainer = false;
  showWarning = false;
  iframeHeight: number;
  iframeWidth: number;
  showEnableInCarTopup = false;
  enableInCarTopup = false;

  // GEN4 vehicles
  isGen4Vehicle = false;

  // ISO codes
  swedenCubic = 'sv';
  czechCubic = 'cs';
  swedenGP = 'se';
  czechGP = 'cz';

  @ViewChild('launchHppBtn') launchHppBtn: ElementRef;

  constructor(
    private languageService: LanguageService,
    private activatedRoute: ActivatedRoute,
    private alertService: AlertService,
    private spinnerService: NgxSpinnerService,
    private authenticationService: AuthenticationService,
    private contextService: ProductCheckoutHppContextService,
    private logger: Logger,
    private paymentService: PaymentsService,
    private vehicleService: VehicleService,
    private configService: ConfigService,
    private monitoringService: MonitoringService
  ) {
  }

  ngOnInit() {
    this.spinnerService.show();
    this.loadRxpHppJS();
    this.setProductCheckoutContext();
    this.isGen4Vehicle = this.vehicleService.checkIsGen4Vehicle(this.vin);
    this.buildPaymentProviderJson();
    this.shouldShowPaymentFailureMessage();
  }

  enableInCarTopupChange = () => this.enableInCarTopup = !this.enableInCarTopup;

  // hppJson contains relevant details to open global payments iFrame
  private buildPaymentProviderJson() {
    this.alertService.clear();
    this.spinnerService.show();
    this.paymentService.buildPaymentProviderJson(this.setPaymentProviderJsonRequest())
      .subscribe({
        next: (data: BaseResponse<any>) => {
          this.loadGlobalPaymentsScript(data);
        },
        error: (err: BaseResponse<any | ResponseError>) => {
          this.resolveBuildPaymentProviderJsonErrorResponse(err);
        }
      });
  }

  private trySetIframeDimensions(response): boolean {
    if (JSON.stringify(response).indexOf('iframe') !== -1) {
      const hppIframe: IHppIframe = JSON.parse(response);
      this.iframeHeight = +hppIframe.iframe.height.slice(0, -2);
      this.iframeWidth = +hppIframe.iframe.width.slice(0, -2);
      return true;
    }
  }

  // Check few expected response values to confirm
  // is the expected HPP response after pay submitted
  private isValidEvent(response) {
    return (JSON.stringify(response).indexOf('SRD') !== -1 || JSON.stringify(response).indexOf('SHIPPING_CODE') !== -1 || JSON.stringify(response).indexOf('RESULT') !== -1);
    // We can add more checks if neeeded, e.g.:
    // && JSON.stringify(response).indexOf('SHIPPING_CODE') !== -1;
  }

  private loadRxpHppJS() {
    const script = document.createElement('script');
    script.src = '../../../scripts/rxp-hpp.js';
    script.id = 'hpp-script';

    document.body.appendChild(script);
  }

  /** Renders the payment form using our own embedded iFrame
   * Rules to update the HPP response object:
   * 1) Set HPP_LANG to selected language
   * 2) Set (always) PAYER_EXIST = 2 - if the payer supplied exists, use it, otherwise create a new one
   */
  private loadGlobalPaymentsScript(data: any) {
    // Parse response adn set init values
    const hppJson = JSON.parse(data);
    // Global payments ISO codes are different from the codes we have assigned.
    if (this.languageService.getActiveLanguage().isoCode.toLowerCase() === this.czechCubic) {
      hppJson.HPP_LANG = this.czechGP;
    }
    else if (this.languageService.getActiveLanguage().isoCode.toLowerCase() === this.swedenCubic) {
      hppJson.HPP_LANG = this.swedenGP;
    }
    else {
      hppJson.HPP_LANG = this.languageService.getActiveLanguage().isoCode;
    }
    hppJson.PAYER_EXIST = '2';

    // Set up the iFrame
    console.log("setting pgw url:" + hppJson.hpp_url)
    RealexHpp.setHppUrl(hppJson.hpp_url);


    RealexHpp.embedded.init('payButtonId', 'payIframeId', '', hppJson);

    // Subscribe t messages from iFrame
    if (window.addEventListener) {
      window.addEventListener('message', this.receiveMessageHpp, false);
    } else {
      (window as any).attachEvent('message', this.receiveMessageHpp);
    }

    // Launch the HPP
    this.launchHppBtn.nativeElement.click();

    // Process 'Launch the HPP' is not instant,
    // we need to allow it to be done in order to show HPP iFrame (2500ms is aproxx value, can be changed)
    setTimeout(() => {
      this.showOrderSummary = true;
      this.showIframeContainer = true;
      this.showWarning = true;
      this.spinnerService.hide();
    }, 2500);
  }

  private setPaymentProviderJsonRequest(): IPaymentProviderJsonRequest {
    return {
      'packageSku': this.packageSku,
      'vin': this.vin,
      'orderNumber': this.contextService.context.order.orderNumber
    };
  }

  private setProcessPaymentProviderRequest(event): IPaymentProviderRequest {
    return {
      'response': event,
      'enableInCarTopUp': this.enableInCarTopup,
    };
  }

  // Manage receive message event. Possible values:
  // 1. undefined
  // 2. object {"iframe":{"height":"678px","width":"509px"}}
  // 3. object {"SRD":"...","MERCHANT_RESPONSE_URL":"...",...}
  receiveMessageHpp = (event: MessageEvent) => {
    this.trySetIframeDimensions(event.data);
    this.monitoringService.info("GlobalPayment response for device: " + this.vin + " has following event details: " + event.data);
    if (typeof event.data !== 'undefined' && this.isValidEvent(event.data)) {
      this.processPaymentProviderResponse(event.data);
    } else {
      this.monitoringService.error("GlobalPayment response for device:" + this.vin + " is not valid: " + event.data);
    }
  }

  private processPaymentProviderResponse(data) {
    this.spinnerService.show();
    this.showIframeContainer = false;
    this.paymentService.processPaymentProviderResponse(this.setProcessPaymentProviderRequest(data))
      .subscribe({
        next: (data: BaseResponse<any>) => {
          this.spinnerService.hide();
          if (data.errors !== null) {
            // Success with internal error => should redirect to confirmation

            // Pending message: https://jira.cubictelecom.com/browse/AB2C-3673
            this.manageSuccessWithErrorMessageResponse(data.errors);
          } else {
            this.languageService.localizedNavigate(['/dashboard/plan/checkout/confirm', this.vin, this.packageSku], { relativeTo: this.activatedRoute });
          }
        },
        error: (err: BaseResponse<any | ResponseError>) => {
          this.spinnerService.hide();
          this.monitoringService.error("GlobalPayment response for device: " + this.vin + "is not valid: " + err.errors.message);
          this.resolvePaymentProviderErrorResponse(err);
        }
      });
  }

  // buildPaymentProviderJsonErrorResponse specific error messages resolver
  private resolveBuildPaymentProviderJsonErrorResponse(res: BaseResponse<any | ResponseError>) {
    this.spinnerService.hide();
    const err = res.errors;
    this.logger.error(err);
    switch (err.code) {
      case '40.5':
        this.alertService.error(err.message, 5, true, true);
        this.languageService.localizedNavigate(['/dashboard/plan']);
        break;
      default:
        this.alertService.error(err.message, 5, true, true);
        this.languageService.localizedNavigate(['/dashboard/plan/checkout', this.vin, this.packageSku], { relativeTo: this.activatedRoute });
        break;
    }
  }

  // paymentProviderResponse specific error messages resolver
  private resolvePaymentProviderErrorResponse(res: BaseResponse<any | ResponseError>) {
    this.spinnerService.hide();
    const responseError: ResponseError = res.errors;
    this.logger.error(responseError);
    switch (responseError.code) {
      case ErrorCode.FailedPayment:
        this.paymentFailureManager(responseError);
        break;
      case ErrorCode.FailedAddPaymentDetails:
      case ErrorCode.FailedAddPackageToWallet:
      case ErrorCode.FailedRegisterUserAsLiable:
      case ErrorCode.FailedRegisterUserAsSponsor:
        // Pending message: https://jira.cubictelecom.com/browse/AB2C-3673
        this.manageSuccessWithErrorMessageResponse(responseError);
        break;
      default:
        // unknown error case
        this.alertService.error(responseError.message, 100, true, true);
        this.languageService.localizedNavigate(['/dashboard/plan/checkout', this.vin, this.packageSku],
          { relativeTo: this.activatedRoute });
        break;
    }
  }

  // Payment success, part of process failed on our side
  // Redirect to payment confirmation page and show error message
  // Update confirmation context
  private manageSuccessWithErrorMessageResponse(responseError: ResponseError) {
    this.contextService.context.showConfirmationAlert = true;
    this.contextService.context.confirmationAlertMessage = responseError.message;
    this.contextService.update();
    this.languageService.localizedNavigate(['/dashboard/plan/checkout/confirm', this.vin, this.packageSku], { relativeTo: this.activatedRoute });
  }

  //Redirects to the refresh component if failure happens during payment on GP side
  //this is needed as we need to create a new order for the iframe if we are to re load it
  //so we need to leave the component and direct back in order for resolver to run again.
  private paymentFailureManager(responseError: ResponseError) {
    this.contextService.context.showPaymentErrorAlert = true;
    this.contextService.context.paymentFailureAlertMessage = responseError.message;
    this.contextService.update();
    this.languageService.localizedNavigate(['/dashboard/plan/checkout/refresh', this.vin, this.packageSku],
      { relativeTo: this.activatedRoute });
  }

  private setProductCheckoutContext() {
    this.alertService.clear();
    this.vin = this.activatedRoute.snapshot.params['vin'];
    this.packageSku = this.activatedRoute.snapshot.params['product'];
    this.canShowInCarTopup();
    this.resetContextConfirmationValues();
  }

  resetContextConfirmationValues() {
    this.contextService.context.showConfirmationAlert = false;
    this.contextService.context.confirmationAlertMessage = '';
    this.contextService.update();
  }

  resetContextPaymentFailureValues() {
    this.contextService.context.showPaymentErrorAlert = false;
    this.contextService.context.paymentFailureAlertMessage = '';
    this.contextService.update();
  }

  showInCarTopup() {
    return this.configService.config.showInCarTopup;
  }

  closeDisclaimer() {
    this.showWarning = false;
  }

  //Method that calls the vehicle service in order to get the statuses of the account needed to display InCarTopUp
  private canShowInCarTopup() {
    const request = new VehicleInfomationRequest;
    request.identityId = this.authenticationService.appAuthUser.identityId;
    this.vehicleService.getVehicles(request)
      .subscribe({
        next: (vehicles: Vehicle[]) => {
          const test = vehicles.filter(x => x.vin === this.vin);
          if (test !== null && test !== undefined) {
            if ((test[0].isLiableUser === true && test[0].isSponsorUser === false) ||
              (test[0].isSponsorUser === false && test[0].isLiableUser === false && test[0].hasLiableUser === false) ||
              (test[0].isSponsorUser === false && test[0].isLiableUser === false && test[0].isUnverifiedLiableUser === true)) {
              this.showEnableInCarTopup = true;
            }
          }
        },
        error: () => {
          this.showEnableInCarTopup = false;
        },
        complete: () => {
          this.spinnerService.hide();
        }
      });
  }

  private shouldShowPaymentFailureMessage() {
    if (this.contextService.context.showPaymentErrorAlert) {
      this.alertService.error(this.contextService.context.paymentFailureAlertMessage, 300, true, true);
    }
    this.resetContextPaymentFailureValues();
  }

  // A method to clear down the script tags, this is to fix the issue of script tags stacking causing plans to be applied multiple times
  private cleanHPPScripts() {
    const script = document.getElementById('hpp-script');
    if (script) {
      script.parentNode.removeChild(script);
    }
  }

  ngOnDestroy(): void {
    this.cleanHPPScripts();
    window.removeEventListener('message', this.receiveMessageHpp);
  }
}
