export enum IdentificationValidationStatus {
    PendingUpload,
    PendingApproval,
    ValidationRejected,
    ValidationApproved,
    ValidationAborted,
    IdentityCheckBlocked,
    VideoValidationRedirect,
    NoDocumentValidationRequired,
    RejectedIdentificationProofUserData,
    RejectedIdentificationProofDocument,
    RejectedIdentificationProofForgery,
    Unknown
}
