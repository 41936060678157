import { Injectable } from '@angular/core';
import { ConfigService } from '@shared/services/config.service';

@Injectable()
export class ThemeService {
  constructor(
    private _configService: ConfigService
  ) { }

  get assetsLocation(): string {
    return './assets/' + this._configService.config.theme;
  }

  get themeColour(): string {
    return this._configService.config.themeColour;
  }

  get assetVersionNumber(): string {
    return "?v" + this._configService.config.assetVersionNumber;
  }

  get themeName(): string {
    return this._configService.config.theme;
  }
}
