import { Component, OnInit } from '@angular/core';
import { AppAuthUser } from '@shared/models/app-auth-user';
import { AuthenticationService } from '@shared/services/authentication.service';
import { CookieService } from 'ngx-cookie-service';
import { CookieKeys } from '@shared/models';
import { ThemeComponent } from '@shared/components/theme.component';
import { ThemeService } from '@shared/services/theme-service.service';

@Component({
  selector: 'app-cookie-policy',
  templateUrl: './cookie-policy.component.html',
  styleUrls: ['./cookie-policy.component.scss']
})
export class CookiePolicyComponent extends ThemeComponent implements OnInit {

  cookieKeys = new CookieKeys();
  hasCookiePolicyBeenDisplayed = false;
  public appAuthUser: AppAuthUser = null;
  // closeIconPath = '{{assets}}/icons/close.svg';
  cookieValue = 'UNKNOWN';

  constructor(
    _themeService: ThemeService,
    private authenticationService: AuthenticationService,
    private cookieService: CookieService) {
    super(_themeService);
  }

  ngOnInit() {
    this.cookieValue = this.cookieService.get(this.cookieKeys.cookieConsent);
    this.cookieValue === '' ? this.addCookieConsent() : this.hasCookiePolicyBeenDisplayed = true;
    this.appAuthUser = this.authenticationService.appAuthUser;
  }

  ngOnChange() {
    this.closeNotification();
  }

  addCookieConsent() {
    this.hasCookiePolicyBeenDisplayed = false;
    this.insertCookieConsentToCookies();
  }

  closeNotification() {
    this.hasCookiePolicyBeenDisplayed = true;
  }

  private insertCookieConsentToCookies() {
    this.cookieService.set(this.cookieKeys.cookieConsent, 'true', 365, '/');
  }
}
