import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { BaseHttpService, ConfigService } from '@shared/services';
import { ResponseError } from '@shared/models';
import { TranslateService } from '@ngx-translate/core';
import { SSOToken } from '../_models/sso-token';
import { SSOUser } from '../_models/sso-user';

@Injectable()
export class SSOService extends BaseHttpService {

    constructor(
        httpClient: HttpClient,
        configService: ConfigService,
        translateService: TranslateService,
    ) {
      super(httpClient, configService, translateService);
    }

    // HTTP POST
    getSSOLink(scopes: Array<string>): Observable<string | ResponseError> {
        const redirectUrl = this.getSSORedirectUrl();

        return this.post<string>('/sso/generate', {
            redirectUrl: redirectUrl,
            scopes: scopes
        });
    }

    getToken(state:string, code: string): Observable<SSOToken | ResponseError> {
        const redirectUrl = this.getSSORedirectUrl();

        return this.post<SSOToken>('/sso/getToken', {
            state: state,
            code: code,
            redirectUrl: redirectUrl
        });
    }

    authorize(): Observable<SSOToken | ResponseError> {
        return this.post<SSOToken>('/sso/authorize', null);
    }

    getUser(): Observable<SSOUser | ResponseError> { 
        return this.post<SSOUser>('/sso/getUser', null);
    }

    private getSSORedirectUrl() {
        return `${window.location.origin}/ssoAuth`;
    }
}