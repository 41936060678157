<div *ngIf="isBrowserOutdated">
  <div class="browser-notice alert-warning alert">
    <div class="container">
      <div class="row">
        <div class="col-12">
          <span>{{'Browser_Update_Notice' | translate }}</span>
          <a href="https://support.google.com/chrome/answer/95414?co=GENIE.Platform%3DDesktop&hl=en-GB" target="_blank"
            class="update-link">Chrome</a>
          <a href="https://support.apple.com/en-us/ht204416" target="_blank" class="update-link">Safari</a>
          <a href="https://www.microsoft.com/en-us/download/internet-explorer.aspx" target="_blank"
            class="update-link">Internet Explorer</a>
          <a href="https://support.mozilla.org/en/kb/update-firefox-latest-version" target="_blank"
            class="update-link">Firefox</a>
        </div>
      </div>
    </div>
  </div>
</div>