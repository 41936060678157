import { Injectable } from '@angular/core';
import { ProductCheckoutHppContext } from '@shared/models/product-checkout-hpp-context';

@Injectable()
export class GoogleAnalyticsEcommerceService {

    constructor() {
    }

    public sendDataToGoogleEcommerce(data: ProductCheckoutHppContext) {
        ga('require', 'ecommerce', 'ecommerce.js');
        this.addItem(data);
        this.addTransaction(data);
        ga('ecommerce:send');
    }

    private addItem(itemDetails: ProductCheckoutHppContext) {
        ga('ecommerce:addItem', {
            id: itemDetails.order.orderNumber,    // Transaction ID. Required. - Order Number
            name: itemDetails.order.productName,  // Product name. Required. - a plan name
            price: itemDetails.order.salePrice,   // Unit price (total price)
            quantity: '1',                  // Quantity - always 1
            currency: itemDetails.order.currencyFormat // Local currency code
        });
    }

    private addTransaction(transactionDetails: ProductCheckoutHppContext) {
        ga('ecommerce:addTransaction', {
            id: transactionDetails.order.orderNumber,         // Transaction ID - Order Number
            revenue: transactionDetails.order.salePrice,      // Total price
            currency: transactionDetails.order.currencyFormat // Local currency code
        });
    }

}
