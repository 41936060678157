<div class="margin-wrapper">
  <div class="container">
    <div class="row">
      <div class="col-12 text-center">
        <h2 class="title" data-test-id="home-favourite-title">{{'Home_Favourite_Title' | translate}}</h2>
        <p class="description-text-one" data-test-id="home-favourite-description-text-one">
          {{'Home_Favourite_Description_Text_One' | translate}}
        </p>
        <p class="description-text-two" data-test-id="home-favourite-description-text-two">
          {{'Home_Favourite_Description_Text_Two' | translate}}
        </p>
      </div>
    </div>
    <div class="row justify-content-center">
      <div class="col-md-10 col-lg-8">
        <div class="row justify-content-center">
          <div *ngFor="let service of streamingServices; let i=index"
            [ngClass]="[ i>4 ? 'col d-none d-sm-block' : 'col']">
            <img [src]="service.icon" class="d-block mx-auto icons" [alt]="service.name">
          </div>
        </div>
      </div>
    </div>
  </div>
</div>