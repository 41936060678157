import { HomeCountry } from '@shared/models';

export class AccountLogin {
    public email: string;
    public password: string;
    public identityId: string;
    public firstName: string;
    public lastName: string;
    public userId: string;
    public homeCountry: HomeCountry;
    public token: string;

    constructor() {
        this.homeCountry = new HomeCountry();
    }
}
