import { ISink } from '@shared/logging/sink';
import { ResponseError } from '@shared/models';

export class ConsoleSink implements ISink {

    info(message: string) {
        console.log(message);
    }
    warn(message: string) {
        console.warn(message);
    }
    error(message: string | Error | ResponseError) {
        if (message instanceof Error) {
            console.error(
                'Message: ' + message.message + '\n' +
                'Stack Trace: ' + message.stack
            );
            return;
        }
        if (message instanceof ResponseError) {
            console.error(message.toString());
            return;
        }
        console.error(message);
    }
    fatal(message: string | Error) {
        this.error(message);
    }
}
