<div class="sticky-top">
  <div class="card-page-header-container">
    <ul class="card-page-order-summary">
      <li>
        <input type="checkbox" checked>
        <i></i>
        <div class="col-sm-12 d-flex justify-content-start">
          <div class="card-page-header-title" data-test-id="card-page-header-title">
            {{'Card_Page_Header_Title' | translate }}
          </div>
          <div class="card-page-header-total" [attr.data-test-id]="'card-page-header-total-'+context.order.packageSKU">
            {{context.order.currencyFormat}} {{context.order.salePrice | number:'1.2-5'}}
          </div>
        </div>
        <ul class="dropdown">
          <app-product-checkout-order-card></app-product-checkout-order-card>
        </ul>
      </li>
    </ul>
  </div>