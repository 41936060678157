import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TeaserEligibilityService } from '@app/registration/_services/teaser-eligibility.service';
import { Teaser } from '@app/vehicles/_models';
import { VerifyVehicle } from '@app/vehicles/_models/verify-vehicle';
import { TeaserResponse } from '@app/vehicles/_models/teaser-response';
import { NgxSpinnerService } from 'ngx-spinner';
import { VehicleImagesUrlService } from '@app/vehicles/_services/vehicle-images-url.service';
import { ResponseError } from '@shared/models';
import { AlertService, ConfigService } from '@shared/services';

@Component({
    selector: 'app-sso-vehicle-verification-details',
    templateUrl: './sso-vehicle-verification-details.component.html',
    styleUrls: ['./sso-vehicle-verification-details.component.scss']
  })
  export class SSOVehicleVerificationDetailsComponent implements OnInit {

    @Input()
    currentVehicle: VerifyVehicle;
    @Input()
    hasVinValue: boolean;
    @Input()
    isUserRegisterd: boolean;

    @Output() retryClick = new EventEmitter();
    @Output() continueClick = new EventEmitter<Teaser | null>();
    @Output() returnClick = new EventEmitter();
    @Output() dashboardClick = new EventEmitter();
    
    showPriceTeaserSection: boolean;
    vehicleTeaser: Teaser;
    ssoAppName: string; 

    constructor(private teaserEligibilityService: TeaserEligibilityService,
        private spinnerService: NgxSpinnerService,
        private vehicleImagesUrlService: VehicleImagesUrlService,
        private alertService: AlertService,
        private configService: ConfigService) {

        this.ssoAppName = this.configService.config.ssoSettings.appName;
    }

    ngOnInit(): void {
        if (this.currentVehicle && this.currentVehicle.IsVerified) {
            this.vehicleConfirmation();
        }
    }

    vehicleImageLoadError() {
        if (this.currentVehicle) {
            const defaultImage = this.vehicleImagesUrlService.getDefaultImageUrl();

            if (this.currentVehicle.vehicle.vehiclePhotoImage !== defaultImage) {
                this.currentVehicle.vehicle.vehiclePhotoImage = defaultImage;
            }
        }
    }

    retry() {
        this.retryClick.emit();
    }

    vehicleConfirmation() {
        this.spinnerService.show();
        this.teaserEligibilityService.getTeasers(this.currentVehicle.vehicle.vin, null)
            .subscribe({
                next: (data: TeaserResponse) => {
                    if (data.teaserPlans && data.teaserPlans.length > 0) {
                        this.vehicleTeaser = data.teaserPlans[0];
                    } else {
                        this.vehicleTeaser = null;
                    }

                    this.spinnerService.hide();
                    this.showPriceTeaserSection = true;
                },
                error: (err: ResponseError) => {
                    this.alertService.error(err.message, 0, true, false);
                    this.spinnerService.hide();
                }
            });
    }

    continue() {
        this.continueClick.emit(this.vehicleTeaser);
    }

    returnToSso() {
        this.returnClick.emit();
    }

    goToDashboard() {
        this.dashboardClick.emit();
    }
  }