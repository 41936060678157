import { Component } from '@angular/core';
import { ThemeComponent } from '@shared/components/theme.component';
import { ThemeService } from '@shared/services/theme-service.service';
import { ConfigService } from '@shared/services/index';

@Component({
  selector: 'app-home-main-caption',
  templateUrl: './main-caption.component.html',
  styleUrls: ['./main-caption.component.scss']
})
export class MainCaptionComponent extends ThemeComponent {
  resellerCode: string;
  isBrandEligible: boolean;
  private eligibleBrandsForTeaser = ['VW', 'AUDI', 'BENTLEY'];
  isSsoEnabled: boolean;

  constructor(
    private configService: ConfigService,
    _themeService: ThemeService) {
    super(_themeService);
    this.isSsoEnabled = this.configService.config.ssoSettings.enabled;
    console.log(this.isSsoEnabled)
  }
  private isBrandTeaserEligible(brand) {
    return this.eligibleBrandsForTeaser.includes(brand);
  }

  isTeaserPromotionRunning() {
    this.resellerCode = this.configService.config.resellerCode;
    let brand = this.resellerCode.toUpperCase;
    this.isBrandEligible = this.isBrandTeaserEligible(brand);
  }
}
