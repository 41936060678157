import { Component, Input, Output, EventEmitter } from '@angular/core';
import { trigger, state, style, animate, transition } from '@angular/animations';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';

@Component({
  selector: 'app-favourite-name',
  templateUrl: './favourite-name.component.html',
  styleUrls: ['./favourite-name.component.scss'],
  animations: [
    trigger('visabilityState', [
      state('1', style({ opacity: 1, transform: 'scale(1.0)', display: 'block' })),
      state('0', style({ opacity: 0, transform: 'scale(0.6)', display: 'none' })),
      transition('1 => 0', animate('100ms ease-in')),
      transition('0 => 1', animate('150ms ease-in'))
    ])
  ]
})
export class FavouriteNameComponent {
  @Input() show: boolean;
  @Input() model: string;
  @Output() cancelClick = new EventEmitter();
  @Output() saveClick = new EventEmitter<string>();
  form: UntypedFormGroup;

  constructor(private fb: UntypedFormBuilder) {
    this.createForm();
  }

  public cancel() {
    this.cancelClick.emit();
  }

  public save(name: string) {
    this.saveClick.emit(name);
  }

  createForm() {
    this.form = this.fb.group({
      favName: ['', Validators.required]
    });
  }

}
