<div class="submit-identity">
  <div class="container">
    <div *ngIf="!idNowVisible" class="verification-id-container text-center">
      <div class="row justify-content-center">
        <div class="col-sm-12">
          <svg class="mx-auto d-block icon text-primary mb-4" width="135" height="101" viewBox="0 0 135 101" fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <circle cx="66" cy="56" r="45" class="circle-light" />
            <circle cx="121" cy="20" r="14" class="circle-light" />
            <circle cx="9" cy="72" r="9" class="circle-light" />
            <circle cx="97.5" cy="6.5" r="6.5" class="circle-light" />
            <path fill-rule="evenodd" clip-rule="evenodd"
              d="M67.0001 49.3C69.944 49.3 72.7673 48.1305 74.849 46.0489C76.9306 43.9672 78.1001 41.1439 78.1001 38.2C78.1001 35.2561 76.9306 32.4328 74.849 30.3511C72.7673 28.2695 69.944 27.1 67.0001 27.1C64.0562 27.1 61.2329 28.2695 59.1512 30.3511C57.0696 32.4328 55.9001 35.2561 55.9001 38.2C55.9001 41.1439 57.0696 43.9672 59.1512 46.0489C61.2329 48.1305 64.0562 49.3 67.0001 49.3ZM41.1001 82.6C41.1001 79.1988 41.77 75.8308 43.0716 72.6885C44.3732 69.5462 46.281 66.691 48.686 64.2859C51.0911 61.8809 53.9463 59.9731 57.0886 58.6715C60.2309 57.3699 63.5989 56.7 67.0001 56.7C70.4013 56.7 73.7693 57.3699 76.9116 58.6715C80.0539 59.9731 82.9091 61.8809 85.3142 64.2859C87.7192 66.691 89.627 69.5462 90.9286 72.6885C92.2302 75.8308 92.9001 79.1988 92.9001 82.6H41.1001Z"
              fill="currentColor" />
            <line x1="12.5" y1="44.5" x2="121.5" y2="44.5" stroke="currentColor" stroke-linecap="round" />
          </svg>

          <h4 class="m-0" data-test-id="identification-submit-video-title">
            {{'Validate_Id_Now_Title' | translate }}</h4>

          <div class="text-center">
            <p class="m-3" data-test-id="verification-id-legal-text">
              {{'Validate_Id_Now_Legal_Text' | translate }}
            </p>

            <div class="button-container">
              <a class="btn btn-primary" id="redirectUrl" role="button" (click)="beginValidationClick()">
                {{'ID_Verification_Start_Button' | translate }}
              </a>
            </div>

            <div class="text-center d-flex justify-content-center mt-3 mb-0">
              <p class="m-0 d-flex"><span class="poweredBy">{{'Powered_By' | translate}}</span>
                <img class=" mx-auto d-block idnow" alt="idnow-logo" src="./assets/identification/id-now-logo.svg">
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="idNowVisible" class="idNowIFrame">
      <iframe *ngIf="redirectUrl" [src]="sanitizedRedirectUrl" #idnowIframe class="w-100" height="700px"
        style="border: 0; overflow-y: none;" scrolling="no" allow="camera *; microphone *" allowfullscreen
        (load)="onIFrameLoad()" id="idnowIFrame"></iframe>
    </div>
  </div>
</div>