import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BaseHttpService } from '@shared/services/base-http-service.service';
import { ConfigService } from '@shared/services/config.service';
import { TranslateService } from '@ngx-translate/core';
import { RedirectContext } from '@shared/models';
import { catchError, map } from 'rxjs/operators';
import { Observable, throwError } from 'rxjs';

@Injectable()
export class RedirectService extends BaseHttpService {

    constructor(
        httpClient: HttpClient,
        configService: ConfigService,
        translateService: TranslateService) {
        super(httpClient, configService, translateService);
    }

    public getRedirectData(cacheKey: string)
        : Observable<any> {
        return this.getBaseResponse<any>(/redirect/ + cacheKey, null)
            .pipe(
                map(res => res),
                catchError(res => throwError(res))
            );
    }

    public mapRedirectData(result: any) {
        const context = new RedirectContext();
        if (result.CacheData) {
            context.CountryISOId = result.CacheData.CountryISOId ? result.CacheData.CountryISOId : null;
            context.LanguageISOId = result.CacheData.LanguageISOId ? result.CacheData.LanguageISOId : null;
            context.VIN = result.CacheData.VIN ? result.CacheData.VIN : null;
            context.VehicleName = result.CacheData.VehicleName ? result.CacheData.VehicleName : null;
            context.Email = result.CacheData.Email ? result.CacheData.Email : null;
            context.Salutation = result.CacheData.Salutation ? result.CacheData.Salutation : null;
            context.FirstName = result.CacheData.FirstName ? result.CacheData.FirstName : null;
            context.LastName = result.CacheData.LastName ? result.CacheData.LastName : null;
            context.Country = result.CacheData.Country ? result.CacheData.Country : null;
            context.BirthDate = (result.CacheData.BirthDate && !isNaN(Date.parse(result.CacheData.BirthDate))) ? result.CacheData.BirthDate : null;
            context.CareOf = result.CacheData.CareOf ? result.CacheData.CareOf : null;
            context.Street = result.CacheData.Street ? result.CacheData.Street : null;
            context.HouseNumber = result.CacheData.HouseNumber ? result.CacheData.HouseNumber : null;
            context.City = result.CacheData.City ? result.CacheData.City : null;
            context.Zip = result.CacheData.Zip ? result.CacheData.Zip : null;
            context.PhoneMobile = result.CacheData.PhoneMobile ? result.CacheData.PhoneMobile : null;
        }

        context.RedirectType = result.RedirectType;

        return context;
    }
}
