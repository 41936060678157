import { Injectable } from '@angular/core';
import { LanguageService } from './language.service';

@Injectable()
export class PriceFormatLocalisationService {


    constructor(private languageService: LanguageService) {

    }

    getCultureCode = (countryCode: string) => this.languageService.getActiveLanguage().isoCode + "-" + countryCode;

    getLocalisedPrice = (countryCultureString: string, price: string) => parseFloat(price).toLocaleString(countryCultureString, { minimumFractionDigits: 2 })
}