<div>
    <app-step [steps]="steps"></app-step>

    <div class="container">
        <div class="mt-4 mb-3 text-md-center">
            <h4>
                {{'SSO_Confirm_Account_Details' | translate }}
            </h4>
            <p class="m-0">
                {{'SSO_Before_Proceeding_Please_Verify_Your_Personal_Information' | translate }}
            </p>
        </div>

        <ngb-accordion #a="ngbAccordion" activeIds="account-details" >
            <ngb-panel id="account-details">
              <ng-template ngbPanelHeader let-opened="opened" class="p-0">
                <button ngbPanelToggle class="w-100 accordion-btn pt-3 pb-3 pl-0 pr-0 p-md-3 d-flex align-items-center justify-content-between">
                    <div class="d-flex">
                        <span class="font-size-18">
                            {{'SSO_Step_Tab_Name' | translate:{number: 1} }}
                            
                            {{'SSO_Personal_Details_Title' | translate }}
                        </span>

                        <div class="ml-2">
                            <svg  *ngIf="!registrationForm.valid; else checkedIcon" width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M12 22.5246C17.5228 22.5246 22 18.0474 22 12.5246C22 7.00175 17.5228 2.5246 12 2.5246C6.47715 2.5246 2 7.00175 2 12.5246C2 18.0474 6.47715 22.5246 12 22.5246Z" 
                                    stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-dasharray="4 4"/>
                            </svg>

                            <ng-template #checkedIcon>
                                <svg class="text-success" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path fill-rule="evenodd" clip-rule="evenodd" 
                                        d="M12 21.6C14.5461 21.6 16.9879 20.5886 18.7882 18.7882C20.5886 16.9879 21.6 14.5461 21.6 12C21.6 9.45392 20.5886 7.01212 18.7882 5.21177C16.9879 3.41142 14.5461 2.39999 12 2.39999C9.45395 2.39999 7.01215 3.41142 5.2118 5.21177C3.41145 7.01212 2.40002 9.45392 2.40002 12C2.40002 14.5461 3.41145 16.9879 5.2118 18.7882C7.01215 20.5886 9.45395 21.6 12 21.6ZM16.4484 10.4484C16.667 10.2221 16.788 9.91895 16.7852 9.60431C16.7825 9.28968 16.6563 8.9887 16.4338 8.76621C16.2113 8.54372 15.9103 8.41752 15.5957 8.41478C15.2811 8.41205 14.9779 8.533 14.7516 8.75159L10.8 12.7032L9.24842 11.1516C9.0221 10.933 8.71898 10.8121 8.40434 10.8148C8.08971 10.8175 7.78873 10.9437 7.56624 11.1662C7.34375 11.3887 7.21755 11.6897 7.21482 12.0043C7.21208 12.3189 7.33303 12.6221 7.55162 12.8484L9.95162 15.2484C10.1767 15.4734 10.4818 15.5997 10.8 15.5997C11.1182 15.5997 11.4234 15.4734 11.6484 15.2484L16.4484 10.4484Z" 
                                        fill="currentColor"/>
                                </svg>
                            </ng-template>
                        </div>
                    </div>
                    <span class="accordion-icon" [ngClass]="{'rotated': opened}">
                        <svg width="17" height="11" viewBox="0 0 17 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M15.0399 10.4713L8.91992 4.35125L2.79992 10.4713L0.919922 8.57792L8.91992 0.577918L16.9199 8.57792L15.0399 10.4713Z" fill="currentColor"/>
                        </svg>                        
                    </span>
                </button>
              </ng-template>
              <ng-template ngbPanelContent>
                <form class="mt-4" [formGroup]="registrationForm" novalidate>
                    <div class="row mb-3 p-0 p-md-3">
                        <div class="col-12 col-md-6">
                            <!-- First name -->
                            <div class="form-group">
                                <label data-test-id="registration-form-firstName-txt">{{'Form_First_Name' | translate
                                    }}</label><label class="text-danger ml-1">*</label>
                                <input type="text" class="form-control" formControlName="firstName"
                                    data-test-id="registration-form-firstName" autofocus>
                                <span data-test-id="registration-form-firstName-error" class="invalid-note"
                                    *ngIf="!registrationForm.controls['firstName'].pristine && registrationForm.controls['firstName'].hasError('required')">
                                    {{'Form_First_Name_Error' | translate }}
                                </span>
                                <span data-test-id="registration-form-firstName-max-characters-error" class="invalid-note"
                                    *ngIf="!registrationForm.controls['firstName'].pristine && registrationForm.controls['firstName'].hasError('maxlength')">
                                    {{'Form_First_Name_Max_Characters_Error' | translate }}
                                </span>
                            </div>
                        </div>
        
                        <div class="col-12 col-md-6">
                            <!-- Last name -->
                            <div class="form-group">
                                <label data-test-id="registration-form-lastName-txt">{{'Form_Last_Name' | translate
                                    }}</label><label class="text-danger ml-1">*</label>
                                <input type="text" class="form-control" formControlName="lastName"
                                    data-test-id="registration-form-lastName">
                                <span data-test-id="registration-form-lastName-error" class="invalid-note"
                                    *ngIf="!registrationForm.controls['lastName'].pristine && registrationForm.controls['lastName'].hasError('required')">
                                    {{'Form_Last_Name_Error' | translate }}
                                </span>
                                <span data-test-id="registration-form-lastName-max-characters-error" class="invalid-note"
                                    *ngIf="!registrationForm.controls['lastName'].pristine && registrationForm.controls['lastName'].hasError('maxlength')">
                                    {{'Form_Last_Name_Max_Characters_Error' | translate }}
                                </span>
                            </div>
                        </div>
                        <div class="col-12 col-md-6">
                            <!-- Email -->
                            <div class="form-group">
                                <label data-test-id="registration-form-email-txt">{{'Email_Address' | translate }}</label><label
                                    class="text-danger ml-1">*</label>
                                <input disabled type="text" class="form-control" formControlName="email"
                                    data-test-id="registration-form-email" >
                                <div *ngIf="registrationForm.controls['email'].errors">
                                    <span data-test-id="registration-form-email-error-required" class="invalid-note"
                                        *ngIf="!registrationForm.controls['email'].pristine && registrationForm.controls['email'].errors.required">{{'Email_Required_Error'
                                        | translate }}</span>
                                    <span data-test-id="registration-form-email-error-invalid" class="invalid-note"
                                        *ngIf="!registrationForm.controls['email'].pristine && registrationForm.controls['email'].errors.email && !registrationForm.controls['email'].errors.required">
                                        {{'Email_Invalid_Error' | translate }}
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 col-md-6">
                            <!-- Country -->
                            <div class="form-group">
                                <label data-test-id="account-identification-form-country">{{'Form_Country' | translate
                                    }}</label><label class="text-danger ml-1">*</label>
                                <select class="form-control form-select" id="defaultCountryCode" formControlName="countryCode"
                                    (change)="onCountryChoice($event.target.value)"
                                    data-test-id="account-identification-form-country-select">
                                    <option *ngFor="let c of countries" [value]="c.countryCode"
                                        [attr.data-test-id]="'account-identification-form-country-option-' + c.countryCode">
                                        {{c.friendlyName | translate }}</option>
                                </select>
                                <span data-test-id="account-identification-form-countrycode-error" class="invalid-note"
                                    *ngIf="!registrationForm.controls['countryCode'].pristine && registrationForm.controls['countryCode'].errors">{{'Form_Country_Error'
                                    |
                                    translate }}</span>
                            </div>
                        </div>
                        <div class="col-12 col-md-6">
                            <!-- DateOfBirth -->
                            <div class="form-group" *ngIf="showDateOfBirth">
                                <label data-test-id="account-identification-form-dateofbirth">{{'Date_Of_Birth' | translate
                                    }}</label><label class="text-danger ml-1">*</label>
                                <div class="row">
                                    <div class="col-3">
                                        <select class="form-control" formControlName="dobDay"
                                            data-test-id="account-identification-form-dateofbirth-day-select">
                                            <option value="" disabled selected>{{'Register_Address_Day' | translate
                                                }}</option>
                                            <option *ngFor="let d of days" [value]="d">{{d}}
                                            </option>
                                        </select>
                                    </div>
                                    <div class="col-5">
                                        <select class="form-control" formControlName="dobMonth"
                                            data-test-id="account-identification-form-dateofbirth-month-select">
                                            <option value="" disabled selected>{{'Register_Address_Month' | translate
                                                }}</option>
                                            <option *ngFor="let m of months" [value]="m.key">
                                                {{m.value}}</option>
                                        </select>
                                    </div>
                                    <div class="col-4">
                                        <select class="form-control" formControlName="dobYear"
                                            data-test-id="account-identification-form-dateofbirth-year-select">
                                            <option value="" disabled selected>{{'Register_Address_Year' | translate
                                                }}</option>
                                            <option *ngFor="let y of years" [value]="y">{{y}}
                                            </option>
                                        </select>
                                    </div>
                                </div>
                                <span data-test-id="account-identification-form-dob-error" class="invalid-note" *ngIf="(!registrationForm.controls['dobMonth'].pristine && registrationForm.controls['dobMonth'].errors) || (!registrationForm.controls['dobDay'].pristine && registrationForm.controls['dobDay'].errors) 
                                || (!registrationForm.controls['dobYear'].pristine && registrationForm.controls['dobYear'].errors)">
                                    {{'Form_DateOfBirth_Error' | translate }}
                                </span>
                            </div>
                        </div>
                    </div>
                </form>
              </ng-template>
            </ngb-panel>
            <ngb-panel id="address-details" >
                <ng-template ngbPanelHeader let-opened="opened" class="p-0">
                  <button ngbPanelToggle class="w-100 accordion-btn pt-3 pb-3 pl-0 pr-0 p-md-3 d-flex align-items-center justify-content-between">
                    <div class="d-flex">
                        <span class="font-size-18">
                            {{'SSO_Step_Tab_Name' | translate:{number: 2} }}

                            {{'SSO_Address_Title' | translate }}
                        </span>

                        <div class="ml-2">
                            <svg *ngIf="!addressForm.valid; else checkedIcon" width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M12 22.5246C17.5228 22.5246 22 18.0474 22 12.5246C22 7.00175 17.5228 2.5246 12 2.5246C6.47715 2.5246 2 7.00175 2 12.5246C2 18.0474 6.47715 22.5246 12 22.5246Z" 
                                    stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-dasharray="4 4"/>
                            </svg>

                            <ng-template #checkedIcon>
                                <svg class="text-success" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path fill-rule="evenodd" clip-rule="evenodd" 
                                        d="M12 21.6C14.5461 21.6 16.9879 20.5886 18.7882 18.7882C20.5886 16.9879 21.6 14.5461 21.6 12C21.6 9.45392 20.5886 7.01212 18.7882 5.21177C16.9879 3.41142 14.5461 2.39999 12 2.39999C9.45395 2.39999 7.01215 3.41142 5.2118 5.21177C3.41145 7.01212 2.40002 9.45392 2.40002 12C2.40002 14.5461 3.41145 16.9879 5.2118 18.7882C7.01215 20.5886 9.45395 21.6 12 21.6ZM16.4484 10.4484C16.667 10.2221 16.788 9.91895 16.7852 9.60431C16.7825 9.28968 16.6563 8.9887 16.4338 8.76621C16.2113 8.54372 15.9103 8.41752 15.5957 8.41478C15.2811 8.41205 14.9779 8.533 14.7516 8.75159L10.8 12.7032L9.24842 11.1516C9.0221 10.933 8.71898 10.8121 8.40434 10.8148C8.08971 10.8175 7.78873 10.9437 7.56624 11.1662C7.34375 11.3887 7.21755 11.6897 7.21482 12.0043C7.21208 12.3189 7.33303 12.6221 7.55162 12.8484L9.95162 15.2484C10.1767 15.4734 10.4818 15.5997 10.8 15.5997C11.1182 15.5997 11.4234 15.4734 11.6484 15.2484L16.4484 10.4484Z" 
                                        fill="currentColor"/>
                                </svg>
                                    
                            </ng-template>
                        </div>
                    </div>
                      <span class="accordion-icon" [ngClass]="{'rotated': opened}">
                        <svg width="17" height="11" viewBox="0 0 17 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M15.0399 10.4713L8.91992 4.35125L2.79992 10.4713L0.919922 8.57792L8.91992 0.577918L16.9199 8.57792L15.0399 10.4713Z" fill="currentColor"/>
                        </svg>                        
                    </span>
                  </button>
                </ng-template>
                <ng-template ngbPanelContent>
                    <form class="mt-4" [formGroup]="addressForm" novalidate>
                        <div class="row mb-3 p-0 p-md-3">
                            <div class="col-12 col-md-6">
                                <!-- Street Address 1 -->
                                <div class="form-group">
                                    <label data-test-id="account-identification-form-address1">{{'Form_Address' | translate
                                        }}</label><label class="text-danger ml-1">*</label>
                                    <input type="text" class="form-control" formControlName="streetAddress1"
                                        data-test-id="account-identification-form-address1-input" autofocus>
                                    <span data-test-id="account-identification-form-address1-error" class="invalid-note"
                                        *ngIf="!addressForm.controls['streetAddress1'].pristine && addressForm.controls['streetAddress1'].hasError('required')">{{'Form_Address1_Error'
                                        | translate }}</span>
                                    <span data-test-id="account-identification-form-address1-max-characters-error"
                                        class="invalid-note"
                                        *ngIf="!addressForm.controls['streetAddress1'].pristine && addressForm.controls['streetAddress1'].hasError('maxlength')">{{'Form_Address1_Max_Characters_Error'
                                        | translate }}</span>
                                </div>
                            </div>
            
                            <div class="col-12 col-md-6">
                                <!-- Street Addres 2 -->
                                <div class="form-group">
                                    <label data-test-id="account-identification-form-address2">{{'Form_Additional_Address' |
                                        translate }}</label>
                                    <input type="text" class="form-control" formControlName="streetAddress2"
                                        data-test-id="account-identification-form-address2-input">
                                </div>
                            </div>
                            <div class="col-12 col-md-6">
                                <!-- City -->
                                <div class="form-group">
                                    <label data-test-id="account-identification-form-city">{{'Form_City' | translate
                                        }}</label><label class="text-danger ml-1">*</label>
                                    <input type="text" class="form-control" formControlName="city"
                                        data-test-id="account-identification-form-city-input">
                                    <span data-test-id="account-identification-form-city-error" class="invalid-note"
                                        *ngIf="!addressForm.controls['city'].pristine && addressForm.controls['city'].hasError('required')">{{'Form_City_Error'
                                        |
                                        translate }}</span>
                                    <span data-test-id="account-identification-form-city-max-characters-error" class="invalid-note"
                                        *ngIf="!addressForm.controls['city'].pristine && addressForm.controls['city'].hasError('maxlength')">{{'Form_City_Max_Characters_Error'
                                        | translate }}</span>
                                </div>
                            </div>
                            <div class="col-12 col-md-6">
                                <!-- State -->
                                <div class="form-group">
                                    <label data-test-id="account-identification-form-state">{{'Form_State' | translate
                                        }}</label><label class="text-danger ml-1">*</label>
                                    <input type="text" class="form-control" formControlName="state"
                                        data-test-id="account-identification-form-state-input">
                                    <span data-test-id="account-identification-form-state-error" class="invalid-note"
                                        *ngIf="!addressForm.controls['state'].pristine && addressForm.controls['state'].hasError('required')">{{'Form_State_Error'
                                        |
                                        translate }}</span>
                                    <span data-test-id="account-identification-form-state-max-characters-error" class="invalid-note"
                                        *ngIf="!addressForm.controls['state'].pristine && addressForm.controls['state'].hasError('maxlength')">{{'Form_State_Max_Characters_Error'
                                        | translate }}</span>
                                </div>
                            </div>
                            <div class="col-12 col-md-6">
                                <!-- PostCode -->
                                <div class="form-group">
                                    <label data-test-id="account-identification-form-postcode">{{'Form_Postcode' | translate
                                        }}</label><label class="text-danger ml-1">*</label>
                                    <input type="text" class="form-control" formControlName="postCode"
                                        data-test-id="account-identification-form-postcode-input">
                                    <span data-test-id="account-identification-form-postcode-error" class="invalid-note"
                                        *ngIf="!addressForm.controls['postCode'].pristine && addressForm.controls['postCode'].hasError('required')">{{'Form_Postcode_Error'
                                        | translate }}</span>
                                    <span data-test-id="account-identification-form-postcode-min-characters-error"
                                        class="invalid-note"
                                        *ngIf="!addressForm.controls['postCode'].pristine && addressForm.controls['postCode'].hasError('minlength')">{{'Form_Postcode_Min_Characters_Error'
                                        | translate }}</span>
                                    <span data-test-id="account-identification-form-postcode-max-characters-error"
                                        class="invalid-note"
                                        *ngIf="addressForm.controls['postCode'].hasError('maxlength')">{{'Form_Postcode_Max_Characters_Error'
                                        | translate
                                        }}</span>
                                </div>
                            </div>
                        </div>
                    </form>
                </ng-template>
            </ngb-panel>
        </ngb-accordion>

        <div class="mt-4">
            <form [formGroup]="termsAndConditonsForm" novalidate>
                <div class="pl-0 pl-md-3">
                    <!-- Terms and conditions -->
                    <div class="custom-control custom-checkbox form-group"
                        [ngClass]="{'invalid' : termsAndConditonsForm.controls['tcAccepted'].errors && !termsAndConditonsForm.controls['tcAccepted'].pristine}">
                        <input role="button" data-test-id="registration-form-tcAccepted" type="checkbox"
                            class="custom-control-input" formControlName="tcAccepted" id="tcAccepted">
                        <label role="button" data-test-id="registration-form-tcAccepted-label"
                            class="custom-control-label small-label " for="tcAccepted"
                            [innerHTML]="'Registration_RegistrationForm_TC' | translate: {link: ('/termsAndConditions' | localize), contractLink: ('/contractualSummary' | localize)}">
                        </label>
                    </div>
                    <!-- Promotions -->
                    <div class="custom-control custom-checkbox registration-form">
                        <input role="button" data-test-id="registration-form-promoAccepted" type="checkbox"
                            formControlName="promoAccepted" class="custom-control-input" id="promoAccepted">
                        <label role="button" data-test-id="registration-form-promoAccepted-label"
                            class="custom-control-label small-label"
                            for="promoAccepted">{{'Registration_RegistrationForm_Promo' | translate }}</label>
                    </div>
                </div>
            </form>
        </div>
        <div class="d-flex flex-row justify-content-center button-container">
            <button type="submit" class="btn btn-primary" [disabled]="!registrationForm.valid || !addressForm.valid || !termsAndConditonsForm.valid"
            (click)="confirm()">
                {{'Confirm_Button' | translate }}
            </button>
        </div>

        <app-partnership></app-partnership>
        
    </div>
</div>