export class SubmitVideoValidation {
    public identityId: string;
    public redirectUrl: string;
    public providerReference: string;
    public reference: string;
    public submissionStatus: IdentificationSubmissionStatus;
}
export enum IdentificationSubmissionStatus {
    Success,
    FailedSubmission,
    Blocked
}
