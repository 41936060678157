
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BaseHttpService, ConfigService } from '@shared/services';
import { ICreateOrderRequest, IGetOrderResponse } from '../_models';
import { Observable } from 'rxjs';
import { BaseResponse, ResponseError } from '@shared/models';
import { map } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class OrdersService extends BaseHttpService {

    createOrderUrl = '/orders/create';
    getOrderUrl = '/orders/{orderNumber}';

    constructor(
        _httpClient: HttpClient,
        _configService: ConfigService,
        _translateService: TranslateService,
    ) {
        super(_httpClient, _configService, _translateService);
    }

    /* GET */
    // Should return order response
    getOrder(orderNumber: string): Observable<IGetOrderResponse | ResponseError> {
        return this.get<IGetOrderResponse>(this.getOrderUrl.replace('{orderNumber}', orderNumber));
    }

    /* POST */
    // If success, should return orderNumber
    createOrder(request: ICreateOrderRequest)
        : Observable<BaseResponse<any | ResponseError>> {
        return this.post<any>(this.createOrderUrl, request)
            .pipe(
                map(res => res)
            );
    }

}
