import { AccountAddress } from '../_models';

export class UpdatePersonalDetails {
    public firstName: string;
    public lastName: string;
    public address: AccountAddress;
    public email: string;
    public contactNumber: string;
    public contactNumberCountryCode: number;
    public isoCode: string;
    public hasAddressBeenSaved: boolean;
    public showDateOfBirth: boolean;
    public dateOfBirth: string;
}
