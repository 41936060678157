import { ModuleWithProviders, NgModule } from '@angular/core';
import { ArrayKeyPairPipePipe } from './array-key-pair-pipe.pipe';

@NgModule({
    imports: [],
    declarations: [ArrayKeyPairPipePipe],
    exports: [ArrayKeyPairPipePipe]
})

export class PipeModule {

    static forRoot(): ModuleWithProviders<PipeModule> {
        return {
            ngModule: PipeModule,
            providers: []
        };
    }
}
