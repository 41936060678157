import { Component, Input } from '@angular/core';
import { Product } from '../../product/_models/product';
import { NgxSpinnerService } from 'ngx-spinner';
import { LanguageService } from '@shared/services';

@Component({
  selector: 'app-product-card',
  templateUrl: './product-card.component.html',
  styleUrls: ['./product-card.component.scss']
})
export class ProductCardComponent {

  // duration unit - Day
  static readonly durationUnitDay: string = 'DAY';

  @Input() public product: Product;
  @Input() public vin: string;
  @Input() enableBuyPlan: boolean;
  @Input() loading: boolean;

  constructor(
    private languageService: LanguageService,
    private spinnerService: NgxSpinnerService
  ) { }

  get staticDurationUnitDay() {
    return ProductCardComponent.durationUnitDay;
  }

  goToCheckout() {
    this.spinnerService.show();
    this.languageService.localizedNavigate(['/dashboard/plan/checkout', this.vin, this.product.packageCode]);
  }
}
