import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { AlertType, ResponseError, UserContext } from '@shared/models';
import { ActivatedRoute, Params } from '@angular/router';
import { AuthenticationService, LanguageService, RedirectContextService, UserContextService } from '@shared/services';
import { LoginAlert } from '@shared/models/login-alert';
import { SSOToken } from './_models/sso-token';
import { SSOService } from './_services/sso.service';
import { Vehicle, VehicleInfomationRequest } from '@app/vehicles/_models';
import { VehicleService } from '@app/vehicles/_services';

@Component({
  selector: 'app-sso-authentication',
  template: '',
})
export class SSOAuthenticationComponent implements OnInit {

  constructor(
    private ssoService: SSOService,
    private spinnerService: NgxSpinnerService,
    private activatedRoute: ActivatedRoute,
    private languageService: LanguageService,
    private redirectContextService: RedirectContextService,
    private userContextService: UserContextService,
    private authService: AuthenticationService,
    private vehicleService: VehicleService) {
  }

  ngOnInit() {
    this.activatedRoute.queryParams.subscribe((params: Params) => {
      if (params['state'] !== null && params['code'] !== undefined) {
        const state = params['state'];
        const code = params['code'];
        this.spinnerService.show();

        this.ssoService.getToken(state, code)
          .subscribe({
            next: (ssoToken: SSOToken) => {
              //If not register send to confirm details
              if(!ssoToken.isRegistered) {
                this.spinnerService.hide();
                this.goToConfirmDetails();
              } else {
                this.setupUserData(ssoToken);

                const cachedVin = this.getCachedVin();
                if(cachedVin) {
                  //Check if cachedVin is already added to the current user
                  const request = new VehicleInfomationRequest;
                  request.identityId = ssoToken.loginDetails.identityId;

                  this.vehicleService.getVehicles(request)
                    .subscribe({
                      next: (vehicles: Vehicle[]) => {
                        this.spinnerService.hide();

                        if(vehicles) {
                          const isCachedVinAdded = vehicles.some(x => x.vin === cachedVin);
                          if(isCachedVinAdded) {
                            this.goToDashboard();
                          } else {
                            this.goToConfirmDetails();
                          }
                        } else {
                          this.goToConfirmDetails();
                        }
                      },
                      error: () => {
                        this.goToDashboard();
                      }
                    });
                } else {
                  this.spinnerService.hide();
                  this.goToDashboard();
                }
              }
            },
            error: (err: ResponseError) => {
              this.spinnerService.hide();
              const apiAlert = new LoginAlert(AlertType.Error, err.message);
              this.goToLogin(apiAlert);
            }
          });
      } else {
        const noParametersAlert = new LoginAlert(AlertType.Error, 'No valid paramteres found in the url');
        this.goToLogin(noParametersAlert);
      }
    });
  }

  private goToLogin(loginAlert: LoginAlert) {
    this.languageService.localizedNavigate(['/login'], { state: { alert: loginAlert } });
  }

  private goToDashboard() {
    this.languageService.localizedNavigate(['/dashboard']);
  }

  private goToConfirmDetails() {
    this.languageService.localizedNavigate(['/ssoAuth/confirmDetails'], { state: { isUserTokenValidated: true } });
  }

  private setupUserData(ssoToken: SSOToken) {
    const isSSOUser = true;
    ssoToken.loginDetails.token = ssoToken.token;
    this.authService.setAuthenticatedUser(ssoToken.loginDetails, isSSOUser);

    const userContext = new UserContext();
    userContext.firstName = ssoToken.loginDetails.firstName;
    userContext.lastName = ssoToken.loginDetails.lastName;
    userContext.userId = ssoToken.loginDetails.userId;
    userContext.homeCountryCode = ssoToken.loginDetails.homeCountry ? ssoToken.loginDetails.homeCountry.countryCode : null;
    userContext.isSSOUser = isSSOUser;
    this.userContextService.setUserContext(userContext);
  }

  private getCachedVin() {
    let cachedVin = null;
    if (this.redirectContextService.context) {
      const vehicle = this.redirectContextService.mapRedirectToVehicle();
      if (vehicle && vehicle.vin) {
        cachedVin = vehicle.vin;
      }
    }

    return cachedVin;
  }
}
