import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BaseHttpService, ConfigService } from '@shared/services';
import { ResponseError } from '@shared/models';
import { Observable } from 'rxjs';
import { LanguageService } from '@shared/services/language.service';
import { TranslateService } from '@ngx-translate/core';
import { ContentResponse } from '@shared/models/content-response';

@Injectable()
export class ContentService extends BaseHttpService {
  constructor(
    httpClient: HttpClient,
    translateService: TranslateService,
    configService: ConfigService,
    private languageService: LanguageService
  ) {
    super(httpClient, configService, translateService);
  }

  public getContent(contentType: string, language?: string): Observable<ContentResponse | ResponseError> {
    const lang = !language ? this.languageService.getActiveLanguage().isoCode : language;
    return this.get<ContentResponse>(`/content/${contentType}/${lang}`);
  }
}
