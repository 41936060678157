import { Directive, ElementRef, EventEmitter, HostListener, Inject, Input, OnInit, Output, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';

declare const window: any;
const defaultUtils = require('intl-tel-input/build/js/utils.js');

//This directive is wrapper of https://github.com/jackocnr/intl-tel-input
@Directive({
    selector: '[appNgxTelInput]',
})
export class NgxTelInputDirective implements OnInit {
    @Input() ngxTelInputOptions: { [key: string]: any } = {};
    @Output() hasError: EventEmitter<boolean> = new EventEmitter();
    @Output() ngxTelOutput: EventEmitter<any> = new EventEmitter();
    @Output() countryChange: EventEmitter<any> = new EventEmitter();
    @Output() intlTelInputObject: EventEmitter<any> = new EventEmitter();

    ngTelInput: any;

    constructor(private el: ElementRef,
        @Inject(PLATFORM_ID) private platformId: string) {
    }

    ngOnInit() {
        if (isPlatformBrowser(this.platformId)) {
            this.ngxTelInputOptions = {
                ...this.ngxTelInputOptions,
                utilsScript: this.getUtilsScript(this.ngxTelInputOptions)
            };
            this.ngTelInput = window.intlTelInput(this.el.nativeElement, {
                ...this.ngxTelInputOptions
            });

            this.el.nativeElement.addEventListener("countrychange", () => {
                this.countryChange.emit(this.ngTelInput.getSelectedCountryData());
            });

            this.intlTelInputObject.emit(this.ngTelInput);
        }
    }

    @HostListener('blur') onBlur() {
        let isInputValid: boolean = this.isInputValid();
        if (isInputValid) {
            let telOutput = this.ngTelInput.getNumber();
            this.hasError.emit(isInputValid);
            this.ngxTelOutput.emit(telOutput);
        } else {
            this.hasError.emit(isInputValid);
        }
    }

    isInputValid(): boolean {
        return this.ngTelInput.isValidNumber();
    }

    setCountry(country: any) {
        this.ngTelInput.setCountry(country);
    }

    getUtilsScript(options: any) {
        return options.utilsScript || defaultUtils;
    }
}