<span data-test-id="vehicle-wallet-item-active" class="d-block"
  *ngIf="plan.isActive === true">{{'Wallet_Item_Active_Plan' | translate}}</span>
<span data-test-id="vehicle-wallet-item-other" class="d-block"
  *ngIf="plan.isActive === false">{{'Wallet_Item_Other_Plan' | translate}}</span>
<span data-test-id="vehicle-wallet-item-planName" class="plan-duration d-block">{{plan.planName}}</span>
<label data-test-id="vehicle-wallet-item-balance" class="plan-data-details d-block">
  {{ 'Wallet_Item_Balance' | translate:{remainingBalance: (plan.remainingBalance | dataVolume), initialBalance:
  (plan.initialBalance | dataVolume)} }}
</label>
<p data-test-id="vehicle-wallet-item-remaningBalance" class="plan-bar">
  <ngb-progressbar type="primary" [value]="plan.remainingBalancePercent"></ngb-progressbar>
  <!-- <app-progress-bar [value]=[plan.remainingBalancePercent]></app-progress-bar> -->
</p>
<label data-test-id="vehicle-wallet-item-expireDate" class="plan-expire-details d-block" *ngIf="plan.isActive === true">
  {{ 'Wallet_Item_Expiry_Date' | translate:{validityDate: (plan.validityDateString)} }}
</label>