<app-browser-update-notification></app-browser-update-notification>
<app-cookie-policy></app-cookie-policy>
<nav class="navbar navbar-expand-lg justify-content-lg-center">
  <div class="container">
    <a class="navbar-brand" [routerLink]="['/' | localize]">
      <svg class="logo"></svg>
    </a>
    <!-- Mobile Button -->
    <button type="button" class="mobile-button float-right" data-toggle="collapse" data-target="#navbarNav"
      (click)="isCollapsed = !isCollapsed" aria-controls="navbarNav" [attr.aria-expanded]="!isCollapsed"
      aria-label="Toggle navigation" data-test-id="header-mobile-btn">
      <div id="nav-icon" [ngClass]="{open:!isCollapsed}">
        <span></span>
        <span></span>
        <span></span>
        <span></span>
      </div>
    </button>
    <!-- End Mobile Button -->

    <div class="collapse navbar-collapse nav-items" id="navbarNav" [ngbCollapse]="isCollapsed">
      <ul class="navbar-nav">
        <!-- Private portal -->
        <li *ngIf="appAuthUser.isAuthenticated" (click)="isCollapsed = true" class="nav-item dashboard"
          data-test-id="header-mobile-dashboard-vehicle">
          <app-icon-speedometer class="vertical-center"></app-icon-speedometer>
          <a class="nav-link standard-link" [routerLink]="['/dashboard/vehicles' | localize]">{{'Title_Dashboard' |
            translate}}</a>
        </li>
        <li *ngIf="appAuthUser.isAuthenticated" [ngClass]="{disabled: getIdentificationStatus() === false}"
          (click)="isCollapsed = true" class="nav-item dashboard" data-test-id="header-mobile-dashboard-plans">
          <app-icon-shopping-cart class="vertical-center"></app-icon-shopping-cart>
          <a class="nav-link standard-link" [routerLink]="['/dashboard/plan' | localize]">{{'Title_Shop' |
            translate}}</a>
        </li>
        <li *ngIf="appAuthUser.isAuthenticated" [ngClass]="{disabled: getIdentificationStatus() === false}"
          (click)="isCollapsed = true" class="nav-item dashboard" data-test-id="header-mobile-dashboard-history">
          <app-icon-history class="vertical-center"></app-icon-history>
          <a class="nav-link standard-link" [routerLink]="['/dashboard/history'] | localize">{{'Title_History' |
            translate}}</a>
        </li>
        <li *ngIf="appAuthUser.isAuthenticated" (click)="isCollapsed = true" class="nav-item dashboard"
          data-test-id="header-mobile-dashboard-account">
          <app-icon-silhouette class="vertical-center"></app-icon-silhouette>
          <a class="nav-link standard-link" [routerLink]="['/dashboard/account'] | localize">{{'Title_Manage_Account' |
            translate}}</a>
        </li>
        <!-- Public portal -->
        <li *ngIf="!appAuthUser.isAuthenticated" class="nav-item order-lg-1 ml-md-3">
          <div class="nav-btn-container d-flex flex-column-reverse flex-md-row">
            <button class="btn btn-outline-secondary" (click)="isCollapsed = true"
              [routerLink]="['/register' | localize]"
              data-test-id="header-register-btn">{{'Layout_Header_Register_Button' | translate }}</button>

            <button class="btn btn-primary" (click)="isCollapsed = true" [routerLink]="['/login' | localize]"
              data-test-id="header-login-btn">{{'Login' | translate }}</button>
          </div>
        </li>
        <li class="nav-item" data-test-id="header-gettingstarted-link">
          <a class="nav-link nav-link-spaced" id="gettingStartedHeader" (click)="isCollapsed = true"
            [routerLink]="['/gettingStarted' | localize]">{{'Getting_Started_SetUp' | translate }}</a>
        </li>
        <li class="nav-item" data-test-id="header-faq-link">
          <a class="nav-link nav-link-spaced" id="faqHeader" (click)="isCollapsed = true"
            [routerLink]="['/faq' | localize]">{{'Layout_Header_FAQ' | translate }}</a>
        </li>

        <!-- Language -->
        <!-- TDODO: TEMP for lang testing -->
        <li class="nav-item nav-item-dropdown" data-test-id="header-language-menu" ngbDropdown>
          <a class="nav-link nav-link-spaced" id="currentLanguageHeader" ngbDropdownToggle data-toggle="dropdown"
            aria-haspopup="true" aria-expanded="false" data-test-id="header-language-link" role='button'>
            {{currentLanguage.name}}
          </a>
          <div aria-labelledby="languageDropdown" ngbDropdownMenu class="dropdown dropdown-content">
            <a class="dropdown-item" [attr.data-test-id]="'header-language-link-' + language.isoCode" role='button'
              *ngFor="let language of filteredLanguages" (click)="changeLanguage(language)">{{language.name}}</a>
          </div>
        </li>
        <!-- User Account -->
        <li *ngIf="appAuthUser.isAuthenticated" class="nav-item dropdown no-mobile myAccountDropdown" ngbDropdown
          data-test-id="header-myaccount-menu">
          <a class="nav-link" data-test-id="userDropdown" id="userDropdown" ngbDropdownToggle data-toggle="dropdown"
            aria-haspopup="true" aria-expanded="false" role="button">
            {{appAuthUser.firstName}} {{appAuthUser.lastName}}
          </a>
          <div aria-labelledby="userDropdown" ngbDropdownMenu class="dropdown dropdown-content">
            <a class="dropdown-item" (click)="isCollapsed = true" [routerLink]="['/dashboard' | localize]"
              data-test-id="header-myaccount-link-dashboard" role="button">{{'Title_Dashboard' | translate}}</a>
            <a class="dropdown-item" (click)="logout()" data-test-id="header-myaccount-link-logout"
              role="button">{{'Layout_Header_Logout' | translate }}</a>
          </div>
        </li>
        <li *ngIf="appAuthUser.isAuthenticated" class="nav-item mobile-only" data-test-id="header-logout-link">
          <a class="nav-link nav-link-spaced" id="logoutHeaderButton" (click)="logout()"
            data-test-id="header-logout-link" role="button">{{'Layout_Header_Logout' | translate }}</a>
        </li>
      </ul>
    </div>
  </div>
</nav>