<div *ngIf="isRequestReady">
  <div class="main-content">
    <div class="container">
      <div class="reset-password-container">
        <div class="row justify-content-center">
          <div class="col-sm-12">
            <h3 class="text-center" data-test-id="reset-password-title">{{'Reset_Password' | translate}}</h3>

            <p class="reset-password-description" data-test-id="reset-password-description">
              {{'ResetPassword_Description' | translate}}
            </p>
          </div>
        </div>
        <form [formGroup]="resetPasswordForm" novalidate class="reset-password-form" data-test-id="reset-password-form">
          <!-- Password -->
          <div class="form-group">
            <app-show-hide-input>
              <label data-test-id="reset-password-newpassword-text">{{'New_Password' | translate}}</label>*
              <input type="password" class="form-control password-input" formControlName="password" placement="bottom"
                [ngbPopover]="passwordRequirementsTip" data-test-id="reset-password-form-input"
                triggers="focusin:focusout" #showhideinput>
            </app-show-hide-input>
            <ng-template #passwordRequirementsTip>
              <div class="password-errors" data-test-id="reset-password-form-password-errors">
                <div>
                  <app-tick-mark data-test-id="reset-password-validation-upper-lower"
                    [isActive]="!resetPasswordForm.controls['password'].hasError('lowerCase')  && !resetPasswordForm.controls['password'].hasError('upperCase')">
                  </app-tick-mark>
                  <span>{{'Form_UpperAndLower' | translate}}</span>
                </div>
                <div>
                  <app-tick-mark data-test-id="reset-password-validation-special-char"
                    [isActive]="!resetPasswordForm.controls['password'].hasError('numberOrSpecial')"></app-tick-mark>
                  <span>{{'Form_SpecialCharacters' | translate}}</span>
                </div>
                <div>
                  <app-tick-mark data-test-id="reset-password-validation-char-count"
                    [isActive]="!resetPasswordForm.controls['password'].hasError('minlength') && !resetPasswordForm.controls['password'].hasError('required')">
                  </app-tick-mark>
                  <span>{{'Form_NumberOfCharacters' | translate}}</span>
                </div>
              </div>
            </ng-template>
            <div *ngIf="resetPasswordForm.controls['password'].hasError('maxlength')">
              <span data-test-id="registration-form-password-max-characters-error" class="invalid-note"
                *ngIf="!resetPasswordForm.controls['password'].hasError('minlength') && !resetPasswordForm.controls['password'].hasError('required') && resetPasswordForm.controls['password'].hasError('maxlength')">The
                {{'Form_Password_Max_Characters_Error' | translate}}
              </span>
            </div>
          </div>

          <button type="submit" class="btn btn-primary btn-block" [disabled]="!resetPasswordForm.valid"
            aria-disabled="!resetPasswordForm.valid" (click)="resetPassword()"
            data-test-id="reset-password-form-btn">{{'Reset_Password_Form_Btn' | translate}}</button>
        </form>
        <div class="row justify-content-center">
          <div class="required-fields" data-test-id="reset-password-required-fields-text">
            {{'Form_Note_Required_Fields' | translate}}
          </div>
        </div>
      </div>
    </div>
  </div>
</div>