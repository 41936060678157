import { Component, OnInit } from '@angular/core';
import { IStreaming } from './entities/istreaming';
import { ThemeService } from '@shared/services';
@Component({
  selector: 'app-home-favourite-content',
  templateUrl: './favourite-content.component.html',
  styleUrls: ['./favourite-content.component.scss']
})
export class FavouriteContentComponent implements OnInit {
  serviceIcon: string = '';
  public streamingServices: IStreaming[];

  constructor(private themeService: ThemeService) { }

  ngOnInit() {
    this.streamingServices = [
      { icon: this.serviceIcon = this.themeService.assetsLocation + '/icons/favourite-content/internet.svg', name: 'Internet' },
      { icon: this.serviceIcon = this.themeService.assetsLocation + '/icons/favourite-content/news.svg', name: 'News' },
      { icon: this.serviceIcon = this.themeService.assetsLocation + '/icons/favourite-content/circled-play.svg', name: 'Streaming' },
      { icon: this.serviceIcon = this.themeService.assetsLocation + '/icons/favourite-content/music.svg', name: 'Music' },
      { icon: this.serviceIcon = this.themeService.assetsLocation + '/icons/favourite-content/game-controller.svg', name: 'Gaming' },
      { icon: this.serviceIcon = this.themeService.assetsLocation + '/icons/favourite-content/envelope.svg', name: 'Mail' }
    ];
  }

}
