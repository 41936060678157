import { Component, EventEmitter, Output, ViewChild } from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import { AccountCountryLegalRequirements, AccountIdentificationDetails, CountryLegalRequirementsResponse } from "@app/account/_models";
import { AccountService } from "@app/account/_services";
import { AccountRegistration } from "@app/registration/_models/account-registration";
import { ConfirmAccountDetails } from "@app/sso-authentication/_models/confirm-account-details";
import { SSOUser } from "@app/sso-authentication/_models/sso-user";
import { SSOService } from "@app/sso-authentication/_services/sso.service";
import { NgbAccordion } from "@ng-bootstrap/ng-bootstrap";
import { Logger } from "@shared/logging/Logger";
import { ResponseError } from "@shared/models";
import { Step } from "@shared/models/step";
import { AlertService, RedirectContextService } from "@shared/services";
import { CountriesService } from "@shared/services/countries.service";
import { DatesUtilityService } from "@shared/services/dates-utility.service";
import { CustomValidators } from "@shared/validators/custom-validators";
import { NgxSpinnerService } from "ngx-spinner";

@Component({
  selector: 'app-sso-confirm-account',
  templateUrl: './sso-confirm-account.component.html',
  styleUrls: ['./sso-confirm-account.component.scss']
})
export class SSOConfirmAccountComponent {
  steps = [
    new Step('Vehicle_Compatibility', true, false), 
    new Step('SSO_Step_Account details', true, true), 
  ];
  
  registrationForm: UntypedFormGroup;
  addressForm: UntypedFormGroup;
  termsAndConditonsForm: UntypedFormGroup;

  showDateOfBirth = false;
  countries: AccountCountryLegalRequirements[];
  // alerts
  alertInvalidRequirements = 'Account_Identification_Invalid_Requirements';
  alertInvalidDob = 'Account_Identification_Form_DateOfBirth_Invalid';
  alertVehicleNotFound = 'Vehicle_Add_Not_Found';
  days: string[] = [];
  months: any[] = [];
  years: string[] = [];

  @ViewChild(NgbAccordion) accordionComponent: NgbAccordion;
  accountDetailsAccordionTabId = 'account-details';
  addressDetailsAccordionTabId = 'address-details';

  @Output() 
  confirmClick = new EventEmitter<ConfirmAccountDetails>();

  constructor(private fb: UntypedFormBuilder,
    private spinnerService: NgxSpinnerService,
    private accountService: AccountService,
    private logger: Logger,
    private alertService: AlertService,
    private countriesService: CountriesService,
    private redirectContextService: RedirectContextService,
    private datesUtilityService: DatesUtilityService,
    private ssoService: SSOService) {
    this.days = this.datesUtilityService.populateDaysList();
    this.months = this.datesUtilityService.populateMonthsList();
    this.years = this.datesUtilityService.populateYearsList(new Date());

    this.initialize();
  }

  private initialize() {
    this.createForm();
    this.spinnerService.show();

    this.accountService.getLegalRequirementsPerCountry()
    .subscribe(
      (data: CountryLegalRequirementsResponse | ResponseError) => {
        if (data instanceof ResponseError) {
          this.handleErrorResponse(data);
          this.spinnerService.hide();
        } else {
          //Get's and translates the countries
          this.countries = this.getTranslatedCountries(data.requirements);

          this.ssoService.getUser().subscribe({
            next: (data: SSOUser | ResponseError) => {
              this.spinnerService.hide();

              if(data instanceof ResponseError) {
                return;
              }

              this.prepareFormInitialData(data);
            },
            error: () => {
              this.spinnerService.hide();
              this.prepareFormInitialData(null);
            }
          });
        }
      }
    );
  }

  prepareFormInitialData(ssoUser: SSOUser) {
    let identiticationDetails = new AccountIdentificationDetails();
    let registrationData = new AccountRegistration();
    let country: AccountCountryLegalRequirements = null;

    if (this.redirectContextService.context) {
      const cacheData = this.redirectContextService.mapRedirectToAccountIdentification();
      
      if(cacheData?.homeCountry?.countryCode) {
        country = this.countries
          .find(c => c.isoCode.toLowerCase() == cacheData.homeCountry.countryCode.toLowerCase());
      }
    }

    if(ssoUser) {
      registrationData.email = ssoUser.email;
      registrationData.firstName = ssoUser.firstName;
      registrationData.lastName = ssoUser.surname;
      
      identiticationDetails.dateOfBirth = ssoUser.birthDate;

      if(ssoUser.address) {
        identiticationDetails.address.city = ssoUser.address.locality;
        identiticationDetails.address.country = ssoUser.address.country;
        identiticationDetails.address.state = ssoUser.address.region;
        identiticationDetails.address.streetAddress1 = ssoUser.address.streetAddress;
        identiticationDetails.address.postCode = ssoUser.address.postalCode;

        country = this.countries
          .find(c => c.isoCode.toLowerCase() == ssoUser.address.country.toLowerCase());
      }
    }

    if(country) {
      identiticationDetails.address.country = country.friendlyName;
      identiticationDetails.address.countryCode = country.countryCode;
    }

    this.populateForm(registrationData, identiticationDetails);
    this.checkRegistrationForm();
    this.checkAddressForm();
  }

  createForm() {
    this.registrationForm = this.fb.group({
      firstName: ['', { validators: [Validators.required, Validators.minLength(1), Validators.maxLength(40)]}],
      lastName: ['', { validators: [Validators.required, Validators.minLength(1), Validators.maxLength(40)]}],
      email: ['', { validators: [Validators.required, Validators.email, CustomValidators.email]}],
      country: [''],
      countryCode: ['', { validators: [Validators.required]}],
      dobMonth: [''],
      dobDay: [''],
      dobYear: ['']
    });

    this.addressForm =  this.fb.group({
      streetAddress1: ['', { validators: [Validators.required, Validators.maxLength(100)]}],
      streetAddress2: [''],
      city: ['', { validators: [Validators.required, Validators.maxLength(50)]}],
      state: ['', { validators: [Validators.required, Validators.maxLength(50)]}],
      postCode: ['', { validators: [Validators.required, Validators.minLength(2), Validators.maxLength(10)]}],
    });

    this.termsAndConditonsForm =  this.fb.group({
      tcAccepted: [false, Validators.pattern('true')],
      promoAccepted: [false]
    });
  }

  onCountryChoice(countryCode: number): void {
    const country: AccountCountryLegalRequirements = this.countries.find(c => c.countryCode == countryCode);
    this.cleanCountryRequirements();
    if (country.requiresIdentificationProof) {
      this.showTrueDateOfBirth();
      this.showAccountDetailsTab();
    } else {
      this.cleanCountryRequirements();
      this.checkRegistrationForm();
    }
  }

  private checkRegistrationForm() {
    if(this.registrationForm.valid) {
      this.showAddressDetailsTab();
    }
  }

  private checkAddressForm() {
    if(this.addressForm.valid) {
      this.showAccountDetailsTab();
    }
  }


  private showAccountDetailsTab() {
    this.accordionComponent.collapse(this.addressDetailsAccordionTabId);
    if(!this.registrationForm.valid) {
      this.accordionComponent.expand(this.accountDetailsAccordionTabId);
    }
  }

  private showAddressDetailsTab() {
    this.accordionComponent.collapse(this.accountDetailsAccordionTabId);
    if(!this.addressForm.valid) {
      this.accordionComponent.expand(this.addressDetailsAccordionTabId);
    }
  }

  private showTrueDateOfBirth(): void {
    this.showDateOfBirth = true;
    this.registrationForm.get('dobDay').setValidators([Validators.required]);
    this.registrationForm.get('dobMonth').setValidators([Validators.required]);
    this.registrationForm.get('dobYear').setValidators([Validators.required]);
    this.registrationForm.get('dobDay').setValue("");
    this.registrationForm.get('dobMonth').setValue("");
    this.registrationForm.get('dobYear').setValue("");
    this.registrationForm.get('dobDay').updateValueAndValidity();
    this.registrationForm.get('dobMonth').updateValueAndValidity();
    this.registrationForm.get('dobYear').updateValueAndValidity();
  }

  private cleanCountryRequirements(): void {
    this.showDateOfBirth = false;
    this.resetRegistrationControls('dobDay');
    this.resetRegistrationControls('dobMonth');
    this.resetRegistrationControls('dobYear');
    this.registrationForm.updateValueAndValidity();
  }

  populateForm(registrationData: AccountRegistration, details: AccountIdentificationDetails) {
    this.registrationForm.controls['firstName'].setValue(registrationData.firstName);
    this.registrationForm.controls['lastName'].setValue(registrationData.lastName);
    this.registrationForm.controls['email'].setValue(registrationData.email);
    this.registrationForm.controls['email'].disable({onlySelf: !registrationData.email});

    this.registrationForm.controls['country'].setValue(details.address.country);
    this.registrationForm.controls['countryCode'].setValue(details.address.countryCode);

    if (details.address.country && details.address.countryCode) {
      this.onCountryChoice(details.address.countryCode);
    }

    if (details.dateOfBirth) {
      const date = new Date(details.dateOfBirth);

      this.registrationForm.controls['dobMonth'].setValue(('0' + (date.getUTCMonth() + 1)).slice(-2), { onlySelf: true });
      this.registrationForm.controls['dobYear'].setValue(date.getUTCFullYear(), { onlySelf: true });
      this.registrationForm.controls['dobDay'].setValue(date.getUTCDate(), { onlySelf: true });
    }

    this.addressForm.controls['streetAddress1'].setValue(details.address.streetAddress1);
    this.addressForm.controls['streetAddress2'].setValue(details.address.streetAddress2);
    this.addressForm.controls['city'].setValue(details.address.city);
    this.addressForm.controls['state'].setValue(details.address.state);
    this.addressForm.controls['postCode'].setValue(details.address.postCode);
  }

  private resetRegistrationControls(property: string): void {
    this.registrationForm.get(property).setErrors(null);
    this.registrationForm.get(property).clearValidators();
  }

  private handleErrorResponse(err: ResponseError) {
    this.alertService.error(err.message, 0, true, false);
    this.logger.error(err);
  }

  private getTranslatedCountries(countries: AccountCountryLegalRequirements[]) {
    return this.countriesService.translateCountries(countries);
  }

  prepareData(): ConfirmAccountDetails {
    const registrationFormModel = this.registrationForm.getRawValue();
    const addressFormModel = this.addressForm.getRawValue();
    const termsAndConditonsFormModel = this.termsAndConditonsForm.getRawValue();

    const accountDetails = new ConfirmAccountDetails();
    accountDetails.identification.address.streetAddress1 = addressFormModel.streetAddress1;
    accountDetails.identification.address.streetAddress2 = addressFormModel.streetAddress2;
    accountDetails.identification.address.city = addressFormModel.city;
    accountDetails.identification.address.state = addressFormModel.state;
    accountDetails.identification.address.postCode = addressFormModel.postCode;

    const country = this.countries.find(c => c.countryCode == registrationFormModel.countryCode)

    if (country) {
      accountDetails.identification.address.country = country.isoCode;
      accountDetails.identification.address.countryCode = country.countryCode;

      if (country.requiresIdentificationProof) {
        const day = registrationFormModel.dobDay;
        const month = registrationFormModel.dobMonth;
        const year = registrationFormModel.dobYear;
        const valid = this.datesUtilityService.validateDateOfBirth(day, month, year);
        
        if (!valid) {
          this.alertService.warn(this.alertInvalidDob, 5, true, false);
          return null;
        }
        
        accountDetails.identification.dateOfBirth = this.datesUtilityService.getFormattedDate(new Date(year, month, day));
      } else {
        accountDetails.identification.dateOfBirth = null;
      }
    }

    accountDetails.account.firstName = registrationFormModel.firstName;
    accountDetails.account.lastName = registrationFormModel.lastName;
    accountDetails.account.email = registrationFormModel.email;

    accountDetails.account.acceptedTermsAndConditions = termsAndConditonsFormModel.tcAccepted;
    accountDetails.account.promoEmailSubscribe = termsAndConditonsFormModel.promoAccepted;

    return accountDetails;
  }

  confirm() {
    const confirmDetails = this.prepareData();
    this.confirmClick.emit(confirmDetails);
  }
}