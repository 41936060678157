<ngx-spinner bdColor="rgba(0,0,0,0.6)" size="default" color="#fff" type="ball-spin" [fullScreen]="true">
</ngx-spinner>

<div class="header sticky-top">
  <app-header-component class="sticky-top"></app-header-component>
</div>
<div class="body">
  <app-floating-alert fixed></app-floating-alert>
  <router-outlet></router-outlet>
</div>
<div class="footer">
  <app-footer></app-footer>
</div>
<app-info></app-info>