import { Component, OnInit } from '@angular/core';
import { RegistrationContextService } from '@shared/services/registration-context.service';

@Component({
  selector: 'app-home-component',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  constructor(private registrationContextService: RegistrationContextService) { }

  ngOnInit() {
    this.removeRegistrationContext();
  }

  private removeRegistrationContext() {
    this.registrationContextService.resetRegistrationContext();
  }
}
