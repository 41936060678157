import { Component } from '@angular/core';
import { ThemeComponent } from '@shared/components/theme.component';
import { ThemeService } from '@shared/services/theme-service.service';

@Component({
  selector: 'app-driving-connectivity',
  templateUrl: './driving-connectivity.component.html',
  styleUrls: ['./driving-connectivity.component.scss']
})
export class DrivingConnectivityComponent extends ThemeComponent {

  // constructor() { }
  constructor(_themeService: ThemeService) {
    super(_themeService);
  }

}
