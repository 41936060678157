import { of, Observable, throwError } from 'rxjs';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { ResponseError, AppAuthUser } from '../models';
import { Vehicle, VehicleInfomationRequest } from '../../app/vehicles/_models';
import { VehicleService } from '../../app/vehicles/_services/vehicle.service';
import { AuthenticationService } from '../services/authentication.service';
import { catchError } from 'rxjs/operators';
import { LanguageService } from '@shared/services';


@Injectable()
export class VehicleResolverService {

  public appAuthUser: AppAuthUser = null;

  constructor(
    private vehicleService: VehicleService,
    private authenticationService: AuthenticationService,
    private languageService: LanguageService) {
    this.appAuthUser = this.authenticationService.appAuthUser;
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<Vehicle | ResponseError> {
    const vin = route.params['vin'];
    const identityId = this.appAuthUser.identityId;

    const request = new VehicleInfomationRequest;
    request.identityId = identityId;
    return this.vehicleService.getVehicle(vin, request, true).pipe(
      catchError(err => {
        const error = err as ResponseError;
        if (error.key === 'resourceNotFound') {
          this.languageService.localizedNavigate(['/dashboard']);
          return of(null);
        }
        return throwError(err);
      })
    );
  }
}
