import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ThemeComponent } from '@shared/components/theme.component';
import { ThemeService } from '@shared/services/theme-service.service';
import { Vehicle } from '../../_models';
import { VehicleService } from '../../_services/vehicle.service';
import { AlertService, ConfigService, AuthenticationService, IdentificationValidationContextService, LanguageService } from '@shared/services/index';
import { ResponseError, BaseResponse } from '@shared/models';
import { IdentificationValidation } from '../../../user/_models/identification-validation';
import { IdentificationValidationStatus } from '../../../user/_models/identification-validation-status';
import { IdentificationValidationService } from '../../../user/_services/identification-validation.service';
import { UserContextService } from '@shared/services/user-context.service';
import { Logger } from '@shared/logging/Logger';
import { TeaserResponse } from '../../../vehicles/_models/teaser-response';
import { TeaserEligibilityService } from './../../../registration/_services/teaser-eligibility.service';
import { ProductService } from '../../../product/_services';
import { ProductCheckoutStatus, IApplyPriceTeaserRequest } from '../../../product/_models';
import { NgxSpinnerService } from 'ngx-spinner';
import { TranslateService } from '@ngx-translate/core';
import { VehicleImagesUrlService } from '@app/vehicles/_services/vehicle-images-url.service';

@Component({
  selector: 'app-vehicle-details-view',
  templateUrl: './vehicle-details-view.component.html',
  styleUrls: ['./vehicle-details-view.component.scss']
})
export class VehicleDetailsViewComponent extends ThemeComponent implements OnInit {
  @Input() vehicle: Vehicle;
  @Output() isVehicleDetailsReadyEvt = new EventEmitter<boolean>();

  redirectToVideoValidation = false;
  disabled = true;
  hasLiableUser = false;
  identityIsBlocked = false;
  showAddIdentification = false;
  showAddAddress = false;
  showPendingApproval = false;
  showRetryPersonalDetails = false;
  showRetryPoorDocuments = false;
  showRegisterPendingLiableUser = false;
  showVideoPendingApproval = false;
  completedMessage = false;
  completeRegistrationForTeaserMessage = false;
  videoRedirectUrl: string;
  favourite: boolean;
  showChangePersonalisedName: boolean;
  vehiclePersonalisedNameForm: UntypedFormGroup;
  vehicleSetFavouriteForm: UntypedFormGroup;

  isBrandEligible = false;
  resellerCode: string;
  vin: string;
  teaserPlans: TeaserResponse;
  isEligibleForTeaser: boolean;

  allowBuyNow = true;
  isProductCheckoutReady = false;
  packageSku: string;

  alertInvalidInput = 'Product_Checkout_Invalid_Input';

  // CONTEXT
  // productCheckoutContext: ProductCheckoutContext = new ProductCheckoutContext();

  private userId: string;
  private countryIsoCode: string;
  public showVehicleImage = false;
  public showFavNameEditor = false;
  // Translations keys
  // alerts
  alertInvalidRequirements = 'Vehicle_Details_Invalid_Requirements';
  alertAccountReady = 'Vehicle_Details_Account_Ready';
  // identification validation
  alertValidationRejected = 'Identification_Validation_Rejected';
  alertValidationIdentityBlocked = 'Identification_Validation_Identity_Blocked';
  alertValidationRejectedGeneric = 'Identification_Validation_Rejected_Generic_Message';

  constructor(
    _themeService: ThemeService,
    private languageService: LanguageService,
    private fb: UntypedFormBuilder,
    private vehicleService: VehicleService,
    private spinnerService: NgxSpinnerService,
    private alertService: AlertService,
    private productService: ProductService,
    private identificationValidationService: IdentificationValidationService,
    private userContextService: UserContextService,
    private configService: ConfigService,
    private translationsService: TranslateService,
    private logger: Logger,
    private teaserEligibilityService: TeaserEligibilityService,
    private identificationValidationContextService: IdentificationValidationContextService,
    private vehicleImagesUrlService: VehicleImagesUrlService) {
    super(_themeService);
    // Populate translated messages
    this.getTranslatedMessages();
  }

  ngOnInit() {
    this.showVehicleImage = this.configService.config.showVehicleImage;
    if (this.vehicle !== undefined) {
      this.userId = this.userContextService.userContext.userId;
      if (this.userId === null) {
        this.alertService.error(this.alertInvalidRequirements, 0, true, true);
        this.languageService.localizedNavigate(['/']);
        return;
      }
      this.createPersonalisedNameForm();
      this.createSetFavouriteForm();
      this.countryIsoCode = this.userContextService.userContext.homeCountryCode;
      this.hasLiableUser = this.vehicle.hasLiableUser;
      this.vin = this.vehicle.vin;

      // If there is no liable user, than the address/country needs to exist
      if (!this.hasLiableUser || !this.identificationValidationContextService.context.isValidIdentification) {
        if (this.countryIsoCode === null || undefined) {
          this.showAddAddress = true;
          this.emitVehicleDetailsReadyEvt(true);
          return;
        }
        const identificationSubmitted = this.identificationValidationContextService.context.isIdentificationSubmitted;
        if (identificationSubmitted !== null && identificationSubmitted !== undefined && identificationSubmitted) {
          this.checkForValidationUpdate();
          return;
        }
        this.validateIdentificationValidation();
      } else {
        this.emitVehicleDetailsReadyEvt(true);
      }
    }

    // Check if the VIN is eligible for a teaser plan
    this.getTeaserPlans();
  }

  /* Favourite Vehicle */
  createSetFavouriteForm() {
    this.vehicleSetFavouriteForm = this.fb.group({
      isFavourite: [this.vehicle.isFavourite, Validators.required]
    });
  }

  prepareFavouriteVehicle(): Vehicle {
    const vehicleData = new Vehicle();
    vehicleData.vin = this.vehicle.vin;
    return vehicleData;
  }

  favouriteVehicle(value: boolean) {
    this.spinnerService.show();
    const checked = value ? true : false;
    this.vehicleService.setAsFavourite(this.prepareFavouriteVehicle(), checked)
      .subscribe(
        (data: Vehicle) => {
          this.spinnerService.hide();
        },
        (err: ResponseError) => {
          this.spinnerService.hide();
          this.alertService.error(err.message, 0, true, false);
          this.logger.error(err);
        },
        () => { this.spinnerService.hide(); }
      );
  }

  /* Personalised Name */
  createPersonalisedNameForm() {
    this.disabled = true;
    this.vehiclePersonalisedNameForm = this.fb.group({
      personalisedName: [this.vehicle.personalisedName, Validators.required]
    });
  }

  preparePersonalisedName(name: string): Vehicle {
    const vehiclePersonalisedNameData = new Vehicle();
    vehiclePersonalisedNameData.personalisedName = name;
    vehiclePersonalisedNameData.vin = this.vehicle.vin;
    return vehiclePersonalisedNameData;
  }

  changePersonalisedName(name: string) {
    this.spinnerService.show();

    this.vehicleService.setPersonalisedName(this.preparePersonalisedName(name))
      .subscribe(
        (data: Vehicle) => {
          this.spinnerService.hide();
          this.showChangePersonalisedName = false;
          this.showFavNameEditor = false;
        },
        (err: ResponseError) => {
          this.spinnerService.hide();
          if (err instanceof AuthenticationService) {
            return;
          }
          this.alertService.error(err.message, 0, true, false);
          this.logger.error(err);
          this.showFavNameEditor = false;
        },
        () => { this.spinnerService.hide(); this.showFavNameEditor = false; }
      );
  }

  /* Identification Validation */

  private prepareIdentificationValidation(): IdentificationValidation {
    const request = new IdentificationValidation();
    request.isoCode = this.countryIsoCode;
    request.userId = parseInt(this.userId, 10);
    request.vin = this.vehicle.vin;
    return request;
  }

  // TODO: identification-service
  private validateIdentificationValidation() {
    this.alertService.clear();
    this.spinnerService.show();

    this.identificationValidationService.getIdentificationValidation
      (this.prepareIdentificationValidation())
      .subscribe(
        (data: IdentificationValidation) => {
          if (data.isIdentificationProofNeeded) {
            this.handleIdentificationStatus(data, true);
          } else {
            this.updateIsValidIdentification(true);
            this.canPromoteToPendingLiableUser();
          }
          this.spinnerService.hide();
          this.emitVehicleDetailsReadyEvt(true);
        },
        (err: ResponseError) => {
          this.spinnerService.hide();
          if (err instanceof AuthenticationService) {
            return;
          }
          this.emitVehicleDetailsReadyEvt(true);
          this.alertService.error(err.message, 0, true, false);
          this.logger.error(err);
        },
        () => { this.spinnerService.hide(); }
      );
  }

  // TODO: identification-service
  protected checkForValidationUpdate() {
    this.alertService.clear();
    this.spinnerService.show();
    this.identificationValidationService.checkForValidationUpdate
      (this.prepareIdentificationValidation())
      .subscribe(
        (data: IdentificationValidation) => {
          if (data.isIdentificationProofNeeded) {
            this.handleIdentificationStatus(data, false);
          } else {
            this.updateIsValidIdentification(true);
            this.canPromoteToPendingLiableUser();
          }
          this.spinnerService.hide();
          this.emitVehicleDetailsReadyEvt(true);
        },
        (err: ResponseError) => {
          if (err instanceof AuthenticationService) {
            return;
          }
          this.spinnerService.hide();
          this.emitVehicleDetailsReadyEvt(true);
          this.logger.error(err);
        },
        () => { this.spinnerService.hide(); }
      );
  }

  private handleIdentificationStatus(data: IdentificationValidation, isFirstTime: boolean) {
    switch (data.identificationValidationStatus) {
      case IdentificationValidationStatus.VideoValidationRedirect:
        this.showAddIdentification = true;
        this.redirectToVideoValidation = true;
        break;
      case IdentificationValidationStatus.PendingUpload:
        if (isFirstTime) {
          this.updateIsIdentificationSubmitted(true);
        }
        this.showAddIdentification = true;
        this.redirectToVideoValidation = true;
        this.updateIsValidIdentification(false);
        break;
      case IdentificationValidationStatus.PendingApproval:
        this.showPendingApproval = true;
        this.showVideoPendingApproval = data.isVideoValidation ? true : false;
        this.redirectToVideoValidation = true;
        this.updateIsValidIdentification(false);
        break;
      case IdentificationValidationStatus.ValidationRejected:
        this.alertService.error(this.alertValidationRejected, 0, true, false);
        this.updateIsValidIdentification(false);
        break;
      case IdentificationValidationStatus.ValidationApproved:
        this.canPromoteToPendingLiableUser();
        this.updateIsIdentificationSubmitted(null);
        this.updateIsValidIdentification(true);
        this.languageService.localizedNavigate(['/dashboard/vehicle', this.vehicle.vin]);
        break;
      case IdentificationValidationStatus.IdentityCheckBlocked:
        this.alertService.error(this.alertValidationIdentityBlocked, 0, true, false);
        this.identityIsBlocked = true;
        this.updateIsValidIdentification(false);
        this.updateIdentificationValidationContext(data.identificationValidationStatus);
        break;
      case IdentificationValidationStatus.RejectedIdentificationProofUserData:
        this.updateIsValidIdentification(false);
        this.updateIdentificationValidationContext(data.identificationValidationStatus, data.dataFields);
        this.redirectToVideoValidation = true;
        this.showRetryPersonalDetails = true;
        break;
      case IdentificationValidationStatus.RejectedIdentificationProofDocument:
        this.updateIsValidIdentification(false);
        this.updateIdentificationValidationContext(data.identificationValidationStatus, data.dataFields);
        this.redirectToVideoValidation = true;
        this.showRetryPoorDocuments = true;
        break;
      case IdentificationValidationStatus.ValidationAborted:
        this.updateIsValidIdentification(false);
        this.updateIdentificationValidationContext(data.identificationValidationStatus);
        this.showAddIdentification = true;
        this.redirectToVideoValidation = true;
        break;
      default:
        this.alertService.warn(this.alertValidationRejectedGeneric, 0, true, false);
        this.updateIsValidIdentification(false);
        this.updateIdentificationValidationContext(data.identificationValidationStatus);
        break;
    }
  }

  redirectToAddIdentification() {
    if (this.redirectToVideoValidation) {
      this.languageService.localizedNavigate(['/dashboard/submitIdentity']);
    }
  }

  private updateIsValidIdentification(value: boolean) {
    this.identificationValidationContextService.context.isValidIdentification = value;
    this.identificationValidationContextService.update();
  }

  private updateIsIdentificationSubmitted(value: any) {
    this.identificationValidationContextService.context.isIdentificationSubmitted = value;
    this.identificationValidationContextService.update();
  }

  private getTranslatedMessages() {
    // alerts
    this.alertInvalidRequirements = this.translationsService.instant(this.alertInvalidRequirements);
    this.alertAccountReady = this.translationsService.instant(this.alertAccountReady);
    // identification validation
    this.alertValidationRejected = this.translationsService.instant(this.alertValidationRejected);
    this.alertValidationIdentityBlocked = this.translationsService.instant(this.alertValidationIdentityBlocked);
    this.alertValidationRejectedGeneric = this.translationsService.instant(this.alertValidationRejectedGeneric);

  }

  public enableFavNameEditor() {
    this.showFavNameEditor = true;
  }

  public disableFavNameEditor() {
    this.showFavNameEditor = false;
  }

  private emitVehicleDetailsReadyEvt(value: boolean) {
    this.isVehicleDetailsReadyEvt.emit(value);
  }

  private canPromoteToPendingLiableUser() {
    this.vehicleService.canPromoteToPendingLiableUser(this.vehicle.vin).subscribe(
      response => {
        if (response) {
          this.showRegisterPendingLiableUser = true;
        }
      }
    );
  }

  redirectToRetryIdentificationValidation() {
    if (this.showRetryPersonalDetails === true || this.showRetryPoorDocuments === true) {
      this.languageService.localizedNavigate(['/dashboard/resubmit']);
    }
  }

  private updateIdentificationValidationContext(status: IdentificationValidationStatus, dataFields: string[] = null) {
    this.identificationValidationContextService.context.dataFields = dataFields;
    this.identificationValidationContextService.context.status = status;
    this.identificationValidationContextService.context.vin = this.vehicle.vin;
    this.identificationValidationContextService.update();
  }

  // Method that checks B2C API If a VIN is eligible for a teaser
  private getTeaserPlans() {
    this.teaserEligibilityService.getTeasers(this.vin, this.countryIsoCode)
      .subscribe(
        (data: TeaserResponse) => {
          this.teaserPlans = data;
          // Get the correct package code for teaser plan that will be applied
          if (data.teaserPlans.length > 0) {
            this.packageSku = data.teaserPlans[0].packageSku;
          }

          // Set eligibility boolean based on whether a teaser plan is retrieved
          this.isEligibleForTeaser = this.teaserPlans.teaserPlans.length > 0;

          // Check whether to display Teaser Eligibility message, for users that have not completed all registration steps
          this.showCompleteRegistrationMessage();
        }
      );
  }

  // Based on brand and eligibility, the completeRegistrationForTeaserMessage will display on the dashboard
  // for users that have created an account and have not completed all registration steps
  // I.e. Addition of address, ID verification, register as pending liable user.
  // Need to update if Audi run teaser
  showCompleteRegistrationMessage() {
    let resellerCode = this.configService.config.resellerCode;
    this.isBrandEligible = this.vehicleService.isBrandTeaserEligible(resellerCode);
    if (this.isBrandEligible)
      this.completeRegistrationForTeaserMessage = this.isEligibleForTeaser;
  }

  submitProductCheckout() {
    this.alertService.clear();
    this.allowBuyNow = false;

    // VALIDATE PRODUCT READY
    this.isProductCheckoutReady = true;

    // LAST STEP IS TO MAKE SURE THE USER STATUS IS UP TO DATE TO BUY THE PLAN
    // This is always true as line 492! (I.D.)
    if (this.isProductCheckoutReady) {
      this.isProductCheckoutStatusValid();
    } else {
      this.allowBuyNow = true;
      this.showInvalidRequestMessage();
      return;
    }
  }

  // If valid request to apply price teaser
  private isProductCheckoutStatusValid() {
    this.vehicleService.forceRefreshVehicleUserStatus(this.vin)
      .subscribe(
        (res) => {
          // SUBMIT
          // This is always true as line 492! (I.D.)
          if (this.isProductCheckoutReady) {
            this.applyTeaserPackage();
          } else {
            this.showInvalidRequestMessage();
            return;
          }
        }
      );
  }

  private applyTeaserPackage() {
    this.alertService.clear();
    this.spinnerService.show();

    this.productService.applyTeaserPackage(this.setApplyPriceTeaserRequest())
      .subscribe(
        (data: BaseResponse<any>) => {
          // If OK set user to be AsLiableUser
          this.updateProductCheckoutStatus(ProductCheckoutStatus.AsLiableUser);

          this.spinnerService.hide();
          this.languageService.localizedNavigate(['/dashboard'], { queryParams: { forceDataRefresh: true } });
        },
        (err: BaseResponse<any | ResponseError>) => {
          this.handleBaseResponseObject(err);
        },
        () => {
          this.spinnerService.hide();
        });
  }

  private setApplyPriceTeaserRequest(): IApplyPriceTeaserRequest {
    return {
      'packageSku': this.packageSku,
      'vin': this.vin,
    };
  }

  private updateProductCheckoutStatus(productCheckoutStatus: ProductCheckoutStatus) {
    this.userContextService.userContext.productCheckoutStatus = productCheckoutStatus;
    this.userContextService.update();
  }

  private handleBaseResponseObject(res: BaseResponse<any | ResponseError>) {
    this.allowBuyNow = true;
    this.spinnerService.hide();
    const err = res.errors;
    this.logger.error(err);

    // In case something wrong with applying teaser, show error in dashboard
    this.alertService.error(err.message, 5, true, true);
    this.languageService.localizedNavigate(['/dashboard'], { queryParams: { forceDataRefresh: true } });
  }

  private showInvalidRequestMessage() {
    this.alertService.error(this.alertInvalidInput, 5, true, false);
  }

  // When completeRegistrationForTeaserMessage has been clicked, we want the message to disappear
  // so that users can continue with finishing registration steps
  removeCompleteRegistrationMessage() {
    // this.showRegisterPendingLiableUser = true;
    this.completeRegistrationForTeaserMessage = false;
    this.completedMessage = true;
  }

  vehicleImageLoadError() {
    if (this.vehicle) {
      const defaultImage = this.vehicleImagesUrlService.getDefaultImageUrl();

      if (this.vehicle.vehiclePhotoImage !== defaultImage) {
        this.vehicle.vehiclePhotoImage = defaultImage;
      }
    }
  }
}
