<app-page-header [title]="title" [btnText]="btnText" (clickEvt)="redirectToAddVehicle()">
</app-page-header>
<div *ngIf="isVehicleRequestReady">
  <div *ngIf="vehicles?.length > 0">
    <div class="row">
      <div *ngFor="let vehicle of vehicles" class="col-12 col-md-6">
        <app-vehicle-details-card [vehicle]="vehicle"
          [attr.data-test-id]="'dashboard-vehicle-details-card-vin-' + vehicle.vin"></app-vehicle-details-card>
      </div>
    </div>
  </div>
  <div *ngIf="vehicles?.length === 0">
    <p data-test-id="dashboard-view-no-vehicle" class="no-vehicles">{{'Dashboard_View_No_Vehicle' | translate }}</p>
  </div>
</div>