<div class="usage-history-card">
  <strong data-test-id="dashboard-usage-history-title" class="header">{{'Usage_History_Title' | translate }}</strong>
  <div class="usage-history-delimiter">
    <div class="row">
      <div data-test-id="dashboard-usage-history-last7days" class="col-6 border-right"
        [innerHTML]="'Dashboard_Usage_History_Last7days' | translate: {lastsevendaysMB: lastsevendaysMB}">
      </div>
      <div data-test-id="dashboard-usage-history-today" class="col-6"
        [innerHTML]="'Today' | translate: {todayMB: todayMB}">
      </div>
    </div>
  </div>
  <div data-test-id="dashboard-usage-history-chart" [hidden]="noUsageHistory">
    <canvas #usageHistoryChart></canvas>
    <div class="usage-history-delimiter"></div>
  </div>
  <div class="card-button">
    <div class="d-flex justify-content-center">
      <a data-test-id="dashboard-usage-history-viewmore"
        class="btn btn-primary" (click)="goToUsageHistory()">{{'View_More' |
        translate}}</a>
    </div>
  </div>
</div>