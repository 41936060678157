import { Injectable } from '@angular/core';
import { LanguageService } from './language.service';
import { NgbDateParserFormatter, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment'
import { DatePipe } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class DatesUtilityService extends NgbDateParserFormatter {
  public daysOfTheWeekAbbv = new Array<string>();
  private calendarWeekdays: string[];
  private calendarMonths: string[];
  _today = new Date();
  daysOfTheWeek = new Array<Date>();

  // Date of Birth
  months: any[] = [];
  days: string[] = [];
  years: string[] = [];

  constructor(
    private languageService: LanguageService,
    private translationsService: TranslateService) {
    super();
    this.calendarMonths = new Array<string>();
    this.calendarMonths = this.getMonthsAbbv();
  }

  public getDaysOfTheWeekAbbv(dayformat: string): string[] {
    moment.updateLocale(this.languageService.getActiveLanguage().isoCode, {
      week: {
        dow: 1, // Makes Monday the first day of the week.
      }
    });

    return moment.weekdaysShort(true)
  }

  public getLocalDateString(date: Date): string {
    let isoCode = this.languageService.getActiveLanguage().isoCode
    moment.locale(isoCode);
    return moment(date).format(this.getDateStringFormatByLanguage(isoCode));
  }

  public getDateStringFormatByLanguage(lang: string): string {
    switch (lang) {
      // FTP-6191: Specific date format for Czechia request from Skoda
      case 'cs':
        return 'DD. MMMM YYYY h:mm:ss A [GMT]Z';
      default:
        return 'DD MMMM YYYY h:mm:ss A [GMT]Z';
    }
  }

  public getMonthsAbbv(): string[] {
    return moment.localeData(this.languageService.getActiveLanguage().isoCode).monthsShort();
  }
  public getMonthsFullName(): string[] {

    return moment.localeData(this.languageService.getActiveLanguage().isoCode).months();
  }
  private getCulture() {
    const browserCulture = this.translationsService.getBrowserCultureLang();
    return browserCulture ? browserCulture : 'en';
  }

  format(date: NgbDateStruct): string {
    if (date === null) {
      return '';
    }
    try {
      const datePipe = this.getDatePipeWithCurrentCulture();
      return datePipe.transform(new Date(date.year, date.month - 1, date.day), 'longDate');
    } catch (e) {
      return '';
    }
  }

  parse(value: string): NgbDateStruct {
    let returnVal: NgbDateStruct;
    if (!value) {
      returnVal = null;
    } else {
      try {
        const datePipe = this.getDatePipeWithCurrentCulture();
        const dateParts = datePipe.transform(value, 'M-d-y').split('-');
        returnVal = { year: parseInt(dateParts[2], 10), month: parseInt(dateParts[0], 10), day: parseInt(dateParts[1], 10) };
      } catch (e) {
        returnVal = null;
      }
    }
    return returnVal;
  }

  getDatePipeWithCurrentCulture() {
    return new DatePipe(this.getCulture());;
  }

  getWeekdayShortName(weekday: number) {
    this.calendarWeekdays = this.getDaysOfTheWeekAbbv('short');
    return this.calendarWeekdays[weekday - 1];
  }

  getMonthShortName(month: number) {

    this.calendarMonths = new Array<string>();
    this.calendarMonths = this.getMonthsAbbv();
    return this.calendarMonths[month - 1];
  }

  getLocalisedDateFromUtc(date: Date) {
    let dateObject = new Date(date);
    if (!dateObject) {
      return null;
    }

    let utcMilliseconds = Date.UTC(dateObject.getFullYear(), dateObject.getMonth(), dateObject.getDate(),
      dateObject.getHours(), dateObject.getMinutes(), dateObject.getSeconds());
    return new Date(utcMilliseconds);
  }

  getMonthFullName(month: number) {
    return this.getMonthShortName(month);
  }

  getDayAriaLabel(date: NgbDateStruct): string {
    return `${date.day}-${date.month}-${date.year}`;
  }

  // DOB Settings
  populateMonthsList() {

    if (this.months.length === 0) {

      this.months.push({ key: '01', value: this.getMonthsAbbv()[0] });
      this.months.push({ key: '02', value: this.getMonthsAbbv()[1] });
      this.months.push({ key: '03', value: this.getMonthsAbbv()[2] });
      this.months.push({ key: '04', value: this.getMonthsAbbv()[3] });
      this.months.push({ key: '05', value: this.getMonthsAbbv()[4] });
      this.months.push({ key: '06', value: this.getMonthsAbbv()[5] });
      this.months.push({ key: '07', value: this.getMonthsAbbv()[6] });
      this.months.push({ key: '08', value: this.getMonthsAbbv()[7] });
      this.months.push({ key: '09', value: this.getMonthsAbbv()[8] });
      this.months.push({ key: '10', value: this.getMonthsAbbv()[9] });
      this.months.push({ key: '11', value: this.getMonthsAbbv()[10] });
      this.months.push({ key: '12', value: this.getMonthsAbbv()[11] });
    }

    return this.months;
  }

  populateDaysList(): string[] {

    if (this.days.length === 0) {
      for (let d = 1; d <= 31; d++) {
        this.days.push(d.toString());
      }
    }

    return this.days;
  }

  populateYearsList(date: Date): string[] {

    if (this.years.length === 0) {
      const currentYear = date.getFullYear();
      const initial = 1919;
      for (let y = initial; y <= currentYear; y++) {
        this.years.push(y.toString());
      }
    }

    return this.years;
  }

  setDobFormattedDate(year, month, day): string {
    return year.toString() + '-' + month.toString() + '-' + day.toString();
  }

  validateDateOfBirth(day, month, year): boolean {
    const currentDate = new Date();
    const selectedDate = new Date(year, month - 1, day);
    if (selectedDate > currentDate) {
      return false;
    }
    return true;
  }

  public getDaysOfTheWeekByRange(dates: string[]): string[] {
    return dates.map(day => new Date(day).toLocaleString(this.languageService.getActiveLanguage().isoCode, { weekday: 'short' }));
  }

  public getDatesListByRange(fromDate: Date, toDate: Date): string[] {
    let currentDate = fromDate;
    const dates: Date[] = [];
    while (currentDate < toDate) {
      dates.push(currentDate);
      currentDate = this.addDays(1, currentDate);
    }
    dates.push(toDate);
    return dates.map(date => this.getFormattedDate(date));

  }

  public getFormattedDate(date: Date) {
    const year = date.getFullYear().toString();
    const month = ((date.getMonth() + 1) < 10 ? '0' : '') + (date.getMonth() + 1);
    const daynumber = date.getDate();
    const day = daynumber < 10 ? '0' + daynumber.toString() : daynumber.toString();
    return year.concat('-').concat(month).concat('-').concat(day);
  }

  private addDays(days: number, currentDate: Date) {
    const date = new Date(currentDate);
    date.setDate(date.getDate() + days);
    return date;
  }
}
