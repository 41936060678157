import { Component, Input } from '@angular/core';
import { Step } from '@shared/models/step';

@Component({
  selector: 'app-step',
  templateUrl: './step.component.html',
  styleUrls: ['./step.component.scss']
})
export class StepComponent {

  @Input() public steps: Step[];
}
