<div class="plan-card-container">
  <div class="row text-center justify-content-center no-gutters">
    <div class="col">
      <h3 class="days" [attr.data-test-id]="'product-card-days-label-'+product.packageCode">
        <div *ngIf="product.packageValidityUnits === staticDurationUnitDay; else useDurationUnitFromDB">
          {{product.packageValidityNumber }}
          {{(product.packageValidityNumber === 1 ? 'Product_Card_DayUnit' : 'Product_Card_DayUnit_Plural') | translate}}
        </div>
        <ng-template #useDurationUnitFromDB>
          {{product.packageValidityNumber}} {{product.packageValidityUnits}}
        </ng-template>
      </h3>
      <div *ngIf="product.packageCode.includes('sbx')" style="color:red; font-size: large; ">
        SANDBOX
      </div>
      <div class="data" [attr.data-test-id]="'product-card-data-label-'+product.packageCode">
        {{product.packageSizeNumber}}{{product.packageSizeUnits}}</div>
      <div class="plan-delimiter"></div>
      <div class="price" [attr.data-test-id]="'product-card-price-label-'+product.packageCode">
        {{product.packageCurrencyCode | currencySign}} {{product.packageGrossPrice }}
      </div>
      <div class="vat" [attr.data-test-id]="'product-card-vat-label-'+product.packageCode">{{'Product_Card_IncludesVAT'
        | translate }}</div>
      <div class="position-relative">
        <ngx-loading [show]="loading"></ngx-loading>
        <div *ngIf="loading === false">
          <button type="submit" class="btn btn-primary buy-plan" [disabled]="!enableBuyPlan" (click)="goToCheckout()"
            [attr.data-test-id]="'product-card-buy-plan-btn-'+product.packageCode">{{'Buy_Plan_Button' | translate
            }}</button>
        </div>
      </div>
    </div>
  </div>
</div>