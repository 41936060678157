<div class="row justify-content-between">
  <div class="col-12 col-md-6 col-lg-7" [ngClass]="{'order-md-12' : isImageOnLeft}">
    <div class="upsell-text">
      <h2 *ngIf="title" [attr.data-test-id]="testIdPrefix + '-upsell-title'" [title]="title | translate">{{title |
        translate}}</h2>
      <p *ngFor="let paragraph of paragraphs; let i = index"
        [attr.data-test-id]="testIdPrefix + '-upsell-paragraph-' + [i+1]" [title]="paragraph | translate">{{paragraph |
        translate}}</p>
    </div>
  </div>
  <div class="col-12 col-md-6 col-lg-4" [ngClass]="[ isImageOnLeft ? 'no-right-padding-lg' : 'no-left-padding-lg']">
    <img *ngIf="imageSrc" [src]="imageSrc" [attr.alt]="imageAlt | translate"
      [attr.data-test-id]="testIdPrefix + '-upsell-paragraph-1'" title="image" />
  </div>
</div>