import { IGetOrderResponse } from '@app/product/_models/get-order-response';
import { Vehicle } from '@app/vehicles/_models';

export class ProductCheckoutHppContext {
    order: IGetOrderResponse;
    vehicle: Vehicle;

    // For Order Confirmation Page
    showConfirmationAlert = false;
    confirmationAlertMessage = '';

    // For payment failure messages on payment page
    showPaymentErrorAlert = false;
    paymentFailureAlertMessage = '';

    constructor(values = {}) {
        Object.assign(this, values);
    }
}
