import { Component } from '@angular/core';
import { ThemeComponent } from '@shared/components/theme.component';
import { ThemeService } from '@shared/services/theme-service.service';

@Component({
  selector: 'app-our-believers',
  templateUrl: './our-believers.component.html',
  styleUrls: ['./our-believers.component.scss']
})
export class OurBelieversComponent extends ThemeComponent {

  constructor(_themeService: ThemeService) {
    super(_themeService);
  }

}
