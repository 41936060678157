<div class="step-container">
  <div class="container">
    <div class="row">
      <div class="col-12">
        <ul class="list-inline d-flex justify-content-center m-0">
          <li class="list-inline-item d-inline-flex align-items-center step-item"
            *ngFor="let step of steps; index as i"
            [ngClass]="{marked: step.isMarked}">
            <span class="badge step-badge">{{i + 1}}</span>
            <span class="step-item-name" *ngIf="step.isActive">{{step.title | translate }}</span>
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>