import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TeaserEligibilityService } from '@app/registration/_services/teaser-eligibility.service';
import { Teaser } from '@app/vehicles/_models';
import { VerifyVehicle } from '@app/vehicles/_models/verify-vehicle';
import { TeaserResponse } from '@app/vehicles/_models/teaser-response';
import { NgxSpinnerService } from 'ngx-spinner';
import { VehicleImagesUrlService } from '@app/vehicles/_services/vehicle-images-url.service';

@Component({
    selector: 'app-vehicle-verification-details',
    templateUrl: './vehicle-verification-details.html',
    styleUrls: ['./vehicle-verification-details.scss']
})
export class VehicleVerificationDetailsComponent implements OnInit {

    @Input()
    currentVehicle: VerifyVehicle;

    @Output() retryClick = new EventEmitter();
    @Output() continueClick = new EventEmitter<Teaser | null>();

    showPriceTeaserSection: boolean;
    vehicleTeaser: Teaser;

    constructor(private teaserEligibilityService: TeaserEligibilityService,
        private spinnerService: NgxSpinnerService,
        private vehicleImagesUrlService: VehicleImagesUrlService) {
    }

    ngOnInit(): void {
        if (this.currentVehicle) {
            this.vehicleTeaser = this.currentVehicle.teaser;
            if (this.currentVehicle.IsVerified) {
                this.showPriceTeaserSection = true;
            }
        }
    }

    vehicleImageLoadError() {
        if (this.currentVehicle) {
            const defaultImage = this.vehicleImagesUrlService.getDefaultImageUrl();

            if (this.currentVehicle.vehicle.vehiclePhotoImage !== defaultImage) {
                this.currentVehicle.vehicle.vehiclePhotoImage = defaultImage;
            }
        }
    }

    retry() {
        this.retryClick.emit();
    }

    vehicleConfirmation() {
        this.spinnerService.show();

        this.teaserEligibilityService.getTeasers(this.currentVehicle.vehicle.vin, null)
            .subscribe(
                (data: TeaserResponse) => {
                    this.spinnerService.hide();
                    this.showPriceTeaserSection = true;
                    if (data.teaserPlans && data.teaserPlans.length > 0) {
                        this.vehicleTeaser = data.teaserPlans[0];
                    } else {
                        this.vehicleTeaser = null;
                    }
                },
                () => { this.spinnerService.hide(); }
            );
    }

    continue() {
        this.continueClick.emit(this.vehicleTeaser);
    }
}
