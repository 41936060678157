import { Injectable } from '@angular/core';
import { DataService } from '@shared/services/data.service';
import { SessionKeys, RegistrationContext } from '@shared/models';
import { TeaserEligibilityContext } from '@shared/models/teaser-eligibility-context';

@Injectable()
export class RegistrationContextService {

    public registrationContext: RegistrationContext = new RegistrationContext();
    public teaserEligibilityContext: TeaserEligibilityContext = new TeaserEligibilityContext();

    constructor(private dataService: DataService) {
        this.getRegistrationContext();
        this.getTeaserEligibilityContext();
    }

    public setRegistrationContext(context: RegistrationContext) {
        this.registrationContext = context;
        this.setSessionValues();
    }

    public setTeaserEligibilityContext(context: TeaserEligibilityContext) {
        this.teaserEligibilityContext = context;
        this.setTeaserEligibilityContextSessionValues();
    }

    public update() {
        this.dataService.setSession(SessionKeys.registrationContext, JSON.stringify(this.registrationContext));
    }

    public updateTeaserEligibilityContext() {
        this.dataService.setSession(SessionKeys.teaserEligibilityContext, JSON.stringify(this.teaserEligibilityContext));
    }

    public resetRegistrationContext() {
        this.registrationContext.firstName = '';
        this.registrationContext.lastName = '';
        this.registrationContext.email = '';
        this.registrationContext.identityId = '';
        this.registrationContext.vinAdded = false;
        this.registrationContext.newVehicle = null;
        this.registrationContext.teaser = null;
        this.registrationContext.address = null;
        this.registrationContext.dateOfBirth = '';
        this.registrationContext.acceptedTermsAndConditions = false;
        this.registrationContext.promoEmailSubscribe = false;

        this.dataService.removeSession(SessionKeys.registrationContext);
    }

    public resetTeaserEligibilityContext() {
        this.teaserEligibilityContext.teaser = null;
        this.dataService.removeSession(SessionKeys.teaserEligibilityContext);
    }

    private setSessionValues() {
        this.dataService.setSession(SessionKeys.registrationContext, JSON.stringify(this.registrationContext));
    }

    private setTeaserEligibilityContextSessionValues() {
        this.dataService.setSession(SessionKeys.teaserEligibilityContext, JSON.stringify(this.teaserEligibilityContext));
    }

    private getRegistrationContext() {
        const registration = this.dataService.getSession(SessionKeys.registrationContext);
        if (registration) {
            this.registrationContext = JSON.parse(registration) as RegistrationContext;
            return;
        }
    }

    private getTeaserEligibilityContext() {
        const teaserEligibility = this.dataService.getSession(SessionKeys.teaserEligibilityContext);
        if (teaserEligibility) {
            this.teaserEligibilityContext = JSON.parse(teaserEligibility) as TeaserEligibilityContext;
            return;
        }
    }
}
