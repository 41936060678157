
export interface IGetOrderResponse {
    orderId: number;
    orderNumber: string;
    orderTotal: number;
    netTotal: number;
    taxPercentage: number;
    taxTotal: number;
    salePrice: number;
    paymentCurrency: string;
    currencyFormat: string;
    creditCardReference: string;
    reseller: string;
    resellerId: number;
    identityId: string;
    userReferenceId: string;
    transactionReference: string;
    productName: string;
    dataUnits: string;
    dataAmount: string;
    packageSKU: string;
    date: string;
}
