import { Component, Input, Output, EventEmitter } from '@angular/core';
import { Tab } from '@shared/models';
import { ActivatedRoute } from '@angular/router';
import { LanguageService } from '@shared/services';

@Component({
  selector: 'app-tab',
  templateUrl: './tab.component.html',
  styleUrls: ['./tab.component.scss']
})

export class TabComponent {

  @Input() tab: Tab;
  @Input() vin: string;
  @Output() clickEvt = new EventEmitter<Tab>();

  constructor(private languageService: LanguageService, protected activatedRoute: ActivatedRoute) {
  }

  selectTab(selectedTab: Tab) {
    this.languageService.localizedNavigate([selectedTab.route], { relativeTo: this.activatedRoute });
    this.clickEvt.emit(selectedTab);
  }
}
