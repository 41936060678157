import { IResponseError } from '@shared/models/errors/iresponse-error';

export class ResponseError {
    code: string;
    key: string;
    message: string;
    moreInfo: string;
    redirectLink: string;

    constructor(obj?: IResponseError) {
        this.code = obj ? obj.code || '' : '';
        this.key = obj ? obj.key || '' : '';
        this.message = obj ? obj.message || '' : '';
        this.moreInfo = obj ? obj.moreInfo || 'no info available' : 'no info available';
        this.redirectLink = obj ? obj.redirectLink || '' : 'no info available';
    }

    public toString(): string {
        return 'Key: ' + this.key
            + '\nMessage: ' + this.message
            + '\nError code: ' + this.code
            + '\nMore Info: ' + this.moreInfo;
    }
}
