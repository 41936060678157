import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AccountActivate } from '../../_models/account-activate';
import { ResponseError, SessionKeys } from '@shared/models';
import { DataService, AlertService, LanguageService } from '@shared/services/index';
import { Logger } from '@shared/logging/Logger';
import { NgxSpinnerService } from 'ngx-spinner';
import { TranslateService } from '@ngx-translate/core';
import { AccountActivateService } from '@app/registration/_services/account-activate.service';

@Component({
  selector: 'app-account-activate-expired',
  templateUrl: './account-activate-expired.component.html',
  styleUrls: ['./account-activate-expired.component.scss']
})
export class AccountActivateExpiredComponent implements OnInit {

  email: string;

  constructor(
    private activatedRoute: ActivatedRoute,
    private languageService: LanguageService,
    private accountActivateService: AccountActivateService,
    private spinnerService: NgxSpinnerService,
    private alertService: AlertService,
    private dataService: DataService,
    private translationsService: TranslateService,
    private logger: Logger
  ) { }

  ngOnInit() {
    this.email = this.dataService.getSession(SessionKeys.email);
    if (this.email === null) {
      this.alertService.error(this.translationsService.instant('Account_Activate_Expired_Invalid_Requirements'), 0, true, true);
      this.languageService.localizedNavigate(['/'], { relativeTo: this.activatedRoute });
    }
  }

  prepareActivateAccountData(): AccountActivate {
    const accountResetPasswordData = new AccountActivate();
    accountResetPasswordData.email = this.email;
    return accountResetPasswordData;
  }

  resendActivationEmail() {
    this.alertService.clear();
    this.spinnerService.show();
    this.accountActivateService.resendActivationEmail(this.prepareActivateAccountData())
      .subscribe(
        (data: AccountActivate) => {
          this.languageService.localizedNavigate(['/'], { relativeTo: this.activatedRoute });
          this.dataService.removeSession(SessionKeys.email);
          this.spinnerService.hide();
        },
        (err: ResponseError) => {
          this.handleError(err);

        },
        () => { this.spinnerService.hide(); }
      );
  }

  private handleError(err: ResponseError) {
    this.spinnerService.hide();
    this.logger.error(err);
    switch (err.code) {
      case '10.16':
        this.alertService.error(err.message, 0, true, true);
        this.languageService.localizedNavigate(['/'], { relativeTo: this.activatedRoute });
        break;
      default:
        this.alertService.error(err.message, 0, true, false);
        break;
    }
  }
}
