import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { ResponseError, BaseResponse } from '@shared/models';
import { BaseHttpService, ConfigService } from '@shared/services';
import { Product } from '../_models/product';
import { Package } from '../_models/package';
import { map } from 'rxjs/operators';
import { IApplyPriceTeaserRequest } from '../_models';
import { TranslateService } from '@ngx-translate/core';
import { VehicleInfomationRequest } from '../../vehicles/_models/index';


@Injectable()
export class ProductService extends BaseHttpService {

    applyTeaserPackageUrl = '/teaser/applyTeaserPackage';

    constructor(
        _httpClient: HttpClient,
        _configService: ConfigService,
        _translateService: TranslateService,
    ) {
        super(_httpClient, _configService, _translateService);
    }

    /* POST*/
    getProducts(vin: string, request: VehicleInfomationRequest): Observable<Package | ResponseError> {
        return this.post<any>(`/v2/vehicles/${vin}/products`, request)
            .pipe(
                map(res => {
                    const productsDetails: Product[] = res.packages[0].packageDetails as Product[];
                    const countries: string[] = res.packages[0].countries;
                    const packageWithProducts = new Package();
                    packageWithProducts.countries = countries;
                    packageWithProducts.productsDetails = productsDetails;
                    return packageWithProducts;
                })
            );
    }

    applyTeaserPackage(request: IApplyPriceTeaserRequest): Observable<BaseResponse<any | ResponseError>> {
        return this.postBaseResponse<any>(this.applyTeaserPackageUrl, request)
            .pipe(
                map(res => res)
            );
    }
}
